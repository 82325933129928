
export const LandingFB = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
  >
    <path
      fill="#2F848F"
      d="M6.227 8.902H4.39c-.297 0-.39-.105-.39-.368v-2.12c0-.28.111-.368.39-.368h1.837V4.504c0-.7.13-1.366.502-1.98.39-.63.946-1.05 1.652-1.296A4.352 4.352 0 0 1 9.81 1h1.819c.26 0 .371.105.371.35v1.998c0 .245-.111.35-.371.35-.501 0-1.002 0-1.504.018-.5 0-.76.228-.76.718-.02.526 0 1.034 0 1.577h2.152c.297 0 .409.105.409.386v2.12c0 .28-.093.368-.409.368H9.364v5.712c0 .298-.093.403-.427.403h-2.32c-.278 0-.39-.105-.39-.368v-5.73Z"
    />
  </svg>
)
