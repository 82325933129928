import 'katex/dist/katex.min.css';
import Latex from 'react-latex';
import { ChangeEvent, Dispatch, ReactElement, ReactNode, SetStateAction, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ExcerciseAnswers, MainTestDto, MathDto } from '../../../../../shared/finalTest';
import "./latex.scss";
import { combineBlocks } from '../../shared/utilities/combiningBlocksOfTextIntoJsx/combiningBlocksOfTextIntoJsx';
import { useTranslation } from 'react-i18next';
import MediaQuery from "react-responsive";

interface LatexWithPlaceholdersProps {
    excerciseData: MathDto;
    modifyExerciseAnswers: (index: number, newAnswers: ExcerciseAnswers) => void;
    revealAnswer: boolean;
    modifyIsFinished: (index: number, finished: boolean) => void;
    currentExcercise: number;
    testDto: MainTestDto;
    notifyIfClicked: number;
}

export const LatexWithPlaceholders = (props: LatexWithPlaceholdersProps) => {
    const pattern = /<<([^>]+)>>/g;
    const squareLatex = '\\square';
    const [placeholders, setPlaceholders] = useState<string[]>([]);
    const [correctAnswers, setCorrectAnswers] = useState<string[]>([]);
    const [inputs, setInputs] = useState<ReactNode[]>();
    const [answerInputs, setAnswerInputs] = useState<ReactNode[]>();
    const [latex, setLatex] = useState("");
    const [currentLatex, setCurrentLatex] = useState("");
    const [correctAnswerLatex, setCorrectAnswerLatex] = useState("");
    const [squaresRendered, setSquaresRendered] = useState(false);
    const [squaresSpansIndexes, setSquaresSpansIndexes] = useState<Array<number>>([]);
    const [swappableIndices, setSwappableIndices] = useState<Array<number>>([]);
    const [userAnswerIcon, setUserAnswerIcon] = useState("check_box_outline_blank");
    const [isMobile, setIsMobile] = useState(false);
    const { t } = useTranslation();

    const handleInputsChange = (event: ChangeEvent<HTMLInputElement>, setFunction: Dispatch<SetStateAction<string[]>>, index: number) => {
        const filterLatexSpecificCharacters = (text: string) => {
            let result = text;
            result = result.replaceAll('{', "");
            result = result.replaceAll('}', "");
            result = result.replaceAll('^', "");
            result = result.replaceAll('_', "");
            result = result.replaceAll('&', "");
            result = result.replaceAll('%', "");
            result = result.replaceAll('$', "");
            result = result.replaceAll('#', "");
            result = result.replaceAll('\\', "");
            result = result.replaceAll(' ', "");

            return result;
        }
        
        const newArray = [...placeholders];
        const filtered = filterLatexSpecificCharacters(event.target.value);
        if(filtered){
            if(filtered.length <= 1)
                newArray[index] = event.target.value;
        } else {
            newArray[index] = squareLatex;
        }
        
        setFunction(newArray);

        props.modifyExerciseAnswers(props.currentExcercise, newArray);
    }

    const handleRevealAnswers = () => {
        if(placeholders.every(x => x !== squareLatex)){
            props.modifyIsFinished(props.currentExcercise, true);
        }
    }

    const getPlaceholderLabel = (index: number) => {
        return <><span className='long-label'>Miejsce </span>{index+1}. <span></span></>
    }

    useLayoutEffect(() => {
        function decodeHTMLEntities(str: string) {
            const parser = new DOMParser();
            const dom = parser.parseFromString('<!doctype html><body>' + str, 'text/html');
            return dom.body.textContent;
        }
        const latexDecoded = decodeHTMLEntities(props.excerciseData.latex);
        if(!latexDecoded){
            console.log("couldnt decode excercise");
            return;
        }

        
        const innerPattern = /<<(.?)>>/g;
        const fractionPattern = /\\frac{<<(.?)>>}{<<(.?)>>}/g;
        const powerPattern = /\^{<<(.?)>>}_{<<(.?)>>}/g;

        const findSwappableIndices = (str: string, pattern: RegExp, existingIndices: Array<number>) => {
            const allMatches = str.match(innerPattern);
            const fracMatches = str.match(pattern);
          
            const indices: number[] = [];
          
            if (fracMatches && allMatches) {
              fracMatches.forEach((match) => {
                const subMatches = match.match(innerPattern);
                if (subMatches) {
                  const numerator = subMatches[0];
          
                  let numeratorIndex = allMatches.indexOf(numerator);

                  if(existingIndices.includes(numeratorIndex) || indices.includes(numeratorIndex)){
                    let i=0;
                    for(let match of allMatches){
                        if(match === numerator){
                            if(!existingIndices.includes(i) && !indices.includes(i)){
                                numeratorIndex = i+1;
                                break
                            } else {
                                continue;
                            }
                        }
                        i++;
                    }
                  }
          
                  if (numeratorIndex !== -1) indices.push(numeratorIndex);
                }
              });
            }
          
            return indices;
        }

        const swappableIndices: Array<number> = [];

        const swappedFractions = latexDecoded.replaceAll(fractionPattern, (match, firstGroup, secondGroup) => {
            return `\\frac{<<${secondGroup}>>}{<<${firstGroup}>>}`;
        });

        // console.log(swappedFractions);
        const fractionPlaceholderIndices = findSwappableIndices(swappedFractions, fractionPattern, swappableIndices);
        swappableIndices.push(...fractionPlaceholderIndices);

        const swappedPowers = swappedFractions.replaceAll(powerPattern, (match, firstGroup, secondGroup) => {
            return `^{<<${secondGroup}>>}_{<<${firstGroup}>>}`;
        });

        const powerPlaceholderIndices = findSwappableIndices(swappedPowers, powerPattern, swappableIndices);
        swappableIndices.push(...powerPlaceholderIndices);

        setSwappableIndices(swappableIndices);

        const matches = [];
        let match;
        while ((match = pattern.exec(swappedPowers)) !== null) {
            matches.push(match[1]);
        }

        setCorrectAnswers(matches);

        const placeholders = [...matches].map((match, index) => squareLatex);
        setPlaceholders(placeholders);
        setLatex(latexDecoded);
        setCorrectAnswerLatex(latexDecoded);
    }, [])

    useEffect(() => {
        if(correctAnswers.length > 0){
            setAnswerInputs(correctAnswers.map((match, index) => 
                <div className="data-panel__latex__input-wrapper">
                    {getPlaceholderLabel(index)}
                    <input 
                        className={props.revealAnswer ? 'correct-answer--user-did-answer' : 'correct-answer--user-didnt-answer'}
                        key={index} 
                        type='text' 
                        value={
                            correctAnswers[index]
                        }
                /></div>
                )
            )
        }
    }, [correctAnswers, props.revealAnswer])

    useLayoutEffect(() => {
        const reactToFocus = (idx: number, isFocused: boolean) => {
            if(props.revealAnswer) return;
            if (rootRef.current && squaresRendered && squaresSpansIndexes.length >= idx) {                
                const spans = rootRef.current.querySelectorAll('span');

                let finalIndex = squaresSpansIndexes[idx]

                if(swappableIndices.includes(idx)){
                    finalIndex = squaresSpansIndexes[idx + 1];
                } else if(swappableIndices.includes(idx-1)){
                    finalIndex = squaresSpansIndexes[idx - 1];
                }

                if(!spans[finalIndex]) return;
                
                if(isFocused){
                    spans[finalIndex].style.color = 'var(--valid-color)';
                } else {
                    spans[finalIndex].style.color = 'black';
                }
            }
        }

        
        if(placeholders.length > 0){
            const onChangeFunction = props.revealAnswer ? (event: ChangeEvent<HTMLInputElement>, setPlaceholders: Dispatch<SetStateAction<string[]>>, index: number) => {} : (event: ChangeEvent<HTMLInputElement>, setPlaceholders: Dispatch<SetStateAction<string[]>>, index: number) => handleInputsChange(event, setPlaceholders, index);
            setInputs(placeholders.map((match, index) => 
                <div className="data-panel__latex__input-wrapper">
                {getPlaceholderLabel(index)}
                <input 
                    className={props.revealAnswer ? 'finished' : ''}
                    onFocus={() => reactToFocus(index, true)}
                    onBlur={() => reactToFocus(index, false)}
                    onMouseOver={() => reactToFocus(index, true)}
                    onMouseLeave={() => reactToFocus(index, false)}
                    key={index} 
                    type='text' 
                    value={
                    placeholders[index] !== squareLatex ? placeholders[index] : ""
                    }
                    onChange={(event) => onChangeFunction(event, setPlaceholders, index)}/></div>
                )
            )
        }

    }, [placeholders, squaresSpansIndexes, swappableIndices, props.revealAnswer]);

    useLayoutEffect(() => {
        if(placeholders.length > 0){
            let index = 0;
            const getReplacementValue = (index: number) => {
                // You can define logic here to return different replacement values based on the index
                return `${placeholders[index]}`;
            };
            const replacer = (match: string) => {
                // Use the current index to determine the replacement value
                const replacementValue = getReplacementValue(index);
                index++; // Increment the index for the next match
                return replacementValue;
            };

            const replacedString = latex.replace(pattern, replacer);

            index = 0;
            const getCorrectReplacementValue = (index: number) => {
                // You can define logic here to return different replacement values based on the index
                return `${correctAnswers[index]}`;
            };
            const correctReplacer = (match: string) => {
                // Use the current index to determine the replacement value
                const replacementValue = getCorrectReplacementValue(index);
                index++; // Increment the index for the next match
                return replacementValue;
            };

            const replacedCorrectString = correctAnswerLatex.replace(pattern, correctReplacer);
            setCorrectAnswerLatex(replacedCorrectString);

            setCurrentLatex(replacedString);
            setSquaresRendered(true);
        }
    }, [placeholders, currentLatex])

    const rootRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
    if (rootRef.current && squaresRendered) {
        const spans = rootRef.current.querySelectorAll('span');
        
        let i=0;
        const spanIndexes = [];
        for(let span of Array.from(spans)){
            if(span.textContent !== null && span.innerText.endsWith('□')  && span.classList.contains("mord") && span.classList.contains("amsrm")){
                spanIndexes.push(i);  
            }
            i++;
        }

        setSquaresSpansIndexes(spanIndexes);

        const givenAnswers = props.testDto.excercises[props.currentExcercise].excerciseAnswers;
        if(givenAnswers){
            if(givenAnswers.length > 0){
                const newPlaceholders = [...placeholders];
                for(let i=0; i<givenAnswers.length; i++){
                    const givenAnswer = givenAnswers[i];
                    if(givenAnswer){
                        newPlaceholders[i] = givenAnswer.toString();
                    }
                }
                setPlaceholders(newPlaceholders);
            }
        }
    }
    }, [squaresRendered]);

    const colorThePlaceholders = () => {
        if (rootRef.current && squaresRendered && props.revealAnswer && squaresSpansIndexes.length > 0) {
            const spans = rootRef.current.querySelectorAll('span');
            let i=0;
            let allCorrect = true;
            for(let index of squaresSpansIndexes){
                if(spans[index].innerText.trim() === correctAnswers[i].trim()) {
                    spans[index].style.color = 'green';
                } else {
                    const correctAnswer = correctAnswers[i];
                    const givenAnswer = spans[index].innerText;
                    spans[index].style.color = 'red';
                    allCorrect = false;
                }
                
                i++;
                    
            }

            if(props.revealAnswer){
                if(allCorrect){
                    setUserAnswerIcon("check_box")
                } else {
                    setUserAnswerIcon("indeterminate_check_box")
                }
            }
        }
    }

    useEffect(() => {
        colorThePlaceholders();
    }, [props.revealAnswer, squaresSpansIndexes, squaresRendered, rootRef.current, correctAnswers, isMobile]);

    useEffect(() => {
        if(props.notifyIfClicked > 0){
            handleRevealAnswers();
            if(rootRef.current === null) return;

            const spans = rootRef.current.querySelectorAll('span');
            let i=0;
            for(let index of squaresSpansIndexes){
                if(spans[index].innerText.trim() === correctAnswers[i].trim()) {
                    spans[index].style.color = 'green';
                } else {
                    spans[index].style.color = 'red';
                }
                
                i++;
                    
            }
        } else if (props.testDto.excercises[props.currentExcercise].finished) {
            handleRevealAnswers();

            if(rootRef.current === null) return;
            
            const spans = rootRef.current.querySelectorAll('span');
            let i=0;
            for(let index of squaresSpansIndexes){
                if(spans[index].innerText.trim() === correctAnswers[i].trim()) {
                    spans[index].style.color = 'green';
                } else {
                    spans[index].style.color = 'red';
                }
                
                i++;
                    
            }
        }
    }, [props.notifyIfClicked])

    const chunkArray = (array: ReactNode[], chunkSize: number = 3): ReactNode[] => {
        const result: ReactNode[] = [];

        const lastIter = Math.floor((array.length - 1) / chunkSize) * chunkSize
        
        for (let i = 0; i < array.length; i += chunkSize) {
          const row = array.slice(i, i + chunkSize);

          if(i === lastIter && row.length < chunkSize && array.length > chunkSize-row.length){
            for(let i=0; i<chunkSize-row.length; i++){
                row.push(<div style={{visibility: 'hidden'}} key={i} className="data-panel__latex__input-wrapper">
                {getPlaceholderLabel(0)}
                <input/></div>)
            }
          }
          result.push(<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start'}}>{row}</div>);
        }

        
        
        return result;
      } 

      useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 950px)'); // Define your desired media query here
    
        const handleMediaQueryChange = (event: MediaQueryListEvent) => {
            setIsMobile(event.matches);
        };
    
        mediaQuery.addEventListener('change', handleMediaQueryChange);
        setIsMobile(mediaQuery.matches);
    
        return () => {
          mediaQuery.removeEventListener('change', handleMediaQueryChange);
        };
      }, []);
    
    return (
        <div className="data-panel__results-1">    
                <div className="data-panel__results-1__correct-anwsers">
        
                    <div className="data-panel__open-test__question__question-data"> 
                         {combineBlocks(props.excerciseData.question, "mainTest data-panel__notes__container__data")}
                    </div>
                    <div className="data-panel__open-test__question__question-data"> 
                        <span className='mainTest data-panel__notes__container__data__text'>
                            Uwaga! W każdym miejscu brakuje conajwyżej jednego symbolu!
                        </span>
                    </div>
                    
                    <div className="data-panel__results-1__correct-anwsers__headlines-spacer" />
    
                    <div className="data-panel__results-1__correct-anwsers__headlines-wrapper">
                        <div className="data-panel__results-1__correct-anwsers__headlines-wrapper__headline data-panel__results-1__correct-anwsers__headlines-wrapper__headline--correct">
                            <span className="material-symbols-outlined">{userAnswerIcon}</span>
                            <span>Twoja odpowiedź</span>
                        </div>

                        {isMobile && <div className="data-panel__results-1__correct-anwsers__headlines-wrapper__mobile">

                            <div ref={rootRef}  className='data-panel__latex__latex-and-inputs-wrapper'>
                                <Latex>{`$$${currentLatex}$$`}</Latex>
                                <MediaQuery maxWidth={500}>
                                    <div className='data-panel__latex__inputs-wrapper'>
                                        <div style={{display: 'flex', flexDirection: 'column', width: '50%', alignItems: 'center'}}>
                                            {inputs !== undefined ? chunkArray(inputs) : <></>}
                                        </div>                    
                                    </div>
                                </MediaQuery>    
                                <MediaQuery minWidth={501}>
                                    <div className={`data-panel__latex__inputs-wrapper ${props.revealAnswer ? 'reveal' : 'hidden'}`}>
                                        <div style={{display: 'flex', flexDirection: 'row', width: '50%', justifyContent: 'center'}}>
                                            {inputs?.map((inp, index) => <div key={index}>{inp}</div>)}
                                        </div>                    
                                    </div>
                                </MediaQuery>    
                            </div>
                            </div>
                        }
                        
                    
                        <div className="data-panel__results-1__correct-anwsers__headlines-wrapper__headline data-panel__results-1__correct-anwsers__headlines-wrapper__headline--yours">
                            <span className="material-symbols-outlined">check_box</span>
                            <span>{t("kurs.quiz.yourAnswer")}</span>
                        </div>

                        {isMobile && <div className="data-panel__results-1__correct-anwsers__headlines-wrapper__mobile">
                            <div className={`data-panel__latex__latex-and-inputs-wrapper ${props.revealAnswer ? 'reveal' : 'hidden'}`}>
                                <Latex>{`$$${correctAnswerLatex}$$`}</Latex>
                                <MediaQuery maxWidth={500}>
                                    <div className='data-panel__latex__inputs-wrapper'>
                                        <div style={{display: 'flex', flexDirection: 'column', width: '50%', alignItems: 'center'}}>
                                            {answerInputs !== undefined ? chunkArray(answerInputs) : <></>}
                                        </div>                    
                                    </div>
                                </MediaQuery>    
                                <MediaQuery minWidth={501}>
                                    <div className='data-panel__latex__inputs-wrapper'>
                                        <div style={{display: 'flex', flexDirection: 'row', width: '50%', justifyContent: 'center'}}>
                                            {answerInputs?.map((inp, index) => <div key={index}>{inp}</div>)}
                                        </div>                    
                                    </div>
                                </MediaQuery>    
                            </div>
                        </div>}
                        
                    </div>
                        {!isMobile && <><div className="data-panel__results-1__correct-anwsers__anwsers-wrapper">
                        <div className="data-panel__results-1__correct-anwsers__anwsers-wrapper__anwser data-panel__results-1__correct-anwsers__anwsers-wrapper__anwser--left">
                            <div ref={rootRef} className='data-panel__latex__wrapper'>
                                <div className='data-panel__latex__latex-and-inputs-wrapper'>
                                    <Latex>{`$$${currentLatex}$$`}</Latex>
                                </div>
                            </div>
                            
                        </div>
                        <div className="data-panel__results-1__correct-anwsers__anwsers-wrapper__anwser data-panel__results-1__correct-anwsers__anwsers-wrapper__anwser--right">
                            <div className='data-panel__latex__wrapper'>
                                <div className={`data-panel__latex__latex-and-inputs-wrapper ${props.revealAnswer ? 'reveal' : 'hidden'}`}>
                                    <Latex>{`$$${correctAnswerLatex}$$`}</Latex>
                                </div>
                            </div>
                            
                        </div>
                        
                    </div>
                    <div className="data-panel__results-1__correct-anwsers__bottom-spacer" /></>}
                </div>
                {!isMobile && <div className='data-panel__latex__inputs-wrapper'>
                        <div style={{display: 'flex', flexDirection: 'column', width: '50%', alignItems: 'center', borderRight: '0.5px solid #bebebe'}}>
                            {inputs !== undefined ? chunkArray(inputs) : <></>}
                        </div>
                        <div className={`${props.revealAnswer ? 'reveal' : 'hidden'}`} style={{display: 'flex', flexDirection: 'column', width: '50%', alignItems: 'center'}}>
                            {answerInputs !== undefined ? chunkArray(answerInputs) : <></>}
                        </div>                    
                    </div>}
            </div>
        
    );
}