
export const OpinionBackground = () => (
  <svg
    className="opinion-background"
    xmlns="http://www.w3.org/2000/svg"
    width={895}
    height={641}
    fill="none"
  >
    <g opacity={0.3}>
      <g fill="#fff" opacity={0.16}>
        <path d="M802.191 587.537H863.2l.127.032 8.924 15.481h-78.684l-16.037-27.054 8.892-15.541 15.769 27.082Z" />
        <path d="m811.97 497.011 19.701-34.122 22.768 38.614h40.134l-47.279 81.929-.205.464h-42.8l-15.773-27.091 8.692-15.195-89.06-157.127h-39.429l-19.773-34.359 39.87-69.055 123.159 215.942h-.005Z" />
      </g>
      <path
        fill="#fff"
        d="M727.839 460.045h-30.49l-38.842-68.076h38.528l84.022 147.356-3.691 6.394-49.527-85.674ZM567.977 373.096h56.089l145.969 256.231h40.197l6.908 11.473h-58.114l-140.73-248.831h-39.42l-10.899-18.873Z"
        opacity={0.16}
      />
      <g fill="#fff" opacity={0.16}>
        <path d="m578.808 235.02 13.635-23.615 44.279 75.758-13.611 23.583-44.303-75.726Z" />
        <path d="m523.461 158.606 25.662 46.141h39.429l1.779 3.04-15.728 27.246 45.814 78.315c.191.355.386.71.577 1.065-1.501 2.599-2.998 5.197-4.5 7.796l11.345 19.377h-31.847L463.613 109.125h87.376l-27.524 49.486-.004-.005Z" />
      </g>
      <g fill="#fff" opacity={0.16}>
        <path d="m829.341 191.327 42.541 75.13-17.402 30.267-42.969-74.515 17.83-30.882Z" />
        <path d="M678.685 133.376c21.726-37.517 43.447-75.038 65.173-112.556 49.855 86.676 99.709 173.356 149.564 260.031l-24.548 40.839-12.292-21.316 19.496-33.899-46.705-82.48-19.96 34.573-66.502-115.323-17.607 30.136h-46.619v-.005Z" />
      </g>
      <path
        fill="#fff"
        d="M525.449 60.08h-27.996l-19.856-33.64h196.574l-19.35 33.64h-66.397l-12.401-19.109h-38.81l-11.764 19.11Z"
        opacity={0.16}
      />
      <g fill="#fff" opacity={0.16}>
        <path d="M479.745 185.715 399.154 45.868h65.724l20.274 35.36H667.6l31.632-54.788h26.318l-58.519 105.635H550.165l19.468-34.432H444.731l55.916 99.645H388.565l-6.681-11.573h97.861Z" />
        <path d="M678.371 26.44h16.656l-29.53 51.147H487.263l-20.278-35.36h-74.129l80.586 139.848h-93.661L289.875 26.44H473.37l22.003 37.281h32.111l11.764-19.109h34.8l12.396 19.109h70.479l21.448-37.28Z" />
      </g>
      <g fill="#fff" opacity={0.16}>
        <path d="M86.98 47.602h132.402l77.615 138.118h72.577l14.918 25.867H509.33l80.919 136.534H629.3l65.937 115.569h30.504l49.522 85.674-14.285 24.743 21.23 35.92h89.638c5.761 10.326 11.523 20.647 17.284 30.973-3.272-.068-6.544-.132-9.816-.2h-57.928l-9.097-15.114h-40.143l-145.968-256.23H565.87l-71.689-124.17H269.255L170.561 74.789H43.192L0 0h59.589l27.387 47.597.004.005Z" />
        <path d="M367.471 182.075h-68.344L221.512 43.957H89.087L63.798 0h69.537c5.238 8.57 10.476 17.134 15.719 25.703 42.4.164 84.805.332 127.205.496l91.217 155.876h-.005Z" />
      </g>
      <path
        fill="#fff"
        d="M537.145 164.577h79.444l23.123 39.374h35.374l36.457-62.024-162.056-1.069-12.342 23.719Zm104.392 36.188-23.123-39.374h-76.017l9.02-17.334L706 145.081l-32.734 55.689h-31.729v-.005ZM826.36 69.4l36.512 63.275h30.55v-3.185H864.71l-32.834-56.904h18.854l29.126 50.419h11.459v-3.186h-9.621L852.569 69.4H826.36ZM866.467 64.486l20.565 35.487 2.754-1.597-19.665-33.936 20.73-37.895-2.795-1.529-21.589 39.47ZM881.039 360.035l-24.616.214-18.426 30.732 17.316 31.128h25.275l3.3 5.729-2.244 3.409-3.163-5.497h-25.307l-19.382-34.837 20.561-34.286 26.654-.232.032 3.64ZM693.853 212.137l77.137 134.159h100.902l-23.278-39.533 3.085-5.347c.697 1.22 1.393 2.444 2.089 3.663l-.96 1.666 25.43 43.192H768.883l-77.132-134.145 2.098-3.655h.004ZM764.027 33.53l101.075 174.862 24.834-43.16 2.757 1.588-27.587 47.952L762.189 36.725l1.838-3.194Z"
        opacity={0.16}
      />
      <g fill="#fff" opacity={0.16}>
        <path d="m753.907 22.49-1.839-3.185h131.315l-24.511 42.368h-48.116l45.613 79.194h34.946v3.185H854.53l-49.285-85.564h51.788l20.825-35.998H753.907ZM750.63 19.41v-.105h.068l-.068.104Z" />
      </g>
      <g fill="#fff" opacity={0.16}>
        <path d="m811.571 221.831.118.069-.036.063-.082-.132ZM680.192 211.082l34.973-60.126 12.642 21.894 7.955-13.124 39.624 69.382h-20.31l24.165 41.708 28.097-48.666c.696 1.219 1.392 2.444 2.089 3.663l-30.181 52.276-30.487-52.622h20.356l-33.444-58.565L727.735 180l-12.579-21.785-32.871 56.508h-48.798l-21.594-37.017-17.444 30.218-2.102-3.641 19.528-33.826 23.7 40.63h44.612l.005-.005Z" />
      </g>
      <path
        fill="#fff"
        d="m816.349 472.696 20.074-32.784 24.993 42.386 19.841-34.55c.697 1.225 1.393 2.449 2.089 3.668l-21.889 38.123-25.094-42.56-20.096 32.821-120.238-207.004 2.093-3.641 118.227 203.541Z"
        opacity={0.16}
      />
      <path
        fill="#fff"
        d="m623.661 318.359 2.094-3.636 12.565 21.489L695.751 234.4l70.015 121.262h76.013l-19.783 34.209 23.897 42.332h27l5.179 8.309-2.203 3.345-4.997-8.014h-27.105l-25.962-45.995 17.662-30.545h-71.804l-67.871-117.558-57.413 101.775-14.718-25.161Z"
        opacity={0.16}
      />
    </g>
  </svg>
)
