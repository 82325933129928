// zwraca elementy w formacie Algorytmy i struktury danych > Drzewa binarne > Złożoność algorytmu

export const combinePath = (path: Array<string>, grayedOut: boolean = false): JSX.Element[] => {
    const result: Array<JSX.Element> = [];
    let keyCounter = 0;
    let counter = 0;
    for(let part of path){
        
        result.push(<span id={(grayedOut && counter > 0)? 'data-panel__nav__gray-text': ''} key={keyCounter++}>{part}</span>)

        if(counter < path.length-1)
            result.push(<span key={keyCounter++} className="material-symbols-outlined">keyboard_arrow_right</span>);
        
        counter++;
    }
    return result;
}