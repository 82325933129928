import { Dispatch, SetStateAction, useEffect, useState } from "react";

interface CarouselElementProps {
    index: number,
    activeIndex: number,
    setActiveIndex: Dispatch<SetStateAction<number>>,
    setPositionStyle: Dispatch<SetStateAction<{ left: string; transition: string}>>,
    text: string,
    scrollLeft: boolean,
    setDeactivatedPrevIndex: Dispatch<SetStateAction<boolean>>,
    deactivatedPrevIndex: boolean
}

export const CarouselElement = (props: CarouselElementProps) => {

    const [lazyLoad, setLazyLoad] = useState(false);

    useEffect(()=> {
        if(lazyLoad) setLazyLoad(false);

        if(props.activeIndex === props.index) {
            setTimeout(() => {
                setLazyLoad(true);
            }, 50);
        }
        
    }, [props.activeIndex]);


    const handlePanelClass = () => {
        const base = "main-page__carousel-section__carousel__row__panel";
        const active = (props.activeIndex === props.index);
        
        let finalClass = `${base}${(lazyLoad && active)? ` ${base}--positioned`: ""}`;
        
        if(props.deactivatedPrevIndex && active) finalClass = `${base} ${base}--deactivated`;

        return finalClass;
    }

    const transformCarousel = (currentIndex: number) => {
        const sign = props.scrollLeft ? '0' : '2';
        const parameter = `calc( var(--base-step) - ${sign} * var(--panel-width)  )`;
        
        props.setPositionStyle(
            { left: parameter, transition: 'left 0.6s ease'}
        );
    }

    const handleClick = () => {

        if(props.activeIndex !== props.index) {

            props.setDeactivatedPrevIndex(true);

            transformCarousel(props.index);

            setTimeout(() => {
                props.setDeactivatedPrevIndex(false);
                props.setPositionStyle({
                    left: 'calc( var(--base-step) - 1 * var(--panel-width)  )',
                    transition: 'left 0s ease'
                });
                props.setActiveIndex(props.index); 
            },  700);
        }
    }

    return (
        <div 
            className={handlePanelClass()}
            onClick={handleClick}
        >
            <div className="main-page__carousel-section__carousel__row__panel__img-wrapper main-page__carousel-section__carousel__row__panel__img-wrapper--desktop">
                <img
                    loading="lazy"
                    src={`https://na-sesje-public.s3.eu-central-1.amazonaws.com/landing-page/platform-panels-preview/panel-0${props.index}.png`}
                    alt="feature"
                />
            </div>

            <div className="main-page__carousel-section__carousel__row__panel__img-wrapper main-page__carousel-section__carousel__row__panel__img-wrapper--mobile">
                <img
                    src={`https://na-sesje-public.s3.eu-central-1.amazonaws.com/landing-page/mobile-platform-panels-preview/panel-0${props.index}.png`}
                    alt="feature"
                    loading="lazy"
                />
            </div>
            
            <div className="main-page__carousel-section__carousel__row__panel__description">
                <span>{props.text}</span>
            </div>
        </div>   
    )
}