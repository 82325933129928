import React from "react";
import { TextFallback, TextFallbackProps } from "./TextFallback";

interface TextLinesFallbackProps extends TextFallbackProps {
    numberOfLines: number;
}

export const TextLinesFallback = (props: TextLinesFallbackProps) => {
    return (
        <>
            {Array(props.numberOfLines).fill(null).map((_, index) => {
                return <TextFallback key={index} variant={props.variant} fill={props.fill} additionalClasses={props.additionalClasses}/>;
            }) }
        </>
    )
}