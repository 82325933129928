
import { useEffect, useRef, useState } from "react";

import "./coursePreview.scss";

import { LandingFooter } from "../LandingFooter/LandingFooter";
import { LandingNavbar } from "../LandingNavbar/LandingNavbar";
import { CourseSectionsWrapper } from "./CourseSectionsWrapper/CourseSectionsWrapper";
import { Checkout } from "../Checkout/Checkout";

import { CoursePreviewPageDto } from "../../../../shared/coursesPreviewDto";
import { get } from "../../utilities/rest/apiClient";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { NotifyMeWhenAvailable } from "./NotifyMeWhenAvailable/NotifyMeWhenAvailable";


interface CoursePropertyProps {
    icon: string,
    prop: number | null,
    text: string
}

const CourseProperty = (props: CoursePropertyProps) => {

    return (
        <div className="course-preview__main-section__left-panel__course-properties__property">
            <span className="material-symbols-outlined">
                {props.icon}
            </span>
            <span className="course-preview__main-section__left-panel__course-properties__property__text">
                {props.prop ? props.prop : ""}{props.text}
            </span>

        </div>    
    );
}

export const CoursePreview = () => {
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const navigate = useNavigate();

    const [coursePreviewDto, setCoursePreviewDto] = useState<CoursePreviewPageDto>({} as CoursePreviewPageDto);
    const [isLoading, setIsLoading] = useState(true);
    const [lessonCount, setLessonCount] = useState(0);
    const { courseId } = useParams();

    const [basicOptionSelected, setBasicOptionSelected] = useState(false); 
    const [enchancedOptionSelected, setEnchancedOptionSelected] = useState(false); 
    const [activeSectionIndex, setActiveSectionIndex] = useState(0);

    const [selectHighlight, setSelectHighlight] = useState(false);
    const [buyNowClicked, setBuyNowClicked] = useState(false);


    const targetRef = useRef<HTMLDivElement | null>(null);


    useEffect(() => {
        if(window.innerWidth <= 950) {
            setActiveSectionIndex(1);
        }
    }, []);

    useEffect(() => {
        setIsLoading(true);

        (async ()=> {
            try {
              const data = await get<CoursePreviewPageDto>(`reactRoutes/getCoursesPreviewForShopPage/${courseId}/${i18n.language}`);
    
              setCoursePreviewDto(data)
              setLessonCount(data.courseContent.reduce((total, chapter) => total + chapter.lessons.length, 0));
              setIsLoading(false);
            } catch(error) {
              //TODO other errors
            }
          })()
    }, [])

    const prosText = [
        `${t("kurs.preview.previewPage.prosText.moreThan")} ${coursePreviewDto.time} ${t("kurs.preview.previewPage.prosText.hoursOfRecordings")}.`,
        t("kurs.preview.previewPage.prosText.interactiveTests"),
        t("kurs.preview.previewPage.prosText.openAssignmentsBundles"),
        t("kurs.preview.previewPage.prosText.notesAccessAfterEachLesson"),
        t("kurs.preview.previewPage.prosText.interactiveFinalTest"),
        t("kurs.preview.previewPage.prosText.materialsAccess")
    ];

    const handleButtonName = (left: boolean) => {
        const base = "not-selectable course-preview__main-section__right-panel__additional-materials__buttons__button";
        let finalClass = `not-selectable ${base} ${base}--${left ? "left" : "right"}`;
        
        const isActive = left ? basicOptionSelected : enchancedOptionSelected;
        finalClass += ` ${base}--${isActive ? 'active' : 'inactive'}`;
        return finalClass;
    }

    const handleBuyNowClass = () => {
        const base = "course-preview__main-section__right-panel__buttons__button";
        let finalClass = `not-selectable ${base} ${base}--left`;

        const isActive = basicOptionSelected || enchancedOptionSelected || coursePreviewDto.coursePubliclyAvailable === false || coursePreviewDto.explanationsAvailable == false;

        finalClass += ` ${base}--${isActive? "active" : "inactive"}`;

        return finalClass;
    }

    const handleBuyNowClick = () => {
        if(basicOptionSelected || enchancedOptionSelected || coursePreviewDto.coursePubliclyAvailable === false || coursePreviewDto.explanationsAvailable == false) {
            setBuyNowClicked(true);

            let html = document.querySelector('html');
            if(html !== null) html.classList.add('html-not-scrollable');
            document.body.classList.add('body-not-scrollable');


        } else {
            setSelectHighlight(true);

            if (targetRef.current) {
                targetRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }

    if(isLoading) return <></> //TODO fallback
    return(
        <div className="course-preview">
            <LandingNavbar/>

            {
                (buyNowClicked && coursePreviewDto.coursePubliclyAvailable) && 
                    <Checkout
                        setBuyNowClicked={setBuyNowClicked}
                        basicOptionSelected={basicOptionSelected}
                        enchancedOptionSelected={enchancedOptionSelected}
                        courseID={ courseId }
                        price={ coursePreviewDto.price + (enchancedOptionSelected ? coursePreviewDto.recordingsPrice : 0)}
                    />
            }

            {
                (buyNowClicked && !coursePreviewDto.coursePubliclyAvailable) && 
                <NotifyMeWhenAvailable
                    setBuyNowClicked={setBuyNowClicked}
                />
            }

            <div className="course-preview__main-section">
                <div className="course-preview__main-section__left-panel">

                    <div className="course-preview__main-section__left-panel__headline">
                        <span>
                            {coursePreviewDto.courseName}
                        </span>
                    </div>
                    <div className="responsive-container course-preview__main-section__left-panel__responsive-container">
                        <iframe
                            src={coursePreviewDto.introductionVideo}
                            frameBorder="0" allow="fullscreen; picture-in-picture" allowFullScreen
                            title="file_example_MP4_1920_18MG"></iframe>
                    </div>
    
                    <div className="course-preview__main-section__left-panel__course-properties">
                        {
                            [
                                // { icon: "play_circle", prop: lessonCount, text: coursePreviewDto.coursePubliclyAvailable ? ` ${t("kurs.preview.previewPage.courseProperties.lessons")}` :  ` ${t("kurs.preview.previewPage.courseProperties.lessonsWhenNotAvailable")}`},
                                // { icon: "schedule", prop: coursePreviewDto.time, text: coursePreviewDto.coursePubliclyAvailable ? t("kurs.preview.previewPage.courseProperties.hoursOfRecordings") : ` ${t("kurs.preview.previewPage.courseProperties.hoursOfRecordingsWhenNotAvailable")}` },
                                // { icon: "extension", prop: coursePreviewDto.closedTestsCount, text: coursePreviewDto.coursePubliclyAvailable ? ` ${t("kurs.preview.previewPage.courseProperties.quizes")}` : ` ${t("kurs.preview.previewPage.courseProperties.quizesWhenNotAvailable")}`},
                                // { icon: "neurology", prop: coursePreviewDto.openTestsCount, text: coursePreviewDto.coursePubliclyAvailable ? ` ${t("kurs.preview.previewPage.courseProperties.assignments")}` : ` ${t("kurs.preview.previewPage.courseProperties.assignmentsWhenNotAvailable")}`}
                                { icon: "play_circle", prop: lessonCount, text: ` ${t("kurs.preview.previewPage.courseProperties.lessons")}` },
                                { icon: "schedule", prop: coursePreviewDto.time, text: t("kurs.preview.previewPage.courseProperties.hoursOfRecordings") },
                                { icon: "extension", prop: coursePreviewDto.closedTestsCount, text: ` ${t("kurs.preview.previewPage.courseProperties.quizes")}` },
                                { icon: "neurology", prop: coursePreviewDto.openTestsCount, text: ` ${t("kurs.preview.previewPage.courseProperties.assignments")}` }

                            ].map((el, index) => 
                                <CourseProperty key={index} icon={el.icon} prop={el.prop} text={el.text}/>)
                        }
                    </div>

                    <div className="display-on-desktop">
                        <CourseSectionsWrapper 
                            activeSectionIndex={activeSectionIndex} 
                            setActiveSectionIndex={setActiveSectionIndex}
                            courseContent={coursePreviewDto.courseContent}
                            description={coursePreviewDto.fullDescription}
                            courseRecomendation={coursePreviewDto.courseRecomendation}
                        />
                    </div>


                </div>

                <div ref={targetRef} className="course-preview__main-section__right-panel">
                    <div className="course-preview__main-section__right-panel__headline">
                        <span>
                            {coursePreviewDto.courseName}
                        </span>
                    </div>
                    <div className="course-preview__main-section__right-panel__brief-description">
                        <span>
                            {coursePreviewDto.shortDescription}
                        </span>
                    </div>

                    <div className="course-preview__main-section__right-panel__additional-materials">
                        {coursePreviewDto.explanationsAvailable && <div className="course-preview__main-section__right-panel__additional-materials__headline">
                            <span>
                                {t("kurs.preview.previewPage.tuneYourExperience")}
                            </span>
                        </div>}

                        {coursePreviewDto.explanationsAvailable && <div className="course-preview__main-section__right-panel__additional-materials__text">
                            <span>
                                {`${t("kurs.preview.previewPage.experienceText.withinTheCourse")} `}
                                <b>{t("kurs.preview.previewPage.experienceText.additionalRecordings")}</b>
                                {` ${t("kurs.preview.previewPage.experienceText.withPreciseExplanationOf")}`}  
                            </span>
                        </div>}
                        {(coursePreviewDto.coursePubliclyAvailable && coursePreviewDto.explanationsAvailable) &&
                            <>
                            <div className="course-preview__main-section__right-panel__additional-materials__buttons">
                                <div 
                                    onClick={() => {setEnchancedOptionSelected(false); setBasicOptionSelected(true); } } 
                                    className={handleButtonName(true)}
                                >
                                    <span className="course-preview__main-section__right-panel__additional-materials__buttons__button__left-text">
                                        {t("kurs.preview.previewPage.basePacket")}
                                    </span>
                                    <span className="course-preview__main-section__right-panel__additional-materials__buttons__button__right-text">
                                        { `+ 0 ${t("kurs.preview.previewPage.currencySymbol")}`}
                                    </span>
                                </div>
                                <div 
                                    onClick={() => { setBasicOptionSelected(false); setEnchancedOptionSelected(true); }}
                                    className={handleButtonName(false)}
                                >
                                    <span className="course-preview__main-section__right-panel__additional-materials__buttons__button__left-text">
                                        {t("kurs.preview.previewPage.additionalRecordings")}
                                    </span>
                                    <span className="course-preview__main-section__right-panel__additional-materials__buttons__button__right-text">
                                        {`+ ${coursePreviewDto.recordingsPrice} ${t("kurs.preview.previewPage.currencySymbol")}`}
                                    </span>
                                </div>
                            </div>
                            {
                                (!basicOptionSelected && !enchancedOptionSelected) ? (
                                    <div className={`course-preview__main-section__right-panel__additional-materials__select-option course-preview__main-section__right-panel__additional-materials__select-option--${selectHighlight? "active" : "inactive"}`}>
                                        <div><span>*&nbsp;</span></div>
                                        <div>
                                            <span>
                                                {t("kurs.preview.previewPage.chooseOneFromTheOptionsAbove")}
                                            </span>
                                        </div>
                                    </div>
                                ) : ""
                            }
                            </>
                        }

                        
                    </div>

                    <div className="course-preview__main-section__right-panel__pros">
                        <div className="course-preview__main-section__right-panel__pros__headline">
                            <span>
                                {t("kurs.preview.previewPage.whatWillYouFindInOurCourse")}
                            </span>
                        </div>

                        {
                            prosText.map((el, index) => 
                                <div
                                    key={index}
                                    className="course-preview__main-section__right-panel__pros__point"
                                >
                                    <div className="course-preview__main-section__right-panel__pros__point__dot"/>
                                    <span className="course-preview__main-section__right-panel__pros__point__text">
                                        {el}
                                    </span>
                                </div>   
                            )
                        }
                    </div>

                    <div className="course-preview__main-section__right-panel__price">
                        <span>
                            { `Cena za kurs: ${coursePreviewDto.courseName} - ` }
                        </span>
                        <span>
                            <b>{coursePreviewDto.price + (enchancedOptionSelected ? coursePreviewDto.recordingsPrice : 0)} zł</b>.
                        </span>
                    </div>

                    <div className="course-preview__main-section__right-panel__buttons">
                        <div 
                            className={handleBuyNowClass()}
                            onClick={handleBuyNowClick}
                        >
                            <span>
                                {t("kurs.preview.previewPage.buyNow")}
                            </span>
                        </div>
                        <div 
                            className="not-selectable course-preview__main-section__right-panel__buttons__button course-preview__main-section__right-panel__buttons__button--right"
                            onClick={() => { 
                                window.scrollTo(0, 0);
                                navigate(`/lessonPreview/${courseId}`);
                            }}
                        >
                            <span className="material-symbols-outlined">
                                play_circle
                            </span>
                            <span>
                                {t("kurs.preview.previewPage.watchSampleLesson")}
                            </span>
                        </div>
                    </div>


                    <div className="display-on-mobile">
                        <CourseSectionsWrapper 
                            activeSectionIndex={activeSectionIndex} 
                            setActiveSectionIndex={setActiveSectionIndex}
                            courseContent={coursePreviewDto.courseContent}
                            description={coursePreviewDto.fullDescription}
                            courseRecomendation={coursePreviewDto.courseRecomendation}
                        />
                    </div>

                </div>
            </div>

            <div className="course-preview__footer-separator">
                <div className="course-preview__footer-separator__line"/>
            </div>

            <LandingFooter/>
        </div>
    )
}