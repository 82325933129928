
export const OpenedEye = () => (

      <svg
        className="opened-eye"
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        width={1600}
        height={1600}
        viewBox="0 0 1200 1200"
      >
        <path
          d="M968.486 584.027c-87.238 74.94-201.256 152.87-367.246 152.87-166-.003-282.669-78.002-369.909-152.956l-54.674 63.265c24.816 21.324 53.711 43.727 85.969 65.148l-71.914 98.977 46.411 33.276 73.934-101.75c33.383 19.309 62.214 31.908 101.211 46.068l-41.582 123.32 57.282 17.358 40.704-121.719c37.379 10.262 60.597 12.492 101.771 14.414l-.505 129.84h61.137l-1.01-129.84c41.171-1.922 74.491-8.7 111.866-18.961l40.223 123.77 58.784-20.627-42.095-120.453c39-14.16 52.867-23.366 86.252-42.71l75.926 105.335 48.284-37.281-71.941-99.023c32.27-21.422 61.176-43.801 86.004-65.148z"
          style={{
            strokeWidth: 1.44975,
            strokeDasharray: "none",
          }}
          transform="rotate(180 600.006 599.991)"
        />
        <path
          d="M598.619 324.456c166.01 0 288.076 86.296 375.296 161.236l48.727-60.797c-93.383-80.234-241.927-177.051-422.647-177.051-180.71 0-329.96 95.641-423.35 175.87l59.234 62.187c87.238-74.938 196.756-161.817 362.746-161.817z"
          style={{
            fill: "#000",
            fillOpacity: 1,
            strokeWidth: 1.5,
            strokeDasharray: "none",
          }}
          transform="rotate(180 600.006 599.991)"
        />
        <path
          d="M764.107 494.439c-6.272 25.334-29.528 44.229-57.486 44.229-32.763 0-59.281-25.865-59.281-57.748 0-27.236 19.38-49.912 45.394-56.023-25.248-13.394-54.168-21-84.907-21-98.237 0-177.847 77.568-177.847 173.282 0 95.702 79.611 173.282 177.847 173.282 98.168 0 177.835-77.58 177.835-173.282 0-29.964-7.82-58.15-21.554-82.741z"
          style={{
            strokeWidth: 1.14686,
          }}
          transform="matrix(.9503 0 0 .9503 26.483 118.696)"
        />
      </svg>
)
