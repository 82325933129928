export const CourseFinishTestFallback = () => {
    return (
        <div className="chapter">
                <div className="chapter__name">Test podsumowujący</div>
                <div className="chapter__data-space">
                    <div className="chapter__data-space__data chapter__data-space__data--highlited chapter__data-space__data--iconless chapter__data-space__data--clickable">
                        <div className="text skeleton__main-navigation"></div>
                        <div className="stamp"></div>
                    </div>
                </div>
            </div>
    )
}