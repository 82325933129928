import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import { useContext, useEffect } from "react";
import { CourseContextProvider } from "./CourseContextProvider";
import { QuizContextProvider } from "./additions/quiz/QuizContextProvider";
import { TitleHeader } from "../shared/components/titleHeader/TitleHeader";
import { GlobalDataContext } from "../global/globalDataContext/GlobalDataContextProvider";
import { WideMenu } from "../shared/components/wideMenu/WideMenu";
import { Quiz } from "./additions/quiz/Quiz";
import { Notes } from "./additions/notes/Notes";
import { CourseNavigation } from "./courseNavigation/CourseNavigation";
import { VideoPage } from "./videoPage/VideoPage";
import { DataPanelWrapper } from "../shared/components/wrappers/dataPanelWrapper/DataPanelWrapper";
import { ContentPanelWrapper } from "../shared/components/wrappers/contentPanelWrapper/ContentPanelWrapper";
import { Assignment } from "./additions/assignment/Assignment";
import { CoursePath } from "./coursePath/CoursePath";
import { NotFound } from "../error/NotFound";


export type ActiveAddition = 'disabled' | 'quiz' | 'notes' | 'assignment';

export const Course = () => {
    const { t } = useTranslation();

    const globalCtx = useContext(GlobalDataContext);

    useEffect(() => {
        globalCtx.setIsMainView(false);
    }, [])

    return (
        <CourseContextProvider>
            <TitleHeader title={`${globalCtx.title}`}/>
            <QuizContextProvider>
                <ContentPanelWrapper>
                    <WideMenu mainCoursePanel={globalCtx.isMainView}/>
                    <DataPanelWrapper>
                        <CoursePath/>
                        <Routes>
                            <Route path="" element={<VideoPage/>}/>
                            <Route path="additions">
                                <Route path="quiz" element={<Quiz/> }/>
                                <Route path="assignment" element={<Assignment/>}/>
                                <Route path="notes" element={<Notes/>}/>
                                <Route path="" element={<NotFound />} />
                                <Route path="*" element={<NotFound />} />
                            </Route>
                            <Route path="" element={<NotFound />} />
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </DataPanelWrapper>
                    <CourseNavigation/>
                </ContentPanelWrapper>
            </QuizContextProvider>
        </CourseContextProvider>
    )
}