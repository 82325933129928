import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";

import "./UserPanelNavigation.scss";

import { UserPanelNavigationChapter } from "./userPanelNavigationChapter/UserPanelNavigationChapter";
import { CourseIconChange } from "../../course/courseIconChange/courseIconChange";
import { ActivePanel } from "../UserPanel";
import { AuthContext } from "../../auth/authContext/AuthContextProvider";
import { basicUserDataDto } from "../../../../../shared/userSettingsDto";
import { get, patch } from "../../../utilities/rest/apiClient";
import { UserPanelNavigationChapters } from "./userPanelNavigationChapters/UserPanelNavigationChapters";

import { Profile01 } from "../../course/courseIconChange/profileIcons/Profile01";
import { Profile02 } from "../../course/courseIconChange/profileIcons/Profile02";
import { Profile03 } from "../../course/courseIconChange/profileIcons/Profile03";
import { Profile04 } from "../../course/courseIconChange/profileIcons/Profile04";
import { Profile05 } from "../../course/courseIconChange/profileIcons/Profile05";
import { Profile06 } from "../../course/courseIconChange/profileIcons/Profile06";
import { Profile07 } from "../../course/courseIconChange/profileIcons/Profile07";
import { Profile08 } from "../../course/courseIconChange/profileIcons/Profile08";
import { useNavigate } from "react-router-dom";

interface UserPanelNavigationProps {
    setActiveChapter: Dispatch<SetStateAction<number>>;
    activeChapter: number;

    usernameUpdate: boolean;
    setUsernameUpdate:  Dispatch<SetStateAction<boolean>>;
}

interface ChangeIconButtonProps {
    iconChange: Function
}

interface Icons {
    id: number,
    component: JSX.Element
}

export const ChangeIconButton = (props: ChangeIconButtonProps) => {
    const { iconChange } = props;
    return(
        <div className="chapter__name chapter__name--username__icon__change-icon" onClick={() => {iconChange()}}>
            <span className="material-symbols-outlined">
                add
            </span>
        </div>
    );
}

export const UserPanelNavigation = (props: UserPanelNavigationProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    
    const [iconChangeActive, setIconChangeActive] = useState(false);
    const [activeIcon, setActiveIcon] = useState(1);
    const authContext = useContext(AuthContext);
    const [userName, setUserName] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [isIconLoaded, setIsIconLoaded] = useState(false);

    const icons: Icons[] = [
        { id: 1, component: <Profile01/>},
        { id: 2, component: <Profile02/>},
        { id: 3, component: <Profile03/>},
        { id: 4, component: <Profile04/>},
        { id: 5, component: <Profile05/>},
        { id: 6, component: <Profile06/>},
        { id: 7, component: <Profile07/>},
        { id: 8, component: <Profile08/>},
    ];

    useEffect(() => {
        setIsLoading(true);
        (async ()=> {
            try{
                const data = await get<basicUserDataDto>("users/basicUserData");
                setUserName(data.username);
                setActiveIcon(data.photoIndex);
                setIsIconLoaded(true);
                setIsLoading(false);
            } catch (error) {
                authContext.handleAuthErrors(error);
                //TODO pozostałe mozliwe błędy
            }
        })()
      }, [])

    const handleIconChange = async () => {
        setIconChangeActive(!iconChangeActive);
        try{
            await patch<any, any>(`users/updateUserIcon/${activeIcon}`, {});
        } catch(error) {
            authContext.handleAuthErrors(error);
            //TODO pozostałe błędy
        }
    }

    useEffect(() => {
        (async ()=> {
            if(props.usernameUpdate) {
                props.setUsernameUpdate(false);
                
                try{
                    const data = await get<basicUserDataDto>("users/basicUserData");
                    setUserName(data.username);
                } catch (error) {
                    authContext.handleAuthErrors(error);
                }
            }
        })()
    }, [props.usernameUpdate])

    const handleActiveIcon = (iconIndex: number) => {
        setActiveIcon(iconIndex);
    }

    const handleUserIconClasses = () => {
        const base = "chapter__name--username__icon"
        let classes = "chapter__name " + base;

        if(iconChangeActive) classes += ` ${base}--img-highlited`;
        classes += ` ${base}--0${activeIcon}`;

        if(iconChangeActive) classes += ` ${base}--priority-layer`;

        return classes;
    }

    const addDefaultSrc = (ev: React.SyntheticEvent<HTMLImageElement, Event>) => {
        const target = ev.target as HTMLImageElement;
        target.src =
          "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";
      };

    return (
        <div className="navigation-panel navigation-panel__scalable">
            <div className="chapter chapter--main">
            <div className="chapter__name chapter__name--username">
                <div className={handleUserIconClasses()}>
                    { isIconLoaded ? icons[activeIcon-1].component : <img alt="" onError={addDefaultSrc} className={"skeleton--circle skeleton"} src={"data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"}/> }

                    { iconChangeActive ? "" : <ChangeIconButton iconChange={isLoading ? () => {} : handleIconChange}/> }
                </div>
                <span className="chapter__name chapter__name--clickable" onClick={() => navigate("changeUsername")}>
                    { iconChangeActive? "i" : userName }
                </span>
            </div>
            <div className="chapter__data-space">
                { (iconChangeActive && !isLoading)? <CourseIconChange activeIcon={activeIcon} handleActiveIcon={handleActiveIcon} handleIconChange={handleIconChange}/> : <UserPanelNavigationChapters activeChapter={props.activeChapter} setActiveChapter={props.setActiveChapter}/>}
            </div>
            </div>
            <div className="chapter">
            <div className="chapter__name">{t("userPanel.viewAvaiableCourses")}</div>
            <div className="chapter__data-space">
                <div 
                    className="chapter__data-space__data chapter__data-space__data--highlited chapter__data-space__data--clickable"
                    onClick={() => navigate('/shop')}
                >
                <div className="text">{t("userPanel.goToTheShop")}</div>
                    <div className="icon icon--bold">
                        <span className="material-symbols-outlined">shopping_bag</span>
                    </div>
                </div>
            </div>
            </div>
        </div>
    );
}