import { FAQ } from "../FAQ/FAQPanel"
import { LandingFooter } from "../../landing/LandingFooter/LandingFooter"
import { LandingNavbar } from "../../landing/LandingNavbar/LandingNavbar"

import "./FAQWrapper.scss";
import { useTranslation } from "react-i18next";

export const FAQWrapper = () => {
    const { t } = useTranslation();

    return (
        <div className="FAQWrapper">
            <LandingNavbar/>

            <div className="FAQWrapper__section-name">
                <div className="FAQWrapper__section-name__text">
                    <span>
                        {t("footer.FAQSection")}
                    </span>
                </div>
            </div>

            <FAQ/>
            
            <LandingFooter/>
        </div>
    )
}