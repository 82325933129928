import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GlobalDataContext } from "../../../../../global/globalDataContext/GlobalDataContextProvider";
import { MainContext } from "../../../MainContextProvider";
import { useNavigate, useParams } from "react-router-dom";
import { post } from "../../../../../../utilities/rest/apiClient";
import { LastVisitedDto } from "../../../../../../../../shared/lastVisitedDto";
import { AuthContext } from "../../../../../auth/authContext/AuthContextProvider";

interface ActiveLessonProps {
    index: number;
    title: string;
    onClick: () => void;
}

const ActiveLesson = (props: ActiveLessonProps) => {
    return (
        <div className="chapter__data-space__opened-data__lessons-list__lesson chapter__data-space__opened-data__lessons-list__lesson--active" onClick={props.onClick}>
            <span>{`${props.index + 1}. ${props.title}`}</span>
            <div className="icon">
                <span className="material-symbols-outlined">
                    description
                </span>
            </div>
        </div>
    );
}

interface InactiveLessonProps {
    index: number;
    title: string;
    onClick: () => void;
}

const InactiveLesson = (props: InactiveLessonProps) => {
    return (
        <div className="chapter__data-space__opened-data__lessons-list__lesson" onClick={props.onClick}>
            <span>{`${props.index + 1}. ${props.title}`}</span>
        </div>
    );
}

interface MainChapterLessonProps {
    chapterIndex: number;
    index: number;
    title: string;
}

export const MainChapterLesson = (props: MainChapterLessonProps) => {
    const mainCtx = useContext(MainContext);
    const { courseId } = useParams();
    const navigate = useNavigate();
    const [isActive, setIsActive] = useState(false);
    const authContext = useContext(AuthContext);

    useEffect(() => {
        if(mainCtx.active.chapterIndex === props.chapterIndex && mainCtx.active.lessonIndex === props.index) {
            setIsActive(true);
        } else {
            setIsActive(false);
        }
    }, [mainCtx.active])

    const handleClick = () => {
        navigate(`${mainCtx.mainDto.chapters[props.chapterIndex].id}/${mainCtx.activeComponent}`)
        mainCtx.setActive({chapterIndex: props.chapterIndex, lessonIndex: props.index});
        const chapterId = mainCtx.mainDto.chapters[props.chapterIndex].id;
        const lessonId = mainCtx.mainDto.chapters[props.chapterIndex].lessons[props.index].id;

        post<any, LastVisitedDto>(`reactRoutes/addLastVisited/${courseId}`, {lastVisitedChapter: chapterId, lastVisitedLesson: lessonId})
        .catch(error => {
            authContext.handleAuthErrors(error);
            console.log(error)
        }); //TODO to trzeba jakoś obsłuzyć. Moze jakiś popup?
    }

    return (
        <>
            {isActive ? 
                <ActiveLesson index={props.index} title={props.title} onClick={handleClick}/> :  
                <InactiveLesson index={props.index} title={props.title} onClick={handleClick}/>
            }
        </>
    )
}