import { TextLinesFallback } from "../../../shared/components/fallbacks/skeleton/TextLinesFallback";

interface NotesLinesFallbackProps {
    numberOfLines: number;
}

export const NotesLinesFallback = (props: NotesLinesFallbackProps) => {
    return (
        <div className="data-panel__notes__container__data__text">
            <TextLinesFallback variant="normal" fill="wide" additionalClasses="data-panel__notes__container__data__text" numberOfLines={props.numberOfLines}/>
        </div>
    )
}