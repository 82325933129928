import { useContext, useEffect, useRef, useState } from "react"
import { CourseContext } from "../CourseContextProvider"
import { beacon } from "../../../utilities/rest/apiClient";
import { CourseVideoChapterDto } from "../../../../../shared/courseVideoChapterDto";

const playerjs =  require("player.js");

interface VideoPlayerProps {
  currentStamp: number;
}

export const VideoPlayer = (props: VideoPlayerProps) => {
    const ctx = useContext(CourseContext);
    const [timestamp, setTimestamp] = useState<number>(0);
    const [iframeLoaded, setIframeLoaded] = useState<boolean>(false);

    const getLessonId = () => ctx.chapters?.lessons[ctx.activeChapter].id;

    const iframeRef = useRef<HTMLIFrameElement>(null);

    const getTimeStampStorageName = () => `timeStamp${getLessonId()}`;

    const handleTimestampChange = () => {
      const savedTimeStamp = localStorage.getItem(getTimeStampStorageName());
      const lessonId = getLessonId();
      if(savedTimeStamp && lessonId){
        beacon(`reactRoutes/updateLessonTimestamp/${lessonId}/${savedTimeStamp}`, null);
      }
    }

    useEffect(() => {
      console.log(ctx.chapters?.lessons[ctx.activeChapter].src  + `?t=${props.currentStamp}s`)
      // Early return if iframe hasn't loaded yet
      if (!iframeLoaded) return;
    
      if (iframeRef.current && iframeRef.current.contentWindow) {
        try {
          const player = new playerjs.Player(iframeRef.current);
    
          player.on('ready', () => {
            console.log('Player is ready.');
    
            player.on('timeupdate', () => {
              player.getCurrentTime((value: any) => {
                setTimestamp(Number(value));
              });
            });
          });
    
          return () => {
            player.off();
          };
        } catch (error) {
          console.error("Error initializing player:", error);
        }
      }
    }, [iframeLoaded]); // Depend only on iframeLoaded
    

    useEffect(() => {
      if(timestamp){
        localStorage.setItem(getTimeStampStorageName(), Math.trunc(timestamp).toString());
      }
    }, [timestamp]);

    useEffect(() => {
      const interval = setInterval(() => {
        handleTimestampChange();
      }, 5000);

      return () => clearInterval(interval);
    }, []);

    return (
        <div className="responsive-container">
          <iframe 
              onLoad={() => {setIframeLoaded(true)}}
              ref={iframeRef}
              src={ctx.chapters?.lessons[ctx.activeChapter].src  + `&t=${props.currentStamp}`}
              loading="lazy"
              allowFullScreen
              style={{border: "none"}}
              allow="fullscreen; picture-in-picture; accelerometer; gyroscope; autoplay; encrypted-media;">
          </iframe>
        </div>
    )
}