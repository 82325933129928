import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ActiveAddition } from "../../Course";
import { CourseContext } from "../../CourseContextProvider";

import { ClosedEye } from "../EyeStamps/ClosedEye";
import { OpenedEye } from "../EyeStamps/OpenedEye";

interface CourseAdditionProps {
    title: string;
    currentStamp: number;
    maxStamp: number;
    thisAdditionType: ActiveAddition;
    testId: string;
    notes?: boolean;
}

export const CourseAddition = (props: CourseAdditionProps) => {
    const ctx = useContext(CourseContext);
    const navigate = useNavigate();

    const [eyeStamp, setEyeStamp] = useState(false);

    useEffect(() => {
        const eyeStamp = (props.notes !== undefined) ? (props.notes ? true : false ) : false;
        setEyeStamp(eyeStamp);
    }, []);

    const handleClick = () => {
        if(props.maxStamp === 0 ) return;

        ctx.setActiveAddition(props.thisAdditionType);
        navigate(props.thisAdditionType);
        if(window.location.pathname.includes("additions")) {
            navigate(props.thisAdditionType);
        } else {
            navigate(`additions/${props.thisAdditionType}`)
        }
    }

    const handleStamp = () => {
    
        if(eyeStamp) {
            if(!props.maxStamp) 
                return <div className="stamp">-</div>;

            return (
                <div className={`stamp stamp--${ctx.activeAddition === props.thisAdditionType ? 'active' : 'inactive'}`}>
                    {
                        (props.currentStamp === 0) ?
                            <ClosedEye/> : <OpenedEye/>
                    }
                </div>
            );
        } else {
            if(!props.maxStamp) 
                return <div className="stamp">0/0</div>;
            
            return <div className="stamp">{`${props.currentStamp}/${props.maxStamp}`}</div>;
        }
    }

    const handleDataClass = () => {
        const base = "chapter__data-space__data";
        let finalClass = `${base} ${base}--${(!eyeStamp) ? "iconless" : "stamp-icon"}`;
        finalClass += (ctx.activeAddition === props.thisAdditionType ? " chapter__data-space__data--highlited" : "");
        finalClass += (props.maxStamp === 0) ? "  chapter__data-space__data--disabled" : "";

        return finalClass;
    }

    return (
        <div className={handleDataClass()}
             onClick={handleClick}
             data-testid={props.testId}
        >
            <div className="text">{props.title}</div>
            {
                handleStamp()
            }
        </div>
    );
}