import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Interweave } from 'interweave';
import { useNavigate } from "react-router-dom";

import "../../App.scss";
import "./FAQPanel.scss";

import { FAQElement } from "./FAQElement/FAQElement";
import { SearchPanel } from "./search/searchPanel";

interface FAQListElement {
    id: number;
    question: string;
    answer: string;
    isActive: boolean;
}

export function FAQ() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [searchValue, setSearchValue] = useState("");
    const [filterElements, setFilterElements] = useState(false);
    const [FAQElements, setFAQElements] = useState<FAQListElement[]>([        
        {
            id: 0,
            question: t("FAQ.listElements.el0.question"),
            answer: t("FAQ.listElements.el0.answer"),
            isActive: false
        },
        {
            id: 1,
            question: t("FAQ.listElements.el1.question"),
            answer: t("FAQ.listElements.el1.answer"),
            isActive: false
        },
        {
            id: 2,
            question: t("FAQ.listElements.el2.question"),
            answer: t("FAQ.listElements.el2.answer"),
            isActive: false
        },
        {
            id: 3,
            question: t("FAQ.listElements.el3.question"),
            answer: t("FAQ.listElements.el3.answer"),
            isActive: false,

        },
        {
            id: 4,
            question: t("FAQ.listElements.el4.question"),
            answer: t("FAQ.listElements.el4.answer"),
            isActive: false
        },
        {
            id: 5,
            question: t("FAQ.listElements.el5.question"),
            answer: t("FAQ.listElements.el5.answer"),
            isActive: false
        },
        {
            id: 6,
            question: t("FAQ.listElements.el6.question"),
            answer: t("FAQ.listElements.el6.answer"),
            isActive: false
        },
        {
            id: 7,
            question: t("FAQ.listElements.el7.question"),
            answer: t("FAQ.listElements.el7.answer"),
            isActive: false
        },
        {
            id: 8,
            question: t("FAQ.listElements.el8.question"),
            answer: t("FAQ.listElements.el8.answer"),
            isActive: false
        },
        {
            id: 9,
            question: t("FAQ.listElements.el9.question"),
            answer: t("FAQ.listElements.el9.answer"),
            isActive: false
        } 
    ]);
    
    const [highlightColor, setHighlightColor] = useState("");
    const [FAQComponents, setFAQComponents] = useState<JSX.Element>(<></> as JSX.Element);
    const [displayedElementsCount, setDisplayedElementsCount] = useState(0);

    const displayFAQList = () => {
        let elementsData = FAQElements;

        elementsData = elementsData.filter(element => {
            const answerText = element.answer;
            const answerQuestion = element.question;
            return answerText.toLowerCase().includes(searchValue.toLowerCase()) || answerQuestion.toLowerCase().includes(searchValue.toLowerCase());
        });

        setDisplayedElementsCount(elementsData.length);

        const length = elementsData.length;
        const middleIndex = length/2 + (length%2);

        const firstRow = elementsData.slice(0, middleIndex).map(el => {
            return <FAQElement highlightColor={highlightColor} textToHighlight={searchValue} key={el.id} id={el.id} answer={el.answer} question={el.question} isActive={el.isActive}/>
        });

        const secondRow =  elementsData.slice(middleIndex, length).map((el, index) => {
            return <FAQElement highlightColor={highlightColor} textToHighlight={searchValue} key={el.id} id={el.id} answer={el.answer} question={el.question} isActive={el.isActive}/>
        });

        return (
            <>
                <div className="FAQ-section__container__list">
                    {firstRow}
                </div>
                <div className="FAQ-section__container__list">
                    {secondRow}
                </div>
            </>
        )
    }

    useEffect(() => {
        setFAQComponents(displayFAQList());
    }, [searchValue])

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const elementsFiltered = (e.target.value.length !== 0);

        setFilterElements(elementsFiltered);  
        setSearchValue(e.target.value);
    }

    const handleSearchClick = () => {
        setFilterElements(false);  
        setFAQComponents(displayFAQList());
        setSearchValue("");
    }

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);

    return (
        <>
            <div id="content-name">
                {t("footer.FAQSection")}
            </div>
            <div className="FAQ-section">
                <div className="FAQ-section__title">
                    <span className="material-symbols-outlined" onClick={() => navigate(-1)}>
                        arrow_back_ios 
                    </span>
                    <span className="FAQ-section__title__text">
                        {t("FAQ.mostFrequentlyAskedQuestions")}
                    </span>
                </div>

                <div className="FAQ-section__description FAQ-section__description--top">
                    <span>
                        <Interweave content={t("FAQ.descriptionTop")}/>
                    </span>
                </div>

                <SearchPanel setHighlightColor={setHighlightColor} searchVal={searchValue} handleChange={handleSearchChange} handleClick={handleSearchClick} />

                <div className="FAQ-section__sub-title">
                    <span>
                        {t("FAQ.questionsAboutThePlatform")} {(filterElements) ? `(${t("FAQ.filtered")})` : ""}:
                    </span>
                </div>

                <div className="FAQ-section__container">
                    { 
                        (displayedElementsCount !== 0) ? FAQComponents : 
                        <span className="FAQ-section__container__not-found">{t("FAQ.textNotFound")}</span>
                    }
                </div>

                <div className="FAQ-section__description FAQ-section__description--bottom">
                    <span>
                        {t("FAQ.descriptionBottom")}<b>{t("supportEmail")}</b>.
                    </span>
                </div>

            </div>
        </>
    )
    
}