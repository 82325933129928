import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";


import "./error.scss";

import { ErrorGraphic } from "./ErrorGraphic";
import { useNavigate } from "react-router-dom";

export const Error = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    
    return(
        <>
            <div className="error">
                <div className="error__text-section">
                    <div className="error__text-section__container">
                        <div className="error__text-section__container__headline">
                            <span>{t("error.oops")},</span>
                        </div>
                        <div className="error__text-section__container__middle-text">
                            <span>{t("error.somethingWentWrong")}</span>
                        </div>
                        <div className="error__text-section__container__spacer"/>
                        <div className="error__text-section__container__end-text">
                            <span>
                                {t("error.weTrackTheseTypesOfErrors")}:&nbsp; 
                                <b>{t("supportEmail")}</b>.
                            </span>

                        </div>
                        <div 
                            className="error__text-section__container__button error__text-section__container__button--desktop"
                            onClick={() => navigate('/userPanel')}
                        >
                            
                            <span>{t("error.backToThePage")}</span>
                        </div>
                    </div>

                </div>
                <div className="error__graphic error__graphic">
                    <ErrorGraphic/>
                </div>

                <div 
                    className="error__text-section__container__button error__text-section__container__button--mobile module-border-wrap"
                    onClick={() => navigate('/userPanel')}
                >
                    <span>{t("error.backToThePage")}</span>
                </div>
                <div className="error__mobile-spacer"/>
            </div>
        </>
    );
}