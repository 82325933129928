import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "../userSettings.scss";
import "./closeAccount.scss";

import { ClosedAccountData } from "../../../../../../shared/closeAccountDto";

import { logout } from "../../../../utilities/rest/logout";
import { UserPanelPageProps } from "../../UserPanel";
import { delete_ } from "../../../../utilities/rest/apiClient";
import { AuthContext } from "../../../auth/authContext/AuthContextProvider";

export const CloseAccount = (props: UserPanelPageProps) => {
    const { t } = useTranslation();
    const authContext = useContext(AuthContext);


    const [userSatisfiedIsChecked, setUserSatisfiedIsChecked] = useState(true);
    const [closeAccountChecked, setCloseAccountChecked] = useState(false);

    const [serviceReviewMessage, setServiceReviewMessage] = useState("");

    const handleUserSatisfiedCheckBox = () => {
        setUserSatisfiedIsChecked(!userSatisfiedIsChecked);
    }

    const handleCloseAccountCheckBox = () => {
        setCloseAccountChecked(!closeAccountChecked);
    }

    const handleAccountClosing = () => {
        if(!closeAccountChecked) return;

        const dto: ClosedAccountData = {
            userSatisfied: closeAccountChecked,
            serviceReview: serviceReviewMessage 
        }

        delete_<any, ClosedAccountData>("users/deactivateMyAccount", dto).then(data => {
            logout();

            authContext.setAlertHeadline(`${t("userPanel.settings.accountDeletedHeadline")}`);
            authContext.setAlertMessage(`${t("userPanel.settings.accountDeletedMessage")}`);
            authContext.setAlertDisplayed(true);    
        })
        .catch(error => {
            // TODO: HANDLE POTENTIAL ERRORS
        });

        setServiceReviewMessage("");
    }

    const handleSubmitButtonClass = () => {
        const base = "data-panel__user-settings__button-wrapper__submit-button";
        return `${base} ${base}--${closeAccountChecked ? "valid" : "invalid"}`
    }

    useEffect(() => {
        props.setActivePanel("closeAccount");
        const activeChapter = props.activePanelNameToIndexMap.get("closeAccount");
        if(activeChapter){
            props.setActiveChapter(activeChapter);
        }
    }, []);
    
    return (
        <div className="data-panel__close-account data-panel__user-settings">
            <div className="data-panel__user-settings__description">
                <h4>{t("userPanel.settings.closeYourAccount")}</h4>
            </div>
            <div className="data-panel__close-account__headline">
                <span className="data-panel__close-account__headline__main">
                    {t("userPanel.settings.closeYourAccountOn")}
                </span>
                <span className="data-panel__close-account__headline__text">

                    {t("userPanel.settings.closeAccountDescription1")}
                    
                </span>
            </div>
            <div className="data-panel__close-account__text">
                <span>
                    {t("userPanel.settings.closeAccountDescription2")}
                </span>
            </div>

            <div className="data-panel__user-settings__input-row">
                <div className="data-panel__user-settings__input-row__text">
                    <span>{t("userPanel.settings.confirmAccountClosing")}</span>
                </div>
                <div className="data-panel__user-settings__input-row__checkbox data-panel__user-settings__input-row__checkbox--close-account">
                    <input type="checkbox" id="click" defaultChecked={closeAccountChecked} onChange={handleCloseAccountCheckBox}/>
                    <label htmlFor="click" className={`text text--${userSatisfiedIsChecked? "active" : "not-active"}`}>
                        { closeAccountChecked? t("userPanel.settings.yes"): t("userPanel.settings.no")}
                    </label>
                </div>
            </div>
            <div className="data-panel__user-settings__spacer" />

            <div className="data-panel__user-settings__input-row">
                <div className="data-panel__user-settings__input-row__text">
                    <span>{t("userPanel.settings.satisfiedExpectationsDescription")}</span>
                </div>
                <div className="data-panel__user-settings__input-row__checkbox data-panel__user-settings__input-row__checkbox--close-account">
                    <input type="checkbox" id="click" defaultChecked={userSatisfiedIsChecked} onChange={handleUserSatisfiedCheckBox}/>
                    <label htmlFor="click" className={`text text--${userSatisfiedIsChecked? "active" : "not-active"}`}>
                        { userSatisfiedIsChecked? t("userPanel.settings.yes"): t("userPanel.settings.no")}
                    </label>
                </div>
            </div>
            <div className="data-panel__user-settings__spacer" />
            <div className="data-panel__close-account__feedback">
                <span className="data-panel__close-account__feedback__question">
                    {t("userPanel.settings.tellUsWhatWeCanImprove")}
                </span>
                <div className="data-panel__close-account__feedback__panel">
                <textarea
                    placeholder={`${t("userPanel.settings.yourOpinion")}`}
                    rows={4}
                    name="opinion"
                    defaultValue={""}
                />
                </div>
            </div>
            <div className="data-panel__user-settings__spacer" />
            <div className="data-panel__user-settings__button-wrapper">
                <div onClick={handleAccountClosing} className={handleSubmitButtonClass()}>
                    <span>{t("userPanel.settings.closeAccount")}</span>
                </div>
            </div>
        </div>
    );
}