export class NotFound extends Error {
    constructor(message: string) {
      super(message);
      this.name = `404 not found`;
      Object.setPrototypeOf(this, NotFound.prototype);
    }
}

export class BadRequest extends Error {
    constructor(message: string) {
      super(message);
      this.name = `400 bad request`;
      Object.setPrototypeOf(this, BadRequest.prototype);
    }
}

export class Unauthorized extends Error {
    constructor(message: string) {
      super(message);
      this.name = `401 unauthorized`;
      Object.setPrototypeOf(this, Unauthorized.prototype);
    }
}