import { useTranslation } from "react-i18next";
import { Block } from "../../../../../../../shared/block";
import { combineBlocks } from "../../../../shared/utilities/combiningBlocksOfTextIntoJsx/combiningBlocksOfTextIntoJsx";
import { Answer } from "../../../../../../../shared/quizesDto";
import { QuizQuestion } from "../../../additions/quiz/quizTest/quizQuestion/QuizQuestion";
import { AnswerForQuestion, AnswerType } from "../../../additions/quiz/quizResult/QuizResult";

interface QuizExcerciseResultsProps {
    text: Block[];
    correctAnswer: Answer;
    userAnswer: Answer;
}

export const QuizExcerciseResults = (props: QuizExcerciseResultsProps) => {
    const { t } = useTranslation();

    return (
        <div className="data-panel__results-1">    
                <div className="data-panel__results-1__correct-anwsers">
        
                    <QuizQuestion text={props.text} isMainTest={true} />
                    
                    <div className="data-panel__results-1__correct-anwsers__headlines-spacer" />
    
                    <div className="data-panel__results-1__correct-anwsers__headlines-wrapper">
                        <div className="data-panel__results-1__correct-anwsers__headlines-wrapper__headline data-panel__results-1__correct-anwsers__headlines-wrapper__headline--correct">
                            <span className="material-symbols-outlined">check_box</span>
                            <span>{t("kurs.quiz.yourAnswer")}</span>
                        </div>
    
                        <div className="data-panel__results-1__correct-anwsers__headlines-wrapper__mobile">
                            <AnswerForQuestion answerType={AnswerType.CORRECT} correctAnswer={props.correctAnswer} userAnswer={props.userAnswer}/>
                        </div>
                    
                        <div className="data-panel__results-1__correct-anwsers__headlines-wrapper__headline data-panel__results-1__correct-anwsers__headlines-wrapper__headline--yours">
                            <span className="material-symbols-outlined">indeterminate_check_box</span>
                            <span>{t("kurs.quiz.yourAnswer")}</span>
                        </div>
    
                        <div className="data-panel__results-1__correct-anwsers__headlines-wrapper__mobile">
                            <AnswerForQuestion answerType={AnswerType.USER} correctAnswer={props.correctAnswer} userAnswer={props.userAnswer}/>
                        </div>
                    </div>
    
                    <div className="data-panel__results-1__correct-anwsers__anwsers-wrapper">
                        <div className="data-panel__results-1__correct-anwsers__anwsers-wrapper__anwser data-panel__results-1__correct-anwsers__anwsers-wrapper__anwser--left">
                            <AnswerForQuestion answerType={AnswerType.CORRECT} correctAnswer={props.correctAnswer} userAnswer={props.userAnswer}/>
                        </div>
                        <div className="data-panel__results-1__correct-anwsers__anwsers-wrapper__anwser data-panel__results-1__correct-anwsers__anwsers-wrapper__anwser--right">
                            <AnswerForQuestion answerType={AnswerType.USER} correctAnswer={props.correctAnswer} userAnswer={props.userAnswer}/>
                        </div>
                    </div>
                </div>
            </div>
    );
}