import { useContext } from "react";
import { CourseNavigationFallback } from "../../components/course/courseNavigation/courseNavigationFallback/CourseNavigationFallback";
import { CoursePathFallback } from "../../components/course/coursePath/coursePathFallback/CoursePathFallback";
import { getContentFallbackWithRegardsToUrl } from "../../components/shared/components/fallbacks/fallbackUtils";
import { WideMenu } from "../../components/shared/components/wideMenu/WideMenu";
import { ContentPanelWrapper } from "../../components/shared/components/wrappers/contentPanelWrapper/ContentPanelWrapper";
import { DataPanelWrapper } from "../../components/shared/components/wrappers/dataPanelWrapper/DataPanelWrapper";
import { GlobalDataContext } from "../../components/global/globalDataContext/GlobalDataContextProvider";
import { PreviewHeader } from "./LessonPreview";

export const LessonPreviewFallback = () => {
    const globalCtx = useContext(GlobalDataContext);
    
    return (
        <>
            <PreviewHeader/>
            <div id="content-name">
                <div className="skeleton"></div>
                <br/>
                <div className="skeleton"></div>
            </div>
            <ContentPanelWrapper>
                <WideMenu mainCoursePanel={globalCtx.isMainView}/>
                <DataPanelWrapper>
                    <CoursePathFallback/>
                    {getContentFallbackWithRegardsToUrl()}
                </DataPanelWrapper>
                <CourseNavigationFallback/>
            </ContentPanelWrapper>
        </>
    );
}