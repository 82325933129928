import { AssignmentDto } from "../../../../../../../shared/assignmentDto"
import { combineBlocks } from "../../../../shared/utilities/combiningBlocksOfTextIntoJsx/combiningBlocksOfTextIntoJsx";
import { useTranslation } from "react-i18next";
import "../../../additions/assignment/assignment.scss"
import { Dispatch, SetStateAction, useEffect, useState } from "react";

interface AssignmentExcerciseProps {
    excerciseData: AssignmentDto;
    revealAnswer: boolean;
    modifyIsFinished: (index: number, finished: boolean) => void;
    notifyIfClicked: number;
    currentExcercise: number;
}

export const AssignmentExcercise = (props: AssignmentExcerciseProps) => {
    const  { t } = useTranslation();

    useEffect(() => {
        if(props.notifyIfClicked > 0){
            props.modifyIsFinished(props.currentExcercise, true);
        }
    }, [props.notifyIfClicked])

    return (
        <>
            <div className="data-panel__open-test">
                        <div className="data-panel__open-test__question">    
                            <div className="data-panel__open-test__question__question-data">
                                <div className="data-panel__open-test__question__question-data"> 
                                    {combineBlocks(props.excerciseData.text, "mainTest data-panel__notes__container__data")}
                                </div>
                            </div>
                        </div>
                        <div className="data-panel__open-test__anwser">
                                <div className="data-panel__open-test__anwser__headline mainTest">
                                    <span>{t("kurs.zadaniaOtwarte.rozwiazanie")}:</span>
                                </div>
    
                            <div className="data-panel__open-test__anwser__data">
    
                                <div style={{filter: `${!props.revealAnswer ? 'blur(4px)': ''}`}} className={`data-panel__open-test__anwser__data__anwser ${props.revealAnswer && "data-panel__open-test__anwser__data__anwser--active"} `}>
                                    {combineBlocks(props.excerciseData.solution)}
                                </div>

                                {/* {((props.revealAnswer === false)) && 
                                    <div className="data-panel__open-test__anwser__data__blur">
                                        {combineBlocks(props.excerciseData.solution)}
                                    </div>
                                } */}
                            </div>
    
                        </div>
                    </div>
        </>
    ); 
}