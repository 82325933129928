import { useTranslation } from "react-i18next";
import { TextFallback } from "../../../components/shared/components/fallbacks/skeleton/TextFallback";
import { TextLinesFallback } from "../../../components/shared/components/fallbacks/skeleton/TextLinesFallback";

import "./checkoutFallback.scss";

export const CheckoutFallback = () => {
    const { t } = useTranslation();

    return (
        <>
            <div className="checkout-fallback">
                <div className="checkout-fallback__headline">
                    <TextFallback variant="h4" text="card"/>
                    <TextFallback variant="h4" text={`${t("checkout.payment")}: 1/2`}/>
                </div>

            </div>
            <div className="checkout-fallback__text">
                <TextFallback variant="normal" text={t("checkout.stripeSection.choosePrefferedPaymentType")}/>
            </div>

            <div className="checkout-fallback__payment-boxes">
                <div className="checkout-fallback__payment-boxes__box">
                    <TextFallback variant="normal" text="payment"/>
                </div>
                <div className="checkout-fallback__payment-boxes__box">
                    <TextFallback variant="normal" text="payment"/>
                </div>
                <div className="checkout-fallback__payment-boxes__box">
                    <TextFallback variant="normal" text="payment"/>
                </div>
            </div>

            <div className="checkout-fallback__payment-input">
                <TextFallback variant="normal" text="payment"/>
                <div className="checkout-fallback__payment-input__box"/>
            </div>

            <div className="checkout-fallback__payment-input">
                <TextFallback variant="normal" text="payment"/>
                <div className="checkout-fallback__payment-input__box"/>
            </div>

            <div className="checkout-fallback__submit-button-wrapper">
                <div className="checkout-fallback__submit-button-wrapper__button">
                    <TextFallback variant="normal" text={`${t("checkout.stripeSection.payForThePurchase")} - x zł`}/>
                </div>
            </div>
        </> 
    );
}