export const QuestionIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
    >
      <path
        stroke="#14181F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"
      />
      <path fill="#14181F" d="M13.5 16a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
      <path
        stroke="#14181F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M10.586 8.114c.39-.39.9-.584 1.41-.585.513-.001 1.027.194 1.418.585.39.39.586.903.586 1.415 0 .511-.195 1.023-.586 1.414a1.993 1.993 0 0 1-1.418.586l.004 1"
      />
    </svg>
  )
  
  