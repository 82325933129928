import * as React from "react"

interface CubeGraphicLightProps {
  isHovered: boolean
}

export const CubeGraphicLight = (props: CubeGraphicLightProps) => {

  const handleHover = () => {
    return props.isHovered? "light-panel--active" : "light-panel--inactive"
  }

  return(
  <svg
    className={`light-panel ${handleHover()}`}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 1726.83 4000"
  >
    <defs>
      <linearGradient
        id="linear-gradient-018429472"
        x1={696.76}
        x2={752.25}
        y1={2001.29}
        y2={2097.41}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#57e3e6" />
        <stop offset={0.05} stopColor="#54dcdf" stopOpacity={0.97} />
        <stop offset={0.12} stopColor="#4dcacc" stopOpacity={0.9} />
        <stop offset={0.19} stopColor="#41abae" stopOpacity={0.79} />
        <stop offset={0.28} stopColor="#318183" stopOpacity={0.63} />
        <stop offset={0.37} stopColor="#1d4b4c" stopOpacity={0.42} />
        <stop offset={0.47} stopColor="#040b0b" stopOpacity={0.17} />
        <stop offset={0.48} stopColor="#010202" stopOpacity={0.13} />
        <stop offset={0.53} stopColor="#050e0e" stopOpacity={0.18} />
        <stop offset={0.62} stopColor="#112e2e" stopOpacity={0.3} />
        <stop offset={0.74} stopColor="#256263" stopOpacity={0.5} />
        <stop offset={0.89} stopColor="#40a8ab" stopOpacity={0.78} />
        <stop offset={1} stopColor="#57e3e6" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-018429472-2"
        x1={341.53}
        x2={442.47}
        y1={1796.38}
        y2={1796.38}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#57e3e6" />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
      <linearGradient
        xlinkHref="#linear-gradient-018429472-2"
        id="linear-gradient-018429472-3"
        x1={320.54}
        x2={363.03}
        y1={1796.48}
        y2={1868.5}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-2"
        id="linear-gradient-018429472-4"
        x1={477.27}
        x2={441.69}
        y1={1915.62}
        y2={1895.52}
      />
      <linearGradient
        id="linear-gradient-018429472-5"
        x1={357.88}
        x2={458.81}
        y1={1930.22}
        y2={1930.22}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopOpacity={0} />
        <stop offset={1} stopColor="#57e3e6" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-018429472-6"
        x1={330.24}
        x2={473}
        y1={1863.29}
        y2={1863.29}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#57e3e6" />
        <stop offset={0.37} stopColor="#56e1e4" stopOpacity={0.99} />
        <stop offset={0.51} stopColor="#53dadd" stopOpacity={0.96} />
        <stop offset={0.61} stopColor="#4fced1" stopOpacity={0.91} />
        <stop offset={0.69} stopColor="#48bdc0" stopOpacity={0.84} />
        <stop offset={0.76} stopColor="#40a7aa" stopOpacity={0.74} />
        <stop offset={0.82} stopColor="#368c8e" stopOpacity={0.62} />
        <stop offset={0.87} stopColor="#296c6e" stopOpacity={0.48} />
        <stop offset={0.93} stopColor="#1b4748" stopOpacity={0.31} />
        <stop offset={0.97} stopColor="#0b1e1e" stopOpacity={0.13} />
        <stop offset={1} stopOpacity={0} />
      </linearGradient>
      <linearGradient
        xlinkHref="#linear-gradient-018429472"
        id="linear-gradient-018429472-7"
        x1={383.22}
        x2={419.87}
        y1={1831.61}
        y2={1895.09}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472"
        id="linear-gradient-018429472-8"
        x1={373.77}
        x2={429.27}
        y1={1815.3}
        y2={1911.42}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-2"
        id="linear-gradient-018429472-9"
        x1={664.52}
        x2={765.45}
        y1={1982.37}
        y2={1982.37}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-2"
        id="linear-gradient-018429472-10"
        x1={643.53}
        x2={686.01}
        y1={1982.47}
        y2={2054.49}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-2"
        id="linear-gradient-018429472-11"
        x1={800.26}
        x2={764.68}
        y1={2101.61}
        y2={2081.51}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-5"
        id="linear-gradient-018429472-12"
        x1={680.86}
        x2={781.8}
        y1={2116.21}
        y2={2116.21}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-6"
        id="linear-gradient-018429472-13"
        x1={653.23}
        x2={795.99}
        y1={2049.28}
        y2={2049.28}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472"
        id="linear-gradient-018429472-14"
        x1={706.2}
        x2={742.85}
        y1={2017.59}
        y2={2081.08}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-2"
        id="linear-gradient-018429472-15"
        x1={1195.95}
        x2={1296.82}
        y1={1647.08}
        y2={1647.08}
      />
      <linearGradient
        id="linear-gradient-018429472-16"
        x1={1064.02}
        x2={1164.9}
        y1={1647.08}
        y2={1647.08}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopOpacity={0} />
        <stop offset={1} stopColor="#57e3e6" />
      </linearGradient>
      <linearGradient
        xlinkHref="#linear-gradient-018429472-2"
        id="linear-gradient-018429472-17"
        x1={1216.8}
        x2={1259.87}
        y1={1547.81}
        y2={1620.81}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-5"
        id="linear-gradient-018429472-18"
        x1={1066.42}
        x2={1166.27}
        y1={1589.8}
        y2={1589.8}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-6"
        id="linear-gradient-018429472-19"
        x1={1040.7}
        x2={1323.82}
        y1={1619.83}
        y2={1619.83}
      />
      <linearGradient
        id="linear-gradient-018429472-20"
        x1={1121.76}
        x2={1237.86}
        y1={1620.08}
        y2={1620.08}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.03} stopColor="#57e3e6" />
        <stop offset={0.54} stopOpacity={0} />
        <stop offset={0.59} stopColor="#020606" stopOpacity={0.03} />
        <stop offset={0.65} stopColor="#091919" stopOpacity={0.11} />
        <stop offset={0.72} stopColor="#153738" stopOpacity={0.24} />
        <stop offset={0.8} stopColor="#256163" stopOpacity={0.43} />
        <stop offset={0.89} stopColor="#3a989a" stopOpacity={0.67} />
        <stop offset={0.99} stopColor="#53d9dc" stopOpacity={0.96} />
        <stop offset={1} stopColor="#57e3e6" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-018429472-21"
        x1={1144.34}
        x2={1215.15}
        y1={1620.03}
        y2={1620.03}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#57e3e6" />
        <stop offset={0.61} stopOpacity={0} />
        <stop offset={0.65} stopColor="#020606" stopOpacity={0.03} />
        <stop offset={0.7} stopColor="#091919" stopOpacity={0.11} />
        <stop offset={0.76} stopColor="#153738" stopOpacity={0.24} />
        <stop offset={0.83} stopColor="#256163" stopOpacity={0.43} />
        <stop offset={0.91} stopColor="#3a989a" stopOpacity={0.67} />
        <stop offset={0.99} stopColor="#53d9dc" stopOpacity={0.96} />
        <stop offset={1} stopColor="#57e3e6" />
      </linearGradient>
      <linearGradient
        xlinkHref="#linear-gradient-018429472-2"
        id="linear-gradient-018429472-22"
        x1={877.3}
        x2={978.18}
        y1={1825.17}
        y2={1825.17}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-16"
        id="linear-gradient-018429472-23"
        x1={745.38}
        x2={846.26}
        y1={1825.17}
        y2={1825.17}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-2"
        id="linear-gradient-018429472-24"
        x1={898.16}
        x2={941.22}
        y1={1725.9}
        y2={1798.9}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-5"
        id="linear-gradient-018429472-25"
        x1={747.77}
        x2={847.63}
        y1={1767.88}
        y2={1767.88}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-6"
        id="linear-gradient-018429472-26"
        x1={722.05}
        x2={1005.18}
        y1={1797.92}
        y2={1797.92}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-20"
        id="linear-gradient-018429472-27"
        x1={803.11}
        x2={919.21}
        y1={1798.17}
        y2={1798.17}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-21"
        id="linear-gradient-018429472-28"
        x1={825.7}
        x2={896.5}
        y1={1798.12}
        y2={1798.12}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-2"
        id="linear-gradient-018429472-29"
        x1={877.3}
        x2={978.18}
        y1={1466.08}
        y2={1466.08}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-16"
        id="linear-gradient-018429472-30"
        x1={745.38}
        x2={846.26}
        y1={1466.08}
        y2={1466.08}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-2"
        id="linear-gradient-018429472-31"
        x1={898.16}
        x2={941.22}
        y1={1366.8}
        y2={1439.81}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-5"
        id="linear-gradient-018429472-32"
        x1={747.77}
        x2={847.63}
        y1={1408.79}
        y2={1408.79}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-6"
        id="linear-gradient-018429472-33"
        x1={722.05}
        x2={1005.18}
        y1={1438.83}
        y2={1438.83}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-20"
        id="linear-gradient-018429472-34"
        x1={803.11}
        x2={919.21}
        y1={1439.08}
        y2={1439.08}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-21"
        id="linear-gradient-018429472-35"
        x1={825.7}
        x2={896.5}
        y1={1439.02}
        y2={1439.02}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-2"
        id="linear-gradient-018429472-36"
        x1={561.03}
        x2={661.91}
        y1={1647.08}
        y2={1647.08}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-16"
        id="linear-gradient-018429472-37"
        x1={429.11}
        x2={529.99}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-2"
        id="linear-gradient-018429472-38"
        x1={581.89}
        x2={624.96}
        y1={1547.81}
        y2={1620.81}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-5"
        id="linear-gradient-018429472-39"
        x1={431.5}
        x2={531.36}
        y1={1589.8}
        y2={1589.8}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-6"
        id="linear-gradient-018429472-40"
        x1={405.79}
        x2={688.91}
        y1={1619.83}
        y2={1619.83}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-20"
        id="linear-gradient-018429472-41"
        x1={486.85}
        x2={602.94}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-21"
        id="linear-gradient-018429472-42"
        x1={509.43}
        x2={580.23}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-2"
        id="linear-gradient-018429472-43"
        x1={664.52}
        x2={765.45}
        y1={2355.37}
        y2={2355.37}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-2"
        id="linear-gradient-018429472-44"
        x1={643.53}
        x2={686.01}
        y1={2355.48}
        y2={2427.5}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-2"
        id="linear-gradient-018429472-45"
        x1={800.26}
        x2={764.68}
        y1={2474.62}
        y2={2454.52}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-5"
        id="linear-gradient-018429472-46"
        x1={680.86}
        x2={781.8}
        y1={2489.22}
        y2={2489.22}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-6"
        id="linear-gradient-018429472-47"
        x1={653.23}
        x2={795.99}
        y1={2422.29}
        y2={2422.29}
      />
      <linearGradient
        id="linear-gradient-018429472-48"
        x1={706.2}
        x2={742.85}
        y1={2390.6}
        y2={2454.08}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#57e3e6" />
        <stop offset={0.05} stopColor="#54dcdf" stopOpacity={0.97} />
        <stop offset={0.13} stopColor="#4dcacc" stopOpacity={0.9} />
        <stop offset={0.21} stopColor="#41abae" stopOpacity={0.79} />
        <stop offset={0.3} stopColor="#318183" stopOpacity={0.63} />
        <stop offset={0.41} stopColor="#1d4b4c" stopOpacity={0.42} />
        <stop offset={0.51} stopColor="#040b0b" stopOpacity={0.17} />
        <stop offset={0.53} stopColor="#010202" stopOpacity={0.13} />
        <stop offset={0.57} stopColor="#050e0e" stopOpacity={0.18} />
        <stop offset={0.66} stopColor="#112e2e" stopOpacity={0.3} />
        <stop offset={0.77} stopColor="#256263" stopOpacity={0.5} />
        <stop offset={0.9} stopColor="#40a8ab" stopOpacity={0.78} />
        <stop offset={1} stopColor="#57e3e6" />
      </linearGradient>
      <linearGradient
        xlinkHref="#linear-gradient-018429472-48"
        id="linear-gradient-018429472-49"
        x1={696.76}
        x2={752.25}
        y1={2374.29}
        y2={2470.42}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-2"
        id="linear-gradient-018429472-50"
        x1={342.21}
        x2={443.15}
        y1={2165.49}
        y2={2165.49}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-2"
        id="linear-gradient-018429472-51"
        x1={321.22}
        x2={363.71}
        y1={2165.6}
        y2={2237.62}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-2"
        id="linear-gradient-018429472-52"
        x1={477.95}
        x2={442.37}
        y1={2284.74}
        y2={2264.64}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-5"
        id="linear-gradient-018429472-53"
        x1={358.56}
        x2={459.49}
        y1={2299.34}
        y2={2299.34}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-6"
        id="linear-gradient-018429472-54"
        x1={330.92}
        x2={473.68}
        y1={2232.41}
        y2={2232.41}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472"
        id="linear-gradient-018429472-55"
        x1={383.9}
        x2={420.55}
        y1={2200.72}
        y2={2264.2}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-48"
        id="linear-gradient-018429472-56"
        x1={374.45}
        x2={429.95}
        y1={2184.41}
        y2={2280.54}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-2"
        id="linear-gradient-018429472-57"
        x1={963.39}
        x2={1064.32}
        y1={1981.95}
        y2={1981.95}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-2"
        id="linear-gradient-018429472-58"
        x1={1042.65}
        x2={1085.49}
        y1={1981.75}
        y2={2054.37}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-2"
        id="linear-gradient-018429472-59"
        x1={964.61}
        x2={928.14}
        y1={2101.44}
        y2={2080.84}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-5"
        id="linear-gradient-018429472-60"
        x1={947.04}
        x2={1047.98}
        y1={2115.79}
        y2={2115.79}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-6"
        id="linear-gradient-018429472-61"
        x1={932.85}
        x2={1075.61}
        y1={2048.86}
        y2={2048.86}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-48"
        id="linear-gradient-018429472-62"
        x1={1022.65}
        x2={986}
        y1={2017.18}
        y2={2080.66}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-48"
        id="linear-gradient-018429472-63"
        x1={1032.06}
        x2={976.56}
        y1={2000.85}
        y2={2096.98}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-2"
        id="linear-gradient-018429472-64"
        x1={1283.92}
        x2={1384.86}
        y1={1795.98}
        y2={1795.98}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-2"
        id="linear-gradient-018429472-65"
        x1={1363.18}
        x2={1406.03}
        y1={1795.79}
        y2={1868.41}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-2"
        id="linear-gradient-018429472-66"
        x1={1285.14}
        x2={1248.67}
        y1={1915.48}
        y2={1894.87}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-5"
        id="linear-gradient-018429472-67"
        x1={1267.58}
        x2={1368.51}
        y1={1929.82}
        y2={1929.82}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-6"
        id="linear-gradient-018429472-68"
        x1={1253.39}
        x2={1396.15}
        y1={1862.9}
        y2={1862.9}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-48"
        id="linear-gradient-018429472-69"
        x1={1343.18}
        x2={1306.53}
        y1={1831.21}
        y2={1894.7}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-48"
        id="linear-gradient-018429472-70"
        x1={1352.59}
        x2={1297.1}
        y1={1814.89}
        y2={1911.01}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-48"
        id="linear-gradient-018429472-71"
        x1={1352.59}
        x2={1297.1}
        y1={1814.89}
        y2={1911.01}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-2"
        id="linear-gradient-018429472-72"
        x1={963.39}
        x2={1064.32}
        y1={2356.05}
        y2={2356.05}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-2"
        id="linear-gradient-018429472-73"
        x1={1042.65}
        x2={1085.49}
        y1={2355.85}
        y2={2428.47}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-2"
        id="linear-gradient-018429472-74"
        x1={964.61}
        x2={928.14}
        y1={2475.54}
        y2={2454.93}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-5"
        id="linear-gradient-018429472-75"
        x1={947.04}
        x2={1047.98}
        y1={2489.89}
        y2={2489.89}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-6"
        id="linear-gradient-018429472-76"
        x1={932.85}
        x2={1075.61}
        y1={2422.96}
        y2={2422.96}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-48"
        id="linear-gradient-018429472-77"
        x1={1022.65}
        x2={986}
        y1={2391.28}
        y2={2454.76}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-48"
        id="linear-gradient-018429472-78"
        x1={1032.06}
        x2={976.56}
        y1={2374.95}
        y2={2471.07}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-2"
        id="linear-gradient-018429472-79"
        x1={1284.84}
        x2={1385.77}
        y1={2169.16}
        y2={2169.16}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-2"
        id="linear-gradient-018429472-80"
        x1={1364.1}
        x2={1406.94}
        y1={2168.96}
        y2={2241.58}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-2"
        id="linear-gradient-018429472-81"
        x1={1286.06}
        x2={1249.58}
        y1={2288.65}
        y2={2268.05}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-5"
        id="linear-gradient-018429472-82"
        x1={1268.49}
        x2={1369.43}
        y1={2303}
        y2={2303}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-6"
        id="linear-gradient-018429472-83"
        x1={1254.3}
        x2={1397.06}
        y1={2236.07}
        y2={2236.07}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-48"
        id="linear-gradient-018429472-84"
        x1={1344.1}
        x2={1307.45}
        y1={2204.39}
        y2={2267.88}
      />
      <linearGradient
        xlinkHref="#linear-gradient-018429472-48"
        id="linear-gradient-018429472-85"
        x1={1353.51}
        x2={1298.01}
        y1={2188.07}
        y2={2284.19}
      />
      <radialGradient
        id="radial-gradient-018429472"
        cx={868.45}
        cy={1623.87}
        r={384.5}
        fx={868.45}
        fy={1623.87}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#dca700" stopOpacity={0} />
        <stop offset={0.46} stopColor="#57e3e6" />
        <stop offset={0.58} stopColor="#7dd7a9" stopOpacity={0.76} />
        <stop offset={0.73} stopColor="#abca61" stopOpacity={0.48} />
        <stop offset={0.86} stopColor="#ccc02c" stopOpacity={0.27} />
        <stop offset={0.95} stopColor="#e1ba0c" stopOpacity={0.15} />
        <stop offset={1} stopColor="#e9b800" stopOpacity={0.1} />
      </radialGradient>
      <radialGradient
        xlinkHref="#radial-gradient-018429472"
        id="radial-gradient-018429472-2"
        cx={625.89}
        cy={1914.3}
        r={346.56}
        fx={625.89}
        fy={1914.3}
        gradientTransform="matrix(.86603 .5 .00028 .9932 21.36 -70.23)"
      />
      <radialGradient
        xlinkHref="#radial-gradient-018429472"
        id="radial-gradient-018429472-3"
        cx={1109.19}
        cy={1942.5}
        r={346.56}
        fx={1109.19}
        fy={1942.5}
        gradientTransform="matrix(.86603 -.5 -.00028 .9932 204.04 769.72)"
      />
      <radialGradient
        id="radial-gradient-018429472-4"
        cx={868.45}
        cy={1623.87}
        r={384.5}
        fx={868.45}
        fy={1623.87}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#dca700" stopOpacity={0} />
        <stop offset={0.46} stopColor="#e7b800" />
        <stop offset={0.54} stopColor="#e7b800" stopOpacity={0.83} />
        <stop offset={0.71} stopColor="#e8b800" stopOpacity={0.52} />
        <stop offset={0.84} stopColor="#e8b800" stopOpacity={0.29} />
        <stop offset={0.94} stopColor="#e8b800" stopOpacity={0.15} />
        <stop offset={1} stopColor="#e9b800" stopOpacity={0.1} />
      </radialGradient>
      <radialGradient
        xlinkHref="#radial-gradient-018429472-4"
        id="radial-gradient-018429472-5"
        cx={625.89}
        cy={1914.3}
        r={346.56}
        fx={625.89}
        fy={1914.3}
        gradientTransform="matrix(.86603 .5 .00028 .9932 21.36 -70.23)"
      />
      <radialGradient
        xlinkHref="#radial-gradient-018429472-4"
        id="radial-gradient-018429472-6"
        cx={1109.19}
        cy={1942.5}
        r={346.56}
        fx={1109.19}
        fy={1942.5}
        gradientTransform="matrix(.86603 -.5 -.00028 .9932 204.04 769.72)"
      />
      <radialGradient
        xlinkHref="#radial-gradient-018429472-4"
        id="radial-gradient-018429472-7"
        cx={625.89}
        cy={1914.3}
        r={346.56}
        fx={625.89}
        fy={1914.3}
        gradientTransform="matrix(.86603 .5 .00028 .9932 21.36 -70.23)"
      />
      <radialGradient
        xlinkHref="#radial-gradient-018429472-4"
        id="radial-gradient-018429472-8"
        cx={1109.19}
        cy={1942.5}
        r={346.56}
        fx={1109.19}
        fy={1942.5}
        gradientTransform="matrix(.86603 -.5 -.00028 .9932 204.04 769.72)"
      />
      <clipPath id="clippath-24">
        <path d="M-2497.52-1299.3h1724.23V424.93h-1724.23z" className="cls-018429472-2" />
      </clipPath>
      <style>
        {
          ".cls-018429472-2{stroke-width:1.31px}.cls-018429472-2,.cls-018429472-3{fill:none;stroke:#57e3e6;stroke-miterlimit:10}.cls-018429472-4{fill:#009eb0}.cls-018429472-6,.cls-018429472-7{fill:#57e3e6}.cls-018429472-14{fill:url(#radial-gradient-018429472-4)}.cls-018429472-17,.cls-018429472-7{opacity:.16}.cls-018429472-3{stroke-width:.57px}"
        }
      </style>
    </defs>
    <path
      id="tlo"
      d="M.36.02h1726.83v4000H.36z"
      style={{
        fill: "#0e0c2d",
      }}
    />
    <g id="dodatki">
      <g className="cls-018429472-17">
        <path
          d="M417.82 194.4V60.33l-.07-.28-34.02-19.61v172.91l59.45 35.23 34.15-19.54-59.51-34.64z"
          className="cls-018429472-6"
        />
        <path
          d="m616.74 172.9 74.97-43.29-84.85-50.02V-8.6L426.83 95.29l-1.01-3.94v98.45l59.53 34.66 33.38-19.11L864 401.06v86.63l75.5 43.45 151.75-87.6L616.74 172.9z"
          className="cls-018429472-6"
        />
      </g>
      <path
        d="M697.97 357.77v67l149.58 85.35v-84.66L523.76 240.83l-14.06 8.11 188.27 108.83zM889.03 709.05V585.8L325.99 265.05v-88.33l-25.21-15.18v127.7l546.77 309.24v86.62l41.48 23.95z"
        className="cls-018429472-7"
      />
      <g className="cls-018429472-17">
        <path
          d="m1192.44 685.25 51.89-29.96-166.47-97.3-51.82 29.92 166.4 97.34z"
          className="cls-018429472-6"
        />
        <path
          d="m1360.35 806.88-101.39-56.39v-86.64l-6.68-3.91-59.87 34.56-172.09-100.67-2.34-1.27c-5.71 3.3-11.42 6.59-17.13 9.89l-42.58-24.93v69.98l510.81 290.89v-192l-108.74 60.48Z"
          className="cls-018429472-6"
        />
      </g>
      <g className="cls-018429472-17">
        <path
          d="m1288.45 134.74-165.09-93.49-66.51 38.25 163.74 94.41 67.86-39.17z"
          className="cls-018429472-6"
        />
        <path
          d="M1415.78 465.78c82.44-47.74 164.89-95.47 247.33-143.21-190.46-109.55-380.93-219.1-571.39-328.65l-89.74 53.94 46.84 27.01 74.49-42.84 181.24 102.63-75.97 43.86 253.41 146.13-66.22 38.69v102.44Z"
          className="cls-018429472-6"
        />
      </g>
      <path
        d="M1576.85 802.5v61.52l73.92 43.63V475.7l-73.92 42.52v145.9l41.98 27.25v85.28l-41.98 25.85z"
        className="cls-018429472-7"
      />
      <g className="cls-018429472-17">
        <path
          d="m1300.77 902.94 307.31 177.08V935.6l-77.7-44.55V490.14l120.39-69.51V362.8l-232.12 128.59v256.8l75.66-42.78v274.46L1275.35 857v246.29l25.42 14.68V902.94z"
          className="cls-018429472-6"
        />
        <path
          d="M1650.77 466.47v-36.6l-112.39 64.89v391.65l77.7 44.56v162.89l-307.31-177.08v205.81l342 197.56V916.94l-81.92-48.35v-70.56l41.98-25.85v-76.47l-41.98-27.24V513.6l81.92-47.13z"
          className="cls-018429472-6"
        />
      </g>
      <g className="cls-018429472-17">
        <path
          d="M1604.27 1765.99v-290.94l-303.5-170.55v-159.48l-56.84-32.78V837.92L943.91 660.11V574.3L689.96 429.41v-67.03L501.7 253.56l-54.37 31.39-78.93-46.65V41.32c-22.49-12.63-44.99-25.26-67.48-37.9-.05 7.16-.09 14.33-.14 21.49V152.2l33.21 19.99v88.21l563.04 320.75v132.52l272.85 157.53v494.25l374.65 216.87v279.88l164.34 94.91v-130.94l-104.59-60.18Z"
          className="cls-018429472-6"
        />
        <path
          d="M1308.77 1149.64v150.18l303.5 170.55v290.99l96.59 55.57v-152.8c-18.83-11.51-37.65-23.02-56.48-34.54-.36-93.17-.73-186.35-1.09-279.52-114.17-66.81-228.35-133.63-342.52-200.44Z"
          className="cls-018429472-6"
        />
      </g>
      <g className="cls-018429472-17">
        <path
          d="M1347.22 776.8V602.23l-86.52-50.81v-77.73l136.29-80.11 2.35 356.1-52.12 27.12Zm-79.52-229.39 86.52 50.81v167.04l38.09-19.82-2.25-339.68-122.37 71.93v69.72Z"
          className="cls-018429472-6"
        />
      </g>
      <g className="cls-018429472-17">
        <path
          d="m1556.36 141.28-139.03-80.23V-6.08h7v63.09l125.03 72.15V87.73l-110.78-64V-1.45h7v21.14l110.78 64v57.59z"
          className="cls-018429472-6"
        />
      </g>
      <g className="cls-018429472-17">
        <path
          d="m1567.17 53.15-77.99-45.19 3.51-6.05 74.58 43.21 83.27-45.55 3.36 6.14-86.73 47.44z"
          className="cls-018429472-6"
        />
      </g>
      <path
        d="m917.72 21.13-.46 54.09-67.53 40.49-68.4-38.05V22.12l-12.6-7.25-7.48 4.93 12.08 6.95v55.61l76.54 42.59 75.35-45.17.5-58.58-8-.07zM1242.72 432.46l-294.8-169.5V41.24l86.87 51.15 11.75-6.78-8.05-4.59-3.66 2.11-94.91-55.88v240.34l294.77 169.49 8.03-4.61ZM1635.18 278.26 1250.94 56.15l94.85-54.56-3.49-6.07-105.37 60.62 391.24 226.15 7.01-4.03z"
        className="cls-018429472-7"
      />
      <g className="cls-018429472-17">
        <path
          d="m1659.44 300.49 7 4.04V15.99l-93.09 53.85v105.73L1399.32 75.34V-1.45h-7v80.83l188.03 108.3V73.88l79.09-45.76v272.37zM1666.21 307.69h.23v-.15l-.23.15z"
          className="cls-018429472-6"
        />
      </g>
      <g className="cls-018429472-17">
        <path
          d="m1221.42 173.78-.15-.26-.15.09.3.17zM1245.03 462.47l132.12-76.85-48.11-27.78 28.84-17.48-152.46-87.07v44.63l-91.65-53.1 106.94-61.74c-2.68-1.53-5.37-3.06-8.05-4.59l-114.87 66.32 115.63 66.99v-44.73l128.69 73.49-28.78 17.44 47.87 27.64-124.17 72.23V565.1l81.34 47.45-66.4 38.33 8 4.62 74.33-42.91-89.28-52.08v-98.03Z"
          className="cls-018429472-6"
        />
      </g>
      <path
        d="m669.69 162.09 72.04-44.11-93.14-54.92 75.92-43.6c-2.69-1.53-5.38-3.06-8.06-4.59l-83.77 48.1 93.52 55.14-72.12 44.16 454.87 264.21 8-4.6-447.26-259.79Z"
        className="cls-018429472-7"
      />
      <path
        d="m1009.31 586.69 7.99-4.6-47.22-27.61 223.72-126.2-266.46-153.85V107.4l-75.17 43.47-93.02-52.51V39.03l-18.26-11.38-7.36 4.84 17.62 10.98v59.56l101.06 57.05 67.13-38.81v157.78l258.32 149.15-223.65 126.15 55.3 32.34z"
        className="cls-018429472-7"
      />
      <g className="cls-018429472-17">
        <path
          d="M1308.78 3809.9v134.06l.07.28 34.02 19.61v-172.9l-59.45-35.24-34.15 19.54 59.51 34.65z"
          className="cls-018429472-6"
        />
        <path
          d="m1109.86 3831.39-74.98 43.29 84.85 50.03v88.19l180.03-103.89 1.02-.45v-94.05l-59.53-34.66-33.39 19.1-345.27-195.7v-86.64l-75.5-43.45-151.74 87.61 474.51 270.63Z"
          className="cls-018429472-6"
        />
      </g>
      <path
        d="M1028.63 3646.52v-67l-149.59-85.35v84.66l323.8 184.63 14.05-8.11-188.26-108.83zM837.57 3295.24v123.25l563.04 320.75v88.33l25.21 15.18v-127.7l-546.78-309.24v-86.62l-41.47-23.95z"
        className="cls-018429472-7"
      />
      <g className="cls-018429472-17">
        <path
          d="M534.16 3319.04 482.27 3349l166.47 97.3 51.82-29.91-166.4-97.35z"
          className="cls-018429472-6"
        />
        <path
          d="m366.25 3197.42 101.39 56.39v86.64l6.68 3.91 59.87-34.56 172.09 100.67c.78.42 1.56.85 2.34 1.27 5.71-3.3 11.42-6.59 17.13-9.89l42.58 24.93v-69.98l-510.81-290.89v192l108.74-60.48Z"
          className="cls-018429472-6"
        />
      </g>
      <g className="cls-018429472-17">
        <path
          d="m438.15 3869.56 165.09 93.48 66.51-38.24-163.74-94.42-67.86 39.18z"
          className="cls-018429472-6"
        />
        <path
          d="M310.81 3538.51c-82.44 47.74-164.89 95.47-247.33 143.21 190.46 109.55 380.93 219.1 571.39 328.65l89.74-53.94-46.84-27.01-74.49 42.84-181.24-102.63 75.97-43.86-253.41-146.13 66.22-38.69v-102.44Z"
          className="cls-018429472-6"
        />
      </g>
      <path
        d="M149.75 3201.79v-61.52l-73.92-43.63v431.95l73.92-42.52v-145.9l-41.99-27.25v-85.28l41.99-25.85z"
        className="cls-018429472-7"
      />
      <g className="cls-018429472-17">
        <path
          d="m425.82 3101.36-307.3-177.09v144.42l77.7 44.55v400.91l-120.39 69.51v57.83l232.12-128.59v-256.8l-75.66 42.78v-274.46l218.96 122.87V2901l-25.43-14.68v215.04z"
          className="cls-018429472-6"
        />
        <path
          d="M75.83 3537.82v36.6l112.39-64.89v-391.65l-77.7-44.56v-162.89l307.3 177.08V2881.7L75.83 2684.14v403.21l81.92 48.35v70.56l-41.99 25.85v76.47l41.99 27.24v154.87l-81.92 47.13z"
          className="cls-018429472-6"
        />
      </g>
      <g className="cls-018429472-17">
        <path
          d="M122.33 2238.3v290.94l303.5 170.55v159.48l56.84 32.78v274.32l300.02 177.81v85.81l253.95 144.89v67.03l188.26 108.82 54.37-31.39 78.93 46.65v196.97c22.69 12.66 45.37 25.32 68.06 37.98-.15-7.19-.29-14.38-.44-21.57v-127.29l-33.21-19.99v-88.21l-563.04-320.75v-132.52l-272.85-157.53v-494.25l-374.65-216.87v-279.88l-164.34-94.91v130.94l104.59 60.18Z"
          className="cls-018429472-6"
        />
        <path
          d="M417.82 2854.65v-150.18l-303.5-170.55v-290.99l-96.59-55.57v152.8c18.83 11.51 37.65 23.02 56.48 34.54.36 93.17.73 186.35 1.09 279.52 114.17 66.81 228.35 133.63 342.52 200.44Z"
          className="cls-018429472-6"
        />
      </g>
      <g className="cls-018429472-17">
        <path
          d="M379.37 3227.49v174.57l86.52 50.81v77.73l-136.29 80.11-2.35-356.1 52.12-27.12Zm79.52 229.39-86.52-50.81v-167.04l-38.09 19.82 2.25 339.68 122.37-71.93v-69.72Z"
          className="cls-018429472-6"
        />
      </g>
      <g className="cls-018429472-17">
        <path
          d="m170.23 3863.01 139.04 80.23v67.13h-7v-63.09l-125.04-72.15v41.43l110.79 64v25.18h-7v-21.14l-110.79-64v-57.59z"
          className="cls-018429472-6"
        />
      </g>
      <g className="cls-018429472-17">
        <path
          d="m159.43 3951.14 77.98 45.19-3.51 6.05-74.57-43.21-83.27 45.55-3.36-6.14 86.73-47.44z"
          className="cls-018429472-6"
        />
      </g>
      <path
        d="m808.87 3983.16.47-54.09 67.53-40.49 68.4 38.05v55.54l12.59 7.25 7.49-4.93-12.08-6.95v-55.61l-76.55-42.59-75.34 45.18-.51 58.57 8 .07zM483.88 3571.84l294.8 169.5v221.72l-86.87-51.15-11.75 6.78c2.68 1.53 5.37 3.06 8.05 4.59l3.66-2.11 94.91 55.88v-240.34l-294.77-169.49-8.03 4.61ZM91.41 3726.04l384.24 222.1-94.84 54.57 3.49 6.06 105.37-60.62L98.43 3722l-7.02 4.04z"
        className="cls-018429472-7"
      />
      <g className="cls-018429472-17">
        <path
          d="m67.15 3703.8-7-4.04v288.55l93.1-53.86v-105.73l174.02 100.23v76.79h7v-80.83l-188.02-108.3v113.8l-79.1 45.76V3703.8zM60.38 3696.6h-.23v.15l.23-.15z"
          className="cls-018429472-6"
        />
      </g>
      <g className="cls-018429472-17">
        <path
          d="m505.18 3830.51.15.26.14-.08-.29-.18zM481.56 3541.82l-132.12 76.85 48.11 27.78-28.84 17.48L521.17 3751v-44.63l91.65 53.1-106.94 61.74c2.68 1.53 5.37 3.06 8.05 4.59l114.87-66.32-115.63-66.99v44.73l-128.69-73.49 28.78-17.44-47.87-27.64 124.17-72.23v-107.23l-81.34-47.45 66.4-38.33-8-4.62-74.33 42.91 89.28 52.08v98.03Z"
          className="cls-018429472-6"
        />
      </g>
      <path
        d="m1056.43 3841.01-72.04 44.11 93.14 54.92-75.92 43.6c2.69 1.53 5.38 3.06 8.06 4.59l83.77-48.1-93.52-55.14 72.12-44.16-454.87-264.21-8 4.6 447.26 259.79Z"
        className="cls-018429472-7"
      />
      <path
        d="m717.29 3417.6-7.99 4.6 47.22 27.61-223.72 126.2 266.46 153.85v167.03l75.17-43.47 93.02 52.51v59.33l18.26 11.38 7.35-4.84-17.61-10.98v-59.56l-101.07-57.05-67.12 38.81v-157.78L548.94 3576.1l223.64-126.16-55.29-32.34z"
        className="cls-018429472-7"
      />
    </g>
    <g id="swiatla">
      <path
        d="m901.4 1625.18 280.77 162.11-37.23 21.49-280.48-162.32-281.1 161.91-36.52-21.08 280.81-162.13-280.1-162.1 36.52-21.09 280.44 161.91 280.43-161.91 37.23 21.49-280.77 161.72z"
        style={{
          fill: "url(#radial-gradient-018429472)",
          opacity: 0.21,
        }}
      />
      <path
        d="M863.53 2296.98v42.72l-281.52-162.53v323.03l-37.23-21.49v-323.03l-281.51-162.54v-42.72l281.51 162.54v-323.03l37.23 21.49v323.03l281.52 162.53z"
        style={{
          opacity: 0.21,
          fill: "url(#radial-gradient-018429472-2)",
        }}
      />
      <path
        d="M1464.76 1950.86v42.73l-281.52 162.53v323.03l-37.22 21.49v-323.03L864.5 2340.15v-42.73l281.52-162.53v-323.03l37.22-21.49v323.03l281.52-162.54z"
        style={{
          fill: "url(#radial-gradient-018429472-3)",
          opacity: 0.21,
        }}
      />
    </g>
    <g id="swiatla_copy" data-name="swiatla copy">
      <path
        d="m901.4 1625.18 280.77 162.11-37.23 21.49-280.48-162.32-281.1 161.91-36.52-21.08 280.81-162.13-280.1-162.1 36.52-21.09 280.44 161.91 280.43-161.91 37.23 21.49-280.77 161.72z"
        className="cls-018429472-14"
      />
      <path
        d="M863.53 2296.98v42.72l-281.52-162.53v323.03l-37.23-21.49v-323.03l-281.51-162.54v-42.72l281.51 162.54v-323.03l37.23 21.49v323.03l281.52 162.53z"
        style={{
          fill: "url(#radial-gradient-018429472-5)",
        }}
      />
      <path
        d="M1464.76 1950.86v42.73l-281.52 162.53v323.03l-37.22 21.49v-323.03L864.5 2340.15v-42.73l281.52-162.53v-323.03l37.22-21.49v323.03l281.52-162.54z"
        style={{
          fill: "url(#radial-gradient-018429472-6)",
        }}
      />
      <path
        d="m901.4 1625.18 280.77 162.11-37.23 21.49-280.48-162.32-281.1 161.91-36.52-21.08 280.81-162.13-280.1-162.1 36.52-21.09 280.44 161.91 280.43-161.91 37.23 21.49-280.77 161.72z"
        className="cls-018429472-14"
      />
      <path
        d="M863.53 2296.98v42.72l-281.52-162.53v323.03l-37.23-21.49v-323.03l-281.51-162.54v-42.72l281.51 162.54v-323.03l37.23 21.49v323.03l281.52 162.53z"
        style={{
          fill: "url(#radial-gradient-018429472-7)",
        }}
      />
      <path
        d="M1464.76 1950.86v42.73l-281.52 162.53v323.03l-37.22 21.49v-323.03L864.5 2340.15v-42.73l281.52-162.53v-323.03l37.22-21.49v323.03l281.52-162.54z"
        style={{
          fill: "url(#radial-gradient-018429472-8)",
        }}
      />
    </g>
    <g id="ozdoba">
      <path
        d="M695.68 2002.19v55.11c0 1.11.13 1.64.74 2.57.63.96 1.25 1.92 1.88 2.88l3.23 4.94c.49.74.91 1.63 1.61 2.18.87.69 2.04 1.18 3.01 1.74 7.02 4.05 14.04 8.11 21.06 12.16 8.02 4.63 16.04 9.27 24.07 13.9.98.57 2.26-.15 2.26-1.3v-45.82c0-1.09.15-2.31-.05-3.39-.36-1.86-1.3-3.77-1.91-5.56-.69-2.01-1.38-4.02-2.07-6.02-.58-1.7-1.14-3.41-1.75-5.1-.68-1.86-3.07-2.64-4.71-3.59-7.01-4.04-14.03-8.09-21.04-12.13-8.02-4.62-16.03-9.24-24.05-13.87-1.68-.97-3.19 1.63-1.51 2.59 16.32 9.41 32.63 18.82 48.95 28.23l-.69-.9c1.38 4.02 2.77 8.03 4.15 12.05.69 2 1.7 4.06 1.7 6.19v47.32c.75-.43 1.5-.86 2.26-1.3-13.9-8.03-27.79-16.05-41.69-24.08-1.72-.99-3.44-1.99-5.16-2.98-.74-.43-1.08-.71-1.56-1.44-.63-.96-1.25-1.92-1.88-2.88l-3.23-4.94c-.5-.76-.6-1.07-.6-1.97v-54.61c0-1.93-3-1.93-3 0Z"
        style={{
          fill: "url(#linear-gradient-018429472)",
        }}
      />
      <path
        d="M341.03 1768.4c26.15 15.24 52.3 30.49 78.45 45.73 7.33 4.27 14.66 8.54 21.98 12.82 1.67.97 3.18-1.62 1.51-2.59-26.15-15.24-52.3-30.49-78.45-45.73-7.33-4.27-14.66-8.54-21.98-12.82-1.67-.97-3.18 1.62-1.51 2.59Z"
        style={{
          fill: "url(#linear-gradient-018429472-2)",
        }}
      />
      <path
        d="m342.94 1880.94.54-75.42.15-21.48c.01-1.93-2.99-1.93-3 0l-.54 75.42-.15 21.48c-.01 1.93 2.99 1.93 3 0Z"
        style={{
          fill: "url(#linear-gradient-018429472-3)",
        }}
      />
      <path
        d="M460.69 1946.75c.15-21.38.31-42.76.46-64.14.04-6.07.09-12.15.13-18.22.01-1.93-2.99-1.93-3 0-.15 21.38-.31 42.76-.46 64.14-.04 6.07-.09 12.15-.13 18.22-.01 1.93 2.99 1.93 3 0Z"
        style={{
          fill: "url(#linear-gradient-018429472-4)",
        }}
      />
      <path
        d="M357.37 1902.24c26.15 15.24 52.3 30.49 78.45 45.73 7.33 4.27 14.66 8.54 21.98 12.82 1.67.97 3.18-1.62 1.51-2.59-26.15-15.24-52.3-30.49-78.45-45.73-7.33-4.27-14.66-8.54-21.98-12.82-1.67-.97-3.18 1.62-1.51 2.59Z"
        style={{
          fill: "url(#linear-gradient-018429472-5)",
        }}
      />
      <path
        d="M304.42 1697.81v192.84c0 1.46-.37 3.51.13 4.88.58 1.58 2.02 3.15 2.94 4.56l5.73 8.76c3.88 5.93 7.75 11.86 11.63 17.79.89 1.37 1.79 2.74 2.68 4.11.38.58.7 1.2 1.25 1.63.75.59 1.74 1.01 2.57 1.49 3.14 1.82 6.29 3.63 9.43 5.45 24.67 14.25 49.33 28.5 74 42.75s49.33 28.5 74 42.75c3.05 1.76 6.1 3.52 9.14 5.28.98.57 2.26-.15 2.26-1.3v-170.21c0-3.3-1.77-6.69-2.83-9.79-1.18-3.42-2.35-6.83-3.53-10.25-2.28-6.63-4.56-13.25-6.84-19.88-2.32-6.73-4.63-13.46-6.95-20.19-.55-1.61-.71-2.4-2.19-3.26-1.62-.94-3.25-1.87-4.87-2.81-3.06-1.76-6.12-3.53-9.17-5.29-24.66-14.22-49.31-28.44-73.97-42.65-24.66-14.22-49.31-28.44-73.97-42.65-3.06-1.76-6.12-3.53-9.17-5.29-1.68-.97-3.19 1.63-1.51 2.59 22.36 12.9 44.73 25.79 67.09 38.69 22.36 12.9 44.73 25.79 67.09 38.69 12.61 7.27 25.23 14.55 37.84 21.82l-.69-.9c4.95 14.39 9.91 28.78 14.86 43.17 1.25 3.62 2.49 7.25 3.74 10.87.57 1.65 1.21 3.3 1.71 4.97.59 1.96.37 4.08.37 6.12v166.25c.75-.43 1.5-.86 2.26-1.3-24.48-14.14-48.95-28.28-73.43-42.42-24.48-14.14-48.95-28.28-73.43-42.42-6.1-3.52-12.19-7.04-18.29-10.56-1.43-.83-3.21-1.51-4.18-2.87s-1.91-2.92-2.86-4.38l-5.73-8.76c-3.82-5.84-7.63-11.68-11.45-17.52-.83-1.27-2.29-2.8-2.66-4.28-.19-.77 0-1.85 0-2.65V1697.82c0-1.93-3-1.93-3 0Z"
        className="cls-018429472-6"
      />
      <path
        d="M329.24 1741.6V1883.58c0 1.05-.24 2.39.05 3.4.34 1.19 1.51 2.43 2.19 3.47 1.4 2.15 2.8 4.29 4.21 6.44 2.89 4.43 5.79 8.86 8.68 13.29.68 1.04 1.36 2.08 2.04 3.11.75 1.14 1.73 1.61 2.89 2.28l6.69 3.87c18.21 10.52 36.43 21.04 54.64 31.56l54.42 31.44 6.69 3.87c.98.57 2.26-.15 2.26-1.3v-125.39c0-1.26-.46-2.32-.87-3.5-.43-1.26-.87-2.51-1.3-3.77-.87-2.51-1.73-5.03-2.6-7.54-1.68-4.87-3.35-9.74-5.03-14.61l-5.19-15.09c-.16-.48-.28-1.05-.59-1.45s-.84-.63-1.28-.88c-1.22-.71-2.45-1.41-3.67-2.12-2.23-1.29-4.46-2.57-6.69-3.86-18.13-10.46-36.27-20.91-54.4-31.37-18.06-10.42-36.12-20.83-54.19-31.25-2.23-1.29-4.46-2.57-6.69-3.86-1.68-.97-3.19 1.63-1.51 2.59 16.48 9.5 32.96 19 49.44 28.51 16.41 9.46 32.81 18.92 49.22 28.38 9.28 5.35 18.57 10.71 27.85 16.06l-.69-.9c3.65 10.61 7.3 21.21 10.95 31.82l2.76 8.01c.43 1.25.93 2.5 1.3 3.77.41 1.41.19 3.09.19 4.55v121.9c.75-.43 1.5-.86 2.26-1.3-18-10.4-35.99-20.79-53.99-31.19-18-10.4-35.99-20.79-53.99-31.19-4.46-2.58-8.93-5.16-13.39-7.73-.94-.54-2.19-1.02-2.89-1.83-.89-1.03-1.56-2.39-2.31-3.53-1.4-2.15-2.8-4.29-4.21-6.44-2.8-4.29-5.61-8.58-8.41-12.87-.56-.85-1.53-1.89-1.83-2.86-.17-.54 0-1.42 0-2V1741.59c0-1.93-3-1.93-3 0Z"
        style={{
          fill: "url(#linear-gradient-018429472-6)",
        }}
      />
      <path
        d="M382.18 1832.49v32.48c0 1.32-.06 2.65 0 3.97s1.08 2.35 1.76 3.38 1.38 2.11 2.07 3.17c.29.44.55.95.89 1.34.45.51 1.13.8 1.71 1.13 4.74 2.74 9.49 5.48 14.23 8.22 5.32 3.07 10.64 6.15 15.96 9.22.98.57 2.26-.15 2.26-1.3v-28.19c0-1.32.21-2.84-.05-4.14-.24-1.18-.82-2.37-1.21-3.51-.46-1.32-.91-2.65-1.37-3.97-.4-1.17-.78-2.35-1.21-3.51-.47-1.28-1.81-1.73-2.93-2.38-4.69-2.7-9.38-5.41-14.06-8.11-5.27-3.04-10.53-6.07-15.8-9.11-1.68-.97-3.19 1.63-1.51 2.59 10.67 6.16 21.35 12.31 32.02 18.47l-.69-.9c1.18 3.42 2.46 6.83 3.54 10.28.52 1.65.27 3.56.27 5.28v27.19c.75-.43 1.5-.86 2.26-1.3-9.06-5.23-18.12-10.47-27.18-15.7-1.15-.66-2.31-1.32-3.45-1.99-.99-.59-1.51-1.77-2.13-2.73s-1.29-1.98-1.94-2.97c-.68-1.04-.44-2.23-.44-3.46v-33.47c0-1.93-3-1.93-3 0Z"
        style={{
          fill: "url(#linear-gradient-018429472-7)",
        }}
      />
      <path
        d="M372.7 1816.21v55.11c0 1.11.13 1.64.74 2.57s1.25 1.92 1.88 2.88l3.23 4.94c.49.74.91 1.63 1.61 2.18.87.69 2.04 1.18 3.01 1.74 7.02 4.05 14.04 8.11 21.06 12.16 8.02 4.63 16.04 9.27 24.07 13.9.98.57 2.26-.15 2.26-1.3v-45.82c0-1.09.15-2.31-.05-3.39-.36-1.86-1.3-3.77-1.91-5.56-.69-2.01-1.38-4.02-2.07-6.02-.58-1.7-1.14-3.41-1.75-5.1-.68-1.86-3.07-2.64-4.71-3.59-7.01-4.04-14.03-8.09-21.04-12.13-8.02-4.62-16.03-9.24-24.05-13.87-1.68-.97-3.19 1.63-1.51 2.59 16.32 9.41 32.63 18.82 48.95 28.23l-.69-.9c1.38 4.02 2.77 8.03 4.15 12.05.69 2 1.7 4.06 1.7 6.19v47.32c.75-.43 1.5-.86 2.26-1.3-13.9-8.03-27.79-16.05-41.69-24.08-1.72-.99-3.44-1.99-5.16-2.98-.74-.43-1.08-.71-1.56-1.44-.63-.96-1.25-1.92-1.88-2.88l-3.23-4.94c-.5-.76-.6-1.07-.6-1.97v-54.61c0-1.93-3-1.93-3 0Z"
        style={{
          fill: "url(#linear-gradient-018429472-8)",
        }}
      />
      <path
        d="M664.01 1954.39c26.15 15.24 52.3 30.49 78.45 45.73 7.33 4.27 14.66 8.54 21.98 12.82 1.67.97 3.18-1.62 1.51-2.59-26.15-15.24-52.3-30.49-78.45-45.73-7.33-4.27-14.66-8.54-21.98-12.82-1.67-.97-3.18 1.62-1.51 2.59Z"
        style={{
          fill: "url(#linear-gradient-018429472-9)",
        }}
      />
      <path
        d="m665.92 2066.93.54-75.42.15-21.48c.01-1.93-2.99-1.93-3 0l-.54 75.42-.15 21.48c-.01 1.93 2.99 1.93 3 0Z"
        style={{
          fill: "url(#linear-gradient-018429472-10)",
        }}
      />
      <path
        d="M783.67 2132.74c.15-21.38.31-42.76.46-64.14.04-6.07.09-12.15.13-18.22.01-1.93-2.99-1.93-3 0-.15 21.38-.31 42.76-.46 64.14-.04 6.07-.09 12.15-.13 18.22-.01 1.93 2.99 1.93 3 0Z"
        style={{
          fill: "url(#linear-gradient-018429472-11)",
        }}
      />
      <path
        d="M680.36 2088.23c26.15 15.24 52.3 30.49 78.45 45.73 7.33 4.27 14.66 8.54 21.98 12.82 1.67.97 3.18-1.62 1.51-2.59-26.15-15.24-52.3-30.49-78.45-45.73-7.33-4.27-14.66-8.54-21.98-12.82-1.67-.97-3.18 1.62-1.51 2.59Z"
        style={{
          fill: "url(#linear-gradient-018429472-12)",
        }}
      />
      <path
        d="M626.73 1883.8v192.84c0 1.46-.37 3.51.13 4.88.58 1.58 2.02 3.15 2.94 4.56l5.73 8.76c3.88 5.93 7.75 11.86 11.63 17.79.89 1.37 1.79 2.74 2.68 4.11.38.58.7 1.2 1.25 1.63.75.59 1.74 1.01 2.57 1.49 3.14 1.82 6.29 3.63 9.43 5.45 24.67 14.25 49.33 28.5 74 42.75s49.33 28.5 74 42.75c3.05 1.76 6.1 3.52 9.14 5.28.98.57 2.26-.15 2.26-1.3v-170.21c0-3.3-1.77-6.69-2.83-9.79-1.18-3.42-2.35-6.83-3.53-10.25-2.28-6.63-4.56-13.25-6.84-19.88-2.32-6.73-4.63-13.46-6.95-20.19-.55-1.61-.71-2.4-2.19-3.26-1.62-.94-3.25-1.87-4.87-2.81-3.06-1.76-6.12-3.53-9.17-5.29-24.66-14.22-49.31-28.44-73.97-42.65-24.66-14.22-49.31-28.44-73.97-42.65-3.06-1.76-6.12-3.53-9.17-5.29-1.68-.97-3.19 1.63-1.51 2.59 22.36 12.9 44.73 25.79 67.09 38.69 22.36 12.9 44.73 25.79 67.09 38.69 12.61 7.27 25.23 14.55 37.84 21.82l-.69-.9c4.95 14.39 9.91 28.78 14.86 43.17 1.25 3.62 2.49 7.25 3.74 10.87.57 1.65 1.21 3.3 1.71 4.97.59 1.96.37 4.08.37 6.12v166.25c.75-.43 1.5-.86 2.26-1.3-24.48-14.14-48.95-28.28-73.43-42.42-24.48-14.14-48.95-28.28-73.43-42.42-6.1-3.52-12.19-7.04-18.29-10.56-1.43-.83-3.21-1.51-4.18-2.87-1.01-1.42-1.91-2.92-2.86-4.38l-5.73-8.76c-3.82-5.84-7.63-11.68-11.45-17.52-.83-1.27-2.29-2.8-2.66-4.28-.19-.77 0-1.85 0-2.65V1883.81c0-1.93-3-1.93-3 0Z"
        className="cls-018429472-6"
      />
      <path
        d="M652.23 1927.59V2069.57c0 1.05-.24 2.39.05 3.4.34 1.19 1.51 2.43 2.19 3.47 1.4 2.15 2.8 4.29 4.21 6.44 2.89 4.43 5.79 8.86 8.68 13.29.68 1.04 1.36 2.08 2.04 3.11.75 1.14 1.73 1.61 2.89 2.28l6.69 3.87c18.21 10.52 36.43 21.04 54.64 31.56l54.42 31.44 6.69 3.87c.98.57 2.26-.15 2.26-1.3v-125.39c0-1.26-.46-2.32-.87-3.5-.43-1.26-.87-2.51-1.3-3.77-.87-2.51-1.73-5.03-2.6-7.54-1.68-4.87-3.35-9.74-5.03-14.61L782 2001.1c-.16-.48-.28-1.05-.59-1.45s-.84-.63-1.28-.88c-1.22-.71-2.45-1.41-3.67-2.12-2.23-1.29-4.46-2.57-6.69-3.86-18.13-10.46-36.27-20.91-54.4-31.37-18.06-10.42-36.12-20.83-54.19-31.25-2.23-1.29-4.46-2.57-6.69-3.86-1.68-.97-3.19 1.63-1.51 2.59 16.48 9.5 32.96 19 49.44 28.51 16.41 9.46 32.81 18.92 49.22 28.38 9.28 5.35 18.57 10.71 27.85 16.06l-.69-.9c3.65 10.61 7.3 21.21 10.95 31.82l2.76 8.01c.43 1.25.93 2.5 1.3 3.77.41 1.41.19 3.09.19 4.55V2171c.75-.43 1.5-.86 2.26-1.3-18-10.4-35.99-20.79-53.99-31.19-18-10.4-35.99-20.79-53.99-31.19-4.46-2.58-8.93-5.16-13.39-7.73-.94-.54-2.19-1.02-2.89-1.83-.89-1.03-1.56-2.39-2.31-3.53-1.4-2.15-2.8-4.29-4.21-6.44-2.8-4.29-5.61-8.58-8.41-12.87-.56-.85-1.53-1.89-1.83-2.86-.17-.54 0-1.42 0-2V1927.58c0-1.93-3-1.93-3 0Z"
        style={{
          fill: "url(#linear-gradient-018429472-13)",
        }}
      />
      <path
        d="M705.16 2018.48v32.48c0 1.32-.06 2.65 0 3.97s1.08 2.35 1.76 3.38 1.38 2.11 2.07 3.17c.29.44.55.95.89 1.34.45.51 1.13.8 1.71 1.13 4.74 2.74 9.49 5.48 14.23 8.22 5.32 3.07 10.64 6.15 15.96 9.22.98.57 2.26-.15 2.26-1.3v-28.19c0-1.32.21-2.84-.05-4.14-.24-1.18-.82-2.37-1.21-3.51-.46-1.32-.91-2.65-1.37-3.97-.4-1.17-.78-2.35-1.21-3.51-.47-1.28-1.81-1.73-2.93-2.38-4.69-2.7-9.38-5.41-14.06-8.11-5.27-3.04-10.53-6.07-15.8-9.11-1.68-.97-3.19 1.63-1.51 2.59 10.67 6.16 21.35 12.31 32.02 18.47l-.69-.9c1.18 3.42 2.46 6.83 3.54 10.28.52 1.65.27 3.56.27 5.28v27.19l2.26-1.3c-9.06-5.23-18.12-10.47-27.18-15.7-1.15-.66-2.31-1.32-3.45-1.99-.99-.59-1.51-1.77-2.13-2.73-.65-.99-1.29-1.98-1.94-2.97-.68-1.04-.44-2.23-.44-3.46v-33.47c0-1.93-3-1.93-3 0Z"
        style={{
          fill: "url(#linear-gradient-018429472-14)",
        }}
      />
      <path
        d="M1295.81 1616.46c-26.13 15.27-52.27 30.54-78.4 45.81-7.32 4.28-14.65 8.56-21.97 12.84-1.67.97-.16 3.57 1.51 2.59 26.13-15.27 52.27-30.54 78.4-45.81 7.32-4.28 14.65-8.56 21.97-12.84 1.67-.97.16-3.57-1.51-2.59Z"
        style={{
          fill: "url(#linear-gradient-018429472-15)",
        }}
      />
      <path
        d="M1063.52 1619.05c26.13 15.27 52.27 30.54 78.4 45.81 7.32 4.28 14.65 8.56 21.97 12.84 1.67.98 3.18-1.62 1.51-2.59-26.13-15.27-52.27-30.54-78.4-45.81-7.32-4.28-14.65-8.56-21.97-12.84-1.67-.98-3.18 1.62-1.51 2.59Z"
        style={{
          fill: "url(#linear-gradient-018429472-16)",
        }}
      />
      <path
        d="M1195.57 1561.47c21.8 12.52 43.6 25.05 65.4 37.57 6.21 3.57 12.42 7.13 18.62 10.7 1.68.96 3.19-1.63 1.51-2.59-21.8-12.52-43.6-25.05-65.4-37.57-6.21-3.57-12.42-7.13-18.62-10.7-1.68-.96-3.19 1.63-1.51 2.59Z"
        style={{
          fill: "url(#linear-gradient-018429472-17)",
        }}
      />
      <path
        d="M1165.26 1558.88c-25.83 15.41-51.67 30.81-77.5 46.22l-21.84 13.02c-1.66.99-.15 3.58 1.51 2.59 25.83-15.41 51.67-30.81 77.5-46.22l21.84-13.02c1.66-.99.15-3.58-1.51-2.59Z"
        style={{
          fill: "url(#linear-gradient-018429472-18)",
        }}
      />
      <path
        d="M1378.12 1620.87c-25.1-14.5-50.19-29.01-75.29-43.51-25.21-14.57-50.42-29.14-75.62-43.71-6.27-3.63-12.55-7.25-18.82-10.88-1.52-.88-2.25-1.18-3.98-1.18h-38.2c-3.59 0-7.22-.14-10.8 0-1.51.06-2.86 1.24-4.13 2l-4.65 2.79c-24.89 14.94-49.77 29.89-74.66 44.83-24.78 14.88-49.55 29.76-74.33 44.63-3.1 1.86-6.19 3.72-9.29 5.58-.96.58-.98 2.01 0 2.59 24.88 14.65 49.77 29.3 74.65 43.95 25 14.71 49.99 29.43 74.99 44.14 6.22 3.66 12.44 7.32 18.66 10.99 1.42.84 2.45.8 4.08.82l5.82.06 10.86.12c7.24.08 14.48.16 21.71.23 1.48.02 3.24.35 4.58-.36 1.6-.84 3.14-1.81 4.7-2.71 3.14-1.81 6.27-3.61 9.41-5.42 25.2-14.52 50.41-29.05 75.61-43.57l75.27-43.38c3.14-1.81 6.27-3.61 9.41-5.42 1.67-.96.16-3.56-1.51-2.59-24.87 14.33-49.73 28.66-74.6 42.99-24.87 14.33-49.73 28.66-74.6 42.99-6.27 3.61-12.55 7.23-18.82 10.84-1.46.84-2.91 1.68-4.37 2.52-.76.44-1.49.99-2.37 1.14-.96.16-2.13-.02-3.1-.03-3.49-.04-6.98-.08-10.47-.11-7.24-.08-14.48-.16-21.71-.23l-5.04-.05c-.86 0-1.85.11-2.67-.12s-1.6-.84-2.34-1.28l-9.33-5.49c-24.66-14.52-49.32-29.04-73.99-43.55-24.66-14.52-49.32-29.04-73.99-43.55l-9.33-5.49v2.59c24.55-14.74 49.11-29.49 73.66-44.23 24.55-14.74 49.11-29.49 73.66-44.23 3.1-1.86 6.19-3.72 9.29-5.58 1.44-.86 2.88-1.73 4.31-2.59s3.33-2.47 4.99-2.78c1.56-.29 3.43 0 5.02 0h42.84c2.01 0 3.21.75 4.92 1.74 3.14 1.81 6.27 3.63 9.41 5.44 24.87 14.37 49.74 28.75 74.61 43.12 24.87 14.37 49.74 28.75 74.61 43.12 3.14 1.81 6.27 3.63 9.41 5.44 1.67.97 3.19-1.62 1.51-2.59Z"
        className="cls-018429472-6"
      />
      <path
        d="M1323.59 1618.01c-18.17-10.61-36.33-21.23-54.5-31.84s-36.33-21.23-54.5-31.84c-4.52-2.64-8.99-5.38-13.56-7.93-1.05-.58-2.02-.48-3.2-.48l-4.19.02-7.81.03-15.63.06c-2.6.01-5.21-.03-7.81.03-1.3.03-2.14.7-3.24 1.36-1.12.67-2.24 1.35-3.36 2.02-18.02 10.82-36.04 21.64-54.06 32.47-18.02 10.82-36.04 21.64-54.06 32.47-2.24 1.35-4.49 2.69-6.73 4.04-.96.58-.98 2.01 0 2.59 18.02 10.61 36.04 21.22 54.06 31.82 18.1 10.66 36.2 21.31 54.3 31.97 4.51 2.65 9.01 5.3 13.52 7.96 1.09.64 1.92.64 3.17.65l4.21.05c2.62.03 5.24.06 7.86.08 5.24.06 10.48.11 15.72.17 1.17.01 2.47.24 3.53-.32 1.16-.61 2.27-1.31 3.41-1.96l6.81-3.93c18.25-10.52 36.5-21.03 54.75-31.55l54.51-31.41 6.81-3.93c1.67-.96.16-3.56-1.51-2.59-18.01 10.38-36.02 20.75-54.02 31.13-18.01 10.38-36.02 20.75-54.02 31.13-4.54 2.62-9.08 5.24-13.63 7.85-1.05.61-2.11 1.22-3.16 1.82-.48.28-.96.66-1.51.77-.67.14-1.57-.02-2.25-.02-2.53-.03-5.05-.05-7.58-.08-5.24-.06-10.48-.11-15.72-.17l-3.65-.04c-.57 0-1.29.09-1.83-.06s-1.1-.61-1.59-.9c-2.25-1.33-4.51-2.65-6.76-3.98-17.86-10.51-35.72-21.03-53.58-31.54-17.86-10.51-35.72-21.03-53.58-31.54-2.25-1.33-4.51-2.65-6.76-3.98v2.59c17.78-10.68 35.56-21.35 53.34-32.03 17.86-10.73 35.72-21.45 53.58-32.18 2.24-1.35 4.49-2.69 6.73-4.04l3.12-1.88c.99-.59 2.28-1.71 3.44-1.82 2.47-.23 5.06-.02 7.54-.03l7.81-.03 15.63-.06c1.21 0 2.42 0 3.63-.01.61 0 1.37-.15 1.95 0s1.2.61 1.74.93c2.18 1.27 4.36 2.55 6.54 3.82 18.01 10.52 36.01 21.04 54.02 31.56 17.93 10.47 35.85 20.95 53.78 31.42l6.78 3.96c1.67.98 3.18-1.62 1.51-2.59Z"
        style={{
          fill: "url(#linear-gradient-018429472-19)",
        }}
      />
      <path
        d="M1237.61 1618.57c-14.86-8.59-29.71-17.17-44.57-25.76-1.79-1.04-3.57-2.11-5.38-3.11-.97-.54-1.84-.45-2.93-.45H1175c-1.08 0-2.17-.05-3.24 0s-1.85.71-2.72 1.23c-7.34 4.41-14.69 8.82-22.03 13.23l-25.02 15.03c-.96.58-.98 2.01 0 2.59 14.8 8.71 29.6 17.43 44.4 26.14 1.74 1.03 3.51 2.35 5.36 3.16.89.39 2.05.22 3.01.23l3.25.04c2.17.02 4.33.05 6.5.07 1.21.01 1.78-.21 2.84-.82.94-.54 1.87-1.08 2.81-1.62l22.23-12.81c8.42-4.85 16.83-9.7 25.25-14.55 1.67-.96.16-3.56-1.51-2.59-14.53 8.37-29.06 16.75-43.59 25.12-1.8 1.04-3.6 2.07-5.4 3.11-.75.43-1.51 1.05-2.37 1.16-1.04.13-2.2-.02-3.25-.04-2.17-.02-4.33-.05-6.5-.07-.91 0-1.79.13-2.6-.33-.94-.53-1.86-1.09-2.79-1.64l-21.66-12.75c-8.15-4.8-16.3-9.6-24.45-14.39v2.59c14.4-8.65 28.8-17.3 43.2-25.94.86-.51 1.71-1.03 2.57-1.54.8-.48 1.76-1.32 2.65-1.57.82-.23 1.98-.03 2.83-.03h9.73c1.07 0 1.61 0 2.53.54s1.87 1.08 2.8 1.62l21.75 12.57c8.18 4.73 16.36 9.46 24.55 14.19 1.67.97 3.19-1.62 1.51-2.59Z"
        style={{
          fill: "url(#linear-gradient-018429472-20)",
        }}
      />
      <path
        d="M1214.9 1618.61c-9.06-5.24-18.12-10.47-27.18-15.71-1.04-.6-2.1-1.39-3.21-1.86s-2.66-.2-3.85-.21h-3.56c-1.25 0-2.45-.17-3.56.46-4.6 2.63-9.1 5.46-13.64 8.19-5.11 3.07-10.23 6.14-15.34 9.21-.96.58-.98 2.01 0 2.59 9 5.3 18.01 10.6 27.01 15.9 1.02.6 2.22 1.6 3.36 1.92s2.64.08 3.83.09l3.67.04c1.38.01 2.25-.64 3.43-1.31 4.52-2.6 9.03-5.21 13.55-7.81 5.16-2.98 10.33-5.95 15.49-8.93 1.67-.96.16-3.56-1.51-2.59-8.75 5.04-17.5 10.08-26.24 15.12-1 .58-2.01 1.16-3.01 1.74-.44.25-.96.67-1.45.78-.58.13-1.36-.01-1.96-.02-1.14-.01-2.29-.02-3.43-.04-.51 0-1.14.07-1.63-.07-.58-.17-1.19-.7-1.71-1.01-4.36-2.57-8.72-5.13-13.08-7.7-4.93-2.9-9.86-5.81-14.79-8.71v2.59c8.67-5.2 17.33-10.41 26-15.61.92-.55 2.03-1.53 3.06-1.82 1.04-.3 2.55-.03 3.64-.03h3.56c.49 0 .86-.02 1.31.17.67.27 1.3.75 1.93 1.11 4.28 2.47 8.56 4.95 12.84 7.42 4.99 2.89 9.99 5.77 14.98 8.66 1.67.97 3.19-1.62 1.51-2.59Z"
        style={{
          fill: "url(#linear-gradient-018429472-21)",
        }}
      />
      <path
        d="M977.17 1794.55c-26.13 15.27-52.27 30.54-78.4 45.81-7.32 4.28-14.65 8.56-21.97 12.84-1.67.97-.16 3.57 1.51 2.59 26.13-15.27 52.27-30.54 78.4-45.81 7.32-4.28 14.65-8.56 21.97-12.84 1.67-.97.16-3.57-1.51-2.59Z"
        style={{
          fill: "url(#linear-gradient-018429472-22)",
        }}
      />
      <path
        d="M744.87 1797.14c26.13 15.27 52.27 30.54 78.4 45.81 7.32 4.28 14.65 8.56 21.97 12.84 1.67.98 3.18-1.62 1.51-2.59-26.13-15.27-52.27-30.54-78.4-45.81-7.32-4.28-14.65-8.56-21.97-12.84-1.67-.98-3.18 1.62-1.51 2.59Z"
        style={{
          fill: "url(#linear-gradient-018429472-23)",
        }}
      />
      <path
        d="M876.92 1739.56c21.8 12.52 43.6 25.05 65.4 37.57 6.21 3.57 12.42 7.13 18.62 10.7 1.68.96 3.19-1.63 1.51-2.59-21.8-12.52-43.6-25.05-65.4-37.57-6.21-3.57-12.42-7.13-18.62-10.7-1.68-.96-3.19 1.63-1.51 2.59Z"
        style={{
          fill: "url(#linear-gradient-018429472-24)",
        }}
      />
      <path
        d="M846.61 1736.97c-25.83 15.41-51.67 30.81-77.5 46.22l-21.84 13.02c-1.66.99-.15 3.58 1.51 2.59 25.83-15.41 51.67-30.81 77.5-46.22l21.84-13.02c1.66-.99.15-3.58-1.51-2.59Z"
        style={{
          fill: "url(#linear-gradient-018429472-25)",
        }}
      />
      <path
        d="M1059.47 1798.96c-25.1-14.5-50.19-29.01-75.29-43.51-25.21-14.57-50.42-29.14-75.62-43.71-6.27-3.63-12.55-7.25-18.82-10.88-1.52-.88-2.25-1.18-3.98-1.18h-38.2c-3.59 0-7.22-.14-10.8 0-1.51.06-2.86 1.24-4.13 2l-4.65 2.79c-24.89 14.94-49.77 29.89-74.66 44.83-24.78 14.88-49.55 29.76-74.33 44.63-3.1 1.86-6.19 3.72-9.29 5.58-.96.58-.98 2.01 0 2.59 24.88 14.65 49.77 29.3 74.65 43.95 25 14.71 49.99 29.43 74.99 44.14 6.22 3.66 12.44 7.32 18.66 10.99 1.42.84 2.45.8 4.08.82l5.82.06 10.86.12c7.24.08 14.48.16 21.71.23 1.48.02 3.24.35 4.58-.36 1.6-.84 3.14-1.81 4.7-2.71 3.14-1.81 6.27-3.61 9.41-5.42 25.2-14.52 50.41-29.05 75.61-43.57l75.27-43.38c3.14-1.81 6.27-3.61 9.41-5.42 1.67-.96.16-3.56-1.51-2.59-24.87 14.33-49.73 28.66-74.6 42.99-24.87 14.33-49.73 28.66-74.6 42.99-6.27 3.61-12.55 7.23-18.82 10.84-1.46.84-2.91 1.68-4.37 2.52-.76.44-1.49.99-2.37 1.14-.96.16-2.13-.02-3.1-.03-3.49-.04-6.98-.08-10.47-.11-7.24-.08-14.48-.16-21.71-.23l-5.04-.05c-.86 0-1.85.11-2.67-.12s-1.6-.84-2.34-1.28l-9.33-5.49c-24.66-14.52-49.32-29.04-73.99-43.55-24.66-14.52-49.32-29.04-73.99-43.55l-9.33-5.49v2.59c24.55-14.74 49.11-29.49 73.66-44.23 24.55-14.74 49.11-29.49 73.66-44.23 3.1-1.86 6.19-3.72 9.29-5.58 1.44-.86 2.88-1.73 4.31-2.59 1.45-.87 3.33-2.47 4.99-2.78 1.56-.29 3.43 0 5.02 0h42.84c2.01 0 3.21.75 4.92 1.74 3.14 1.81 6.27 3.63 9.41 5.44 24.87 14.37 49.74 28.75 74.61 43.12 24.87 14.37 49.74 28.75 74.61 43.12 3.14 1.81 6.27 3.63 9.41 5.44 1.67.97 3.19-1.62 1.51-2.59Z"
        className="cls-018429472-6"
      />
      <path
        d="M1004.94 1796.1c-18.17-10.61-36.33-21.23-54.5-31.84s-36.33-21.23-54.5-31.84c-4.52-2.64-8.99-5.38-13.56-7.93-1.05-.58-2.02-.48-3.2-.48l-4.19.02-7.81.03-15.63.06c-2.6.01-5.21-.03-7.81.03-1.3.03-2.14.7-3.24 1.36-1.12.67-2.24 1.35-3.36 2.02-18.02 10.82-36.04 21.64-54.06 32.47-18.02 10.82-36.04 21.64-54.06 32.47-2.24 1.35-4.49 2.69-6.73 4.04-.96.58-.98 2.01 0 2.59 18.02 10.61 36.04 21.22 54.06 31.82 18.1 10.66 36.2 21.31 54.3 31.97 4.51 2.65 9.01 5.3 13.52 7.96 1.09.64 1.92.64 3.17.65l4.21.05c2.62.03 5.24.06 7.86.08 5.24.06 10.48.11 15.72.17 1.17.01 2.47.24 3.53-.32 1.16-.61 2.27-1.31 3.41-1.96l6.81-3.93c18.25-10.52 36.5-21.03 54.75-31.55l54.51-31.41 6.81-3.93c1.67-.96.16-3.56-1.51-2.59-18.01 10.38-36.02 20.75-54.02 31.13-18.01 10.38-36.02 20.75-54.02 31.13-4.54 2.62-9.08 5.24-13.63 7.85-1.05.61-2.11 1.22-3.16 1.82-.48.28-.96.66-1.51.77-.67.14-1.57-.02-2.25-.02-2.53-.03-5.05-.05-7.58-.08-5.24-.06-10.48-.11-15.72-.17-1.22-.01-2.43-.03-3.65-.04-.57 0-1.29.09-1.83-.06s-1.1-.61-1.59-.9c-2.25-1.33-4.51-2.65-6.76-3.98-17.86-10.51-35.72-21.03-53.58-31.54-17.86-10.51-35.72-21.03-53.58-31.54-2.25-1.33-4.51-2.65-6.76-3.98v2.59c17.78-10.68 35.56-21.35 53.34-32.03 17.86-10.73 35.72-21.45 53.58-32.18l6.73-4.04 3.12-1.88c.99-.59 2.28-1.71 3.44-1.82 2.47-.23 5.06-.02 7.54-.03l7.81-.03 15.63-.06c1.21 0 2.42 0 3.63-.01.61 0 1.37-.15 1.95 0s1.2.61 1.74.93c2.18 1.27 4.36 2.55 6.54 3.82 18.01 10.52 36.01 21.04 54.02 31.56 17.93 10.47 35.85 20.95 53.78 31.42l6.78 3.96c1.67.98 3.18-1.62 1.51-2.59Z"
        style={{
          fill: "url(#linear-gradient-018429472-26)",
        }}
      />
      <path
        d="M918.97 1796.65c-14.86-8.59-29.71-17.17-44.57-25.76-1.79-1.04-3.57-2.11-5.38-3.11-.97-.54-1.84-.45-2.93-.45h-9.73c-1.08 0-2.17-.05-3.24 0s-1.85.71-2.72 1.23c-7.34 4.41-14.69 8.82-22.03 13.23l-25.02 15.03c-.96.58-.98 2.01 0 2.59 14.8 8.71 29.6 17.43 44.4 26.14 1.74 1.03 3.51 2.35 5.36 3.16.89.39 2.05.22 3.01.23l3.25.04c2.17.02 4.33.05 6.5.07 1.21.01 1.78-.21 2.84-.82.94-.54 1.87-1.08 2.81-1.62l22.23-12.81c8.42-4.85 16.83-9.7 25.25-14.55 1.67-.96.16-3.56-1.51-2.59-14.53 8.37-29.06 16.75-43.59 25.12-1.8 1.04-3.6 2.07-5.4 3.11-.75.43-1.51 1.05-2.37 1.16-1.04.13-2.2-.02-3.25-.04-2.17-.02-4.33-.05-6.5-.07-.91 0-1.79.13-2.6-.33-.94-.53-1.86-1.09-2.79-1.64l-21.66-12.75c-8.15-4.8-16.3-9.6-24.45-14.39v2.59c14.4-8.65 28.8-17.3 43.2-25.94.86-.51 1.71-1.03 2.57-1.54.8-.48 1.76-1.32 2.65-1.57.82-.23 1.98-.03 2.83-.03h9.73c1.07 0 1.61 0 2.53.54.93.54 1.87 1.08 2.8 1.62l21.75 12.57c8.18 4.73 16.36 9.46 24.55 14.19 1.67.97 3.19-1.62 1.51-2.59Z"
        style={{
          fill: "url(#linear-gradient-018429472-27)",
        }}
      />
      <path
        d="M896.26 1796.7c-9.06-5.24-18.12-10.47-27.18-15.71-1.04-.6-2.1-1.39-3.21-1.86s-2.66-.2-3.85-.21h-3.56c-1.25 0-2.45-.17-3.56.46-4.6 2.63-9.1 5.46-13.64 8.19-5.11 3.07-10.23 6.14-15.34 9.21-.96.58-.98 2.01 0 2.59 9 5.3 18.01 10.6 27.01 15.9 1.02.6 2.22 1.6 3.36 1.92s2.64.08 3.83.09 2.45.03 3.67.04c1.38.01 2.25-.64 3.43-1.31 4.52-2.6 9.03-5.21 13.55-7.81 5.16-2.98 10.33-5.95 15.49-8.93 1.67-.96.16-3.56-1.51-2.59-8.75 5.04-17.5 10.08-26.24 15.12-1 .58-2.01 1.16-3.01 1.74-.44.25-.96.67-1.45.78-.58.13-1.36-.01-1.96-.02-1.14-.01-2.29-.02-3.43-.04-.51 0-1.14.07-1.63-.07-.58-.17-1.19-.7-1.71-1.01-4.36-2.57-8.72-5.13-13.08-7.7-4.93-2.9-9.86-5.81-14.79-8.71v2.59c8.67-5.2 17.33-10.41 26-15.61.92-.55 2.03-1.53 3.06-1.82s2.55-.03 3.64-.03h3.56c.49 0 .86-.02 1.31.17.67.27 1.3.75 1.93 1.11 4.28 2.47 8.56 4.95 12.84 7.42 4.99 2.89 9.99 5.77 14.98 8.66 1.67.97 3.19-1.62 1.51-2.59Z"
        style={{
          fill: "url(#linear-gradient-018429472-28)",
        }}
      />
      <path
        d="M977.17 1435.46c-26.13 15.27-52.27 30.54-78.4 45.81-7.32 4.28-14.65 8.56-21.97 12.84-1.67.97-.16 3.57 1.51 2.59 26.13-15.27 52.27-30.54 78.4-45.81 7.32-4.28 14.65-8.56 21.97-12.84 1.67-.97.16-3.57-1.51-2.59Z"
        style={{
          fill: "url(#linear-gradient-018429472-29)",
        }}
      />
      <path
        d="M744.87 1438.05c26.13 15.27 52.27 30.54 78.4 45.81 7.32 4.28 14.65 8.56 21.97 12.84 1.67.98 3.18-1.62 1.51-2.59-26.13-15.27-52.27-30.54-78.4-45.81-7.32-4.28-14.65-8.56-21.97-12.84-1.67-.98-3.18 1.62-1.51 2.59Z"
        style={{
          fill: "url(#linear-gradient-018429472-30)",
        }}
      />
      <path
        d="M876.92 1380.47c21.8 12.52 43.6 25.05 65.4 37.57 6.21 3.57 12.42 7.13 18.62 10.7 1.68.96 3.19-1.63 1.51-2.59-21.8-12.52-43.6-25.05-65.4-37.57-6.21-3.57-12.42-7.13-18.62-10.7-1.68-.96-3.19 1.63-1.51 2.59Z"
        style={{
          fill: "url(#linear-gradient-018429472-31)",
        }}
      />
      <path
        d="M846.61 1377.88c-25.83 15.41-51.67 30.81-77.5 46.22l-21.84 13.02c-1.66.99-.15 3.58 1.51 2.59 25.83-15.41 51.67-30.81 77.5-46.22l21.84-13.02c1.66-.99.15-3.58-1.51-2.59Z"
        style={{
          fill: "url(#linear-gradient-018429472-32)",
        }}
      />
      <path
        d="M1059.47 1439.86c-25.1-14.5-50.19-29.01-75.29-43.51-25.21-14.57-50.42-29.14-75.62-43.71-6.27-3.63-12.55-7.25-18.82-10.88-1.52-.88-2.25-1.18-3.98-1.18h-38.2c-3.59 0-7.22-.14-10.8 0-1.51.06-2.86 1.24-4.13 2l-4.65 2.79c-24.89 14.94-49.77 29.89-74.66 44.83-24.78 14.88-49.55 29.76-74.33 44.63-3.1 1.86-6.19 3.72-9.29 5.58-.96.58-.98 2.01 0 2.59 24.88 14.65 49.77 29.3 74.65 43.95 25 14.71 49.99 29.43 74.99 44.14 6.22 3.66 12.44 7.32 18.66 10.99 1.42.84 2.45.8 4.08.82l5.82.06 10.86.12c7.24.08 14.48.16 21.71.23 1.48.02 3.24.35 4.58-.36 1.6-.84 3.14-1.81 4.7-2.71 3.14-1.81 6.27-3.61 9.41-5.42 25.2-14.52 50.41-29.05 75.61-43.57s50.18-28.92 75.27-43.38c3.14-1.81 6.27-3.61 9.41-5.42 1.67-.96.16-3.56-1.51-2.59-24.87 14.33-49.73 28.66-74.6 42.99-24.87 14.33-49.73 28.66-74.6 42.99-6.27 3.61-12.55 7.23-18.82 10.84-1.46.84-2.91 1.68-4.37 2.52-.76.44-1.49.99-2.37 1.14-.96.16-2.13-.02-3.1-.03-3.49-.04-6.98-.08-10.47-.11-7.24-.08-14.48-.16-21.71-.23l-5.04-.05c-.86 0-1.85.11-2.67-.12s-1.6-.84-2.34-1.28l-9.33-5.49c-24.66-14.52-49.32-29.04-73.99-43.55-24.66-14.52-49.32-29.04-73.99-43.55l-9.33-5.49v2.59c24.55-14.74 49.11-29.49 73.66-44.23 24.55-14.74 49.11-29.49 73.66-44.23 3.1-1.86 6.19-3.72 9.29-5.58 1.44-.86 2.88-1.73 4.31-2.59 1.45-.87 3.33-2.47 4.99-2.78 1.56-.29 3.43 0 5.02 0h42.84c2.01 0 3.21.75 4.92 1.74 3.14 1.81 6.27 3.63 9.41 5.44 24.87 14.37 49.74 28.75 74.61 43.12 24.87 14.37 49.74 28.75 74.61 43.12 3.14 1.81 6.27 3.63 9.41 5.44 1.67.97 3.19-1.62 1.51-2.59Z"
        className="cls-018429472-6"
      />
      <path
        d="M1004.94 1437.01c-18.17-10.61-36.33-21.23-54.5-31.84s-36.33-21.23-54.5-31.84c-4.52-2.64-8.99-5.38-13.56-7.93-1.05-.58-2.02-.48-3.2-.48l-4.19.02-7.81.03-15.63.06c-2.6.01-5.21-.03-7.81.03-1.3.03-2.14.7-3.24 1.36-1.12.67-2.24 1.35-3.36 2.02-18.02 10.82-36.04 21.64-54.06 32.47s-36.04 21.64-54.06 32.47c-2.24 1.35-4.49 2.69-6.73 4.04-.96.58-.98 2.01 0 2.59 18.02 10.61 36.04 21.22 54.06 31.82s36.2 21.31 54.3 31.97c4.51 2.65 9.01 5.3 13.52 7.96 1.09.64 1.92.64 3.17.65l4.21.05c2.62.03 5.24.06 7.86.08 5.24.06 10.48.11 15.72.17 1.17.01 2.47.24 3.53-.32 1.16-.61 2.27-1.31 3.41-1.96l6.81-3.93c18.25-10.52 36.5-21.03 54.75-31.55l54.51-31.41 6.81-3.93c1.67-.96.16-3.56-1.51-2.59-18.01 10.38-36.02 20.75-54.02 31.13-18.01 10.38-36.02 20.75-54.02 31.13-4.54 2.62-9.08 5.24-13.63 7.85-1.05.61-2.11 1.22-3.16 1.82-.48.28-.96.66-1.51.77-.67.14-1.57-.02-2.25-.02-2.53-.03-5.05-.05-7.58-.08-5.24-.06-10.48-.11-15.72-.17-1.22-.01-2.43-.03-3.65-.04-.57 0-1.29.09-1.83-.06s-1.1-.61-1.59-.9c-2.25-1.33-4.51-2.65-6.76-3.98-17.86-10.51-35.72-21.03-53.58-31.54-17.86-10.51-35.72-21.03-53.58-31.54-2.25-1.33-4.51-2.65-6.76-3.98v2.59c17.78-10.68 35.56-21.35 53.34-32.03 17.86-10.73 35.72-21.45 53.58-32.18l6.73-4.04 3.12-1.88c.99-.59 2.28-1.71 3.44-1.82 2.47-.23 5.06-.02 7.54-.03l7.81-.03 15.63-.06c1.21 0 2.42 0 3.63-.01.61 0 1.37-.15 1.95 0s1.2.61 1.74.93c2.18 1.27 4.36 2.55 6.54 3.82 18.01 10.52 36.01 21.04 54.02 31.56 17.93 10.47 35.85 20.95 53.78 31.42l6.78 3.96c1.67.98 3.18-1.62 1.51-2.59Z"
        style={{
          fill: "url(#linear-gradient-018429472-33)",
        }}
      />
      <path
        d="M918.97 1437.56c-14.86-8.59-29.71-17.17-44.57-25.76-1.79-1.04-3.57-2.11-5.38-3.11-.97-.54-1.84-.45-2.93-.45h-9.73c-1.08 0-2.17-.05-3.24 0s-1.85.71-2.72 1.23c-7.34 4.41-14.69 8.82-22.03 13.23l-25.02 15.03c-.96.58-.98 2.01 0 2.59 14.8 8.71 29.6 17.43 44.4 26.14 1.74 1.03 3.51 2.35 5.36 3.16.89.39 2.05.22 3.01.23l3.25.04c2.17.02 4.33.05 6.5.07 1.21.01 1.78-.21 2.84-.82.94-.54 1.87-1.08 2.81-1.62l22.23-12.81c8.42-4.85 16.83-9.7 25.25-14.55 1.67-.96.16-3.56-1.51-2.59-14.53 8.37-29.06 16.75-43.59 25.12-1.8 1.04-3.6 2.07-5.4 3.11-.75.43-1.51 1.05-2.37 1.16-1.04.13-2.2-.02-3.25-.04-2.17-.02-4.33-.05-6.5-.07-.91 0-1.79.13-2.6-.33-.94-.53-1.86-1.09-2.79-1.64l-21.66-12.75c-8.15-4.8-16.3-9.6-24.45-14.39v2.59c14.4-8.65 28.8-17.3 43.2-25.94.86-.51 1.71-1.03 2.57-1.54.8-.48 1.76-1.32 2.65-1.57.82-.23 1.98-.03 2.83-.03h9.73c1.07 0 1.61 0 2.53.54.93.54 1.87 1.08 2.8 1.62l21.75 12.57c8.18 4.73 16.36 9.46 24.55 14.19 1.67.97 3.19-1.62 1.51-2.59Z"
        style={{
          fill: "url(#linear-gradient-018429472-34)",
        }}
      />
      <path
        d="M896.26 1437.61c-9.06-5.24-18.12-10.47-27.18-15.71-1.04-.6-2.1-1.39-3.21-1.86s-2.66-.2-3.85-.21h-3.56c-1.25 0-2.45-.17-3.56.46-4.6 2.63-9.1 5.46-13.64 8.19-5.11 3.07-10.23 6.14-15.34 9.21-.96.58-.98 2.01 0 2.59 9 5.3 18.01 10.6 27.01 15.9 1.02.6 2.22 1.6 3.36 1.92s2.64.08 3.83.09 2.45.03 3.67.04c1.38.01 2.25-.64 3.43-1.31 4.52-2.6 9.03-5.21 13.55-7.81 5.16-2.98 10.33-5.95 15.49-8.93 1.67-.96.16-3.56-1.51-2.59-8.75 5.04-17.5 10.08-26.24 15.12-1 .58-2.01 1.16-3.01 1.74-.44.25-.96.67-1.45.78-.58.13-1.36-.01-1.96-.02-1.14-.01-2.29-.02-3.43-.04-.51 0-1.14.07-1.63-.07-.58-.17-1.19-.7-1.71-1.01-4.36-2.57-8.72-5.13-13.08-7.7-4.93-2.9-9.86-5.81-14.79-8.71v2.59c8.67-5.2 17.33-10.41 26-15.61.92-.55 2.03-1.53 3.06-1.82s2.55-.03 3.64-.03h3.56c.49 0 .86-.02 1.31.17.67.27 1.3.75 1.93 1.11 4.28 2.47 8.56 4.95 12.84 7.42 4.99 2.89 9.99 5.77 14.98 8.66 1.67.97 3.19-1.62 1.51-2.59Z"
        style={{
          fill: "url(#linear-gradient-018429472-35)",
        }}
      />
      <path
        d="M660.9 1616.46c-26.13 15.27-52.27 30.54-78.4 45.81-7.32 4.28-14.65 8.56-21.97 12.84-1.67.97-.16 3.57 1.51 2.59 26.13-15.27 52.27-30.54 78.4-45.81 7.32-4.28 14.65-8.56 21.97-12.84 1.67-.97.16-3.57-1.51-2.59Z"
        style={{
          fill: "url(#linear-gradient-018429472-36)",
        }}
      />
      <path
        d="M428.6 1619.05c26.13 15.27 52.27 30.54 78.4 45.81 7.32 4.28 14.65 8.56 21.97 12.84 1.67.98 3.18-1.62 1.51-2.59-26.13-15.27-52.27-30.54-78.4-45.81-7.32-4.28-14.65-8.56-21.97-12.84-1.67-.98-3.18 1.62-1.51 2.59Z"
        style={{
          fill: "url(#linear-gradient-018429472-37)",
        }}
      />
      <path
        d="M560.65 1561.47c21.8 12.52 43.6 25.05 65.4 37.57 6.21 3.57 12.42 7.13 18.62 10.7 1.68.96 3.19-1.63 1.51-2.59-21.8-12.52-43.6-25.05-65.4-37.57-6.21-3.57-12.42-7.13-18.62-10.7-1.68-.96-3.19 1.63-1.51 2.59Z"
        style={{
          fill: "url(#linear-gradient-018429472-38)",
        }}
      />
      <path
        d="M530.34 1558.88c-25.83 15.41-51.67 30.81-77.5 46.22L431 1618.12c-1.66.99-.15 3.58 1.51 2.59 25.83-15.41 51.67-30.81 77.5-46.22l21.84-13.02c1.66-.99.15-3.58-1.51-2.59Z"
        style={{
          fill: "url(#linear-gradient-018429472-39)",
        }}
      />
      <path
        d="M743.2 1620.87c-25.1-14.5-50.19-29.01-75.29-43.51-25.21-14.57-50.42-29.14-75.62-43.71-6.27-3.63-12.55-7.25-18.82-10.88-1.52-.88-2.25-1.18-3.98-1.18h-38.2c-3.59 0-7.22-.14-10.8 0-1.51.06-2.86 1.24-4.13 2l-4.65 2.79c-24.89 14.94-49.77 29.89-74.66 44.83-24.78 14.88-49.55 29.76-74.33 44.63-3.1 1.86-6.19 3.72-9.29 5.58-.96.58-.98 2.01 0 2.59 24.88 14.65 49.77 29.3 74.65 43.95 25 14.71 49.99 29.43 74.99 44.14 6.22 3.66 12.44 7.32 18.66 10.99 1.42.84 2.45.8 4.08.82l5.82.06 10.86.12c7.24.08 14.48.16 21.71.23 1.48.02 3.24.35 4.58-.36 1.6-.84 3.14-1.81 4.7-2.71 3.14-1.81 6.27-3.61 9.41-5.42 25.2-14.52 50.41-29.05 75.61-43.57l75.27-43.38c3.14-1.81 6.27-3.61 9.41-5.42 1.67-.96.16-3.56-1.51-2.59-24.87 14.33-49.73 28.66-74.6 42.99-24.87 14.33-49.73 28.66-74.6 42.99-6.27 3.61-12.55 7.23-18.82 10.84-1.46.84-2.91 1.68-4.37 2.52-.76.44-1.49.99-2.37 1.14-.96.16-2.13-.02-3.1-.03-3.49-.04-6.98-.08-10.47-.11-7.24-.08-14.48-.16-21.71-.23l-5.04-.05c-.86 0-1.85.11-2.67-.12s-1.6-.84-2.34-1.28l-9.33-5.49c-24.66-14.52-49.32-29.04-73.99-43.55-24.66-14.52-49.32-29.04-73.99-43.55l-9.33-5.49v2.59c24.55-14.74 49.11-29.49 73.66-44.23 24.55-14.74 49.11-29.49 73.66-44.23 3.1-1.86 6.19-3.72 9.29-5.58 1.44-.86 2.88-1.73 4.31-2.59s3.33-2.47 4.99-2.78c1.56-.29 3.43 0 5.02 0h42.84c2.01 0 3.21.75 4.92 1.74 3.14 1.81 6.27 3.63 9.41 5.44 24.87 14.37 49.74 28.75 74.61 43.12 24.87 14.37 49.74 28.75 74.61 43.12 3.14 1.81 6.27 3.63 9.41 5.44 1.67.97 3.19-1.62 1.51-2.59Z"
        className="cls-018429472-6"
      />
      <path
        d="M688.67 1618.01c-18.17-10.61-36.33-21.23-54.5-31.84s-36.33-21.23-54.5-31.84c-4.52-2.64-8.99-5.38-13.56-7.93-1.05-.58-2.02-.48-3.2-.48l-4.19.02-7.81.03-15.63.06c-2.6.01-5.21-.03-7.81.03-1.3.03-2.14.7-3.24 1.36-1.12.67-2.24 1.35-3.36 2.02-18.02 10.82-36.04 21.64-54.06 32.47s-36.04 21.64-54.06 32.47c-2.24 1.35-4.49 2.69-6.73 4.04-.96.58-.98 2.01 0 2.59 18.02 10.61 36.04 21.22 54.06 31.82 18.1 10.66 36.2 21.31 54.3 31.97 4.51 2.65 9.01 5.3 13.52 7.96 1.09.64 1.92.64 3.17.65l4.21.05c2.62.03 5.24.06 7.86.08 5.24.06 10.48.11 15.72.17 1.17.01 2.47.24 3.53-.32 1.16-.61 2.27-1.31 3.41-1.96l6.81-3.93c18.25-10.52 36.5-21.03 54.75-31.55l54.51-31.41 6.81-3.93c1.67-.96.16-3.56-1.51-2.59-18.01 10.38-36.02 20.75-54.02 31.13-18.01 10.38-36.02 20.75-54.02 31.13-4.54 2.62-9.08 5.24-13.63 7.85-1.05.61-2.11 1.22-3.16 1.82-.48.28-.96.66-1.51.77-.67.14-1.57-.02-2.25-.02-2.53-.03-5.05-.05-7.58-.08-5.24-.06-10.48-.11-15.72-.17l-3.65-.04c-.57 0-1.29.09-1.83-.06s-1.1-.61-1.59-.9c-2.25-1.33-4.51-2.65-6.76-3.98-17.86-10.51-35.72-21.03-53.58-31.54-17.86-10.51-35.72-21.03-53.58-31.54-2.25-1.33-4.51-2.65-6.76-3.98v2.59c17.78-10.68 35.56-21.35 53.34-32.03 17.86-10.73 35.72-21.45 53.58-32.18 2.24-1.35 4.49-2.69 6.73-4.04l3.12-1.88c.99-.59 2.28-1.71 3.44-1.82 2.47-.23 5.06-.02 7.54-.03l7.81-.03 15.63-.06c1.21 0 2.42 0 3.63-.01.61 0 1.37-.15 1.95 0s1.2.61 1.74.93c2.18 1.27 4.36 2.55 6.54 3.82 18.01 10.52 36.01 21.04 54.02 31.56s35.85 20.95 53.78 31.42l6.78 3.96c1.67.98 3.18-1.62 1.51-2.59Z"
        style={{
          fill: "url(#linear-gradient-018429472-40)",
        }}
      />
      <path
        d="M602.7 1618.57c-14.86-8.59-29.71-17.17-44.57-25.76-1.79-1.04-3.57-2.11-5.38-3.11-.97-.54-1.84-.45-2.93-.45h-9.73c-1.08 0-2.17-.05-3.24 0s-1.85.71-2.72 1.23c-7.34 4.41-14.69 8.82-22.03 13.23l-25.02 15.03c-.96.58-.98 2.01 0 2.59 14.8 8.71 29.6 17.43 44.4 26.14 1.74 1.03 3.51 2.35 5.36 3.16.89.39 2.05.22 3.01.23l3.25.04c2.17.02 4.33.05 6.5.07 1.21.01 1.78-.21 2.84-.82.94-.54 1.87-1.08 2.81-1.62l22.23-12.81c8.42-4.85 16.83-9.7 25.25-14.55 1.67-.96.16-3.56-1.51-2.59-14.53 8.37-29.06 16.75-43.59 25.12-1.8 1.04-3.6 2.07-5.4 3.11-.75.43-1.51 1.05-2.37 1.16-1.04.13-2.2-.02-3.25-.04-2.17-.02-4.33-.05-6.5-.07-.91 0-1.79.13-2.6-.33-.94-.53-1.86-1.09-2.79-1.64l-21.66-12.75c-8.15-4.8-16.3-9.6-24.45-14.39v2.59c14.4-8.65 28.8-17.3 43.2-25.94.86-.51 1.71-1.03 2.57-1.54.8-.48 1.76-1.32 2.65-1.57.82-.23 1.98-.03 2.83-.03h9.73c1.07 0 1.61 0 2.53.54.93.54 1.87 1.08 2.8 1.62l21.75 12.57c8.18 4.73 16.36 9.46 24.55 14.19 1.67.97 3.19-1.62 1.51-2.59Z"
        style={{
          fill: "url(#linear-gradient-018429472-41)",
        }}
      />
      <path
        d="M579.99 1618.61c-9.06-5.24-18.12-10.47-27.18-15.71-1.04-.6-2.1-1.39-3.21-1.86s-2.66-.2-3.85-.21h-3.56c-1.25 0-2.45-.17-3.56.46-4.6 2.63-9.1 5.46-13.64 8.19-5.11 3.07-10.23 6.14-15.34 9.21-.96.58-.98 2.01 0 2.59 9 5.3 18.01 10.6 27.01 15.9 1.02.6 2.22 1.6 3.36 1.92s2.64.08 3.83.09l3.67.04c1.38.01 2.25-.64 3.43-1.31 4.52-2.6 9.03-5.21 13.55-7.81 5.16-2.98 10.33-5.95 15.49-8.93 1.67-.96.16-3.56-1.51-2.59-8.75 5.04-17.5 10.08-26.24 15.12-1 .58-2.01 1.16-3.01 1.74-.44.25-.96.67-1.45.78-.58.13-1.36-.01-1.96-.02-1.14-.01-2.29-.02-3.43-.04-.51 0-1.14.07-1.63-.07-.58-.17-1.19-.7-1.71-1.01-4.36-2.57-8.72-5.13-13.08-7.7-4.93-2.9-9.86-5.81-14.79-8.71v2.59c8.67-5.2 17.33-10.41 26-15.61.92-.55 2.03-1.53 3.06-1.82 1.04-.3 2.55-.03 3.64-.03h3.56c.49 0 .86-.02 1.31.17.67.27 1.3.75 1.93 1.11 4.28 2.47 8.56 4.95 12.84 7.42 4.99 2.89 9.99 5.77 14.98 8.66 1.67.97 3.19-1.62 1.51-2.59Z"
        style={{
          fill: "url(#linear-gradient-018429472-42)",
        }}
      />
      <path
        d="M664.01 2327.4c26.15 15.24 52.3 30.49 78.45 45.73 7.33 4.27 14.66 8.54 21.98 12.82 1.67.97 3.18-1.62 1.51-2.59-26.15-15.24-52.3-30.49-78.45-45.73-7.33-4.27-14.66-8.54-21.98-12.82-1.67-.97-3.18 1.62-1.51 2.59Z"
        style={{
          fill: "url(#linear-gradient-018429472-43)",
        }}
      />
      <path
        d="m665.92 2439.94.54-75.42.15-21.48c.01-1.93-2.99-1.93-3 0l-.54 75.42-.15 21.48c-.01 1.93 2.99 1.93 3 0Z"
        style={{
          fill: "url(#linear-gradient-018429472-44)",
        }}
      />
      <path
        d="M783.67 2505.75c.15-21.38.31-42.76.46-64.14.04-6.07.09-12.15.13-18.22.01-1.93-2.99-1.93-3 0-.15 21.38-.31 42.76-.46 64.14-.04 6.07-.09 12.15-.13 18.22-.01 1.93 2.99 1.93 3 0Z"
        style={{
          fill: "url(#linear-gradient-018429472-45)",
        }}
      />
      <path
        d="M680.36 2461.24c26.15 15.24 52.3 30.49 78.45 45.73 7.33 4.27 14.66 8.54 21.98 12.82 1.67.97 3.18-1.62 1.51-2.59-26.15-15.24-52.3-30.49-78.45-45.73-7.33-4.27-14.66-8.54-21.98-12.82-1.67-.97-3.18 1.62-1.51 2.59Z"
        style={{
          fill: "url(#linear-gradient-018429472-46)",
        }}
      />
      <path
        d="M626.73 2256.81v192.84c0 1.46-.37 3.51.13 4.88.58 1.58 2.02 3.15 2.94 4.56l5.73 8.76c3.88 5.93 7.75 11.86 11.63 17.79.89 1.37 1.79 2.74 2.68 4.11.38.58.7 1.2 1.25 1.63.75.59 1.74 1.01 2.57 1.49 3.14 1.82 6.29 3.63 9.43 5.45 24.67 14.25 49.33 28.5 74 42.75s49.33 28.5 74 42.75c3.05 1.76 6.1 3.52 9.14 5.28.98.57 2.26-.15 2.26-1.3v-170.2c0-3.3-1.77-6.69-2.83-9.79-1.18-3.42-2.35-6.83-3.53-10.25-2.28-6.63-4.56-13.25-6.84-19.88s-4.63-13.46-6.95-20.19c-.55-1.61-.71-2.4-2.19-3.26-1.62-.94-3.25-1.87-4.87-2.81-3.06-1.76-6.12-3.53-9.17-5.29-24.66-14.22-49.31-28.44-73.97-42.65-24.66-14.22-49.31-28.44-73.97-42.65-3.06-1.76-6.12-3.53-9.17-5.29-1.68-.97-3.19 1.63-1.51 2.59 22.36 12.9 44.73 25.79 67.09 38.69 22.36 12.9 44.73 25.79 67.09 38.69 12.61 7.27 25.23 14.55 37.84 21.82l-.69-.9c4.95 14.39 9.91 28.78 14.86 43.17 1.25 3.62 2.49 7.25 3.74 10.87.57 1.65 1.21 3.3 1.71 4.97.59 1.96.37 4.08.37 6.12v166.25c.75-.43 1.5-.86 2.26-1.3-24.48-14.14-48.95-28.28-73.43-42.42-24.48-14.14-48.95-28.28-73.43-42.42-6.1-3.52-12.19-7.04-18.29-10.56-1.43-.83-3.21-1.51-4.18-2.87s-1.91-2.92-2.86-4.38l-5.73-8.76c-3.82-5.84-7.63-11.68-11.45-17.52-.83-1.27-2.29-2.8-2.66-4.28-.19-.77 0-1.85 0-2.65V2256.83c0-1.93-3-1.93-3 0Z"
        className="cls-018429472-6"
      />
      <path
        d="M652.23 2300.59V2442.57c0 1.05-.24 2.39.05 3.4.34 1.19 1.51 2.43 2.19 3.47 1.4 2.15 2.8 4.29 4.21 6.44 2.89 4.43 5.79 8.86 8.68 13.29.68 1.04 1.36 2.08 2.04 3.11.75 1.14 1.73 1.61 2.89 2.28l6.69 3.87c18.21 10.52 36.43 21.04 54.64 31.56l54.42 31.44 6.69 3.87c.98.57 2.26-.15 2.26-1.3v-125.39c0-1.26-.46-2.32-.87-3.5-.43-1.26-.87-2.51-1.3-3.77-.87-2.51-1.73-5.03-2.6-7.54-1.68-4.87-3.35-9.74-5.03-14.61L782 2374.1c-.16-.48-.28-1.05-.59-1.45s-.84-.63-1.28-.88c-1.22-.71-2.45-1.41-3.67-2.12-2.23-1.29-4.46-2.57-6.69-3.86-18.13-10.46-36.27-20.91-54.4-31.37-18.06-10.42-36.12-20.83-54.19-31.25-2.23-1.29-4.46-2.57-6.69-3.86-1.68-.97-3.19 1.63-1.51 2.59 16.48 9.5 32.96 19 49.44 28.51 16.41 9.46 32.81 18.92 49.22 28.38 9.28 5.35 18.57 10.71 27.85 16.06l-.69-.9c3.65 10.61 7.3 21.21 10.95 31.82l2.76 8.01c.43 1.25.93 2.5 1.3 3.77.41 1.41.19 3.09.19 4.55V2544c.75-.43 1.5-.86 2.26-1.3-18-10.4-35.99-20.79-53.99-31.19s-35.99-20.79-53.99-31.19c-4.46-2.58-8.93-5.16-13.39-7.73-.94-.54-2.19-1.02-2.89-1.83-.89-1.03-1.56-2.39-2.31-3.53-1.4-2.15-2.8-4.29-4.21-6.44-2.8-4.29-5.61-8.58-8.41-12.87-.56-.85-1.53-1.89-1.83-2.86-.17-.54 0-1.42 0-2V2300.58c0-1.93-3-1.93-3 0Z"
        style={{
          fill: "url(#linear-gradient-018429472-47)",
        }}
      />
      <path
        d="M705.16 2391.49v32.48c0 1.32-.06 2.65 0 3.97s1.08 2.35 1.76 3.38 1.38 2.11 2.07 3.17c.29.44.55.95.89 1.34.45.51 1.13.8 1.71 1.13 4.74 2.74 9.49 5.48 14.23 8.22 5.32 3.07 10.64 6.15 15.96 9.22.98.57 2.26-.15 2.26-1.3v-28.19c0-1.32.21-2.84-.05-4.14-.24-1.18-.82-2.37-1.21-3.51-.46-1.32-.91-2.65-1.37-3.97-.4-1.17-.78-2.35-1.21-3.51-.47-1.28-1.81-1.73-2.93-2.38-4.69-2.7-9.38-5.41-14.06-8.11-5.27-3.04-10.53-6.07-15.8-9.11-1.68-.97-3.19 1.63-1.51 2.59 10.67 6.16 21.35 12.31 32.02 18.47l-.69-.9c1.18 3.42 2.46 6.83 3.54 10.28.52 1.65.27 3.56.27 5.28v27.19c.75-.43 1.5-.86 2.26-1.3-9.06-5.23-18.12-10.47-27.18-15.7-1.15-.66-2.31-1.32-3.45-1.99-.99-.59-1.51-1.77-2.13-2.73s-1.29-1.98-1.94-2.97c-.68-1.04-.44-2.23-.44-3.46v-33.47c0-1.93-3-1.93-3 0Z"
        style={{
          fill: "url(#linear-gradient-018429472-48)",
        }}
      />
      <path
        d="M695.68 2375.2v55.11c0 1.11.13 1.64.74 2.57s1.25 1.92 1.88 2.88l3.23 4.94c.49.74.91 1.63 1.61 2.18.87.69 2.04 1.18 3.01 1.74 7.02 4.05 14.04 8.11 21.06 12.16 8.02 4.63 16.04 9.27 24.07 13.9.98.57 2.26-.15 2.26-1.3v-45.82c0-1.09.15-2.31-.05-3.39-.36-1.86-1.3-3.77-1.91-5.56-.69-2.01-1.38-4.02-2.07-6.02-.58-1.7-1.14-3.41-1.75-5.1-.68-1.86-3.07-2.64-4.71-3.59-7.01-4.04-14.03-8.09-21.04-12.13-8.02-4.62-16.03-9.24-24.05-13.87-1.68-.97-3.19 1.63-1.51 2.59 16.32 9.41 32.63 18.82 48.95 28.23l-.69-.9c1.38 4.02 2.77 8.03 4.15 12.05.69 2 1.7 4.06 1.7 6.19v47.32l2.26-1.3c-13.9-8.03-27.79-16.05-41.69-24.08-1.72-.99-3.44-1.99-5.16-2.98-.74-.43-1.08-.71-1.56-1.44-.63-.96-1.25-1.92-1.88-2.88l-3.23-4.94c-.5-.76-.6-1.07-.6-1.97v-54.61c0-1.93-3-1.93-3 0Z"
        style={{
          fill: "url(#linear-gradient-018429472-49)",
        }}
      />
      <path
        d="M341.71 2137.52c26.15 15.24 52.3 30.49 78.45 45.73 7.33 4.27 14.66 8.54 21.98 12.82 1.67.97 3.18-1.62 1.51-2.59-26.15-15.24-52.3-30.49-78.45-45.73-7.33-4.27-14.66-8.54-21.98-12.82-1.67-.97-3.18 1.62-1.51 2.59Z"
        style={{
          fill: "url(#linear-gradient-018429472-50)",
        }}
      />
      <path
        d="m343.62 2250.06.54-75.42.15-21.48c.01-1.93-2.99-1.93-3 0l-.54 75.42-.15 21.48c-.01 1.93 2.99 1.93 3 0Z"
        style={{
          fill: "url(#linear-gradient-018429472-51)",
        }}
      />
      <path
        d="M461.37 2315.87c.15-21.38.31-42.76.46-64.14.04-6.07.09-12.15.13-18.22.01-1.93-2.99-1.93-3 0-.15 21.38-.31 42.76-.46 64.14-.04 6.07-.09 12.15-.13 18.22-.01 1.93 2.99 1.93 3 0Z"
        style={{
          fill: "url(#linear-gradient-018429472-52)",
        }}
      />
      <path
        d="M358.06 2271.36c26.15 15.24 52.3 30.49 78.45 45.73 7.33 4.27 14.66 8.54 21.98 12.82 1.67.97 3.18-1.62 1.51-2.59-26.15-15.24-52.3-30.49-78.45-45.73-7.33-4.27-14.66-8.54-21.98-12.82-1.67-.97-3.18 1.62-1.51 2.59Z"
        style={{
          fill: "url(#linear-gradient-018429472-53)",
        }}
      />
      <path
        d="M304.42 2066.93v192.84c0 1.46-.37 3.51.13 4.88.58 1.58 2.02 3.15 2.94 4.56l5.73 8.76c3.88 5.93 7.75 11.86 11.63 17.79.89 1.37 1.79 2.74 2.68 4.11.38.58.7 1.2 1.25 1.63.75.59 1.74 1.01 2.57 1.49 3.14 1.82 6.29 3.63 9.43 5.45 24.67 14.25 49.33 28.5 74 42.75s49.33 28.5 74 42.75c3.05 1.76 6.1 3.52 9.14 5.28.98.57 2.26-.15 2.26-1.3v-170.2c0-3.3-1.77-6.69-2.83-9.79-1.18-3.42-2.35-6.83-3.53-10.25-2.28-6.63-4.56-13.25-6.84-19.88s-4.63-13.46-6.95-20.19c-.55-1.61-.71-2.4-2.19-3.26-1.62-.94-3.25-1.87-4.87-2.81-3.06-1.76-6.12-3.53-9.17-5.29-24.66-14.22-49.31-28.44-73.97-42.65-24.66-14.22-49.31-28.44-73.97-42.65-3.06-1.76-6.12-3.53-9.17-5.29-1.68-.97-3.19 1.63-1.51 2.59 22.36 12.9 44.73 25.79 67.09 38.69 22.36 12.9 44.73 25.79 67.09 38.69 12.61 7.27 25.23 14.55 37.84 21.82l-.69-.9c4.95 14.39 9.91 28.78 14.86 43.17 1.25 3.62 2.49 7.25 3.74 10.87.57 1.65 1.21 3.3 1.71 4.97.59 1.96.37 4.08.37 6.12v166.25c.75-.43 1.5-.86 2.26-1.3-24.48-14.14-48.95-28.28-73.43-42.42-24.48-14.14-48.95-28.28-73.43-42.42-6.1-3.52-12.19-7.04-18.29-10.56-1.43-.83-3.21-1.51-4.18-2.87s-1.91-2.92-2.86-4.38l-5.73-8.76c-3.82-5.84-7.63-11.68-11.45-17.52-.83-1.27-2.29-2.8-2.66-4.28-.19-.77 0-1.85 0-2.65V2066.95c0-1.93-3-1.93-3 0Z"
        className="cls-018429472-6"
      />
      <path
        d="M329.92 2110.71V2252.69c0 1.05-.24 2.39.05 3.4.34 1.19 1.51 2.43 2.19 3.47 1.4 2.15 2.8 4.29 4.21 6.44 2.89 4.43 5.79 8.86 8.68 13.29.68 1.04 1.36 2.08 2.04 3.11.75 1.14 1.73 1.61 2.89 2.28l6.69 3.87c18.21 10.52 36.43 21.04 54.64 31.56l54.42 31.44 6.69 3.87c.98.57 2.26-.15 2.26-1.3v-125.39c0-1.26-.46-2.32-.87-3.5-.43-1.26-.87-2.51-1.3-3.77-.87-2.51-1.73-5.03-2.6-7.54-1.68-4.87-3.35-9.74-5.03-14.61l-5.19-15.09c-.16-.48-.28-1.05-.59-1.45s-.84-.63-1.28-.88c-1.22-.71-2.45-1.41-3.67-2.12-2.23-1.29-4.46-2.57-6.69-3.86-18.13-10.46-36.27-20.91-54.4-31.37-18.06-10.42-36.12-20.83-54.19-31.25-2.23-1.29-4.46-2.57-6.69-3.86-1.68-.97-3.19 1.63-1.51 2.59 16.48 9.5 32.96 19 49.44 28.51 16.41 9.46 32.81 18.92 49.22 28.38 9.28 5.35 18.57 10.71 27.85 16.06l-.69-.9c3.65 10.61 7.3 21.21 10.95 31.82l2.76 8.01c.43 1.25.93 2.5 1.3 3.77.41 1.41.19 3.09.19 4.55v121.9c.75-.43 1.5-.86 2.26-1.3-18-10.4-35.99-20.79-53.99-31.19-18-10.4-35.99-20.79-53.99-31.19-4.46-2.58-8.93-5.16-13.39-7.73-.94-.54-2.19-1.02-2.89-1.83-.89-1.03-1.56-2.39-2.31-3.53-1.4-2.15-2.8-4.29-4.21-6.44-2.8-4.29-5.61-8.58-8.41-12.87-.56-.85-1.53-1.89-1.83-2.86-.17-.54 0-1.42 0-2V2110.7c0-1.93-3-1.93-3 0Z"
        style={{
          fill: "url(#linear-gradient-018429472-54)",
        }}
      />
      <path
        d="M382.86 2201.61v32.48c0 1.32-.06 2.65 0 3.97s1.08 2.35 1.76 3.38 1.38 2.11 2.07 3.17c.29.44.55.95.89 1.34.45.51 1.13.8 1.71 1.13 4.74 2.74 9.49 5.48 14.23 8.22 5.32 3.07 10.64 6.15 15.96 9.22.98.57 2.26-.15 2.26-1.3v-28.19c0-1.32.21-2.84-.05-4.14-.24-1.18-.82-2.37-1.21-3.51-.46-1.32-.91-2.65-1.37-3.97-.4-1.17-.78-2.35-1.21-3.51-.47-1.28-1.81-1.73-2.93-2.38-4.69-2.7-9.38-5.41-14.06-8.11-5.27-3.04-10.53-6.07-15.8-9.11-1.68-.97-3.19 1.63-1.51 2.59 10.67 6.16 21.35 12.31 32.02 18.47l-.69-.9c1.18 3.42 2.46 6.83 3.54 10.28.52 1.65.27 3.56.27 5.28v27.19c.75-.43 1.5-.86 2.26-1.3-9.06-5.23-18.12-10.47-27.18-15.7-1.15-.66-2.31-1.32-3.45-1.99-.99-.59-1.51-1.77-2.13-2.73s-1.29-1.98-1.94-2.97c-.68-1.04-.44-2.23-.44-3.46v-33.47c0-1.93-3-1.93-3 0Z"
        style={{
          fill: "url(#linear-gradient-018429472-55)",
        }}
      />
      <path
        d="M373.38 2185.32v55.11c0 1.11.13 1.64.74 2.57s1.25 1.92 1.88 2.88l3.23 4.94c.49.74.91 1.63 1.61 2.18.87.69 2.04 1.18 3.01 1.74 7.02 4.05 14.04 8.11 21.06 12.16 8.02 4.63 16.04 9.27 24.07 13.9.98.57 2.26-.15 2.26-1.3v-45.82c0-1.09.15-2.31-.05-3.39-.36-1.86-1.3-3.77-1.91-5.56-.69-2.01-1.38-4.02-2.07-6.02-.58-1.7-1.14-3.41-1.75-5.1-.68-1.86-3.07-2.64-4.71-3.59-7.01-4.04-14.03-8.09-21.04-12.13-8.02-4.62-16.03-9.24-24.05-13.87-1.68-.97-3.19 1.63-1.51 2.59 16.32 9.41 32.63 18.82 48.95 28.23l-.69-.9c1.38 4.02 2.77 8.03 4.15 12.05.69 2 1.7 4.06 1.7 6.19v47.32c.75-.43 1.5-.86 2.26-1.3-13.9-8.03-27.79-16.05-41.69-24.08-1.72-.99-3.44-1.99-5.16-2.98-.74-.43-1.08-.71-1.56-1.44-.63-.96-1.25-1.92-1.88-2.88l-3.23-4.94c-.5-.76-.6-1.07-.6-1.97v-54.61c0-1.93-3-1.93-3 0Z"
        style={{
          fill: "url(#linear-gradient-018429472-56)",
        }}
      />
      <path
        d="M1063.31 1951.38c-26.15 15.24-52.3 30.49-78.45 45.73-7.33 4.27-14.66 8.54-21.98 12.82-1.67.97-.16 3.57 1.51 2.59 26.15-15.24 52.3-30.49 78.45-45.73 7.33-4.27 14.66-8.54 21.98-12.82 1.67-.97.16-3.57-1.51-2.59Z"
        style={{
          fill: "url(#linear-gradient-018429472-57)",
        }}
      />
      <path
        d="m1065.92 2066.51-.54-75.42-.15-21.48c-.01-1.93-3.01-1.93-3 0l.54 75.42.15 21.48c.01 1.93 3.01 1.93 3 0Z"
        style={{
          fill: "url(#linear-gradient-018429472-58)",
        }}
      />
      <path
        d="M948.17 2132.32c-.15-21.38-.31-42.76-.46-64.14-.04-6.07-.09-12.15-.13-18.22-.01-1.93-3.01-1.93-3 0 .15 21.38.31 42.76.46 64.14.04 6.07.09 12.15.13 18.22.01 1.93 3.01 1.93 3 0Z"
        style={{
          fill: "url(#linear-gradient-018429472-59)",
        }}
      />
      <path
        d="M1046.97 2085.22c-26.15 15.24-52.3 30.49-78.45 45.73-7.33 4.27-14.66 8.54-21.98 12.82-1.67.97-.16 3.57 1.51 2.59 26.15-15.24 52.3-30.49 78.45-45.73 7.33-4.27 14.66-8.54 21.98-12.82 1.67-.97.16-3.57-1.51-2.59Z"
        style={{
          fill: "url(#linear-gradient-018429472-60)",
        }}
      />
      <path
        d="M1099.12 1883.38v193.84c0 .8.19 1.87 0 2.65-.35 1.47-1.84 3.03-2.66 4.28l-5.73 8.76c-3.82 5.84-7.63 11.68-11.45 17.52-.95 1.45-1.84 2.98-2.86 4.38-.99 1.35-2.74 2.04-4.18 2.87-3.05 1.76-6.1 3.52-9.14 5.28-24.57 14.19-49.14 28.39-73.72 42.58-24.38 14.08-48.76 28.17-73.14 42.25-3.05 1.76-6.1 3.52-9.14 5.28.75.43 1.5.86 2.26 1.3V2048.45c0-1.98-.29-4.24.27-6.14 1.07-3.66 2.5-7.26 3.74-10.87l16.68-48.45-.69.9c22.36-12.9 44.73-25.79 67.09-38.69 22.36-12.9 44.73-25.79 67.09-38.69 12.61-7.27 25.23-14.55 37.84-21.82 1.67-.96.16-3.56-1.51-2.59-24.66 14.22-49.31 28.44-73.97 42.65-24.66 14.22-49.31 28.44-73.97 42.65-6.21 3.58-12.42 7.16-18.64 10.75-1.43.83-2.87 1.65-4.3 2.48-.59.34-1.29.62-1.68 1.2-.45.66-.64 1.78-.9 2.53-1.14 3.31-2.28 6.63-3.42 9.94-4.6 13.36-9.2 26.71-13.79 40.07-1.07 3.1-2.83 6.5-2.83 9.79V2214.37c0 1.14 1.27 1.86 2.26 1.3 24.76-14.3 49.53-28.61 74.29-42.91 24.57-14.19 49.14-28.39 73.72-42.58 6.19-3.58 12.38-7.15 18.57-10.73 1.47-.85 2.71-1.41 3.65-2.84 1.01-1.55 2.03-3.1 3.04-4.65l5.73-8.76c3.88-5.93 7.75-11.86 11.63-17.79.84-1.28 2.09-2.69 2.68-4.11s.2-3.53.2-5.06V1883.4c0-1.93-3-1.93-3 0Z"
        className="cls-018429472-6"
      />
      <path
        d="M1073.61 1927.17V2067.65c0 1.2.2 2.57 0 3.75-.19 1.12-1.34 2.35-1.97 3.31-1.4 2.15-2.8 4.29-4.21 6.44-2.8 4.29-5.61 8.58-8.41 12.87-.67 1.03-1.3 2.12-2.04 3.11-.7.95-2.01 1.45-3.03 2.04-2.3 1.33-4.61 2.66-6.91 3.99-17.92 10.35-35.85 20.71-53.77 31.06-18 10.4-35.99 20.79-53.99 31.19l-6.69 3.87c.75.43 1.5.86 2.26 1.3v-121.65c0-1.44-.25-3.17.11-4.56.7-2.71 1.85-5.37 2.76-8.01 4.11-11.94 8.22-23.89 12.33-35.83l-.69.9c16.48-9.5 32.96-19 49.44-28.51s32.81-18.92 49.22-28.38c9.28-5.35 18.57-10.71 27.85-16.06 1.67-.96.16-3.56-1.51-2.59-18.13 10.46-36.27 20.91-54.4 31.37-18.06 10.42-36.12 20.83-54.19 31.25-4.53 2.61-9.07 5.23-13.6 7.84-1.08.62-2.16 1.24-3.24 1.87-.45.26-1.03.48-1.38.86-.44.48-.59 1.24-.79 1.84-.84 2.44-1.68 4.87-2.52 7.31-3.41 9.9-6.82 19.8-10.22 29.7-.82 2.38-2.16 4.99-2.16 7.52v125.15c0 1.14 1.27 1.86 2.26 1.3l54.42-31.44 54.42-31.44c4.61-2.66 9.21-5.32 13.82-7.98.99-.57 1.89-.99 2.54-1.95.78-1.17 1.54-2.35 2.31-3.53 1.4-2.15 2.8-4.29 4.21-6.44 2.89-4.43 5.79-8.86 8.68-13.29.63-.97 1.57-2.01 1.98-3.09s.13-2.67.13-3.83V1927.18c0-1.93-3-1.93-3 0Z"
        style={{
          fill: "url(#linear-gradient-018429472-61)",
        }}
      />
      <path
        d="M1020.68 2018.06v35.21c0 .51.03.87-.18 1.32-.28.61-.79 1.21-1.16 1.78-.65.99-1.29 1.98-1.94 2.97-.25.38-.46.8-.8 1.1-.53.46-1.33.77-1.94 1.12-4.53 2.62-9.06 5.23-13.59 7.85-5.18 2.99-10.35 5.98-15.53 8.97.75.43 1.5.86 2.26 1.3V2052.74c0-1.75-.27-3.81.27-5.53 1.08-3.46 2.36-6.86 3.54-10.28l-.69.9c10.67-6.16 21.35-12.31 32.02-18.47 1.67-.96.16-3.56-1.51-2.59-9.38 5.41-18.75 10.81-28.13 16.22-1.08.62-2.16 1.25-3.25 1.87-1.33.77-1.5 1.74-1.98 3.14-.86 2.49-1.72 4.99-2.57 7.48-.21.6-.51 1.25-.64 1.87s-.05 1.28-.05 1.9v30.43c0 1.14 1.27 1.86 2.26 1.3 9.42-5.44 18.84-10.88 28.25-16.32 1.08-.62 2.44-1.17 3.39-1.99s1.54-2.18 2.2-3.19 1.38-2.11 2.07-3.17c.28-.44.6-.83.7-1.35.13-.69.03-1.49.03-2.18v-34.72c0-1.93-3-1.93-3 0Z"
        style={{
          fill: "url(#linear-gradient-018429472-62)",
        }}
      />
      <path
        d="M1030.16 2001.78v54.11c0 .79.09 1.37-.33 2.05-.6.97-1.26 1.92-1.88 2.88l-3.23 4.94c-.43.66-.72 1.19-1.4 1.6-.99.6-2.01 1.16-3.01 1.74-6.88 3.97-13.75 7.94-20.63 11.92l-23.64 13.65c.75.43 1.5.86 2.26 1.3V2050.4c0-.98-.12-2.05.05-3.02.2-1.11.72-2.23 1.09-3.29 1.57-4.56 3.14-9.11 4.71-13.67l-.69.9c16.32-9.41 32.63-18.82 48.95-28.23 1.67-.96.16-3.56-1.51-2.59l-42.51 24.51c-1.72.99-3.44 1.98-5.15 2.97-.81.47-1.57.77-1.98 1.64-.48 1.01-.75 2.18-1.12 3.24-1.28 3.71-2.55 7.41-3.83 11.12-.31.9-.78 1.85-.96 2.78s-.05 1.95-.05 2.89v46.31c0 1.14 1.27 1.86 2.26 1.3 14.18-8.19 28.36-16.38 42.55-24.58 1.72-.99 3.47-1.93 5.16-2.98 1.63-1.01 2.62-3.31 3.66-4.9l3.23-4.94c.57-.87.98-1.43 1.01-2.49.04-1.16 0-2.32 0-3.48V2001.76c0-1.93-3-1.93-3 0Z"
        style={{
          fill: "url(#linear-gradient-018429472-63)",
        }}
      />
      <path
        d="M1383.85 1765.41c-26.15 15.24-52.3 30.49-78.45 45.73-7.33 4.27-14.66 8.54-21.98 12.82-1.67.97-.16 3.57 1.51 2.59 26.15-15.24 52.3-30.49 78.45-45.73 7.33-4.27 14.66-8.54 21.98-12.82 1.67-.97.16-3.57-1.51-2.59Z"
        style={{
          fill: "url(#linear-gradient-018429472-64)",
        }}
      />
      <path
        d="m1386.45 1880.55-.54-75.42-.15-21.48c-.01-1.93-3.01-1.93-3 0l.54 75.42.15 21.48c.01 1.93 3.01 1.93 3 0Z"
        style={{
          fill: "url(#linear-gradient-018429472-65)",
        }}
      />
      <path
        d="M1268.7 1946.35c-.15-21.38-.31-42.76-.46-64.14-.04-6.07-.09-12.15-.13-18.22-.01-1.93-3.01-1.93-3 0 .15 21.38.31 42.76.46 64.14.04 6.07.09 12.15.13 18.22.01 1.93 3.01 1.93 3 0Z"
        style={{
          fill: "url(#linear-gradient-018429472-66)",
        }}
      />
      <path
        d="M1367.5 1899.26c-26.15 15.24-52.3 30.49-78.45 45.73-7.33 4.27-14.66 8.54-21.98 12.82-1.67.97-.16 3.57 1.51 2.59 26.15-15.24 52.3-30.49 78.45-45.73 7.33-4.27 14.66-8.54 21.98-12.82 1.67-.97.16-3.57-1.51-2.59Z"
        style={{
          fill: "url(#linear-gradient-018429472-67)",
        }}
      />
      <path
        d="M1419.65 1697.42v193.84c0 .8.19 1.87 0 2.65-.35 1.47-1.84 3.03-2.66 4.28l-5.73 8.76c-3.82 5.84-7.63 11.68-11.45 17.52-.95 1.45-1.84 2.98-2.86 4.38s-2.74 2.04-4.18 2.87c-3.05 1.76-6.1 3.52-9.14 5.28-24.57 14.19-49.14 28.39-73.72 42.58-24.38 14.08-48.76 28.17-73.14 42.25-3.05 1.76-6.1 3.52-9.14 5.28l2.26 1.3V1862.49c0-1.98-.29-4.24.27-6.14 1.07-3.66 2.5-7.26 3.74-10.87l16.68-48.45-.69.9c22.36-12.9 44.73-25.79 67.09-38.69 22.36-12.9 44.73-25.79 67.09-38.69 12.61-7.27 25.23-14.55 37.84-21.82 1.67-.96.16-3.56-1.51-2.59-24.66 14.22-49.31 28.44-73.97 42.65-24.66 14.22-49.31 28.44-73.97 42.65-6.21 3.58-12.42 7.16-18.64 10.75-1.43.83-2.87 1.65-4.3 2.48-.59.34-1.29.62-1.68 1.2-.45.66-.64 1.78-.9 2.53-1.14 3.31-2.28 6.63-3.42 9.94-4.6 13.36-9.2 26.71-13.79 40.07-1.07 3.1-2.83 6.5-2.83 9.79V2028.41c0 1.14 1.27 1.86 2.26 1.3 24.76-14.3 49.53-28.61 74.29-42.91 24.57-14.19 49.14-28.39 73.72-42.58 6.19-3.58 12.38-7.15 18.57-10.73 1.47-.85 2.71-1.41 3.65-2.84 1.01-1.55 2.03-3.1 3.04-4.65l5.73-8.76c3.88-5.93 7.75-11.86 11.63-17.79.84-1.28 2.09-2.69 2.68-4.11.59-1.42.2-3.53.2-5.06v-192.84c0-1.93-3-1.93-3 0Z"
        className="cls-018429472-6"
      />
      <path
        d="M1394.15 1741.2V1881.68c0 1.2.2 2.57 0 3.75-.19 1.12-1.34 2.35-1.97 3.31-1.4 2.15-2.8 4.29-4.21 6.44-2.8 4.29-5.61 8.58-8.41 12.87-.67 1.03-1.3 2.12-2.04 3.11s-2.01 1.45-3.03 2.04c-2.3 1.33-4.61 2.66-6.91 3.99-17.92 10.35-35.85 20.71-53.77 31.06-18 10.4-35.99 20.79-53.99 31.19l-6.69 3.87c.75.43 1.5.86 2.26 1.3v-121.65c0-1.44-.25-3.17.11-4.56.7-2.71 1.85-5.37 2.76-8.01 4.11-11.94 8.22-23.89 12.33-35.83l-.69.9c16.48-9.5 32.96-19 49.44-28.51s32.81-18.92 49.22-28.38c9.28-5.35 18.57-10.71 27.85-16.06 1.67-.96.16-3.56-1.51-2.59-18.13 10.46-36.27 20.91-54.4 31.37-18.06 10.42-36.12 20.83-54.19 31.25-4.53 2.61-9.07 5.23-13.6 7.84-1.08.62-2.16 1.24-3.24 1.87-.45.26-1.03.48-1.38.86-.44.48-.59 1.24-.79 1.84-.84 2.44-1.68 4.87-2.52 7.31-3.41 9.9-6.82 19.8-10.22 29.7-.82 2.38-2.16 4.99-2.16 7.52v125.15c0 1.14 1.27 1.86 2.26 1.3l54.42-31.44 54.42-31.44c4.61-2.66 9.21-5.32 13.82-7.98.99-.57 1.89-.99 2.54-1.95.78-1.17 1.54-2.35 2.31-3.53 1.4-2.15 2.8-4.29 4.21-6.44 2.89-4.43 5.79-8.86 8.68-13.29.63-.97 1.57-2.01 1.98-3.09s.13-2.67.13-3.83V1741.21c0-1.93-3-1.93-3 0Z"
        style={{
          fill: "url(#linear-gradient-018429472-68)",
        }}
      />
      <path
        d="M1341.21 1832.1v35.21c0 .51.03.87-.18 1.32-.28.61-.79 1.21-1.16 1.78-.65.99-1.29 1.98-1.94 2.97-.25.38-.46.8-.8 1.1-.53.46-1.33.77-1.94 1.12-4.53 2.62-9.06 5.23-13.59 7.85-5.18 2.99-10.35 5.98-15.53 8.97.75.43 1.5.86 2.26 1.3V1866.78c0-1.75-.27-3.81.27-5.53 1.08-3.46 2.36-6.86 3.54-10.28l-.69.9c10.67-6.16 21.35-12.31 32.02-18.47 1.67-.96.16-3.56-1.51-2.59-9.38 5.41-18.75 10.81-28.13 16.22-1.08.62-2.16 1.25-3.25 1.87-1.33.77-1.5 1.74-1.98 3.14-.86 2.49-1.72 4.99-2.57 7.48-.21.6-.51 1.25-.64 1.87s-.05 1.28-.05 1.9v30.43c0 1.14 1.27 1.86 2.26 1.3 9.42-5.44 18.84-10.88 28.25-16.32 1.08-.62 2.44-1.17 3.39-1.99s1.54-2.18 2.2-3.19c.69-1.06 1.38-2.11 2.07-3.17.28-.44.6-.83.7-1.35.13-.69.03-1.49.03-2.18v-34.72c0-1.93-3-1.93-3 0Z"
        style={{
          fill: "url(#linear-gradient-018429472-69)",
        }}
      />
      <path
        d="M1350.69 1815.81v54.11c0 .79.09 1.37-.33 2.05-.6.97-1.26 1.92-1.88 2.88l-3.23 4.94c-.43.66-.72 1.19-1.4 1.6-.99.6-2.01 1.16-3.01 1.74-6.88 3.97-13.75 7.94-20.63 11.92l-23.64 13.65c.75.43 1.5.86 2.26 1.3V1864.43c0-.98-.12-2.05.05-3.02.2-1.11.72-2.23 1.09-3.29 1.57-4.56 3.14-9.11 4.71-13.67l-.69.9c16.32-9.41 32.63-18.82 48.95-28.23 1.67-.96.16-3.56-1.51-2.59l-42.51 24.51c-1.72.99-3.44 1.98-5.15 2.97-.81.47-1.57.77-1.98 1.64-.48 1.01-.75 2.18-1.12 3.24-1.28 3.71-2.55 7.41-3.83 11.12-.31.9-.78 1.85-.96 2.78s-.05 1.95-.05 2.89v46.31c0 1.14 1.27 1.86 2.26 1.3 14.18-8.19 28.36-16.38 42.55-24.58 1.72-.99 3.47-1.93 5.16-2.98 1.63-1.01 2.62-3.31 3.66-4.9l3.23-4.94c.57-.87.98-1.43 1.01-2.49.04-1.16 0-2.32 0-3.48V1815.79c0-1.93-3-1.93-3 0Z"
        style={{
          fill: "url(#linear-gradient-018429472-70)",
        }}
      />
      <path
        d="M1350.69 1815.81v54.11c0 .79.09 1.37-.33 2.05-.6.97-1.26 1.92-1.88 2.88l-3.23 4.94c-.43.66-.72 1.19-1.4 1.6-.99.6-2.01 1.16-3.01 1.74-6.88 3.97-13.75 7.94-20.63 11.92l-23.64 13.65c.75.43 1.5.86 2.26 1.3V1864.43c0-.98-.12-2.05.05-3.02.2-1.11.72-2.23 1.09-3.29 1.57-4.56 3.14-9.11 4.71-13.67l-.69.9c16.32-9.41 32.63-18.82 48.95-28.23 1.67-.96.16-3.56-1.51-2.59l-42.51 24.51c-1.72.99-3.44 1.98-5.15 2.97-.81.47-1.57.77-1.98 1.64-.48 1.01-.75 2.18-1.12 3.24-1.28 3.71-2.55 7.41-3.83 11.12-.31.9-.78 1.85-.96 2.78s-.05 1.95-.05 2.89v46.31c0 1.14 1.27 1.86 2.26 1.3 14.18-8.19 28.36-16.38 42.55-24.58 1.72-.99 3.47-1.93 5.16-2.98 1.63-1.01 2.62-3.31 3.66-4.9l3.23-4.94c.57-.87.98-1.43 1.01-2.49.04-1.16 0-2.32 0-3.48V1815.79c0-1.93-3-1.93-3 0Z"
        style={{
          fill: "url(#linear-gradient-018429472-71)",
        }}
      />
      <path
        d="M1063.31 2325.48c-26.15 15.24-52.3 30.49-78.45 45.73-7.33 4.27-14.66 8.54-21.98 12.82-1.67.97-.16 3.57 1.51 2.59 26.15-15.24 52.3-30.49 78.45-45.73 7.33-4.27 14.66-8.54 21.98-12.82 1.67-.97.16-3.57-1.51-2.59Z"
        style={{
          fill: "url(#linear-gradient-018429472-72)",
        }}
      />
      <path
        d="m1065.92 2440.61-.54-75.42-.15-21.48c-.01-1.93-3.01-1.93-3 0l.54 75.42.15 21.48c.01 1.93 3.01 1.93 3 0Z"
        style={{
          fill: "url(#linear-gradient-018429472-73)",
        }}
      />
      <path
        d="M948.17 2506.42c-.15-21.38-.31-42.76-.46-64.14-.04-6.07-.09-12.15-.13-18.22-.01-1.93-3.01-1.93-3 0 .15 21.38.31 42.76.46 64.14.04 6.07.09 12.15.13 18.22.01 1.93 3.01 1.93 3 0Z"
        style={{
          fill: "url(#linear-gradient-018429472-74)",
        }}
      />
      <path
        d="M1046.97 2459.32c-26.15 15.24-52.3 30.49-78.45 45.73-7.33 4.27-14.66 8.54-21.98 12.82-1.67.97-.16 3.57 1.51 2.59 26.15-15.24 52.3-30.49 78.45-45.73 7.33-4.27 14.66-8.54 21.98-12.82 1.67-.97.16-3.57-1.51-2.59Z"
        style={{
          fill: "url(#linear-gradient-018429472-75)",
        }}
      />
      <path
        d="M1099.12 2257.48v193.84c0 .8.19 1.87 0 2.65-.35 1.47-1.84 3.03-2.66 4.28l-5.73 8.76c-3.82 5.84-7.63 11.68-11.45 17.52-.95 1.45-1.84 2.98-2.86 4.38s-2.74 2.04-4.18 2.87c-3.05 1.76-6.1 3.52-9.14 5.28-24.57 14.19-49.14 28.39-73.72 42.58-24.38 14.08-48.76 28.17-73.14 42.25-3.05 1.76-6.1 3.52-9.14 5.28.75.43 1.5.86 2.26 1.3V2422.55c0-1.98-.29-4.24.27-6.14 1.07-3.66 2.5-7.26 3.74-10.87l16.68-48.45-.69.9c22.36-12.9 44.73-25.79 67.09-38.69 22.36-12.9 44.73-25.79 67.09-38.69 12.61-7.27 25.23-14.55 37.84-21.82 1.67-.96.16-3.56-1.51-2.59-24.66 14.22-49.31 28.44-73.97 42.65-24.66 14.22-49.31 28.44-73.97 42.65-6.21 3.58-12.42 7.16-18.64 10.75-1.43.83-2.87 1.65-4.3 2.48-.59.34-1.29.62-1.68 1.2-.45.66-.64 1.78-.9 2.53-1.14 3.31-2.28 6.63-3.42 9.94-4.6 13.36-9.2 26.71-13.79 40.07-1.07 3.1-2.83 6.5-2.83 9.79V2588.47c0 1.14 1.27 1.86 2.26 1.3 24.76-14.3 49.53-28.61 74.29-42.91 24.57-14.19 49.14-28.39 73.72-42.58 6.19-3.58 12.38-7.15 18.57-10.73 1.47-.85 2.71-1.41 3.65-2.84 1.01-1.55 2.03-3.1 3.04-4.65l5.73-8.76c3.88-5.93 7.75-11.86 11.63-17.79.84-1.28 2.09-2.69 2.68-4.11s.2-3.53.2-5.06V2257.5c0-1.93-3-1.93-3 0Z"
        className="cls-018429472-6"
      />
      <path
        d="M1073.61 2301.27V2441.75c0 1.2.2 2.57 0 3.75-.19 1.12-1.34 2.35-1.97 3.31-1.4 2.15-2.8 4.29-4.21 6.44-2.8 4.29-5.61 8.58-8.41 12.87-.67 1.03-1.3 2.12-2.04 3.11s-2.01 1.45-3.03 2.04c-2.3 1.33-4.61 2.66-6.91 3.99-17.92 10.35-35.85 20.71-53.77 31.06-18 10.4-35.99 20.79-53.99 31.19l-6.69 3.87 2.26 1.3v-121.65c0-1.44-.25-3.17.11-4.56.7-2.71 1.85-5.37 2.76-8.01 4.11-11.94 8.22-23.89 12.33-35.83l-.69.9c16.48-9.5 32.96-19 49.44-28.51 16.41-9.46 32.81-18.92 49.22-28.38 9.28-5.35 18.57-10.71 27.85-16.06 1.67-.96.16-3.56-1.51-2.59-18.13 10.46-36.27 20.91-54.4 31.37-18.06 10.42-36.12 20.83-54.19 31.25-4.53 2.61-9.07 5.23-13.6 7.84-1.08.62-2.16 1.24-3.24 1.87-.45.26-1.03.48-1.38.86-.44.48-.59 1.24-.79 1.84-.84 2.44-1.68 4.87-2.52 7.31-3.41 9.9-6.82 19.8-10.22 29.7-.82 2.38-2.16 4.99-2.16 7.52v125.15c0 1.14 1.27 1.86 2.26 1.3l54.42-31.44 54.42-31.44c4.61-2.66 9.21-5.32 13.82-7.98.99-.57 1.89-.99 2.54-1.95.78-1.17 1.54-2.35 2.31-3.53 1.4-2.15 2.8-4.29 4.21-6.44 2.89-4.43 5.79-8.86 8.68-13.29.63-.97 1.57-2.01 1.98-3.09s.13-2.67.13-3.83V2301.28c0-1.93-3-1.93-3 0Z"
        style={{
          fill: "url(#linear-gradient-018429472-76)",
        }}
      />
      <path
        d="M1020.68 2392.16v35.21c0 .51.03.87-.18 1.32-.28.61-.79 1.21-1.16 1.78-.65.99-1.29 1.98-1.94 2.97-.25.38-.46.8-.8 1.1-.53.46-1.33.77-1.94 1.12-4.53 2.62-9.06 5.23-13.59 7.85-5.18 2.99-10.35 5.98-15.53 8.97.75.43 1.5.86 2.26 1.3V2426.84c0-1.75-.27-3.81.27-5.53 1.08-3.46 2.36-6.86 3.54-10.28l-.69.9c10.67-6.16 21.35-12.31 32.02-18.47 1.67-.96.16-3.56-1.51-2.59-9.38 5.41-18.75 10.81-28.13 16.22-1.08.62-2.16 1.25-3.25 1.87-1.33.77-1.5 1.74-1.98 3.14-.86 2.49-1.72 4.99-2.57 7.48-.21.6-.51 1.25-.64 1.87s-.05 1.28-.05 1.9v30.43c0 1.14 1.27 1.86 2.26 1.3 9.42-5.44 18.84-10.88 28.25-16.32 1.08-.62 2.44-1.17 3.39-1.99s1.54-2.18 2.2-3.19 1.38-2.11 2.07-3.17c.28-.44.6-.83.7-1.35.13-.69.03-1.49.03-2.18v-34.72c0-1.93-3-1.93-3 0Z"
        style={{
          fill: "url(#linear-gradient-018429472-77)",
        }}
      />
      <path
        d="M1030.16 2375.88v54.11c0 .79.09 1.37-.33 2.05-.6.97-1.26 1.92-1.88 2.88l-3.23 4.94c-.43.66-.72 1.19-1.4 1.6-.99.6-2.01 1.16-3.01 1.74-6.88 3.97-13.75 7.94-20.63 11.92l-23.64 13.65c.75.43 1.5.86 2.26 1.3V2424.5c0-.98-.12-2.05.05-3.02.2-1.11.72-2.23 1.09-3.29 1.57-4.56 3.14-9.11 4.71-13.67l-.69.9c16.32-9.41 32.63-18.82 48.95-28.23 1.67-.96.16-3.56-1.51-2.59l-42.51 24.51c-1.72.99-3.44 1.98-5.15 2.97-.81.47-1.57.77-1.98 1.64-.48 1.01-.75 2.18-1.12 3.24-1.28 3.71-2.55 7.41-3.83 11.12-.31.9-.78 1.85-.96 2.78s-.05 1.95-.05 2.89v46.31c0 1.14 1.27 1.86 2.26 1.3 14.18-8.19 28.36-16.38 42.55-24.58 1.72-.99 3.47-1.93 5.16-2.98s2.62-3.31 3.66-4.9l3.23-4.94c.57-.87.98-1.43 1.01-2.49.04-1.16 0-2.32 0-3.48V2375.86c0-1.93-3-1.93-3 0Z"
        style={{
          fill: "url(#linear-gradient-018429472-78)",
        }}
      />
      <path
        d="M1384.76 2138.59c-26.15 15.24-52.3 30.49-78.45 45.73-7.33 4.27-14.66 8.54-21.98 12.82-1.67.97-.16 3.57 1.51 2.59 26.15-15.24 52.3-30.49 78.45-45.73 7.33-4.27 14.66-8.54 21.98-12.82 1.67-.97.16-3.57-1.51-2.59Z"
        style={{
          fill: "url(#linear-gradient-018429472-79)",
        }}
      />
      <path
        d="m1387.37 2253.72-.54-75.42-.15-21.48c-.01-1.93-3.01-1.93-3 0l.54 75.42.15 21.48c.01 1.93 3.01 1.93 3 0Z"
        style={{
          fill: "url(#linear-gradient-018429472-80)",
        }}
      />
      <path
        d="M1269.62 2319.53c-.15-21.38-.31-42.76-.46-64.14-.04-6.07-.09-12.15-.13-18.22-.01-1.93-3.01-1.93-3 0 .15 21.38.31 42.76.46 64.14.04 6.07.09 12.15.13 18.22.01 1.93 3.01 1.93 3 0Z"
        style={{
          fill: "url(#linear-gradient-018429472-81)",
        }}
      />
      <path
        d="M1368.42 2272.43c-26.15 15.24-52.3 30.49-78.45 45.73-7.33 4.27-14.66 8.54-21.98 12.82-1.67.97-.16 3.57 1.51 2.59 26.15-15.24 52.3-30.49 78.45-45.73 7.33-4.27 14.66-8.54 21.98-12.82 1.67-.97.16-3.57-1.51-2.59Z"
        style={{
          fill: "url(#linear-gradient-018429472-82)",
        }}
      />
      <path
        d="M1420.57 2070.59v193.84c0 .8.19 1.87 0 2.65-.35 1.47-1.84 3.03-2.66 4.28l-5.73 8.76c-3.82 5.84-7.63 11.68-11.45 17.52-.95 1.45-1.84 2.98-2.86 4.38-.99 1.35-2.74 2.04-4.18 2.87-3.05 1.76-6.1 3.52-9.14 5.28-24.57 14.19-49.14 28.39-73.72 42.58-24.38 14.08-48.76 28.17-73.14 42.25-3.05 1.76-6.1 3.52-9.14 5.28.75.43 1.5.86 2.26 1.3V2235.66c0-1.98-.29-4.24.27-6.14 1.07-3.66 2.5-7.26 3.74-10.87l16.68-48.45-.69.9c22.36-12.9 44.73-25.79 67.09-38.69 22.36-12.9 44.73-25.79 67.09-38.69 12.61-7.27 25.23-14.55 37.84-21.82 1.67-.96.16-3.56-1.51-2.59-24.66 14.22-49.31 28.44-73.97 42.65-24.66 14.22-49.31 28.44-73.97 42.65-6.21 3.58-12.42 7.16-18.64 10.75-1.43.83-2.87 1.65-4.3 2.48-.59.34-1.29.62-1.68 1.2-.45.66-.64 1.78-.9 2.53-1.14 3.31-2.28 6.63-3.42 9.94-4.6 13.36-9.2 26.71-13.79 40.07-1.07 3.1-2.83 6.5-2.83 9.79V2401.58c0 1.14 1.27 1.86 2.26 1.3 24.76-14.3 49.53-28.61 74.29-42.91 24.57-14.19 49.14-28.39 73.72-42.58 6.19-3.58 12.38-7.15 18.57-10.73 1.47-.85 2.71-1.41 3.65-2.84 1.01-1.55 2.03-3.1 3.04-4.65l5.73-8.76c3.88-5.93 7.75-11.86 11.63-17.79.84-1.28 2.09-2.69 2.68-4.11.59-1.42.2-3.53.2-5.06v-192.84c0-1.93-3-1.93-3 0Z"
        className="cls-018429472-6"
      />
      <path
        d="M1395.06 2114.38V2254.86c0 1.2.2 2.57 0 3.75s-1.34 2.35-1.97 3.31c-1.4 2.15-2.8 4.29-4.21 6.44-2.8 4.29-5.61 8.58-8.41 12.87-.67 1.03-1.3 2.12-2.04 3.11s-2.01 1.45-3.03 2.04c-2.3 1.33-4.61 2.66-6.91 3.99-17.92 10.35-35.85 20.71-53.77 31.06-18 10.4-35.99 20.79-53.99 31.19l-6.69 3.87c.75.43 1.5.86 2.26 1.3v-121.65c0-1.44-.25-3.17.11-4.56.7-2.71 1.85-5.37 2.76-8.01 4.11-11.94 8.22-23.89 12.33-35.83l-.69.9c16.48-9.5 32.96-19 49.44-28.51 16.41-9.46 32.81-18.92 49.22-28.38 9.28-5.35 18.57-10.71 27.85-16.06 1.67-.96.16-3.56-1.51-2.59-18.13 10.46-36.27 20.91-54.4 31.37-18.06 10.42-36.12 20.83-54.19 31.25-4.53 2.61-9.07 5.23-13.6 7.84-1.08.62-2.16 1.24-3.24 1.87-.45.26-1.03.48-1.38.86-.44.48-.59 1.24-.79 1.84-.84 2.44-1.68 4.87-2.52 7.31-3.41 9.9-6.82 19.8-10.22 29.7-.82 2.38-2.16 4.99-2.16 7.52v125.15c0 1.14 1.27 1.86 2.26 1.3l54.42-31.44 54.42-31.44c4.61-2.66 9.21-5.32 13.82-7.98.99-.57 1.89-.99 2.54-1.95.78-1.17 1.54-2.35 2.31-3.53 1.4-2.15 2.8-4.29 4.21-6.44 2.89-4.43 5.79-8.86 8.68-13.29.63-.97 1.57-2.01 1.98-3.09s.13-2.67.13-3.83V2114.39c0-1.93-3-1.93-3 0Z"
        style={{
          fill: "url(#linear-gradient-018429472-83)",
        }}
      />
      <path
        d="M1342.13 2205.27v35.21c0 .51.03.87-.18 1.32-.28.61-.79 1.21-1.16 1.78-.65.99-1.29 1.98-1.94 2.97-.25.38-.46.8-.8 1.1-.53.46-1.33.77-1.94 1.12-4.53 2.62-9.06 5.23-13.59 7.85-5.18 2.99-10.35 5.98-15.53 8.97.75.43 1.5.86 2.26 1.3V2239.95c0-1.79-.27-3.81.27-5.53 1.08-3.46 2.36-6.86 3.54-10.28l-.69.9c10.67-6.16 21.35-12.31 32.02-18.47 1.67-.96.16-3.56-1.51-2.59-9.38 5.41-18.75 10.81-28.13 16.22-1.08.62-2.16 1.25-3.25 1.87-1.33.77-1.5 1.74-1.98 3.14-.86 2.49-1.72 4.99-2.57 7.48-.21.6-.51 1.25-.64 1.87s-.05 1.28-.05 1.9v30.43c0 1.14 1.27 1.86 2.26 1.3 9.42-5.44 18.84-10.88 28.25-16.32 1.08-.62 2.44-1.17 3.39-1.99s1.54-2.18 2.2-3.19 1.38-2.11 2.07-3.17c.28-.44.6-.83.7-1.35.13-.69.03-1.49.03-2.18v-34.72c0-1.93-3-1.93-3 0Z"
        style={{
          fill: "url(#linear-gradient-018429472-84)",
        }}
      />
      <path
        d="M1351.61 2188.99v54.11c0 .79.09 1.37-.33 2.05-.6.97-1.26 1.92-1.88 2.88l-3.23 4.94c-.43.66-.72 1.19-1.4 1.6-.99.6-2.01 1.16-3.01 1.74-6.88 3.97-13.75 7.94-20.63 11.92l-23.64 13.65c.75.43 1.5.86 2.26 1.3V2237.61c0-.98-.12-2.05.05-3.02.2-1.11.72-2.23 1.09-3.29 1.57-4.56 3.14-9.11 4.71-13.67l-.69.9c16.32-9.41 32.63-18.82 48.95-28.23 1.67-.96.16-3.56-1.51-2.59l-42.51 24.51c-1.72.99-3.44 1.98-5.15 2.97-.81.47-1.57.77-1.98 1.64-.48 1.01-.75 2.18-1.12 3.24-1.28 3.71-2.55 7.41-3.83 11.12-.31.9-.78 1.85-.96 2.78s-.05 1.95-.05 2.89v46.31c0 1.14 1.27 1.86 2.26 1.3 14.18-8.19 28.36-16.38 42.55-24.58 1.72-.99 3.47-1.93 5.16-2.98s2.62-3.31 3.66-4.9l3.23-4.94c.57-.87.98-1.43 1.01-2.49.04-1.16 0-2.32 0-3.48V2188.97c0-1.93-3-1.93-3 0Z"
        style={{
          fill: "url(#linear-gradient-018429472-85)",
        }}
      />
    </g>
    <g id="szescian">
      <path
        d="M1460.75 1621.25c-19.94 11.52-39.89 23.03-59.83 34.55-47.88 27.64-95.76 55.28-143.63 82.93-57.71 33.32-115.42 66.64-173.13 99.95-49.87 28.79-99.75 57.59-149.62 86.38-16.52 9.54-33.03 19.07-49.55 28.61-7.47 4.31-16.43 8.08-23.23 13.41-.3.24-.7.41-1.04.6h5.39c-19.94-11.52-39.89-23.03-59.83-34.55-47.88-27.64-95.76-55.28-143.63-82.93-57.71-33.32-115.42-66.64-173.13-99.95-49.87-28.79-99.74-57.59-149.62-86.38-16.52-9.54-33.03-19.07-49.55-28.61-7.45-4.3-15.21-10.22-23.23-13.41-.36-.14-.7-.41-1.04-.6v9.22c19.94-11.52 39.89-23.03 59.83-34.55 47.88-27.64 95.76-55.28 143.63-82.93 57.71-33.32 115.42-66.64 173.13-99.95 49.87-28.79 99.75-57.59 149.62-86.38 16.52-9.54 33.03-19.07 49.55-28.61 7.47-4.31 16.43-8.08 23.23-13.41.3-.24.7-.41 1.04-.6h-5.39c19.94 11.52 39.89 23.03 59.83 34.55 47.88 27.64 95.76 55.28 143.63 82.93 57.71 33.32 115.42 66.64 173.13 99.95 49.87 28.79 99.74 57.59 149.62 86.38 24.21 13.98 48.12 28.83 72.78 42.02.35.19.69.4 1.04.6 5.96 3.44 11.35-5.78 5.39-9.22-20.05-11.58-40.11-23.16-60.16-34.73l-144.3-83.31c-57.71-33.32-115.43-66.64-173.14-99.96-50.01-28.87-100.02-57.75-150.03-86.62-16.34-9.43-32.67-18.86-49.01-28.3-7.45-4.3-14.83-9.3-22.59-13.04-5.34-2.58-10.35 1.93-14.97 4.59-12.38 7.15-24.75 14.29-37.13 21.44-45.36 26.19-90.73 52.38-136.09 78.57-57.59 33.25-115.18 66.5-172.78 99.75-51.98 30.01-103.97 60.03-155.95 90.04-28.47 16.44-56.95 32.88-85.42 49.32l-3.9 2.25c-3.46 2-3.46 7.22 0 9.22 20.05 11.58 40.11 23.16 60.16 34.73l144.3 83.31c57.71 33.32 115.43 66.64 173.14 99.96 50.01 28.87 100.02 57.75 150.03 86.62 16.34 9.43 32.67 18.86 49.01 28.3 7.45 4.3 14.83 9.3 22.59 13.04 5.34 2.58 10.35-1.93 14.97-4.59 12.38-7.15 24.75-14.29 37.13-21.44 45.36-26.19 90.73-52.38 136.09-78.57 57.59-33.25 115.18-66.5 172.78-99.75 51.98-30.01 103.97-60.03 155.95-90.04 28.47-16.44 56.95-32.88 85.42-49.32l3.9-2.25c5.95-3.44.58-12.67-5.39-9.22Z"
        className="cls-018429472-4"
      />
      <path
        d="M258.04 1625.86v607.88c0 27.88-.9 55.93 0 83.8.01.39 0 .78 0 1.18 0 1.87 1.02 3.67 2.65 4.61l60.12 34.71c47.78 27.59 95.57 55.17 143.35 82.76l173.19 99.99c49.88 28.8 99.76 57.6 149.65 86.4 24.2 13.97 48.11 28.71 72.71 41.98.35.19.68.39 1.02.59 3.5 2.02 8.04-.55 8.04-4.61v-607.88c0-27.88.9-55.93 0-83.8-.01-.39 0-.78 0-1.18 0-1.87-1.02-3.67-2.65-4.61-20-11.55-40-23.1-60.01-34.64-47.71-27.54-95.42-55.09-143.13-82.63-57.67-33.3-115.34-66.59-173.02-99.89-50.1-28.92-100.19-57.85-150.29-86.77-24.15-13.94-48.01-28.68-72.58-41.9-.34-.19-.68-.39-1.02-.59-5.96-3.44-11.35 5.78-5.39 9.22 20 11.55 40 23.1 60.01 34.64 47.71 27.54 95.42 55.09 143.13 82.63 57.67 33.3 115.34 66.59 173.02 99.89 50.1 28.92 100.19 57.85 150.29 86.77 16.34 9.44 32.69 18.87 49.03 28.31 7.65 4.42 15.41 10.1 23.55 13.6.35.15.69.4 1.02.59-.88-1.54-1.76-3.07-2.65-4.61v664.49c0 8.82-1.07 18.42 0 27.19.05.38 0 .79 0 1.18 2.68-1.54 5.36-3.07 8.04-4.61L806 2625.84c-47.78-27.59-95.57-55.17-143.35-82.76l-173.19-99.99c-49.88-28.8-99.76-57.6-149.65-86.4-16.5-9.53-33-19.05-49.5-28.58-7.56-4.37-15.2-9.88-23.21-13.4-.35-.15-.69-.4-1.02-.59.88 1.54 1.76 3.07 2.65 4.61v-607.87c0-27.88.9-55.93 0-83.8-.01-.39 0-.78 0-1.18 0-6.87-10.68-6.88-10.68 0Z"
        className="cls-018429472-4"
      />
      <path
        d="M1458.1 1625.86v664.49c0 8.84-1.03 18.4 0 27.19.04.38 0 .79 0 1.18.88-1.54 1.76-3.07 2.65-4.61l-60.12 34.71c-47.78 27.59-95.57 55.17-143.35 82.76l-173.19 99.99c-49.88 28.8-99.76 57.6-149.65 86.4-16.5 9.53-33 19.05-49.5 28.58-7.55 4.36-16.18 8.2-23.21 13.4-.31.23-.69.4-1.02.59 2.68 1.54 5.36 3.07 8.04 4.61v-664.49c0-8.84 1.03-18.4 0-27.19-.04-.38 0-.79 0-1.18-.88 1.54-1.76 3.07-2.65 4.61 20-11.55 40-23.1 60.01-34.64 47.71-27.54 95.42-55.09 143.13-82.63 57.67-33.3 115.34-66.59 173.02-99.89 50.1-28.92 100.19-57.85 150.29-86.77 24.15-13.94 48.89-27.19 72.58-41.9.33-.21.68-.39 1.02-.59 5.95-3.44.58-12.67-5.39-9.22-20 11.55-40 23.1-60.01 34.64-47.71 27.54-95.42 55.09-143.13 82.63-57.67 33.3-115.34 66.59-173.02 99.89-50.1 28.92-100.19 57.85-150.29 86.77-24.15 13.94-48.89 27.19-72.58 41.9-.33.21-.68.39-1.02.59-1.63.94-2.65 2.74-2.65 4.61v607.88c0 27.88-.9 55.93 0 83.8.01.39 0 .78 0 1.18 0 4.06 4.53 6.63 8.04 4.61l60.12-34.71c47.78-27.59 95.57-55.17 143.35-82.76l173.19-99.99c49.88-28.8 99.76-57.6 149.65-86.4 24.19-13.97 48.96-27.26 72.71-41.98.33-.21.68-.39 1.02-.59 1.63-.94 2.65-2.74 2.65-4.61v-607.88c0-27.88.9-55.93 0-83.8-.01-.39 0-.78 0-1.18 0-6.87-10.68-6.88-10.68 0Z"
        className="cls-018429472-4"
      />
      <g
        style={{
          opacity: 0.19,
        }}
      >
        <g
          style={{
            clipPath: "url(#clippath-24)",
          }}
        >
          <path
            d="M-2126.79 1285.06 101.09-1.21M-2148.22 1247.93 79.65-38.34M-2169.66 1210.79 58.22-75.47M-2191.1 1173.66 36.78-112.6M-2212.54 1136.53 15.34-149.73"
            className="cls-018429472-3"
          />
        </g>
        <path d="M-2497.52-1299.3h1724.23V424.93h-1724.23z" className="cls-018429472-2" />
      </g>
    </g>
  </svg>)
}
