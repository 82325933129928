import { LandingFooter } from "../LandingFooter/LandingFooter";
import { LandingNavbar } from "../LandingNavbar/LandingNavbar";

export const InformativeDuties = () => {
  return (
    <div className="document">
      <LandingNavbar />

      <div className="document__text document__text--information-Duties">
        <div>
          <p
            style={{
              marginBottom: "6pt",
              textAlign: "center",
              fontSize: "20px",
            }}
          >
            <strong>
              <span>OBOWIĄZKI INFORMACYJNE </span>
            </strong>
          </p>
          <p
            style={{
              marginBottom: "6pt",
              textAlign: "center",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <strong>
              <span>dla Konsumentów korzystających z Serwisu NaSesje.com</span>
            </strong>
          </p>
          <br></br> <br></br>
          <ol className="awlist1" style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li
              style={{
                marginLeft: "14pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span
                style={{
                  width: "4.2pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;{" "}
              </span>
              <span style={{ fontWeight: "normal" }}>
                Serwis Nasesje.com prowadzony jest przez sprzedawcę ELERNA GROUP
                Spółka z ograniczoną odpowiedzialnością (ul. Chmieleniec nr 6
                lok. 9, 30 - 348 Kraków), wpisaną do rejestru przedsiębiorców
                Krajowego Rejestru Sądowego przez Sąd Rejonowy dla Krakowa –
                Śródmieścia w Krakowie, Wydział XI Gospodarczy Krajowego
                Rejestru Sądowego pod numerem KRS: 0001098308, NIP: 6762666516,
                REGON: 528290584, adres poczty elektronicznej:
                support@nasesje.com.
              </span>
              <span style={{ fontWeight: "normal" }}>&nbsp;</span>
              <span style={{ fontWeight: "normal" }}>
                (dalej jako: „Sprzedawca” lub „Serwis”).
              </span>
            </li>
            <li
              style={{
                marginLeft: "14pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span
                style={{
                  width: "4.2pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;{" "}
              </span>
              <span style={{ fontWeight: "normal" }}>
                Akceptując niniejszy dokument (dalej również jako: „Obowiązki
                informacyjne”) Konsument oświadcza, iż zapoznał się z zawartymi
                w nim informacjami.
              </span>
            </li>
            <li
              style={{
                marginLeft: "14pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span
                style={{
                  width: "4.2pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;{" "}
              </span>
              <span style={{ fontWeight: "normal" }}>
                Wyrażenia napisane wielką literą, które nie zostały zdefiniowane
                w niniejszym dokumencie posiadają znaczenie nadane im w
                Regulaminie Serwisu.{" "}
              </span>
            </li>
            <li
              style={{
                marginLeft: "14pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span
                style={{
                  width: "4.2pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;{" "}
              </span>
              <span style={{ fontWeight: "normal" }}>
                Sprzedawca ponosi odpowiedzialność za zgodność świadczenia
                (produktu) z zawartą Umową.
              </span>
            </li>
            <li
              style={{
                marginLeft: "14pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span
                style={{
                  width: "4.2pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;{" "}
              </span>
              <span style={{ fontWeight: "normal" }}>
                W związku z obowiązkami, które wynikają z umów licencyjnych
                zawartych z dostawcami treści lub partnerami handlowymi, do
                zakupu lub korzystania z Produktów świadczonych przez Serwis
                może być wymagane podanie przez Konsumenta danych osobowych, w
                szczególności takich jak: imię i nazwisko, dokładny i aktualny
                adres zamieszkania, adres e-mail, numer telefonu.
              </span>
            </li>
            <li
              style={{
                marginLeft: "14pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span
                style={{
                  width: "4.2pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;{" "}
              </span>
              <span style={{ fontWeight: "normal" }}>
                W przypadku jakichkolwiek pytań dotyczących Produktów, Umowy
                zawartej ze Sprzedawcą lub reklamacji prosimy o kontakt:
              </span>
            </li>
          </ol>
          <ol
            className="awlist1"
            style={{ margin: "0pt", paddingLeft: "17pt" }}
          >
            <li
              style={{
                marginLeft: "14pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",
                fontSize: "16px",
              }}
            >
              <span
                style={{
                  width: "4.2pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;{" "}
              </span>
              <span style={{ fontWeight: "normal" }}>
                za pomocą elektronicznego formularza kontaktowego, który jest
                dostępny pod adresem Nasesje.com wraz z jego subdomenami,
              </span>
            </li>
            <li
              style={{
                marginLeft: "14pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",
                fontSize: "16px",
              }}
            >
              <span
                style={{
                  width: "4.2pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;{" "}
              </span>
              <span style={{ fontWeight: "normal" }}>
                pod adresem poczty elektronicznej: support@nasesje.com,
              </span>
            </li>
            <li
              style={{
                marginLeft: "14pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",
                fontSize: "16px",
              }}
            >
              <span
                style={{
                  width: "4.2pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;{" "}
              </span>
              <span style={{ fontWeight: "normal" }}>
                przy wykorzystaniu danych teleadresowych wskazanych na stronie
                internetowej Serwisu (numer telefonu, adres e-mail, adres
                korespondencyjny).
              </span>
            </li>
          </ol>
          <ol
            start={7}
            className="awlist2"
            style={{ margin: "0pt", paddingLeft: "0pt" }}
          >
            <li
              className="ListParagraph"
              style={{
                marginLeft: "14pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span
                style={{
                  width: "4.2pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;{" "}
              </span>
              <span style={{ fontWeight: "normal" }}>
                Wszelkie informacje, potwierdzenia i dokumenty dotyczące
                zawartej Umowy będą przekazywane Konsumentowi za pomocą
                wiadomości e-mail na adres poczty elektronicznej podany przez
                niego z zastrzeżeniem postanowień Regulaminu Serwisu.
              </span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "14pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span
                style={{
                  width: "4.2pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;{" "}
              </span>
              <span style={{ fontWeight: "normal" }}>
                Opłaty za korzystanie z Produktów oferowanych przez Serwis,
                wskazane są w Serwisie i szczegółowo opisane, w tym także w
                zakresie promocji na poszczególne Produkty. Konsument jest
                jednocześnie informowany o dodatkowych kosztach bądź o
                możliwości ich wystąpienia oraz wysokości, względnie o sposobie
                ich obliczenia (w przypadku gdy takie występują).
              </span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "14pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span
                style={{
                  width: "4.2pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;{" "}
              </span>
              <span style={{ fontWeight: "normal" }}>
                W związku ze złożeniem w Serwisie
              </span>
              <span style={{ fontWeight: "normal" }}>&nbsp;</span>
              <span style={{ fontWeight: "normal" }}>
                {" "}
                zamówienia z obowiązkiem zapłaty i zawarciem Umowy, Konsument
                jest zobowiązany uiścić opłatę za wybrany Produkt, dokonując
                płatności online w sposób wskazany w opisie tego Produktu.
                Sprzedawca realizuje zlecenie aktywacji Produktu najpóźniej w
                ciągu 12 godzin od zawarcia Umowy, chyba że warunki promocji lub
                szczegółowe warunki dla danego Produktu przewidują inny okres
                aktywacji.
              </span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "14pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span
                style={{
                  width: "5.37pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;&nbsp;{" "}
              </span>
              <span style={{ fontWeight: "normal" }}>
                Prawo do odstąpienia od Umowy zawartej na odległość:
              </span>
              <ol
                className="awlist1"
                style={{ margin: "0pt", paddingLeft: "4pt" }}
              >
                <li
                  style={{
                    marginLeft: "14pt",
                    textIndent: "-5pt",
                    textAlign: "justify",
                    lineHeight: "150%",
                    fontSize: "16px",
                  }}
                >
                  <span
                    style={{
                      width: "4.2pt",
                      font: '7pt "Times New Roman"',
                      display: "inline-block",
                    }}
                  >
                    &nbsp;&nbsp;{" "}
                  </span>
                  <span style={{ fontWeight: "normal" }}>
                    Konsument może zrealizować uprawnienie do odstąpienia od
                    Umowy zawartej ze Sprzedawcą w terminie 14 dni od dnia jej
                    zawarcia, bez podawania przyczyny, z zastrzeżeniem pkt 10 e)
                    poniżej,
                  </span>
                </li>
                <li
                  style={{
                    marginLeft: "14pt",
                    textIndent: "-5pt",
                    textAlign: "justify",
                    lineHeight: "150%",
                    fontSize: "16px",
                  }}
                >
                  <span
                    style={{
                      width: "4.2pt",
                      font: '7pt "Times New Roman"',
                      display: "inline-block",
                    }}
                  >
                    &nbsp;&nbsp;{" "}
                  </span>
                  <span style={{ fontWeight: "normal" }}>
                    Oświadczenie o odstąpieniu od Umowy może przybrać dowolną
                    treść pod warunkiem, że będzie jednoznacznie wskazywać wolę
                    odstąpienia od Umowy przez Konsumenta,
                  </span>
                </li>
                <li
                  style={{
                    marginLeft: "14pt",
                    textIndent: "-5pt",
                    textAlign: "justify",
                    lineHeight: "150%",
                    fontSize: "16px",
                  }}
                >
                  <span
                    style={{
                      width: "4.2pt",
                      font: '7pt "Times New Roman"',
                      display: "inline-block",
                    }}
                  >
                    &nbsp;&nbsp;{" "}
                  </span>
                  <span style={{ fontWeight: "normal" }}>
                    Oświadczenie o odstąpieniu od Umowy może zostać złożone z
                    wykorzystaniem wzoru formularza w formie edytowalnego pliku
                    pdf, który stanowi załącznik niniejszego dokumentu, m.in. na
                    adres e-mail: support@nasesje.com, lub na adres
                    korespondencyjny: adres: ul. Chmieleniec nr 6 lok. 9, 30 -
                    348 Kraków,
                  </span>
                </li>

                <li
                  style={{
                    marginLeft: "14pt",
                    textIndent: "-5pt",
                    textAlign: "justify",
                    lineHeight: "150%",
                    fontSize: "16px",
                  }}
                >
                  <span
                    style={{
                      width: "4.2pt",
                      font: '7pt "Times New Roman"',
                      display: "inline-block",
                    }}
                  >
                    &nbsp;&nbsp;{" "}
                  </span>
                  <span style={{ fontWeight: "normal" }}>
                    Do zachowania terminu wystarczy wysłanie oświadczenia przed
                    jego upływem (o zachowaniu terminu decyduje data wysyłki).
                    Odstąpienie od Umowy jest skuteczne od chwili doręczenia
                    Sprzedawcy oświadczenia o odstąpieniu od Umowy.
                  </span>
                </li>

                <li
                  style={{
                    marginLeft: "14pt",
                    textIndent: "-5pt",
                    textAlign: "justify",
                    lineHeight: "150%",
                    fontSize: "16px",
                  }}
                >
                  <span
                    style={{
                      width: "4.2pt",
                      font: '7pt "Times New Roman"',
                      display: "inline-block",
                    }}
                  >
                    &nbsp;&nbsp;{" "}
                  </span>
                  <span style={{ fontWeight: "normal" }}>
                    Prawo odstąpienia od Umowy zawartej poza lokalem
                    przedsiębiorstwa lub na odległość nie przysługuje
                    Konsumentowi w odniesieniu do świadczeń (Produktów)
                    Sprzedawcy realizowanych w ramach umów:
                  </span>
                </li>
              </ol>
            </li>
          </ol>
          <ol
            className="awlist1"
            style={{ margin: "0pt", paddingLeft: "35pt" }}
          >
            <li
              style={{
                marginLeft: "14pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",
                fontSize: "16px",
              }}
            >
              <span
                style={{
                  width: "4.2pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;{" "}
              </span>
              <span style={{ fontWeight: "normal" }}>
                dostarczanie treści cyfrowych (materiałów audiowizualnych np.
                kursów online, dostępów do nagrań, innych etc.) niedostarczanych
                na nośniku materialnym, za które Konsument jest zobowiązany do
                zapłaty ceny, jeżeli Serwis rozpoczął świadczenie za wyraźną i
                uprzednią zgodą Konsumenta, który został poinformowany przed
                rozpoczęciem świadczenia, że po spełnieniu świadczenia przez
                Serwis utraci prawo odstąpienia. Powyższą zgodę Konsument może
                wyrazić podczas procesu zakupowego w postaci złożenia, w formie
                elektronicznej, osobnego oświadczenia woli w tym zakresie,
                następującego poprzez zaznaczenie odpowiedniego pola wyboru
                (checkbox) dotyczącego Obowiązków informacyjnych Sprzedawcy,
              </span>
            </li>
            <li
              style={{
                marginLeft: "14pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",
                fontSize: "16px",
              }}
            >
              <span
                style={{
                  width: "4.2pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;{" "}
              </span>
              <span style={{ fontWeight: "normal" }}>
                świadczenie usług, za które Konsument jest zobowiązany do
                zapłaty ceny, jeżeli Serwis wykonał w pełni usługę za wyraźną i
                uprzednią zgodą Konsumenta, który został poinformowany przez
                Serwis przed rozpoczęciem świadczenia, że po spełnieniu
                świadczenia przez Serwis utraci prawo odstąpienia od umowy i
                przyjął to do wiadomości. Powyższą zgodę Konsument może wyrazić
                podczas procesu zakupowego w postaci złożenia, w formie
                elektronicznej, osobnego oświadczenia woli w tym zakresie,
                następującego poprzez zaznaczenie odpowiedniego pola wyboru
                (checkbox) dotyczącego Obowiązków informacyjnych Sprzedawcy.
              </span>
            </li>
          </ol>
          <ol
            start={11}
            className="awlist4"
            style={{ margin: "0pt", paddingLeft: "0pt" }}
          >
            <li
              className="ListParagraph"
              style={{
                marginLeft: "14pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span
                style={{
                  width: "5.37pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;&nbsp;{" "}
              </span>
              <span style={{ fontWeight: "normal" }}>
                Skutki odstąpienia od Umowy zawartej na odległość:
              </span>
              <ol
                className="awlist5"
                style={{
                  marginRight: "0pt",
                  marginLeft: "0pt",
                  paddingLeft: "0pt",
                }}
              >
                <li
                  className="ListParagraph"
                  style={{
                    marginLeft: "18pt",
                    textIndent: "-5pt",
                    fontSize: "16px",
                  }}
                >
                  <span
                    style={{
                      width: "4.72pt",
                      font: '7pt "Times New Roman"',
                      display: "inline-block",
                    }}
                  >
                    &nbsp;&nbsp;{" "}
                  </span>
                  <span style={{ fontWeight: "normal" }}>
                    W razie odstąpienia od Umowy zawartej na odległość, Umowa
                    jest uważana za niezawartą z zastrzeżeniem pkt 10 e)
                    powyżej,
                  </span>
                </li>
                <li
                  className="ListParagraph"
                  style={{
                    marginLeft: "18pt",
                    textIndent: "-5pt",
                    fontSize: "16px",
                  }}
                >
                  <span
                    style={{
                      width: "4.15pt",
                      font: '7pt "Times New Roman"',
                      display: "inline-block",
                    }}
                  >
                    &nbsp;&nbsp;{" "}
                  </span>
                  <span style={{ fontWeight: "normal" }}>
                    Sprzedawca ma obowiązek niezwłocznie, nie później niż w
                    terminie 14 dni od dnia otrzymania od Konsumenta
                    oświadczenia woli o odstąpieniu od Umowy, zwrócić wszystkie
                    dokonane przez niego płatności, z zastrzeżeniem pkt 10 e)
                    powyżej,{" "}
                  </span>
                </li>
                <li
                  className="ListParagraph"
                  style={{
                    marginLeft: "18pt",
                    textIndent: "-5pt",
                    fontSize: "16px",
                  }}
                >
                  <span
                    style={{
                      width: "5.38pt",
                      font: '7pt "Times New Roman"',
                      display: "inline-block",
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;{" "}
                  </span>
                  <span style={{ fontWeight: "normal" }}>
                    Sprzedawca dokonuje zwrotu płatności przy użyciu takiego
                    samego sposobu zapłaty, jakiego użył Konsument, chyba że
                    wyraźnie zgodził się na inny sposób zwrotu, który nie wiąże
                    się dla niego z żadnymi kosztami.
                  </span>
                </li>
              </ol>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "14pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span
                style={{
                  width: "5.37pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;&nbsp;{" "}
              </span>
              <span style={{ fontWeight: "normal" }}>
                Sprzedawca udostępni Konsumentowi wzór odstąpienia od Umowy, o
                którym mowa w pkt 10 c) powyżej, w załączniku do niniejszego
                dokumentu w sposób, który umożliwia jego pobranie, odtwarzanie,
                utrwalenie i wydrukowanie w zwykłym toku czynności.
              </span>
            </li>
            <li
              style={{
                marginLeft: "14pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span
                style={{
                  width: "5.37pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;&nbsp;{" "}
              </span>
              <span style={{ fontWeight: "normal" }}>
                Szczegółowe zasady świadczeń, są określone w komplecie
                dokumentów doręczonym Konsumentowi w związku z zawarciem Umowy,
                tj. w potwierdzeniu zawarcia Umowy, Regulaminie, warunkach
                promocji oraz „obowiązkach informacyjnych”.{" "}
              </span>
            </li>
            <li
              style={{
                marginLeft: "14pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span
                style={{
                  width: "5.37pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;&nbsp;{" "}
              </span>
              <span style={{ fontWeight: "normal" }}>
                Zasady rozpatrywania reklamacji są szczegółowo określone w
                Regulaminie, dostępnym na stronie internetowej Nasesje.com wraz
                z jego subdomenami.
              </span>
            </li>
            <li
              style={{
                marginLeft: "14pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span
                style={{
                  width: "5.37pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;&nbsp;{" "}
              </span>
              <span style={{ fontWeight: "normal" }}>
                Korzystanie z Serwisu
              </span>
              <span style={{ fontWeight: "normal" }}>&nbsp;</span>
              <span style={{ fontWeight: "normal" }}>
                ELERNA może być związane z zainstalowaniem plików „Cookies”
                zgodnie z Polityką Prywatności Serwisu
              </span>
              <span style={{ fontWeight: "normal" }}>&nbsp;</span>
              <span style={{ fontWeight: "normal" }}>
                ELERNA (nasesje.com. wraz z jego subdomenami).{" "}
              </span>
            </li>
            <li
              style={{
                marginLeft: "14pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span
                style={{
                  width: "5.37pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;&nbsp;{" "}
              </span>
              <span style={{ fontWeight: "normal" }}>
                Spór cywilnoprawny wynikający z Umowy zawartej przez Konsumenta
                może zostać zakończony w drodze postępowania w sprawie
                pozasądowego rozwiązywania sporów konsumenckich. Udział
                Sprzedawcy w postępowaniu w sprawie pozasądowego rozwiązywania
                sporów konsumenckich jest dobrowolny. W przypadku, gdy w
                następstwie reklamacji złożonej przez Konsumenta spór nie został
                rozwiązany, Sprzedawca każdorazowo podejmuje decyzję o zgodzie
                na udział w postępowaniu w sprawie pozasądowego rozwiązywania
                sporów konsumenckich lub o odmowie udziału w takim postępowaniu
                i przekazuje ją Konsumentowi. Jeśli Sprzedawca nie złożył
                żadnego oświadczenia, uznaje się, że nie wyraża zgody na udział
                w postępowaniu w sprawie pozasądowego rozwiązywania sporów
                konsumenckich.
              </span>
            </li>
            <li
              style={{
                marginLeft: "14pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span
                style={{
                  width: "5.37pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;&nbsp;{" "}
              </span>
              <span style={{ fontWeight: "normal" }}>
                Możliwość zawarcia przez Konsumenta Umowy oraz korzystania przez
                niego z Produktów może być uzależniona od posługiwania się w
                tych celach komputerem z zainstalowaną przeglądarką internetową,
                na systemach operacyjnych Windows, Mac OS X, z włączoną obsługą
                plików „Cookies” oraz JavaScript albo innym urządzeniem, jeżeli
                Sprzedawca dopuści taką możliwość.
              </span>
            </li>
            <li
              style={{
                marginLeft: "14pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span
                style={{
                  width: "5.37pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;&nbsp;{" "}
              </span>
              <span style={{ fontWeight: "normal" }}>
                Korzystanie przez Konsumenta z Produktów objętych zawartą przez
                niego Umową, za pośrednictwem Urządzeń przenośnych, w których
                Konsument nie wprowadził modyfikacji niezgodnych z zaleceniami
                producenta urządzenia, określonymi w jego szczegółowych
                warunkach użytkowania oraz warunkach gwarancji, jest możliwe
                przy spełnieniu przez ww. Urządzenia następujących wymagań
                technicznych:
              </span>
              <ol
                className="awlist5"
                style={{
                  marginRight: "0pt",
                  marginLeft: "0pt",
                  paddingLeft: "0pt",
                }}
              >
                <li
                  className="ListParagraph"
                  style={{
                    marginLeft: "18pt",
                    textIndent: "-5pt",
                    fontSize: "16px",
                  }}
                >
                  <span
                    style={{
                      width: "4.72pt",
                      font: '7pt "Times New Roman"',
                      display: "inline-block",
                    }}
                  >
                    &nbsp;&nbsp;{" "}
                  </span>
                  <span style={{ fontWeight: "normal" }}>
                    zainstalowana i uruchomiona Aplikacja Mobilna dla Urządzeń
                    przenośnych (rekomendowane);
                  </span>
                </li>
                <li
                  className="ListParagraph"
                  style={{
                    marginLeft: "18pt",
                    textIndent: "-5pt",
                    fontSize: "16px",
                  }}
                >
                  <span
                    style={{
                      width: "4.15pt",
                      font: '7pt "Times New Roman"',
                      display: "inline-block",
                    }}
                  >
                    &nbsp;&nbsp;{" "}
                  </span>
                  <span style={{ fontWeight: "normal" }}>
                    wyposażenie w jeden z następujących systemów operacyjnych:{" "}
                  </span>
                </li>
              </ol>
            </li>
          </ol>
          <ol
            className="awlist1"
            style={{ margin: "0pt", paddingLeft: "35pt" }}
          >
            <li
              style={{
                marginLeft: "14pt",
                textIndent: "-5pt",
                lineHeight: "150%",
                fontSize: "16px",
              }}
            >
              <span
                style={{
                  width: "4.2pt",
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;{" "}
              </span>
              <span style={{ fontWeight: "normal" }}>
                Android, wersja oficjalnie opublikowana przez producenta systemu
                (informacje dostępne pod adresem internetowym:
                https://www.android.com/)
              </span>
            </li>

            <li
              style={{
                marginLeft: "14pt",
                textIndent: "-5pt",
                lineHeight: "150%",
                fontSize: "16px",
              }}
            >
              <span
                style={{
                  width: "4.2pt",
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;{" "}
              </span>
              <span style={{ fontWeight: "normal" }}>
                iOS, oficjalnie opublikowana przez producenta systemu
                (informacje dostępne pod adresem internetowym:
                https://www.apple.com/)
              </span>
            </li>
          </ol>
          <ol
            start={19}
            className="awlist6"
            style={{ margin: "0pt", paddingLeft: "0pt" }}
          >
            <li
              className="ListParagraph"
              style={{
                marginLeft: "14pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span
                style={{
                  width: "5.37pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;&nbsp;{" "}
              </span>
              <span style={{ fontWeight: "normal" }}>
                Dla korzystania z Produktów objętych Umową może być wymagane
                posiadanie dostępu do Internetu. W takim przypadku do sprawnego
                korzystania z Produktów rekomendowany jest dostęp do dowolnego
                typu połączenia z Internetem, o przepustowości co najmniej 8
                megabitów/sekundę oraz co najmniej 25 megabitów na sekundę w
                przypadku Produktów świadczonych w wysokiej jakości.
              </span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "14pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span
                style={{
                  width: "5.37pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;&nbsp;{" "}
              </span>
              <span style={{ fontWeight: "normal" }}>
                Ze względu na niezależne od Sprzedawcy właściwości środowiska
                informatycznego, którym posługuje się Konsument, korzystanie z
                Serwisu, na niektórych urządzeniach może być ograniczone lub
                niemożliwe, a jakość odtwarzanych materiałów obniżona – w
                przypadku aktywności, w ramach ww. środowiska, oprogramowania
                pochodzącego od podmiotów trzecich lub od Konsumenta (w
                szczególności różnego rodzaju firewalli, programów
                antywirusowych, blokad, w tym uniemożliwiających wyświetlanie
                reklam), jego kombinacji lub konfiguracji, mających wpływ na
                udostępnioną funkcjonalność lub prawidłowe działanie techniczne
                Serwisu. W przypadku zaistnienia takiej sytuacji, może w
                szczególności okazać się konieczne odinstalowanie określonego
                oprogramowania ze środowiska informatycznego, którym posługuje
                się Konsument lub dodanie w jego ramach określonego wyjątku.
              </span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "14pt",
                textIndent: "-5pt",
                marginBottom: "14pt",

                textAlign: "justify",
                lineHeight: "150%",

                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span
                style={{
                  width: "5.37pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;&nbsp;&nbsp;{" "}
              </span>
              <span style={{ fontWeight: "normal" }}>
                Funkcjonowanie Serwisu
              </span>
              <span style={{ fontWeight: "normal" }}>&nbsp;</span>
              <span style={{ fontWeight: "normal" }}>&nbsp;</span>
              <span style={{ fontWeight: "normal" }}>
                i niektórych Produktów wymaga cyklicznego monitoringu
                technicznego, poprawek i konserwacji, co może spowodować czasowe
                i krótkotrwałe przerwy w działaniu Serwisu lub niektórych
                Produktów
              </span>
              <span
                style={{
                  lineHeight: "150%",
                  fontSize: "16px",
                  fontWeight: "normal",
                }}
              >
                .
              </span>
            </li>
          </ol>
          <p style={{ fontSize: "16px" }}>
            <br style={{ pageBreakBefore: "always", clear: "both" }} />
          </p>
          <div className="spacer" />
          <p
            style={{
              marginTop: "14pt",
              marginBottom: "14pt",
              textAlign: "left",
              fontSize: "16px",
            }}
          >
            <strong>
              <span>Załącznik nr 1</span>
            </strong>
          </p>
          <p
            style={{
              marginTop: "14pt",
              marginBottom: "14pt",
              textAlign: "center",
              fontSize: "16px",
            }}
          >
            <strong>
              <span>
                WZÓR FORMULARZA ODSTĄPIENIA OD UMOWY ZAWARTEJ NA ODLEGŁOŚĆ
              </span>
            </strong>
          </p>
          <p
            style={{
              marginTop: "14pt",
              //   marginLeft: "248.1pt",
              marginBottom: "6pt",
              textAlign: "left",
              lineHeight: "115%",
              fontSize: "16px",
            }}
          >
            <strong>
              <span>Adresat: </span>
            </strong>
          </p>
          <p
            style={{
              //   marginLeft: "248.1pt",
              textAlign: "left",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <span>ELERNA GROUP Spółka z ograniczoną odpowiedzialnością, </span>
          </p>
          <p
            style={{
              //   marginLeft: "248.1pt",
              textAlign: "left",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <span>ul. Chmieleniec nr 6 lok. 9</span>
          </p>
          <p
            style={{
              //   marginLeft: "248.1pt",
              textAlign: "left",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <span style={{}}>30 – 348 Kraków,</span>
            <span>&nbsp; </span>
          </p>
          <p
            style={{
              textAlign: "justify",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <span>&nbsp;</span>
          </p>
          <p
            style={{
              marginBottom: "6pt",
              textAlign: "justify",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <span>
              Niniejszym informuję o moim odstąpieniu od umowy o świadczenie
              następującej usługi/Produktu: ...
            </span>
          </p>
          <p
            style={{
              marginBottom: "6pt",
              textAlign: "justify",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <span>Data zawarcia umowy (zakupienia usługi/Produktu): ...</span>
          </p>
          <p
            style={{
              marginBottom: "6pt",
              textAlign: "justify",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <span>Numer Klienta/Zamówienia: ...</span>
          </p>
          <p
            style={{
              marginBottom: "6pt",
              textAlign: "justify",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <span>
              Adres e-mail stanowiący login Konsumenta w Serwisie: ...
            </span>
          </p>
          <p
            style={{
              marginBottom: "6pt",
              textAlign: "justify",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <span>Imię i nazwisko Konsumenta: ...</span>
          </p>
          <p
            style={{
              marginBottom: "6pt",
              textAlign: "justify",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <span>Adres Konsumenta: ...</span>
          </p>
          <p style={{ textAlign: "justify", fontSize: "16px" }}>
            <span>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: "14pt",
              marginBottom: "14pt",
              textIndent: "318.95pt",
              textAlign: "right",
              fontSize: "16px",
            }}
          >
            <span>__________________</span>
          </p>
          <p
            style={{
              marginTop: "14pt",
              marginBottom: "14pt",
              textAlign: "right",
              fontSize: "16px",
            }}
          >
            <span>Podpis Konsumenta </span>
          </p>
          <p
            style={{
              marginTop: "14pt",
              marginBottom: "14pt",
              textAlign: "right",
              fontSize: "16px",
            }}
          >
            <em>
              <span>
                (tylko, jeżeli formularz jest przesłany w wersji papierowej)
              </span>
            </em>
          </p>
          <p
            style={{
              marginTop: "14pt",
              marginBottom: "14pt",
              textAlign: "justify",
              fontSize: "16px",
            }}
          >
            <span>Data: __________________ roku</span>
          </p>
          <div className="spacer"/>
          <p>&nbsp;</p>
          <div style={{ clear: "both" }}>
            <br style={{ clear: "both" }} />
            <p className="Footer" style={{ marginRight: "18pt" }}>
              &nbsp;
            </p>
          </div>
        </div>
      </div>

      <LandingFooter />
    </div>
  );
};
