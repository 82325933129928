import { Block } from "../../../../../../../../shared/block";
import { svgServerUrl } from "../../../../../../dummyUtilities/svgServer";
import { combineBlocks } from "../../../../../shared/utilities/combiningBlocksOfTextIntoJsx/combiningBlocksOfTextIntoJsx";

interface QuizQuestionProps {
    text: Block[];
    isMainTest?: boolean;
}

export const QuizQuestion = (props: QuizQuestionProps) => {
    const quizClassPrefix = props.isMainTest ? "mainTest data-panel__quiz__question" : "data-panel__quiz__question";

    return (
        <div className="data-panel__quiz__question">
            {combineBlocks(props.text, quizClassPrefix)}
        </div>
    );
}