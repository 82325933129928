interface ContentPanelWrapperProps {
    children: React.ReactNode;
}

export const ContentPanelWrapper = (props: ContentPanelWrapperProps) => {
    return (
        <div id="content-panel">
            {props.children}
        </div>
    );
}