import { useContext } from "react";
import { combinePath } from "../../shared/utilities/combiningPath/combinePath";
import { CourseContext } from "../CourseContextProvider";

export const CoursePath = () => {
    const ctx = useContext(CourseContext);

    return (
        <div id="data-panel__nav">
            {combinePath(ctx.path)}
        </div>
    )
}