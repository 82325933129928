
interface OfferProps {
    text: string
}

export const OfferPoint = (props: OfferProps) => {
    return (
        <div className="open-test-panel__offer__preview__text__description__point open-test-panel__offer__preview__text__description__point--first">
            <div className="open-test-panel__offer__preview__text__description__point__dot"/>
            <span>
                {props.text}
            </span>
        </div>
    );
}