import { useNavigate } from "react-router-dom";

interface NavigationLinkProps {
    href: string;
    text: string;
}

export const NavigationLink = (props: NavigationLinkProps) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(props.href);
    }

    return(
    <div 
        id="navigation__user-panel__my-courses"
        onClick={handleClick}
        >
        {props.text}
    </div>
    )
}