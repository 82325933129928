import "../../../../../shared/components/fallbacks/skeleton/skeleton.scss"
import { ChaptersFallback } from "./ChaptersFallback";
import { CourseFinishTestFallback } from "./CourseFinishTestFallback";

export const CourseMainNavigationFallback = () => {
    //TODO translate
    return (
        <div id="navigation-panel">
            <ChaptersFallback title="Działy kursu"/>
            <CourseFinishTestFallback/>
        </div>
    );
}
