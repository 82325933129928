import { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { get, post } from "../../../utilities/rest/apiClient";
import { AuthContext } from "../authContext/AuthContextProvider";

import { Logo } from "../../navbar/logo/logo";

import "./login.scss";

import { useTranslation } from "react-i18next";
import { checkNumberOfCharacters, checkCapitalization, checkNonCapitlization, checkDigits } from "../shared/sharedFunctions"

export const Login = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const authContext = useContext(AuthContext);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [validEmail, setValidEmail] = useState(false);
    const [validPassword, setValidPassword] = useState(false);

    const [wrongPassword, setWrongPassword] = useState(false);
      
    const handleUsernameChange = (e: ChangeEvent<HTMLInputElement>) => {
      const new_email = e.target.value;
      setEmail(new_email);

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setValidEmail(emailRegex.test(new_email) && (new_email.length !== 0));
    };
  
    const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
      const new_password = e.target.value;
      setPassword(new_password);
      if(
        checkCapitalization(new_password, 1) && 
        checkNumberOfCharacters(new_password, 8) && 
        checkNonCapitlization(new_password, 1) && 
        checkDigits(new_password, 1)
      ) 
        setValidPassword(true);
      else
        setValidPassword(false);
    };


    const getEmailFieldState = () => {
        if(validEmail)  return "input--valid"
        else if( (!validEmail) && (email.length !== 0) ) return "input--invalid"
        else return "input--default";
    }

    const getPasswordFieldState = () => {
        if(validPassword) return "input--valid"
        else if( (!validPassword) && (password.length !== 0 ) ) return "input--invalid"
        else return "input--default";
    }
  
    const handleLogin = (e: FormEvent) => {
        e.preventDefault();

        if(validPassword && validEmail) {
            setWrongPassword(false);
            
            const data = { email: email, password: password};

            post<any, any>(`users/login`, data)
            .then(() => {
                authContext.setLoggedIn(true);
                navigate('/userPanel');
            })
            .catch((error) => {
                authContext.setLoggedIn(false);

                setWrongPassword(true);
                setEmail("");
                setPassword("");
                setValidPassword(false);
                setValidEmail(false);
            });
        }
    };

    const handleSubmitButtonStyle = () => {
        let buttonClass = "button";
        buttonClass += (validPassword && validEmail) ? "--active" : "--inactive";
        buttonClass += " button";
        buttonClass += (wrongPassword) ? "--wide" : "";

        return buttonClass;
    }
    
    return (
        <div className="login-panel">
            <div className="login-panel__form">
                <div className="login-panel__form__logo-wrapper" onClick={() => navigate('/')}>
                    <Logo/>
                </div>
                <div className="login-panel__form__headline">
                    <span>{t("login.loginToYourAccount")}</span>
                </div>
                <div className="login-panel__form__sign-up">
                    <span>{t("login.dontHaveAnAccount")}? </span>
                    <a href="/signup">{t("login.signUp")}</a>
                </div>

                <form onSubmit={handleLogin}>
                    <div className="login-panel__form__email-input-section">
                        <label htmlFor="username">{t("login.emailAddress")}</label>
                        <input
                        className={getEmailFieldState()}
                        type="text"
                        id="username"
                        value={email}
                        onChange={handleUsernameChange}
                        />
                        
                        { validEmail ? <span className="material-symbols-outlined material-symbols-outlined--valid"> done </span> : ""}    
                        { ((!validEmail) && (email.length !== 0)) ? <span className="material-symbols-outlined material-symbols-outlined--invalid">warning</span>: ""}
                    </div>
                    <div className="login-panel__form__password-input-section">
                        <label htmlFor="password">{t("login.password")}</label>
                        <input
                        className={getPasswordFieldState()}
                        type="password"
                        id="password"
                        value={password}
                        onChange={handlePasswordChange}
                        />

                        { validPassword ? <span className="material-symbols-outlined material-symbols-outlined--valid"> done </span> : ""}    
                        { ((!validPassword) && (password.length !== 0)) ? <span className="material-symbols-outlined material-symbols-outlined--invalid">warning</span>: ""}
                    </div>
                    <div className="login-panel__form__buttons">
                        <button type="submit" className={ handleSubmitButtonStyle()}>{t("login.login")}</button>
                        { wrongPassword ? 
                            <button 
                                type="submit" 
                                className="button--active button--reset-password button--wide"
                                onClick={() => navigate("/sendResetEmail")}
                            >{t("login.resetPassword")}</button> : ""
                        }
                    </div>
                </form>

                <div className="login-panel__form__forgot-password">
                    { 
                        wrongPassword ? (<>
                            <div className="login-panel__form__forgot-password__invalid-credentials">
                                <span>{t("login.invalidEmailOrPassword")}</span>
                            </div>

                            <button type="submit" className="login-panel__form__forgot-password__mobile-reset-button">{t("login.login")}</button>

                        </>) : <a href="/sendResetEmail">{t("login.forgotPassword")}? </a>
                    }            
                </div>

            </div>

            <div className="login-panel__svg-container">
                <img src="https://na-sesje-public.s3.eu-central-1.amazonaws.com/signupGraphic.png" alt="" />
            </div>
        </div>
    );
}