import { ColElement } from "./ColElement";

interface ColProps {
    handleClick: Function,
    title: string,
    id: number,
    activeId: number,
    elements: {icon?: JSX.Element, text: string, path: string, param?: string}[]
}

export const Col = (props: ColProps) => {
    const getTitleClass = () => {
        const base = "footer__container__row__col";
        let fullClass = `${base} ${base}`;

        fullClass += (props.id !== props.activeId) ? "--closed" : "--active";
        
        if(props.id === 0) fullClass += ` ${base}--first`

        return fullClass;
    }

    return (
        <>
            <div className={getTitleClass()}>
                <div className="footer__container__row__col__title" onClick={() => props.handleClick(props.id)}>
                    <h4>
                        {props.title}
                    </h4>
                    <span className="material-symbols-outlined">expand_less</span>
                </div>

                <ul>
                    {   
                        props.elements.map((el, index) => 
                            <ColElement 
                                key={index} 
                                text={el.text} 
                                icon={el.icon} 
                                param={el.param}
                                path={el.path}
                            />
                        )}
                </ul>
            </div>
        </>
    )
}