import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";


import "./error.scss";

import { ErrorGraphic } from "./ErrorGraphic";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../auth/authContext/AuthContextProvider";

export const NotFoundError = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const authContext = useContext(AuthContext);
    
    useEffect(() => {
        const footer = document.getElementById('bottom-separator');
        const nav = document.getElementById('navigation');
        
        if (footer && nav) {
            footer.style.display = 'none';
            nav.style.marginBottom =  '0px';
        }

        return () => {
            if (footer && nav) {
                footer.style.display = 'block';
                nav.style.marginBottom =  '20px';
            }
        };
    }, []);

    const handleBackToPageButton = () => {

        const navPath = (authContext !== undefined) ? 
            (authContext.loggedIn ?  "/userPanel" :  "/") : "/";
        
        navigate(navPath);
    }
    
    return(
        <>
            <div className="error">
                <div className="error__text-section">
                    <div className="error__text-section__container">
                        <div className="error__text-section__container__headline error__text-section__container__headline--error-code">
                            <span>#404</span>
                        </div>
                        <div className="error__text-section__container__middle-text">
                            <span>{t("error.pageYouAreLookingForDoesntExist")}</span>
                        </div>
                        <div className="error__text-section__container__spacer"/>
                        <div className="error__text-section__container__end-text">
                            <span>{t("error.pageYouAreLookingForDoesntExistFull")}</span>
                        </div>
                        <div 
                            className="error__text-section__container__button error__text-section__container__button--desktop"
                            onClick={handleBackToPageButton}
                        >
                            
                            <span>{t("error.backToThePage")}</span>
                        </div>
                    </div>

                </div>
                <div className="error__graphic error__graphic">
                    <ErrorGraphic/>
                </div>

                <div 
                    className="error__text-section__container__button error__text-section__container__button--mobile module-border-wrap"
                    onClick={() => navigate('/userPanel')}
                >
                    <span>{t("error.backToThePage")}</span>
                </div>
                <div className="error__mobile-spacer"/>
            </div>
        </>
    );
}