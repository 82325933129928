import { useTranslation } from "react-i18next";
import { TitleWithBackArrowButton } from "../../../shared/components/titleWithBackButton/TitleWithBackArrowButton";
import { VideoPlayerFallback } from "./VideoPlayerFallback";
import { VideoDescriptionFallback } from "./VideoDescriptionFallback";

interface VideoPageFallbackProps {
    isMainVideoPage?: boolean;
}

export const VideoPageFallback = (props: VideoPageFallbackProps) => {
    const { t } = useTranslation();

    return (
        <>
            <TitleWithBackArrowButton title={t("kurs.nawigacja.backToCourseMenu")} navigateTo={undefined}/>
            <VideoPlayerFallback/>
            <VideoDescriptionFallback/>
        </>
    );
}
