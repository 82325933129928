import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import { Col } from "./col/col";

import { Envelope } from "./icons/envelope";
import { Phone } from "./icons/phone";
import { Messenger } from "./icons/messenger";
import { FacebookContact } from "./icons/facebookContact";

import { Youtube } from "./icons/youtube";
import { Instagram } from "./icons/instagram";
import { Facebook } from "./icons/facebook";

import "./footer.scss"
import { TikTokService } from "./icons/tikTokService";

interface FooterProps { }

export const Footer = (props: FooterProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const [activeIndex, setActiveIndex] = useState(0);
    const [displaySeparator, setDisplaySeparator] = useState(true);


    useEffect(() => {
        const parts = location.pathname.split('/');
        const subsite = parts[parts.length - 1];
        if(subsite === 'error' || subsite === 'notFound') {
            setDisplaySeparator(false);
        } else if(!displaySeparator) setDisplaySeparator(true);

    }, [location]);

    const handleActivePart = (index: number) => {
        setActiveIndex(index);
    };

    const content = [
        {
            title: t("footer.bottomHeadlines.moreInformations"),
            elements: [
                {text: t("footer.bottomLinks.aboutUs"), path: "/aboutUs" },
                {text: t("footer.bottomLinks.termsOfService"), path: "/regulamin" },
                {text: t("footer.bottomLinks.privacyPolicy"), path: "/politykaPrywatnosci" },
                {text: t("footer.bottomLinks.informativeDuties"), path: "/obowiazekInformacyjny" }
            ]
        },
        {
            title: t("footer.bottomHeadlines.helpfullLinks"),
            elements: [
                {text: t("footer.bottomLinks.myAccount"), path: "/userPanel" },
                {text: t("footer.bottomLinks.courseShop"), path: "/shop" },
                {text: t("footer.bottomLinks.FAQSection"), path: "/FAQLanding" },
                {text: t("footer.bottomLinks.forum"), path: "#" }
            ]
        },
        {
            title: t("footer.bottomHeadlines.contact"),
            elements: [
                {text: t("supportEmail"), path: "", icon: <Envelope/>},
                {text: "643 237 755", path: "", icon: <Phone/> },
                {text: "Messenger", path: "https://m.me/61558293877112", icon: <Messenger/> },
                {text: "Facebook", path: "https://www.facebook.com/profile.php?id=61558293877112", icon: <FacebookContact/> }
            ]
        },
        {
            title: "Social media",
            elements: [
                {text: "Youtube", path: "https://youtube.com/@NaSesje?si=INgVSj4vD5JyW6Nh", icon: <Youtube/>},
                {text: "Facebook", path: "https://www.facebook.com/profile.php?id=61558293877112", icon: <Facebook/>, param: "fixed"},
                {text: "Instagram", path: "https://www.instagram.com/nasesje?igsh=OGRnd2MwY25wOGU4", icon: <Instagram/> },
                {text: "TikTok", path: "https://www.tiktok.com/@nasesje.com", icon: <TikTokService/>}
            ]
        },
    ];

    const sendEmail = () => {
        window.location.href = "mailto: support@naSesje.com?subject=Zapytanie";
    }

    const goToFAQ = () => {
        navigate("FAQ");
    }

    return (
        <>
            {
                displaySeparator ? <div id="bottom-separator"></div> : ""
            }

            <footer className="footer">

                <div className="footer__wrapper">
                    <div className="footer__wrapper__FAQ">

                        <div className="footer__wrapper__FAQ__description">
                            <div className="footer__wrapper__FAQ__description__title">
                                <h4>
                                    {t("footer.anyQuestionsAsk")}
                                </h4>
                            </div>
                            <div className="footer__wrapper__FAQ__description__text">
                                {t("footer.anyQuestionsAnswer")}
                            </div>
                        </div>

                        <div className="footer__wrapper__FAQ__buttons">
                            <div onClick={() => sendEmail()} className="footer__wrapper__FAQ__buttons__button footer__wrapper__FAQ__buttons__button--left">
                                {t("footer.sendEmail")}
                            </div>
                            <div className="footer__wrapper__FAQ__buttons__button footer__wrapper__FAQ__buttons__button--right" onClick={() => goToFAQ()}>
                                {t("footer.FAQSection")}
                            </div>
                        </div>

                    </div>
                </div>

                <div className="footer__spacer" />

                <div className="footer__container">
                    <div className="footer__container__row">
                        {
                            content.map(
                                (el, index) => <Col key={index} id={index} handleClick={handleActivePart} activeId={activeIndex} title={el.title} elements={el.elements}/>
                            )
                        }
                    </div>
                </div>

                <div className="footer__copyright">
                    <span>{t("platformName")} 2024 ©. {t("footer.copyrightDisclaimer.allRightsReserved")}.</span>
                </div>
            </footer>
        </>
        
    )
}