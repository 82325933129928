import { useState } from "react";

import { CarouselBackground } from "../graphics/CarouselBackground";
import { CarouselElement } from "./CarouselElement/CarouselElement";

import "./carousel.scss";
import { useTranslation } from "react-i18next";



interface NavPointProps {
    index: number,
    activeIndex: number
}

export const NavPoint = (props: NavPointProps) => {
    const { index, activeIndex } = props;

    const handlePointClass = () => {
        const base = "main-page__carousel-section__progress-bar__point";
        return `${base}${( index === activeIndex) ? ` ${base}--enrolled`: ""}`;
    }

    return (
        <div className={handlePointClass()}></div>
    )
}


export const Carousel = () => {
    const { t } = useTranslation();
    
    const PanelsCount = 5;

    const [activeIndex, setActiveIndex] = useState(2);
    
    const [positionStyle, setPositionStyle] = useState({ left: 'calc( var(--base-step) - 1 * var(--panel-width)  )', transition: 'left 0s ease'});
    const [deactivatedPrevIndex, setDeactivatedPrevIndex] = useState(false);

    const carousel = [
        t("landing.carousel.interactiveTests"),
        t("landing.carousel.exercisesSets"),
        t("landing.carousel.importantNotes"),
        t("landing.carousel.additionalVideos"),
        t("landing.carousel.courseDividedIntoChapter")
    ];

    // const handleMobileNextClick = () => {

    //     let newIndex = activeIndex + 1;
    //     if (newIndex === PanelsCount) newIndex = 0;
        
    //     handleMobileClick(false, newIndex);
    // }

    // const handleMobilePreviousClick = () => {

    //     let newIndex = activeIndex - 1;
    //     if (newIndex === -1) newIndex = (PanelsCount-1);

    //     handleMobileClick(true, newIndex);
    // }

    // const handleMobileClick = (scrollLeft: boolean, index: number) => {
    
    //     setDeactivatedPrevIndex(true);

    //     const sign = scrollLeft ? '0' : '2';
    //     const parameter = `calc( var(--base-step) - ${sign} * var(--panel-width)  )`;
        
    //     setPositionStyle(
    //         { left: parameter, transition: 'left 0.5s ease'}
    //     );

    //     setTimeout(() => {
    //         setDeactivatedPrevIndex(false);
    //         setPositionStyle({
    //             left: 'calc( var(--base-step) - 1 * var(--panel-width)  )',
    //             transition: 'left 0s ease'
    //         });
    //         setActiveIndex(index); 
    //     },  700);       
    // }

    const getIndex = (change: number) => {

        console.log('activeNumber:', activeIndex);

        if (change < 0) {
            return (activeIndex + change < 0) ?
                (PanelsCount ) - ( Math.abs(activeIndex + change) % (PanelsCount - 1) ) :
                activeIndex + change;
        } 
        else {
            return (activeIndex + change > (PanelsCount-1) ) ? 
                activeIndex + change - PanelsCount:
                activeIndex + change;
        }

    }

    return (
        <>
        <div className="main-page__carousel-intro">
            <div className="main-page__carousel-intro__text-main">
                <span className="main-page__carousel-intro__text-main__main">
                    {t("landing.forSession")}<b>.com</b>
                </span>
                <span className="main-page__carousel-intro__text-main__second">
                    to <b>{t("landing.muchMoreThan")}</b>&nbsp;{t("landing.onlyRecordings")}
                </span>
            </div>    
            <div className="main-page__carousel-intro__text-bottom">
                    <span>
                        {t("landing.meetingTheExpectations")}
                    </span>
            </div>
        </div>
        <div className="main-page__carousel-section">                
            <div className="main-page__carousel-section__text-section">
                <CarouselBackground/>
            </div>


            <div className="main-page__carousel-section__carousel">
                <div 
                    className="main-page__carousel-section__carousel__row"
                    style={positionStyle}
                >
                    { 
                        [
                            getIndex(-2),
                            getIndex(-1), 
                            activeIndex, 
                            getIndex(1),
                            getIndex(2),                      
                        ].map((el, index) => (
                            <CarouselElement 
                                key={index} 
                                index={el} 
                                activeIndex={activeIndex} 
                                text={carousel[el]}
                                setActiveIndex={setActiveIndex} 
                                setPositionStyle={setPositionStyle}
                                scrollLeft={index < Math.floor(PanelsCount/2)}
                                setDeactivatedPrevIndex={setDeactivatedPrevIndex}
                                deactivatedPrevIndex={deactivatedPrevIndex}
                            />
                        ))
                    
                    }     
                </div>
            </div>

            <div className="main-page__carousel-section__progress-bar">
                {
                    Array.from({ length: PanelsCount }, (_, index) => index).map(
                        (el) => <NavPoint key={el} index={el} activeIndex={activeIndex}/>
                    )
                }
            </div>

            {/* <div className="main-page__carousel-section__mobile-buttons">
                <div 
                    className="main-page__carousel-section__mobile-buttons__button main-page__carousel-section__mobile-buttons__button--left"
                    onClick={handleMobilePreviousClick}    
                >
                    <span className="material-symbols-outlined">
                        arrow_back_ios
                    </span>
                </div>
                <div  
                    className="main-page__carousel-section__mobile-buttons__button main-page__carousel-section__mobile-buttons__button--right"
                    onClick={handleMobileNextClick}    
                >
                    <span className="material-symbols-outlined">
                        arrow_forward_ios
                    </span>
                </div>
            </div> */}

        </div>
        </>
    )
}