import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { popLastElementFromUrl } from "../../../components/shared/utilities/url/url";
import { ClosedEye } from "../../../components/course/courseNavigation/EyeStamps/ClosedEye";
import { OpenedEye } from "../../../components/course/courseNavigation/EyeStamps/OpenedEye";

type ActiveMaterial = "quiz-button" | "assignments-button" | "notes-button" | "none";

interface LessonPreviewNavigationPreview {

    activeMaterial: ActiveMaterial;
    setActiveMaterial: Dispatch<SetStateAction<ActiveMaterial>>;

    assignmentsCompleted: number;
    assignmentsCount: number;

    quizCompleted: number;
    quizesCount: number;

    notesActivated: boolean;

    data: {
        path: string[];
        videoSrc: string;
        lessonDescription: string;
        lessons: ({
            name: string;
            duration: string;
            active?: boolean;
        })[];
    };
}

export const LessonPreviewNavigation = (props: LessonPreviewNavigationPreview) => {
    const { t } = useTranslation();
    const { courseId } = useParams();
    
    const navigate = useNavigate();
    const location = useLocation();
    
    const { data, activeMaterial } = props;

    const maxTitleLength = 29;

    const getCurrentText = (index: number, title: string, hideNumber: boolean) => {

        if(hideNumber) return t("kurs.nawigacja.backToTheCourse");
        

        if(title.length > maxTitleLength) {

            const divIndex = title.substring(0, maxTitleLength).lastIndexOf(" ");
            const firstPart = title.substring(0, divIndex);
            const secondPart = title.substring(divIndex, title.length); 
            
            return (
                <>
                    <div className="text">{ `${index}. ${firstPart}` }</div>
                    <div className="text text--artifically-positioned">{secondPart}</div>
                </>
            );
        }
        return <div className="text">{`${index}. ${title}`}</div>;
    }

    const handleClick = (buttonSignature: ActiveMaterial) => {

        if(buttonSignature !== props.activeMaterial) {

            props.setActiveMaterial(buttonSignature);

            let pathPrefix = buttonSignature.substring(0, buttonSignature.indexOf("-button"));

            if(window.location.pathname.includes("additions")) {
                navigate(`${popLastElementFromUrl()}/${pathPrefix}`);
            } else {
                navigate(`additions/${pathPrefix}`)
            }
        }
        
    }

    const handleNavigationChaptersClass = (active: boolean, nestedActive: boolean) => {
        const base = "chapter__data-space__data";
        let finalClass = `${base} ${base}--additional-responsive ${active ? `${base}--highlited ${base}--go-back-button` : "preview-data-space__data--inactive"}`;

        finalClass += ` ${base}${nestedActive ? "--clickable" : "--equal-mobile-padding"}`;
  
        
        return finalClass;
    }

    const handleBackToCoursePreview = () => {
        navigate(`/coursePreview/${courseId}`);
    }

    const timeStampLengthEqualizer = (duration: string) => {
        let timeStamp = duration;

        if(timeStamp.length !== 5) {
            let splited = timeStamp.split(":");
            if(splited[0].length == 1){
                splited[0] = "0" + splited[0];
            
            }
            if(splited[1].length == 1){
                splited[1] = "0" + splited[1];
            
            }

            timeStamp = splited.join(":");
            
        }

        return timeStamp
    }

    return (
        <div id="navigation-panel" className="navigation-panel__lesson-additions-scalable preview-navigation-panel">
            <div className="chapter chapter--main">
                <div className="chapter__name"> {data.path[1]} </div>
                <div className="chapter__data-space">
                    {
                        data.lessons.map((el, index) => {
                            const active = (el.active !== undefined) ? el.active : false;
                            const nestedActive = props.activeMaterial !== "none" as ActiveMaterial;

                            return (
                                <div 
                                    key={index} 
                                    className={handleNavigationChaptersClass(active, nestedActive)}
                                    onClick={() => { 
                                        if(active && nestedActive) {
                                            const indexOfSubstring = location.pathname.indexOf('/additions/');
                                            const newPath = location.pathname.substring(0, indexOfSubstring);
                                            
                                            props.setActiveMaterial("none");
                                            navigate(newPath); 
                                        }
                                    }}
                                >
                                    <div className={`icon icon--additional-responsive ${(active && nestedActive) ? "icon--centered-arrow" : ""}`}>
                                        <span className="material-symbols-outlined">
                                            {  active ? ( nestedActive ? "arrow_back_ios" : "pause_circle") : "lock" }
                                        </span>
                                    </div>
                                    
                                    { getCurrentText(index+1, el.name, (active && nestedActive)) }
                                    
                                    {
                                        (active && nestedActive) ? "" : (
                                            <div className="stamp">
                                                {timeStampLengthEqualizer(el.duration)}
                                            </div>
                                        )
                                    }
                                </div>   
                            )
                        })
                    }

                </div>
            </div>
            
            <div className="chapter">
                <div className="chapter__name">{t("kurs.nawigacja.materialyDoLekcji")}</div>
                <div className="chapter__data-space">
                
                    <div className={"chapter__data-space__data chapter__data-space__data--iconless " + (activeMaterial === ("quiz-button" as ActiveMaterial) ? "chapter__data-space__data--highlited" : "")}
                        onClick={() => handleClick("quiz-button")}
                        data-testid={"quiz-button"}
                    >
                        <div className="text">{t("kurs.nawigacja.rozwiazTest")}</div>
                        <div className="stamp">{`${props.quizCompleted}/${props.quizesCount}`}</div>
                    </div>

                    <div className={"chapter__data-space__data chapter__data-space__data--iconless " + (activeMaterial === ("assignments-button" as ActiveMaterial) ? "chapter__data-space__data--highlited" : "")}
                        onClick={() => handleClick("assignments-button")}
                        data-testid={"assignments-button"}
                    >
                        <div className="text">{t("kurs.nawigacja.rozwiazZadania")}</div>
                        <div className="stamp">{`${props.assignmentsCompleted}/${props.assignmentsCount}`}</div>
                    </div>

                    <div className={"chapter__data-space__data chapter__data-space__data--stamp-icon " + (activeMaterial === ("notes-button" as ActiveMaterial) ? "chapter__data-space__data--highlited" : "")}
                        onClick={ () => handleClick("notes-button")}
                        data-testid={"notes-button"}
                        >
                        <div className="text">{t("kurs.nawigacja.przegladnijNotatki")}</div>
                        <div className={`stamp stamp--${(props.activeMaterial === "notes-button") ? 'active' : 'inactive'}`}>
                            {
                                (!props.notesActivated) ? <ClosedEye/> : <OpenedEye/>
                            }
                        </div>
                    </div>

                </div>
            </div>

            <div 
                className="not-selectable chapter preview-navigation-panel__chapter__back-to-course-preview"
                onClick={handleBackToCoursePreview}
            >
                <span className="material-symbols-outlined">
                    local_mall
                </span>
                <span>{t("kurs.preview.previewPage.backToCoursePreview")}</span>
            </div>

        </div>
    )
}