import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "./assignment.scss"

import { get, patch } from "../../../../utilities/rest/apiClient";
import { CourseContext } from "../../CourseContextProvider";
import { combineBlocks } from "../../../shared/utilities/combiningBlocksOfTextIntoJsx/combiningBlocksOfTextIntoJsx";
import { AssignmentDto, AssignmentsDto } from "../../../../../../shared/assignmentDto";
import { AuthContext } from "../../../auth/authContext/AuthContextProvider";

import "../../../shared/components/fallbacks/skeleton/skeleton.scss"
import { VideoOfferSolutionWrapper } from "./videoOfferSolutionWrapper/VideoOfferSolutionWrapper";
import { useParams } from "react-router-dom";
import { GlobalDataContext } from "../../../global/globalDataContext/GlobalDataContextProvider";
import { AssignmentFallback } from "./AssignmentFallback";
import { MainContext } from "../../main/MainContextProvider";

export const PutPlaceholder = () => {
    return (
        <div>
        <div>
            <span>Metody numeryczne to techniki matematyczne służące do znajdowania przybliżonych rozwiązań trudnych problemów matematycznych. Poznaj różne metody numeryczne i zastosuj je w praktyce.</span>
        </div>
        <br/>
        <div>
            <span>Metody numeryczne to techniki matematyczne służące do znajdowania przybliżonych rozwiązań trudnych problemów matematycznych. Poznaj różne metody numeryczne i zastosuj je w praktyce. Metody numeryczne to techniki matematyczne służące do znajdowania przybliżonych rozwiązań trudnych problemów matematycznych. Poznaj różne metody numeryczne i zastosuj je w praktyce.</span>
        </div>
        </div>
    );
}

export const Assignment = () => {
    const [showSolution, setShowSolution] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [assignmentsDto, setAssignmentDto] = useState<AssignmentDto[]>({} as AssignmentDto[]);
    const [explanationsAvailable, setExplanationsAvailable] = useState(false);
    const [numberOfQuestions, setNumberOfQuestions] = useState(0);
    const [possesion, setPossesion] = useState(false);
    const { t, i18n } = useTranslation();
    const { courseId } = useParams();
    const ctx = useContext(CourseContext);
    const mainCtx = useContext(MainContext);
    const authContext = useContext(AuthContext);
    const globalCtx = useContext(GlobalDataContext);

    const [videoSolutionOpen, setVideoSolutionOpen] = useState(false);

    const setCurrentData = (assignmentsDto: AssignmentDto[]) => {
        setAssignmentDto(assignmentsDto);
        setNumberOfQuestions(assignmentsDto.length);
        setShowSolution(false);
    }

    useEffect(() => {
        ctx.setActiveAddition("assignment");
        setIsLoading(true);
        (async ()=> {
            try {
              const lessonIndex = globalCtx.isMainView ? mainCtx.active.lessonIndex : ctx.activeChapter;
              const data = await get<AssignmentsDto>(`reactRoutes/getAssignments/${courseId}/${ctx.getLessonId()}/${lessonIndex}/${i18n.language}`);
              

              setAssignmentDto(data.assignments);
              setCurrentData(data.assignments);
              setPossesion(data.openAssignmentPossesion);
              setExplanationsAvailable(data.explanationsAvailable);
              ctx.setCurrentAssignment(data.completedOpenTasksIndex);
              setIsLoading(false);
            } catch(error) {
              authContext.handleAuthErrors(error);
              //TODO other errors
              console.log(error)
            }
        })()
    }, [mainCtx.active]);

    const handleClickShowSolution = () => {
        setShowSolution(true);
    }

    const getActiveAssignment = () => {
        return ctx.currentAssignment;
    }

    const updateCurrentAssignmentInDatabase = (questionNumber: number) => {
        if(questionNumber !== getActiveAssignment()) {
            patch<any, any>(`userChapters/incrementCompletedOpenTestCount/${ctx.getLessonId()}/${questionNumber}`, {})
            .then(() => {
                ctx.setCurrentAssignment(questionNumber);
            })
            .catch(error => {}); //TODO handle error
        }
    }
    
    const handleClickNext = () => {
        const currentAssignment = getActiveAssignment();

        const number = currentAssignment < (numberOfQuestions - 1) ? currentAssignment + 1 : currentAssignment;

        updateCurrentAssignmentInDatabase(number);

        setShowSolution(false);        
    }

    const handleClickPrevious = () => {
        const currentAssignment = getActiveAssignment();

        const number = currentAssignment > 0 ? currentAssignment - 1 : currentAssignment

        updateCurrentAssignmentInDatabase(number);

        setShowSolution(false);
    }

    const handleOpeningVideoSolution = () => {
        let html = document.querySelector('html');

        if(!videoSolutionOpen){    
            if(html !== null) html.classList.add('html-not-scrollable');
            document.body.classList.add('body-not-scrollable');
        } else {
            document.body.classList.remove('body-not-scrollable');
            if(html !== null) html.classList.remove('html-not-scrollable');
        }
        setVideoSolutionOpen(!videoSolutionOpen);
    }

    
    type PrevNext = "prev" | "next";

    const handleButtonClass = (type: PrevNext) => {
        const base = "data-panel__open-test__nav-buttons__button";

        let finalClassName = `${base} ${base}--${type}`;
        let inactive = false;

        if(type === "prev") inactive = (getActiveAssignment() === 0);
        else if(type === "next") inactive = (getActiveAssignment() === (numberOfQuestions-1));


        finalClassName += inactive ? ` ${base}--inactive` : "";
        
        return finalClassName;
    }

    if(isLoading){
        return <AssignmentFallback prevButtonClass={handleButtonClass('prev')} nextButtonClass={handleButtonClass("next")}/>
    } else {
        return (
                <>  <VideoOfferSolutionWrapper 
                    openAssignmentPossesion={possesion} 
                    videoSolutionOpen={videoSolutionOpen} 
                    setVideoSolutionOpen={setVideoSolutionOpen}
                    assignmentNumber={getActiveAssignment()+1} 
                    assignmentsCount={numberOfQuestions}
                    videoLink={assignmentsDto[getActiveAssignment()]?.videoLink}
                    isFinalTest={undefined}/>


                    <div className="data-panel__open-test">
                        <div className="data-panel__open-test__question">
                            <div className="data-panel__open-test__question__question-number">
                                <h4 className="underlined">{t("kurs.zadaniaOtwarte.zadanie")}: {getActiveAssignment()+1} / {numberOfQuestions}</h4>
                            </div>
    
                            <div className="data-panel__open-test__question__question-data">
    
                                <div className="data-panel__open-test__question__question-data"> 
                                    {combineBlocks(assignmentsDto[getActiveAssignment()].text, "data-panel__notes__container__data").map(element => element)}
                                </div>
    
                            </div>
    
                        </div>
                        
                        <div className="data-panel__open-test__anwser">
                                <div className="data-panel__open-test__anwser__headline">
                                    <span>{t("kurs.zadaniaOtwarte.rozwiazanie")}:</span>
                                </div>
    
                            <div className="data-panel__open-test__anwser__data">
    
                                <div className={`data-panel__open-test__anwser__data__anwser ${showSolution && "data-panel__open-test__anwser__data__anwser--active"}`}>
                                    {
                                        (showSolution === false) ?
                                            <PutPlaceholder/> : 
                                            combineBlocks(assignmentsDto[getActiveAssignment()].solution, "data-panel__notes__container__data").map(element => element)
                                    }
                                </div>
                                
    
                                {((showSolution === false)) && 
                                    <div className="data-panel__open-test__anwser__data__blur">
                                        {explanationsAvailable && <div 
                                        data-testid='show-explanation'
                                        onClick={handleOpeningVideoSolution} className={`data-panel__open-test__anwser__data__blur__result-button data-panel__open-test__anwser__data__blur__result-button--filled ${videoSolutionOpen? "data-panel__open-test__anwser__data__blur__result-button--hidden" : ""}`}>
                                            <span>{t("kurs.zadaniaOtwarte.watchAssignmentVideo")}</span>
                                        </div>}
                                        <div 
                                        data-testid='show-solution'
                                        onClick={handleClickShowSolution} className="data-panel__open-test__anwser__data__blur__result-button data-panel__open-test__anwser__data__blur__result-button--border-only">
                                            <span>{t("kurs.zadaniaOtwarte.zobaczRozwiazanie")}</span>
                                        </div>
                                    </div>
                                }
                            </div>
    
                        </div>
    
                        <div className="data-panel__open-test__buttons-spacer data-panel__open-test__buttons-spacer--pre"></div>
    
                        <div className="data-panel__open-test__nav-buttons">
                            <div 
                                className={handleButtonClass('prev')}
                                onClick={ handleClickPrevious}
                                data-testid='previous-button'
                                >
                                    <span className="material-symbols-outlined">
                                        arrow_back_ios
                                    </span>     
                                    <span>
                                        {t("kurs.zadaniaOtwarte.poprzednie")}
                                    </span>
                            </div>
                            <div 
                                className={handleButtonClass('next')}
                                onClick={handleClickNext}
                                data-testid='next-button'
                                >
                                    <span>
                                        {t("kurs.zadaniaOtwarte.nastepne")}
                                    </span>
                                    <span className="material-symbols-outlined">
                                        arrow_forward_ios
                                    </span>
                            </div>
                        </div>
        
                    </div>
                </>
        );
    }
}