import { LandingFooter } from "../../landing/LandingFooter/LandingFooter"
import { LandingNavbar } from "../../landing/LandingNavbar/LandingNavbar"
import { Error } from './Error';

export const ErrorUnlogged = () => {

    return (
        <>
            <LandingNavbar/>
            <Error/>
            <LandingFooter/>
        </>
    )
}