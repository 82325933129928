import "./courseMain.scss";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { get } from "../../../../utilities/rest/apiClient";
import { useNavigate, useParams } from "react-router-dom";

import { CourseMainButtonSection } from "./courseMainButtonSection/CourseMainButtonSection";
import { MainContext } from "../MainContextProvider";
import { GlobalDataContext } from "../../../global/globalDataContext/GlobalDataContextProvider";
import { CourseMainDto } from "../../../../../../shared/courseMainDto";
import { AuthContext } from "../../../auth/authContext/AuthContextProvider";
import { CourseMainFallback } from "./courseMainFallback/CourseMainFallback";
import { LastVisitedDto } from "../../../../../../shared/lastVisitedDto";
import { getActiveElement } from "@testing-library/user-event/dist/utils";
import { combineBlocks } from "../../../shared/utilities/combiningBlocksOfTextIntoJsx/combiningBlocksOfTextIntoJsx";


interface menuDotElementProps {
    myIndex: number,
    activeIndex: number
}

const MenuDotElement = (props: menuDotElementProps) => {
    const { myIndex, activeIndex } = props;

    const handleClassName = () => {
        const base ="data-panel__course__materials__data__mobile-scroll-satus__circle";
        return `${base}${(myIndex === activeIndex) ? ` ${base}--active` : ""}`;
    }
    return (
        <div className={handleClassName()}></div>
    );
}

export const CourseMain = () => {
    const { t, i18n } = useTranslation();
    const mainCtx = useContext(MainContext);
    const globalCtx = useContext(GlobalDataContext);
    const [courseDto, setCourseDto] = useState<CourseMainDto>({} as CourseMainDto);
    const [isLoading, setIsLoading] = useState(true);
    const { courseId } = useParams();
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);
    const [lastVisitedChapter, setLastVisitedChapter] = useState("");

    const [mobileActiveButtonIndex, setMobileActiveButtonIndex] = useState(0);


    const sections = [
        { section: "quiz", mainText: t("kurs.glowna.utrwalZdobytaWiedze"), sideText: t("kurs.glowna.zbiorTestow") },
        { section: "assignment", mainText: t("kurs.glowna.przygotowujeszSieDoSesji"), sideText: t("kurs.glowna.zestawyZadan") },
        { section: "notes", mainText: t("kurs.glowna.przegladajLubPobierzZestawienieNotatek"), sideText: t("kurs.glowna.notatki.notatki") }
    ];

    useEffect(() => {
        setIsLoading(true);
        mainCtx.setActiveComponent('course');
        globalCtx.setIsMainTest(false);

        (async ()=> {
          try {
            const data = await get<CourseMainDto>(`reactRoutes/getCourseMain/${courseId}/${i18n.language}`);
            const lastVisited = await get<LastVisitedDto>(`reactRoutes/getLastVisited/${courseId}`);
            setLastVisitedChapter(lastVisited.lastVisitedChapter);
            setCourseDto(data);
            mainCtx.setChapterData(data.chapters);
            mainCtx.setFinishedFinalTest(data.finishedFinalTest);
            globalCtx.setTitle(data.title);
            mainCtx.setPath([data.title, t("kurs.glowna.selectedChapter"), t("kurs.glowna.selectedLesson")]);
            setIsLoading(false);
          } catch(error) {
            authContext.handleAuthErrors(error);
            //TODO obłsuga błędu
            console.log(error);
          }
        })()
    }, [])

    if(isLoading) {
        return <CourseMainFallback textLines={5}/>;
    } else {
        return (
                <div className="data-panel__course">
                    <div className="data-panel__course__headline">
                        <span>{t("kurs.glowna.nagranieWprowadzajace")}</span>
                    </div>
                    <div className="data-panel__course__video responsive-container">
                        <iframe 
                            title="landing-material"
                            src={courseDto.src} 
                            loading="lazy" 
                            allow="accelerometer; gyroscope; encrypted-media; picture-in-picture;" 
                            allowFullScreen={true}
                            scrolling="no"
                            frameBorder={0}
                        ></iframe>
                    </div>
                    <div className="data-panel__course__attributes">
                        <div className="data-panel__course__attributes__section">
                            <div className="data-panel__course__attributes__section__element">
                            <span className="data-panel__course__attributes__section__element__number">
                                {courseDto.numberOfLessons}
                            </span>
                            <span>{t("kurs.glowna.lekcji")}</span>
                            </div>
                            <div className="data-panel__course__attributes__section__element">
                            <span className="data-panel__course__attributes__section__element__number">
                                {courseDto.numberOfHours}
                            </span>
                            <span>{t("kurs.glowna.godzinNagran")}</span>
                            </div>
                        </div>
                        <div className="data-panel__course__attributes__section">
                            <div className="data-panel__course__attributes__section__element">
                            <span className="data-panel__course__attributes__section__element__number">
                                {courseDto.numberOfTests}
                            </span>
                            <span>{t("kurs.glowna.testow")}</span>
                            </div>
                            <div className="data-panel__course__attributes__section__element">
                            <span className="data-panel__course__attributes__section__element__number">
                                {courseDto.numberOfAssignments}
                            </span>
                            <span>{t("kurs.glowna.zadanOtwartych")}</span>
                            </div>
                        </div>
                    </div>

                    <div className="data-panel__course__description">
                        <div className="data-panel__course__description__title">
                            <span>{t("kurs.glowna.krotkiOpisKursu")}</span>
                        </div>
                        <div className="data-panel__course__description__text">
                            {combineBlocks(courseDto.fullDescription, null)}
                        </div>
                    </div>

                    {/* <div className="data-panel__course__materials">
                    
                        <div className="data-panel__course__materials__headline">
                            <span>{t("kurs.glowna.materialyDodatkoweDoKursu")}</span>
                        </div>
                    
                        <div className="data-panel__course__materials__data">

                            {
                                sections.map((el, index) => 
                                    <CourseMainButtonSection 
                                        key={index} 
                                        {...el} 
                                        index={index} 
                                        indexesCnt={sections.length} 
                                        lastVisitedChapter={lastVisitedChapter} 
                                        mobileActiveButtonIndex={mobileActiveButtonIndex} />
                                )
                            }

                            <div className="data-panel__course__materials__data__spacer data-panel__course__materials__data__spacer--desktop-hidden"/>
                            <div className="data-panel__course__materials__data__mobile-scroll-buttons">
                                <span 
                                    onClick={() => { if(mobileActiveButtonIndex > 0) setMobileActiveButtonIndex(mobileActiveButtonIndex-1)}} 
                                    className={`material-symbols-outlined left ${(mobileActiveButtonIndex === 0) ? "inactive" : "active"}`}>
                                    arrow_back_ios
                                </span>
                                <span 
                                    onClick={() => { if(mobileActiveButtonIndex < sections.length-1) setMobileActiveButtonIndex(mobileActiveButtonIndex+1)}} 
                                    className={`material-symbols-outlined right ${mobileActiveButtonIndex === (sections.length-1) ? "inactive" : "active"}`}>
                                    arrow_forward_ios
                                </span>
                            </div>
                            <div className="data-panel__course__materials__data__mobile-scroll-satus">
                                { [0, 1, 2].map( (el) => <MenuDotElement key={el} myIndex={el} activeIndex={mobileActiveButtonIndex}/>) }
                            </div>

                        </div>
                    </div> */}
            </div>
        );
    }
}