import { useTranslation } from "react-i18next";

export const FooterPartWideMenu = () => {
    const { t } = useTranslation();

    return (
        <div className="chapter chapter">
            <div className="chapter__name">{t("kurs.nawigacja.maszJakiesPytania")}</div>
            <div className="chapter__data-space">
                <div className="chapter__data-space__FAQ-section">
                    <div className="chapter__data-space__FAQ-section__text">
                        {t("kurs.nawigacja.chetnieNaNieOdpowiemy")}
                    </div>
                    <div className="chapter__data-space__FAQ-section__buttons">
                        <div className="chapter__data-space__FAQ-section__buttons__button chapter__data-space__FAQ-section__buttons__button--left">
                            {t("kurs.nawigacja.wyslijMaila")}
                        </div>
                        <div className="chapter__data-space__FAQ-section__buttons__button chapter__data-space__FAQ-section__buttons__button--right">
                            {t("kurs.nawigacja.sekcjaFaq")}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}