import React, { ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Unauthorized } from "../../../utilities/rest/errors";
import { post } from "../../../utilities/rest/apiClient";

interface isLoggedInDto {
    loggedIn: boolean;
}

interface AuthContextProviderProps {
    children: ReactNode;
}

interface AuthContextProps {
    handleAuthErrors: (error: unknown) => void,

    alertDisplayed: boolean,
    alertMessage: string,
    alertHeadline: string,

    setAlertDisplayed: React.Dispatch<React.SetStateAction<boolean>>,
    setAlertMessage: React.Dispatch<React.SetStateAction<string>>,
    setAlertHeadline: React.Dispatch<React.SetStateAction<string>>,

    loggedIn: boolean,
    setLoggedIn: React.Dispatch<React.SetStateAction<boolean>>

    isLoading: boolean
}

export const AuthContext = React.createContext<AuthContextProps>({} as AuthContextProps);

export const AuthContextProvider = (props: AuthContextProviderProps) => {
    const navigate = useNavigate();

    
    const [alertDisplayed, setAlertDisplayed] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertHeadline, setAlertHeadline] = useState("");

    const [loggedIn, setLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        (async ()=> {
            try {
              const data = await post<isLoggedInDto, any>("users/checkIfUserIsLoggedIn", null);
            
              if(data.loggedIn !== undefined) {
                setLoggedIn(data.loggedIn);
                setIsLoading(false);
              }
                
            } catch(error) {
              //TODO other errors
            }
          })()
    }, []);
    
    const handleAuthErrors = (error: unknown) => {
        if(error instanceof Error) {
            if(error instanceof Unauthorized){
                navigate("/login");
            }
        }
    }

    return (
        <AuthContext.Provider value={{
                handleAuthErrors,
                alertDisplayed,
                alertMessage,
                alertHeadline,
                setAlertDisplayed,
                setAlertHeadline,
                setAlertMessage,
                loggedIn,
                setLoggedIn,
                isLoading
            }}>
            {props.children}
        </AuthContext.Provider>
    )
}