import { Dispatch, SetStateAction } from "react";

interface ExpandButtonProps {
    expand: boolean;
    setExpand: Dispatch<SetStateAction<boolean>>
}

export const ExpandButton = (props: ExpandButtonProps) => {
    const handleClick = () => {
        props.setExpand(!props.expand);
    }

    const getIcon = () => {
        return props.expand ? <span className="material-symbols-outlined">expand_less</span> : <span className="material-symbols-outlined">expand_more</span>;
    }

    return (
        <div onClick={handleClick} className={`data-panel__mainTest__nav__buttons__button expand expandButtonWhenExpaned`}>
            {getIcon()}
        </div>)
}