
import { Interweave } from 'interweave';

import "./FAQElement.scss";
import { useEffect, useState } from 'react';

interface FAQElementProps {
    answer: string;
    isActive: boolean;
    question: string;
    id: number;
    textToHighlight: string;
    highlightColor: string;
}

interface FAQElementQuestionProps {
    textToHighlight: string;
    question: string;
    highlightColor: string;
}

const borderRadius = "3px";

const FAQElementQuestion = (props: FAQElementQuestionProps) => {
    const parts = props.question.split(new RegExp(`(${props.textToHighlight})`, 'gi'));
    return <span> { parts.map((part, i) => 
        part.toLowerCase() === props.textToHighlight.toLowerCase() 
            ? <mark style={{backgroundColor: props.highlightColor, borderRadius: borderRadius}}><span key={i}>{part}</span></mark>
            : <span key={i}>{part}</span>)
    } </span>;
}

export const FAQElement = (props: FAQElementProps) => {
    const highlightColor = '#ffca43';
    const { answer, question, textToHighlight } = props;
    const [isActive, setIsActive] = useState(props.isActive);
    const [answerInnerHtml, setAnswerInnerHtml] = useState(props.answer);
    


    useEffect(() => {
        if(textToHighlight.length === 0) {
            setIsActive(false);
            setAnswerInnerHtml(highlightTextAnswer(answer, "", false));
            return;
        }
        setAnswerInnerHtml(highlightTextAnswer(answer, textToHighlight, true));
    }, [textToHighlight])

    const highlightTextAnswer = (text: string, textToHighlight: string, shouldExpand: boolean) => {
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(text, "text/html");
        let didHighlightAnything = false;
        
        const walk = (node: Node) => {
            if (node.nodeType === Node.TEXT_NODE) {
              const textNode = node as Text;
              if(textNode.textContent && textNode.textContent.toLowerCase().includes(textToHighlight.toLowerCase())) {
                const newNode = document.createElement('span');
                if(shouldExpand)
                    didHighlightAnything = true;
                newNode.innerHTML = textNode.textContent.replace(new RegExp(`(${textToHighlight})`, 'gi'), match => `<mark style="background-color: ${highlightColor}; border-radius: ${borderRadius};">${match}</mark>`);
                if(textNode.parentNode) {
                  textNode.parentNode.replaceChild(newNode, textNode);
                }
              }
            } else {
              node.childNodes.forEach(n => walk(n));
            }
          };
        
        htmlDoc.body.childNodes.forEach(n => walk(n));
        setTimeout(() => setIsActive(didHighlightAnything), 100);
        return htmlDoc.body.innerHTML;
      }

    return (
        <div className="FAQ-section__container__list__item">
            <button list-expanded={isActive.toString()} onClick={() => setIsActive(!isActive)}>
                <span className="list-title"><FAQElementQuestion highlightColor={highlightColor} textToHighlight={textToHighlight} question={question}/></span>
                <span className="icon"></span>
            </button>
            <div className="FAQ-section__container__list__item__content">
                <p><Interweave content={answerInnerHtml} /></p>
            </div>
        </div>
    )
}