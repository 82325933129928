import { useNavigate } from "react-router-dom";
import { logout } from "../../../utilities/rest/logout";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction, useContext } from "react";
import { AuthContext } from "../../auth/authContext/AuthContextProvider";
import { setSelectionRange } from "@testing-library/user-event/dist/utils";

interface DesktopElementProps {
    path: string,
    icon: string,
    text: string,
    first?: boolean,
    last?: boolean,

    setLangActivePanel?: React.Dispatch<React.SetStateAction<boolean>>,
    handleMenuOpeningMobile?: () => void
}

export const DesktopElement = (props: DesktopElementProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);

    const { first = false, last = false } = props;

    const handleClasses = () => {
        const baseElement = "rolledUserMenu__element";
        let classes = `${baseElement}`;

        if(first) classes += ` ${baseElement}--first`;
        if(last) classes += ` ${baseElement}--last`;

        if(props.path === "language") classes += " rolledUserMenu__element--language"

        return classes;
    }

    const handleAction = (actionName: string) => {
        if(actionName === "logout"){ 
            logout();
            
            const headline = t("alert.logout.headline");
            const message = t("alert.logout.message")       
            
            if(headline !== null && message !== null) {
                authContext.setAlertHeadline(headline);
                authContext.setAlertMessage(message);
                authContext.setAlertDisplayed(true);    
            } 
            else { 
                if(props.handleMenuOpeningMobile !== undefined)
                    props.handleMenuOpeningMobile();

                navigate('/');
            }
    
        }
        else if(actionName === "language") {
            if(props.handleMenuOpeningMobile !== undefined)
                props.handleMenuOpeningMobile();
        
            setTimeout(() => { 
                if(props.setLangActivePanel !== undefined) props.setLangActivePanel(true)
            }, 600);
            
        }
        else {
            if(props.handleMenuOpeningMobile !== undefined)
                props.handleMenuOpeningMobile();
                
            navigate(actionName);
        }
    }

    return(
        <div className={handleClasses()} onClick={() => handleAction(props.path)}>
            <span className="material-symbols-outlined">
                {props.icon}
            </span>
            <span className="rolledUserMenu__element__text">
                {props.text}
            </span>
        </div>
    )
}