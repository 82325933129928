import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { post } from '../../utilities/rest/apiClient';

interface isLoggedInDto { loggedIn: boolean; }

export const NotFound = () => {

const navigate = useNavigate();

useEffect(() => {
  (async ()=> {
      try {
        const data = await post<isLoggedInDto, any>("users/checkIfUserIsLoggedIn", null);
      
        if(data.loggedIn !== undefined) {
          if(data.loggedIn) navigate('/notFoundLogged');
          else navigate('/notFoundUnlogged');
        }
        else navigate('/notFoundUnlogged');
        
         
      } catch(error) {
        navigate('/errorUnlogged');
      }
    })()
}, []);

  return null; 
};
  