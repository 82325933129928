import { CourseDescription } from "../courseDescription/CourseDescription";
import { VideoPlayer } from "../videoPlayer/VideoPlayer";
import { CoursePath } from "../coursePath/CoursePath";
import { CourseContext } from "../CourseContextProvider";
import { useContext, useEffect, useState } from "react";
import { TitleWithBackArrowButton } from "../../shared/components/titleWithBackButton/TitleWithBackArrowButton";
import { useTranslation } from "react-i18next";
import { VideoPageFallback } from "./videoPageFallback/VideoPageFallback";
import { get } from "../../../utilities/rest/apiClient";
import {TimestampDto} from "shared/timestampDto";

export const VideoPage = () => {
    const ctx = useContext(CourseContext);
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [currentStamp, setCurrentStamp] = useState<number>(0);

    useEffect(() => {
        setIsLoading(true);
        (async ()=> {
            try{
                const timestamp = await get<TimestampDto>(`reactRoutes/updateLessonTimestamp/${ctx.chapters?.lessons[ctx.activeChapter]?.id}`);
                setCurrentStamp(timestamp.currentStamp);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                //jakoś bez aktualizacji timestampa przeżyjemy
            }
        })();
    }, [ctx.activeChapter]);

    if(isLoading)
    {
        return <VideoPageFallback/>
    }
    return (
        <>
            <TitleWithBackArrowButton title={t("kurs.nawigacja.backToCourseMenu")} navigateTo={`/course/${ctx.courseId}/main`}/>
            <VideoPlayer currentStamp={currentStamp}/>

            <CourseDescription
                title={t("kurs.video.shortLessonDescription")}
                text={ctx.chapters?.lessons[ctx.activeChapter]?.description}
                />
        </>
    );
}