import { Block } from "../../../../../shared/block";
import { combineBlocks } from "../../../components/shared/utilities/combiningBlocksOfTextIntoJsx/combiningBlocksOfTextIntoJsx";

interface DisplayedCourseRecommendationProps {
    courseRecomendation: Block[];
}

export const DisplayedCourseRecommendation = (props: DisplayedCourseRecommendationProps) => {

    return (
        <div className="course-preview__main-section__left-panel__text-section">
            <div className="course-preview__main-section__left-panel__text-section__text">
                {combineBlocks(props.courseRecomendation, null)}
            </div>
        </div>
    );
}
