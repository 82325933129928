import { ChaptersFallback } from "./ChaptersFallback";

interface MainNavigationFallbackProps {
    title: string;
}

export const MainNavigationFallback = (props: MainNavigationFallbackProps) => {
    return (
        <div id="navigation-panel">
            <ChaptersFallback title={props.title}/>
        </div>
    );
}