import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ChapterData } from "../../../../../../../../shared/courseMainDto";
import { GlobalDataContext } from "../../../../../global/globalDataContext/GlobalDataContextProvider";

interface CourseMainNavigationChapterProps extends ChapterData {
    index: number;
}

export const CourseMainNavigationChapter = (props: CourseMainNavigationChapterProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { courseId } = useParams();
    const globalCtx = useContext(GlobalDataContext);
    
    const isFinished = (finishedEpisodes: number, maxEpisodes: number): boolean => {
        return finishedEpisodes === maxEpisodes;
    }

    const formatMinutes = (minutes: number) => {
        let minutesFull = minutes.toString();
        if(minutesFull.length === 1)
            minutesFull = "0" + minutesFull;
        return minutesFull 
    }

    const handleClick = () => {
        navigate(`/course/${courseId}/${props.id}`);
        globalCtx.setChapterTitle(props.name);
    }

    return (
        <div className="chapter__data-space__wrapper chapter__data-space__wrapper--wide" onClick={handleClick}>
            <div className="chapter__data-space__data chapter__data-space__data--chapter">
                <div className="icon icon--centered">
                    <span className="material-symbols-outlined">{isFinished(props.finishedEpisodes, props.maxEpisodes) ? "check_box": "check_box_outline_blank"}</span>
                </div>
                <div className="text">{`${props.index}. ${props.name}`}</div>
            </div>
            <div className="chapter__data-space__params chapter__data-space__course-params">
                <div className="chapter__data-space__course-params__lessons">
                    {`${props.finishedEpisodes}/${props.maxEpisodes}`}
                </div>
                <div className="chapter__data-space__course-params__time">
                    {`${props.fullDurationHours} ${t("kurs.glowna.rozdzial.godz")}. ${formatMinutes(props.fullDurationMinures)} ${t("kurs.glowna.rozdzial.min")}.`}
                </div>
            </div>
        </div>
    );
}