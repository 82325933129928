import { To } from "react-router-dom";
import { BackArrowButton } from "./backArrowButton/BackArrowButton"
import { Title } from "./title/Title"

interface TitleWithBackArrowButtonProps {
    navigateTo: To | undefined;
    title: string;
}

export const TitleWithBackArrowButton = (props: TitleWithBackArrowButtonProps) => {

    return (
        <div id="data-panel__title">
            <BackArrowButton navigateTo={props.navigateTo}/>
            <Title title={props.title}/>
        </div>
    )
}