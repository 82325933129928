import { Suspense, useContext, useState } from 'react';
import { Helmet } from "react-helmet";

import "./App.scss";


import { useTranslation } from 'react-i18next';

import { MainPage } from './landing/MainPage/MainPage';
import { Alert } from './components/alert/Alert';
import { Navbar } from './components/navbar/Navbar';
import { Course } from './components/course/Course';
import { Footer } from './components/footer/Footer';
import { Route, Routes, useLocation } from 'react-router-dom';
import { UserPanel } from './components/userPanel/UserPanel';
import { Error } from './components/error/Error';
import { ErrorUnlogged } from './components/error/ErrorUnlogged';
import { NotFoundError } from './components/error/NotFoundError';
import { NotFoundErrorUnlogged } from './components/error/NotFoundErrorUnlogged';
import { MainContextProvider } from './components/course/main/MainContextProvider';
import { GlobalDataContextProvider } from './components/global/globalDataContext/GlobalDataContextProvider';
import { Main } from './components/course/main/Main';
import { Login } from './components/auth/login/Login';
import { SignUp } from './components/auth/signUp/SignUp';
import { SendResetEmail } from './components/auth/sendResetEmail/SendResetEmail';
import { ResetPassword } from './components/auth/resetPassword/ResetPassword';
import { AuthContextProvider } from './components/auth/authContext/AuthContextProvider';
import { FAQ } from './components/FAQ/FAQPanel';
import { FAQWrapper } from './components/FAQWrapper/FAQWrapper';
import { NotFound } from './components/error/NotFound';
import { AuthContext } from "./components/auth/authContext/AuthContextProvider";
import { Shop } from './landing/Shop/Shop';
import { PaymentConfirmation } from './landing/PaymentConfirmation/paymentConfirmation';
import { CoursePreview } from './landing/CoursePreview/CoursePreview';
import { LessonPreview } from './landing/LessonPreview/LessonPreview';
import { ErrorRedirect } from './components/error/ErrorRedirect';
import { AboutUs } from './landing/AboutUs/AboutUs';

import { WebsiteRegulations } from './landing/Documents/WebsiteRegulations';
import { PrivacyPolicy } from './landing/Documents/PrivacyPolicy';
import { InformativeDuties } from './landing/Documents/InformationDuties';

function App() {
  const { i18n, t } = useTranslation();
  const authContext = useContext(AuthContext);
  const location = useLocation();
  
    const NavbarExcludedSites = [
      "login",
      "signup",
      "shop",
      "paymentConfirmation",
      "FAQLanding",
      "sendResetEmail",
      "resetPassword",
      "coursePreview",
      "lessonPreview",
      "notFoundUnlogged",
      "errorUnlogged",
      "aboutUs",
      "regulamin",
      "politykaPrywatnosci",
      "obowiazekInformacyjny",
      ""
    ]

    const FooterExcludedSites = [
      "login",
      "signup",
      "shop",
      "paymentConfirmation",
      "FAQLanding",
      "sendResetEmail",
      "resetPassword",
      "coursePreview",
      "notFoundUnlogged",
      "errorUnlogged",
      "aboutUs",
      "regulamin",
      "politykaPrywatnosci",
      "obowiazekInformacyjny",
      ""
    ]

    const hideComponent = (excluded: string[]) => {
      const parts = location.pathname.split('/');
      const subsite = parts[1];

      return (excluded.includes(subsite));
    }

  return (
    <Suspense>
      <GlobalDataContextProvider>
        <AuthContextProvider>
          <div className="App">
            <Helmet>
              <title>{t("title")}</title>
              <meta name="description" content="" />
            </Helmet>
            <Alert/>
            { hideComponent(NavbarExcludedSites) ? "" : <Navbar/> } 
                <Routes>
                    <Route path="course/:courseId">
                      <Route path="main/*" element={
                        <MainContextProvider>
                            <Main/>
                        </MainContextProvider>}
                      />
                      <Route path=":courseChapterId/*" element={<Course/>}/>
                      <Route path="*" element={<NotFound />} />
                      <Route path="" element={<NotFound />} />
                    </Route>
                    <Route path="userPanel/*" element={<UserPanel/>}/>
                    <Route path="login" element={<Login/>}/>
                    <Route path="signUp" element={<SignUp/>}/>
                    <Route path="sendResetEmail" element={<SendResetEmail/>}/>
                    <Route path="resetPassword/:resetToken" element={<ResetPassword/>}/>
                    <Route path="FAQ" element={<FAQ/>}/>
                    <Route path="FAQLanding" element={<FAQWrapper/>}/>
                    <Route path="course" element={<NotFound />} />
                    <Route path="aboutUs" element={<AboutUs/>}/>
                    <Route path="regulamin" element={<WebsiteRegulations/>}/>
                    <Route path="politykaPrywatnosci" element={<PrivacyPolicy/>}/>
                    <Route path="obowiazekInformacyjny" element={<InformativeDuties/>}/>
                    
                    <Route path="error" element={<ErrorRedirect />} />
                    
                    <Route path="errorUnlogged" element={<ErrorUnlogged />} />
                    <Route path="errorLogged" element={<Error />}/>
                    <Route path="notFoundLogged" element={<NotFoundError/>} />
                    <Route path="notFoundUnlogged" element={<NotFoundErrorUnlogged/>} />

                    <Route path="shop" element={<Shop />}/>
                    <Route path="paymentConfirmation" element={<PaymentConfirmation />}/>

                    <Route path="coursePreview/:courseId" element={<CoursePreview/>}/>
                    <Route path="lessonPreview/:courseId/*" element={<LessonPreview/>}/>
                    <Route path="*" element={<NotFound />} />
                    <Route path="" element={ <MainPage />} />
                </Routes>
            { hideComponent(FooterExcludedSites) ? "" : <Footer/> }
          </div>
        </AuthContextProvider>
      </GlobalDataContextProvider>
    </Suspense>
  );
}

export default App;
