export const checkNumberOfCharacters = (password: string, length: number) => {
    return password.length >= length;
}

export const checkCapitalization = (password: string, numberOfCapitalized: number) => {
    const regex = new RegExp(`([A-Z].*){${numberOfCapitalized}}`);
    return regex.test(password);
}

export const checkNonCapitlization = (password: string, numberOfNonCapitalized: number) => {
    const regex = new RegExp(`([a-z].*){${numberOfNonCapitalized}}`);
    return regex.test(password);
}

export const checkDigits = (password: string, numberOfDigits: number) => {
    const regex: RegExp = new RegExp(`^(?=.*?\\d){${numberOfDigits}}.*$`);
    return regex.test(password);
}

export const getInputFieldState = (elementValidation: boolean, elementLength: number) => {
    if(elementValidation)  return "input--valid"
    else if( (!elementValidation) && (elementLength!== 0) ) return "input--invalid"
    else return "input--default";
}
