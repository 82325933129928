import { useContext, useEffect } from "react";
import { MainContext } from "../MainContextProvider";
import { Assignment } from "../../additions/assignment/Assignment";

export const AssignmentMain = () => {
    const mainCtx = useContext(MainContext);
    // const setPath = () => {
    //     mainCtx.setPath([mainCtx.mainDto.title, t("kurs.glowna.notatki.notatki"),
    //         mainCtx.mainDto.chapters[mainCtx.active.chapterIndex].lessons[mainCtx.active.lessonIndex].title]);
    // }

    useEffect(() => {
        mainCtx.setActiveComponent('assignment');
    }, []);
    return (
        <Assignment/>
    );
}