import { useContext } from "react";
import { MainContext } from "../../../../course/main/MainContextProvider";

interface DataPanelWrapperProps {
    children: React.ReactNode;
    currentPanel?: string;
}

export const DataPanelWrapper = (props: DataPanelWrapperProps) => {
    const mainCtx = useContext(MainContext);
    
    return (
        <div id={`data-panel-wrapper`} className={mainCtx.activeComponent === 'mainTest' ? "main-test" : ''}>
            <div id="data-panel" className={(props.currentPanel === "myCourses")? "data-panel--padding-reduced": ""}>
                {props.children}
            </div>
        </div>
    );
}