import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { UserPanelPageProps } from "../../UserPanel";
import "../userSettings.scss";
import { emailSettingsDto } from "../../../../../../shared/userSettingsDto";
import { get, patch } from "../../../../utilities/rest/apiClient";
import { AuthContext } from "../../../auth/authContext/AuthContextProvider";

import { UpdateEmailDto } from "../../../../../../shared/updateEmailDto";

import { handleNotification } from "../mutual/handleNotification";
import { CodePanel } from "./CodePanel/CodePanel";

export const ChangeEmail = (props: UserPanelPageProps) => {
    const { t } = useTranslation();
    const authContext = useContext(AuthContext);

    const [isLoading, setIsLoading] = useState(true);
    const [email, setEmail] = useState("");

    const [newEmail, setNewEmail] = useState("");
    const [newEmailConfirmation, setNewEmailConfirmation] = useState("");

    const [displayCodePanel, setDisplayCodePanel] = useState(false);
    const [codeValue, setCodeValue] = useState("");

    const [displayBadNotification, setDisplayBadNotification] = useState(false);

    const handleNewEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
        setNewEmail(event.target.value);
    }

    const handleNewEmailConfirmChange = (event: ChangeEvent<HTMLInputElement>) => {
        setNewEmailConfirmation(event.target.value);
    }

    const validInputs = () => {
        const sameEmail = (newEmail === newEmailConfirmation);
        const validEmails = (checkValidEmail(newEmail) && checkValidEmail(newEmailConfirmation));
        return (sameEmail && validEmails);
    }
 
    const handleEmailChangeSubmit = () => {

        if(!validInputs()) return;
            
        const dto: UpdateEmailDto = {
            email: newEmail,
            emailConfirm: newEmailConfirmation
        }

        const displayTime = 4000;

        patch<any, UpdateEmailDto>("users/changeEmail", dto).then(data => {

            setDisplayCodePanel(true);
        })
        .catch(error => { 
            setDisplayBadNotification(true);

            setTimeout(() => {
                setDisplayBadNotification(false);
            }, displayTime);
        });

        setNewEmail("");
        setNewEmailConfirmation("");

    }

    const handleSubmitButtonClass = () => {
        const base = "data-panel__user-settings__button-wrapper__submit-button";
        let finalClassName = `${base} ${base}`;

        finalClassName += validInputs() ? "--valid" : "--invalid";

        return finalClassName;
    }

    const checkValidEmail = (email: string) => {
        const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return expression.test(email);
    }

    useEffect(() => {
        props.setActivePanel("changeEmail");
        const activeChapter = props.activePanelNameToIndexMap.get("changeEmail");
        if(activeChapter){
            props.setActiveChapter(activeChapter);
        }

        setIsLoading(true);
        (async ()=> {
            try{
                const data = await get<emailSettingsDto>("users/emailSettings");
                setEmail(data.email);
                setIsLoading(false);
            } catch (error) {
                authContext.handleAuthErrors(error);
                //TODO pozostałe mozliwe błędy
            }
        })()
    }, []);
    return (
        <div className="data-panel__change-email data-panel__user-settings">
            {
                displayCodePanel ? <CodePanel setDisplayCodePanel={setDisplayCodePanel} codeValue={codeValue} setCodeValue={setCodeValue}/> : ""
            }

            <div className="data-panel__user-settings__description">
                <h4>{t("userPanel.settings.changeEmail")}</h4>
            </div>
            <div className="data-panel__user-settings__text">
                <span>
                    {t("userPanel.settings.changeEmailInitialDescription")}
                </span>
            </div>
            <div className="data-panel__user-settings__text">
                <span>
                    <h4>{t("userPanel.settings.changeEmailDescription")}</h4>
                </span>
            </div>
            <div className="data-panel__user-settings__input-row">
                <div className="data-panel__user-settings__input-row__text">
                    <span>
                        {`${t("userPanel.settings.currentEmail")}:`}
                    </span>
                </div>
                <div className="data-panel__user-settings__input-row__data">
                    {isLoading? <span className="skeleton"/> : <span>{email}</span>}
                </div>
            </div>
            <div className="data-panel__user-settings__spacer" />
            <div className="data-panel__user-settings__text data-panel__user-settings__text--middle-margin">
                <span>
                    {t("userPanel.settings.provideNewEmailDescription")}
                </span>
            </div>
            <div className="data-panel__user-settings__input-row">
                <div className="data-panel__user-settings__input-row__text">
                    <span>
                        {t("userPanel.settings.provideNewEmail")}:
                    </span>
                </div>
                <div className="data-panel__user-settings__input-row__input-wrapper">
                    <input 
                        type="text" 
                        placeholder={`${t("userPanel.settings.newAddress")}`} 
                        name="new-email" 
                        value={newEmail} 
                        onChange={handleNewEmailChange}
                        className={(checkValidEmail(newEmail) || (newEmail.length === 0)) ? "" : "invalid" } 
                    />
                </div>
            </div>
            <div className="data-panel__user-settings__spacer" />
            <div className="data-panel__user-settings__input-row">
                <div className="data-panel__user-settings__input-row__text">
                    <span>
                        {t("userPanel.settings.repeatNewEmail")}:
                    </span>
                </div>
                <div className="data-panel__user-settings__input-row__input-wrapper">
                    <input 
                        type="text" 
                        placeholder={`${t("userPanel.settings.repeatNewAddress")}`} 
                        name="new-email-confirm"
                        value={newEmailConfirmation}
                        onChange={handleNewEmailConfirmChange}
                        className={ 
                            (
                                (checkValidEmail(newEmailConfirmation) && (newEmailConfirmation === newEmail)) || 
                                (newEmailConfirmation.length === 0)
                            ) ? "" : "invalid" } 
                    />
                </div>
            </div>
            <div className="data-panel__user-settings__spacer" />
            <div className="data-panel__user-settings__button-wrapper">
                { handleNotification(false, displayBadNotification) }
                <div 
                    onClick={handleEmailChangeSubmit}
                    className={handleSubmitButtonClass()}
                >
                    <span>
                        {t("userPanel.settings.updateEmail")}
                    </span>
                </div>
            </div>
        </div>
    );
}