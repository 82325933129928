import { LandingFooter } from "../LandingFooter/LandingFooter";
import { LandingNavbar } from "../LandingNavbar/LandingNavbar";

import "./documents.scss";

export const PrivacyPolicy = () => {

  return (
    <div className="document">
      <LandingNavbar />

      <div className="document__text">
        <div>
          <p
            style={{
              marginBottom: "6pt",
              textAlign: "center",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <strong>
              <span style={{ color: "#222222", fontSize: "20px" }}>
                POLITYKA PRYWATNOŚCI
              </span>
            </strong>
          </p>
          <p
            style={{
              marginBottom: "6pt",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <strong>
              <span>
                Zawartość i cele polityki prywatności.
              </span>
            </strong>
          </p>
          <p
            style={{
              marginBottom: "6pt",
              textAlign: "justify",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <span>
              Polityka Prywatności określa
            </span>
            <span>&nbsp;</span>
            <span>
              zasady przetwarzania danych osobowych Użytkowników Serwisu ELERNA
              (zwanego dalej „Serwis ELERNA”), które zostały podane przez nich
              osobiście i dobrowolnie, celem korzystania z usług oferowanych
              przez Administratora Serwisu.{" "}
            </span>
          </p>
          <p
            style={{
              marginBottom: "6pt",
              textAlign: "justify",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <span>
              Naszym celem jest zapewnienie
            </span>
            <span>&nbsp; </span>
            <span>
              wysokiego standardu ochrony przekazywanych przez Państwa za
              pośrednictwem Serwisu danych, odpowiadającego przepisom
              wynikającym z Rozporządzenia Parlamentu Europejskiego i Rady (UE)
              2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób
              fizycznych w związku z przetwarzaniem danych osobowych i w sprawie
              swobodnego przepływu takich danych oraz uchylenia dyrektywy
              95/46/WE{" "}
            </span>
            <em>
              <span>
                (ogólne rozporządzenie o ochronie danych){" "}
              </span>
            </em>
            <span>– dalej „RODO”.</span>
          </p>
          <p
            style={{
              marginBottom: "6pt",
              textAlign: "justify",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <span>
              Informujemy, że w Serwisie mogą zostać umieszczone linki do
              zewnętrznych serwisów internetowych bądź aplikacji. Nie ponosimy
              odpowiedzialności za bezpieczeństwo danych, które przekazują
              Państwo serwisom zewnętrznym. Podczas odwiedzania zewnętrznych
              serwisów internetowych, zalecamy Państwu zapoznanie się z ich
              politykami prywatności.
            </span>
          </p>
          <p
            style={{
              marginBottom: "6pt",
              textAlign: "justify",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <span>
              Poniższa Polityka Prywatności jest integralną częścią
            </span>
            <span>&nbsp;</span>
            <span>Regulaminu Serwisu</span>
            <span className="CommentReference" style={{ lineHeight: "150%" }}>
              ,{" "}
            </span>
            <span
              className="CommentReference"
              style={{ fontSize: "16px" }}
            >
              k
            </span>
            <span>
              tóry określa zasady, prawa i obowiązki Użytkowników korzystających
              z Serwisu.
            </span>
          </p>
          <p
            style={{
              marginBottom: "6pt",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <strong>
              <span>
                Administrator danych osobowych
              </span>
            </strong>
          </p>
          <p
            style={{
              marginBottom: "6pt",
              textAlign: "justify",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <span>
              Administratorem danych osobowych jest spółka pod firma{" "}
            </span>
              <span>
                ELERNA GROUP Spółka z ograniczoną odpowiedzialnością z siedzibą w Krakowie (adres: ulica Chmieleniec nr 6 lok. 9, 30 – 348 Kraków , REGON:
                528290584, NIP: 6762666516) wpisana do rejestru przedsiębiorców Krajowego Rejestru Sądowego
                pod numerem KRS: 0001098308.
              </span>


          </p>
          <p
            style={{
              marginBottom: "6pt",
              textAlign: "justify",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <span>
              Mogą Państwo skontaktować się z nami korespondencyjnie pod
              powyższym adresem lub wysyłając wiadomość na adres e-mail:
              support@nasesje.com.
            </span>
          </p>
          <p
            style={{
              marginBottom: "6pt",
              textAlign: "justify",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <span>
              Podanie adresu korespondencyjnego albo adresu poczty
              elektronicznej (e-mail) jest dobrowolne, ale konieczne, abyśmy
              mogli odpowiedzieć na zadane przez Państwa pytanie.
            </span>
          </p>
          <p
            style={{
              marginBottom: "6pt",
              textAlign: "justify",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <strong>
              <span>
                Bezpieczeństwo przetwarzania
              </span>
            </strong>
          </p>
          <p
            style={{
              marginBottom: "6pt",
              textAlign: "justify",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <span>
              Bezpieczeństwo Twoich danych jest dla nas najważniejsze, dlatego
              zgodnie z RODO wdrożyliśmy i utrzymujemy adekwatne do rozpoznanego
              ryzyka środki organizacyjne i techniczne. Wdrożone zabezpieczenia
              podlegają cyklicznym przeglądom, a w razie konieczności
              modyfikacji.
            </span>
          </p>
          <p
            style={{
              marginBottom: "6pt",
              textAlign: "justify",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <span>
              W celu zapewnienia integralności i poufności danych wdrożyliśmy
              również procedury umożliwiające dostęp do danych osobowych jedynie
              osobom upoważnionym.
            </span>
          </p>
          <p
            style={{
              marginBottom: "6pt",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <strong>
              <span>
                Cel przetwarzania danych osobowych.
              </span>
            </strong>
          </p>
          <p
            style={{
              marginBottom: "6pt",
              textAlign: "justify",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <span>
              Udostępnione przez Państwa dane osobowe będą wykorzystywane do
              celów określonych
            </span>
            <span>&nbsp;</span>
            <br />
            <span>
              w niniejszej Polityce prywatności lub Regulaminie Serwisu.{" "}
            </span>
          </p>

          <div className="spacer"/>
          <p
            style={{
              marginBottom: "6pt",
              textAlign: "justify",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <span>
              Administrator Serwisu informuje, że:
            </span>
          </p>
          <ol className="awlist1" style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "17pt",
                marginBottom: "6pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",
            
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span
                style={{
                  width: "3.19pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;{" "}
              </span>
              <em>
                <span style={{ fontWeight: "normal" }}>
                  W przypadku skorzystania przez Użytkownika z formularza
                  kontaktowego
                </span>
              </em>
              <span style={{ fontWeight: "normal" }}>
                {" "}
                – dane osobowe, które Użytkownik podaje w formularzu
                kontaktowym,
              </span>
              <span style={{ fontWeight: "normal" }}>&nbsp;</span>
              <span
                style={{
                  lineHeight: "150%",
                  fontFamily: '"Times New Roman"',
                  fontSize: "12pt",
                  fontWeight: "normal",
                }}
              >
                m.in.
              </span>
              <span style={{ fontWeight: "normal" }}>
                {" "}
                poprzez zapytanie e-mail, lub na innym kanale komunikacji, w tym
                w social mediach, przetwarzane są w celu identyfikacji nadawcy i
                udzielenia odpowiedzi na zapytanie. Podanie danych jest wymagane
                w celu przyjęcia i obsługi zapytania, a ich niepodanie skutkuje
                brakiem możliwości obsługi. Podstawą prawną przetwarzania jest
                niezbędność przetwarzania do wykonania umowy o świadczenie
                usługi (art. 6 ust. 1 lit. b RODO),{" "}
              </span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "17pt",
                marginBottom: "6pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",
            
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span
                style={{
                  width: "3.19pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;{" "}
              </span>
              <em>
                <span style={{ fontWeight: "normal" }}>
                  W przypadku formularza rejestracji konta członkowskiego
                </span>
              </em>
              <span style={{ fontWeight: "normal" }}>
                {" "}
                – dane osobowe, które Użytkownik podaje w formularzu
                przetwarzane są w celu rejestracji konta w serwisie. Podanie
                danych jest wówczas wymagane, a ich niepodanie skutkuje brakiem
                możliwości rejestracji. Podstawą prawną przetwarzania jest
                niezbędność przetwarzania do wykonania umowy o świadczenie usług
                (art. 6 ust. 1 lit. b RODO),{" "}
              </span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "17pt",
                marginBottom: "6pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",
            
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span
                style={{
                  width: "3.19pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;{" "}
              </span>
              <em>
                <span style={{ fontWeight: "normal" }}>
                  W przypadku korespondencji e-mail z Użytkownikiem Serwisu
                </span>
              </em>
              <span style={{ fontWeight: "normal" }}>
                {" "}
                (np. w celu udzielenia mu pomocy, zgodnie z żądaniem) – dane
                osobowe, podane przez Użytkownika w korespondencji e-mail
                przetwarzane są w celu komunikacji z Użytkownikiem i w celu
                rozwiązania zgłoszonego problemu, której dotyczy korespondencja.
                Podstawą prawną przetwarzania jest niezbędność przetwarzania do
                wykonania umowy o świadczenie usług (art. 6 ust. 1 lit. b RODO).
              </span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "17pt",
                marginBottom: "6pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",
            
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span
                style={{
                  width: "3.19pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;{" "}
              </span>
              <em>
                <span style={{ fontWeight: "normal" }}>
                  W przypadku zapisu na Newsletter
                </span>
              </em>
              <span style={{ fontWeight: "normal" }}>
                {" "}
                – dane osobowe przetwarzane są w celu przesyłania Użytkownikowi
                treści marketingowych. Podstawą prawną przetwarzania jest
                wyrażona przez Użytkownika zgoda (art. 6 ust. 1 lit. a RODO).
              </span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "17pt",
                marginBottom: "6pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",
            
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span
                style={{
                  width: "3.19pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;{" "}
              </span>
              <em>
                <span style={{ fontWeight: "normal" }}>
                  W przypadku kontaktu telefonicznego w celu przedstawienia
                  oferty i marketingu bezpośredniego
                </span>
              </em>
              <span style={{ fontWeight: "normal" }}> –</span>
              <span style={{ fontWeight: "normal" }}>&nbsp; </span>
              <span style={{ fontWeight: "normal" }}>
                dane osobowe Użytkownika mogą być przetwarzane na podstawie art.
                6 ust. 1 lit. a RODO (zgoda) oraz na podstawie art. 6 ust. 1
                lit. f RODO (prawnie uzasadniony interes administratora),{" "}
              </span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "17pt",
                marginBottom: "6pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",
            
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span
                style={{
                  width: "3.19pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;{" "}
              </span>
              <span style={{ fontWeight: "normal" }}>
                pozostałe cele realizowane w ramach tzw. uzasadnionego interesu
                administratora danych,
              </span>
              <span style={{ fontWeight: "normal" }}>&nbsp; </span>
              <span style={{ fontWeight: "normal" }}>
                w tym przechowywania danych dla celów archiwizacyjnych i
                statystycznych, zapewnienia możliwości wykonywania oraz
                rozliczalności w zakresie wykazania spełnienia obowiązków
                wynikających z przepisów prawa na postawie art. 6 ust. 1 lit f
                RODO.
              </span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "17pt",
                marginBottom: "6pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",
            
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span
                style={{
                  width: "3.19pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;{" "}
              </span>
              <span style={{ fontWeight: "normal" }}>
                Ponadto, w przypadkach wskazanych w ust. 1) – 6) powyżej, w
                których Administrator Serwisu jest Administratorem Danych
                Osobowych Użytkownika, odbiorcą Danych Osobowych Użytkownika
                mogą być także dostawcy rozwiązań informatycznych lub dostawcy
                świadczący informatyczne wykorzystywane do działania Serwisu (w
                tym między innymi Microsoft Corporation, Podmioty Google i
                inne).{" "}
              </span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "17pt",
                marginBottom: "6pt",
                textIndent: "-5pt",
                textAlign: "justify",
                lineHeight: "150%",
            
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span
                style={{
                  width: "3.19pt",
                  font: '7pt "Times New Roman"',
                  display: "inline-block",
                }}
              >
                &nbsp;{" "}
              </span>
              <span style={{ fontWeight: "normal" }}>
                Wszelkie posiadane przez nas dane mogą być również przetwarzane
                przez nas w celu dochodzenia lub obrony przed roszczeniami,
                jeżeli zajdzie taka konieczność. Przetwarzanie danych odbywa się
                wówczas na podstawie naszego prawnie uzasadnionego celu (art. 6
                ust. 1 lit. f RODO).
              </span>
            </li>
          </ol>

          <div className="spacer"/>
          <p
            style={{
              marginBottom: "6pt",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <strong>
              <span>
                Inne podmioty, które mają dostęp do danych osobowych.
              </span>
            </strong>
          </p>
          <p
            className="NormalWeb"
            style={{
              marginTop: "0pt",
              marginBottom: "6pt",
              textAlign: "justify",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <span style={{ color: "#333333" }}>
              Dane osobowe Użytkowników Serwisu, poza przypadkami wskazanymi w
              niniejszej Polityce prywatności, nie są udostępniane stronom
              trzecim, za wyjątkiem sytuacji, gdy udostępnienie takie wynika z
              obowiązujących przepisów prawa obligujących Administratora danych
              osobowych do przekazania ich uprawnionym podmiotom.
            </span>
            <span>
              {" "}
              Dane osobowe nie będą przekazywane poza obszar Unii Europejskiej.
            </span>
          </p>
          <p
            style={{
              marginBottom: "6pt",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <strong>
              <span>
                Rodzaj danych osobowych jakie są przetwarzane.
              </span>
            </strong>
          </p>
          <p
            style={{
              marginBottom: "6pt",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <span>
              Administrator może przetwarzać następujące kategorie danych
              osobowych Użytkowników:{" "}
            </span>
          </p>

          <ol className="awlist1" style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li
                className="ListParagraph"
                style={{
                    marginLeft: "17pt",
                    marginBottom: "6pt",
                    textIndent: "-5pt",
                    textAlign: "justify",
                    lineHeight: "150%",
                
                    fontSize: "16px",
                    fontWeight: "bold",
                }}
                >
                <span
                    style={{
                    width: "3.19pt",
                    font: '7pt "Times New Roman"',
                    display: "inline-block",
                    }}
                >
                    &nbsp;{" "}
                </span>
                <span style={{ fontWeight: "normal" }}>
                    dane osobowe (np. imię, nazwisko, adres zamieszkania)
                </span>
            </li>
            <li
                className="ListParagraph"
                style={{
                    marginLeft: "17pt",
                    marginBottom: "6pt",
                    textIndent: "-5pt",
                    textAlign: "justify",
                    lineHeight: "150%",
                
                    fontSize: "16px",
                    fontWeight: "bold",
                }}
                >
                <span
                    style={{
                    width: "3.19pt",
                    font: '7pt "Times New Roman"',
                    display: "inline-block",
                    }}
                >
                    &nbsp;{" "}
                </span>
                <span style={{ fontWeight: "normal" }}>
                dane kontaktowe (np. numer telefonu, e-mail, adres
              korespondencyjny);
                </span>
            </li>
            <li
                className="ListParagraph"
                style={{
                    marginLeft: "17pt",
                    marginBottom: "6pt",
                    textIndent: "-5pt",
                    textAlign: "justify",
                    lineHeight: "150%",
                
                    fontSize: "16px",
                    fontWeight: "bold",
                }}
                >
                <span
                    style={{
                    width: "3.19pt",
                    font: '7pt "Times New Roman"',
                    display: "inline-block",
                    }}
                >
                    &nbsp;{" "}
                </span>
                <span style={{ fontWeight: "normal" }}>
                dane identyfikacyjne (np. data urodzenia, PESEL itp.);
                </span>
            </li>

          </ol>
          
          <p
            style={{
              marginBottom: "6pt",
              textAlign: "justify",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <span>
              Ponadto Administrator może przetwarzać inne dane osobowe, o ile
              nie można ich zakwalifikować do żadnej z powyższych grup, a jest
              to dokonywane w celach opisanych w niniejszym dokumencie.{" "}
            </span>
          </p>
          <p
            style={{
              marginBottom: "6pt",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <strong>
              <span>
                Podstawa prawna przetwarzania danych osobowych.
              </span>
            </strong>
          </p>
          <p
            style={{
              marginBottom: "6pt",
              textAlign: "justify",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <span>
              Dane osobowe są przetwarzane zgodnie z przepisami Rozporządzenia
              Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia
              2016r. w sprawie ochrony osób fizycznych w związku z
              przetwarzaniem danych osobowych i w sprawie swobodnego przepływu
              takich danych oraz uchylenia dyrektywy 95/46/WE (RODO), celem
              wykonania usług opisanych w Regulaminie Serwisu.
            </span>
          </p>
          <p
            style={{
              marginBottom: "6pt",
              textAlign: "justify",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <strong>
              <span>
                Czas przetwarzania i okres przechowywania danych:
              </span>
            </strong>
          </p>
          <p
            style={{
              marginBottom: "6pt",
              textAlign: "justify",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <span>
              Dane osobowe Użytkowników zazwyczaj będą przetwarzane przez czas
              wykonywania zawartej umowy – w celu zapewnienia możliwości
              wykonania umowy (art. 6 ust. 1 lit. b RODO), jak również przez
              czas niezbędny do realizacji celów wynikających z prawnie
              uzasadnionych interesów realizowanych przez Administratora lub
              przez stronę trzecią (art. 6 ust. 1 lit. f RODO).{" "}
            </span>
          </p>
          <p
            style={{
              marginBottom: "6pt",
              textAlign: "justify",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <span>
              Okres przez który Państwa dane osobowe będą przechowywane jest
              ustalany w oparciu o następujące kryteria:
            </span>
          </p>

          <ol className="awlist1" style={{ margin: "0pt", paddingLeft: "0pt" }}>

            <li
                className="ListParagraph"
                style={{
                    marginLeft: "17pt",
                    marginBottom: "6pt",
                    textIndent: "-5pt",
                    textAlign: "justify",
                    lineHeight: "150%",
                
                    fontSize: "16px",
                    fontWeight: "bold",
                }}
                >
                <span
                    style={{
                    width: "3.19pt",
                    font: '7pt "Times New Roman"',
                    display: "inline-block",
                    }}
                >
                    &nbsp;{" "}
                </span>
                <span style={{ fontWeight: "normal" }}>
                przepisy prawa, które mogą obligować Administratora do
              przetwarzania danych przez określny czas.
                </span>
            </li>
            <li
                className="ListParagraph"
                style={{
                    marginLeft: "17pt",
                    marginBottom: "6pt",
                    textIndent: "-5pt",
                    textAlign: "justify",
                    lineHeight: "150%",
                
                    fontSize: "16px",
                    fontWeight: "bold",
                }}
                >
                <span
                    style={{
                    width: "3.19pt",
                    font: '7pt "Times New Roman"',
                    display: "inline-block",
                    }}
                >
                    &nbsp;{" "}
                </span>
                <span style={{ fontWeight: "normal" }}>
                okres przez jaki wykonywane są przez Administratora obowiązki i
              uprawnienia związane z zawartą umową czy świadczoną usługą.
                </span>
            </li>
            <li
                className="ListParagraph"
                style={{
                    marginLeft: "17pt",
                    marginBottom: "6pt",
                    textIndent: "-5pt",
                    textAlign: "justify",
                    lineHeight: "150%",
                
                    fontSize: "16px",
                    fontWeight: "bold",
                }}
                >
                <span
                    style={{
                    width: "3.19pt",
                    font: '7pt "Times New Roman"',
                    display: "inline-block",
                    }}
                >
                    &nbsp;{" "}
                </span>
                <span style={{ fontWeight: "normal" }}>
                okres, który jest niezbędny do zabezpieczenia obrony interesów
              Administratora przed roszczeniami oraz w celu dochodzenia roszczeń
              przez Administratora, w tym w szczególności przez okres do upływu
              przedawnienia lub wygaśnięcia ewentualnych roszczeń.
                </span>
            </li>
          </ol>
         
          <p
            style={{
              marginBottom: "6pt",
              textAlign: "justify",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <span>
              Pozostałe cele realizowane w ramach tzw. uzasadnionego interesu
              Administratora danych,
            </span>
            <span>&nbsp; </span>
            <span>
              w tym przechowywania danych dla celów archiwizacyjnych i
              statystycznych oraz do celów promocji i marketingu przez okres 10
              lat.
            </span>
          </p>
          <p
            style={{
              marginBottom: "6pt",
              textAlign: "justify",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <strong>
              <span>
                Przysługujące prawa:
              </span>
            </strong>
          </p>
          <p
            style={{
              marginBottom: "6pt",
              textAlign: "justify",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <span>
              W związku z przetwarzaniem przez Administratora danych osobowych,
              Użytkownikowi przysługują następujące prawa:
            </span>
          </p>
          <ol style={{ margin: "0pt", paddingLeft: "4pt" }}>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "7.23pt",
            
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                prawo dostępu do danych, tj. można uzyskać informacje, czy
                Administrator przetwarza dane osobowe Użytkowników. Jeśli tak –
                można uzyskać do nich dostęp, np. poprosić o ich kopię. Można
                również uzyskać szczegółowe informacje m.in. o tym, jakie dane
                są przetwarzane i w jakim celu, komu są przekazywane;{" "}
              </span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "7.23pt",
            
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                prawo do sprostowania danych tj. jeżeli są one nieprawidłowe –
                można poprosić o ich poprawienie;
              </span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "7.23pt",
            
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                prawo do usunięcia danych (prawo do bycia zapomnianym) lub
                ograniczenia przetwarzania w przypadkach przewidzianych prawem;
              </span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "7.23pt",
            
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                prawo do wniesienia sprzeciwu wobec przetwarzania danych
                osobowych w przypadku przetwarzania ich w celu realizacji
                uzasadnionego interesu Administratorowi;
              </span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "7.23pt",
            
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                prawo do przenoszenia danych;
              </span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "14.07pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "7.23pt",
            
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                prawo do wniesienia skargi do organu nadzorczego zajmującego się
                ochroną danych osobowych - Prezesa Urzędu Ochrony Danych
                Osobowych, w przypadku gdy uznania, że Państwa dane przetwarzane
                są z naruszeniem prawa;
              </span>
            </li>
            <li
              className="ListParagraph"
              style={{
                marginLeft: "14.07pt",
                marginBottom: "6pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "7.23pt",
            
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              <span style={{ fontWeight: "normal" }}>
                dodatkowo, jeżeli przetwarzanie danych osobowych odbywa się na
                podstawie zgody, przysługuje Państwu prawo do cofnięcia zgody na
                przetwarzanie danych osobowych w dowolnym momencie, przy czym
                wycofanie zgody nie ma wpływu na zgodność z prawem
                przetwarzania, którego dokonano na podstawie zgody przed jej
                wycofaniem.
              </span>
            </li>
          </ol>
          <p
            style={{
              marginBottom: "6pt",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <strong>
              <span>Profilowanie</span>
            </strong>
          </p>
          <p
            style={{
              marginBottom: "6pt",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <span>
              Dane osobowe nie podlegają profilowaniu przez Administratora.
            </span>
          </p>
          <p
            style={{
              marginBottom: "6pt",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <strong>
              <span>
                Informacja o wymogu podania danych
              </span>
            </strong>
          </p>
          <p
            style={{
              marginBottom: "6pt",
              textAlign: "justify",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <span>
              W zakresie, w jakim przetwarzanie danych osobowych Użytkowników
              następuje w celu zawarcia i realizacji umowy zawartej z
              Administratorem, podanie danych jest niezbędne do zawarcia i
              realizacji umowy. Podanie danych osobowych jest dobrowolne,
              jednakże bez ich podania nie jest możliwe zawarcie i realizacja
              umowy/ świadczenie usługi
            </span>
            <span
              style={{
                lineHeight: "150%",
            
                fontSize: "8.5pt",
              }}
            >
              .{" "}
            </span>
          </p>
          <p
            style={{
              marginBottom: "6pt",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <strong>
              <span>Pliki Cookies </span>
            </strong>
          </p>
          <p
            className="Default"
            style={{
              marginBottom: "6pt",
              textAlign: "justify",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <span>
              Administrator Serwisu może wykorzystywać mechanizmy m.in. takie
              jak pliki Cookies (tzw. ciasteczka), Web Storage i inne, które
              służą m.in. do zapewnienia optymalnej obsługi podczas korzystania
              z Serwisu. Powyższe mechanizmy mogą być wykorzystywane przez
              Administratora Serwisu jak i przez partnerów Administratora
              Serwisu. Część z tych mechanizmów jest niezbędna do prawidłowego
              działania Serwisu, w tym zapewnienia niezbędnego poziomu
              bezpieczeństwa – w tym zakresie zgoda Użytkownika na ich działanie
              nie jest konieczna.{" "}
            </span>
          </p>
          <p
            className="Default"
            style={{
              marginBottom: "6pt",
              textAlign: "justify",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <span>
              Pozostałe mechanizmy są wykorzystywane do:{" "}
            </span>
          </p>
          <p
            className="Default"
            style={{
              textAlign: "justify",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <span>
              1. obsługi dodatkowych funkcjonalności usprawniających działanie
              Serwisu (np. w zakresie streamingu),{" "}
            </span>
          </p>
          <p
            className="Default"
            style={{
              textAlign: "justify",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <span>
              2. analizy tego, w jaki sposób Użytkownik korzysta z Serwisu,{" "}
            </span>
          </p>
          <p
            className="Default"
            style={{
              marginBottom: "0.3pt",
              textAlign: "justify",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <span>
              3. marketingu bezpośredniego i wyświetlania reklam, w tym reklam
              spersonalizowanych,{" "}
            </span>
          </p>
          <p
            className="Default"
            style={{
              marginBottom: "6pt",
              textAlign: "justify",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <span>
              4. udostępniania funkcji mediów społecznościowych.{" "}
            </span>
          </p>
          <p
            className="Default"
            style={{
              marginBottom: "6pt",
              textAlign: "justify",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <span>
              – w tym zakresie zgoda Użytkownika na ich działanie jest
              konieczna. Zgoda może być wyrażona podczas korzystania z Serwisu.
              Użytkownik w każdym czasie może cofnąć zgodę lub zmienić jej
              zakres.{" "}
            </span>
          </p>
          <p
            className="Default"
            style={{
              marginBottom: "6pt",
              textAlign: "justify",
              lineHeight: "150%",
              fontSize: "16px",
            }}
          >
            <span>
              Administrator Serwisu informuje Użytkownika, że w ramach Serwisu
              może wykorzystywać następujące technologie, usługi i narzędzia
              zewnętrzne:{" "}
            </span>
          </p>
          <ol style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li
              className="Default"
              style={{
                marginLeft: "13.35pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "4.5pt",
            
                fontSize: "16px",
              }}
            >
              Statystyki Google Analytics wraz z funkcjami reklamowymi – w celu
              analizy statystyk i prowadzenia działań remarketingowych,
            </li>
            <li
              className="Default"
              style={{
                marginLeft: "13.35pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "4.5pt",
            
                fontSize: "16px",
              }}
            >
              Piksel Facebooka – w celu zarządzania reklamami na Facebooku i
              prowadzenia działań remarketingowych,
            </li>
            <li
              className="Default"
              style={{
                marginLeft: "13.35pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "4.5pt",
            
                fontSize: "16px",
              }}
            >
              Firebase Cloud Messaging – w celu umożliwienia wyświetlania
              użytkownikowi Serwisu powiadomień push,
            </li>
            <li
              className="Default"
              style={{
                marginLeft: "13.35pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "4.5pt",
            
                fontSize: "16px",
              }}
            >
              Google Adsense – w celu wyświetlania reklam,
            </li>
            <li
              className="Default"
              style={{
                marginLeft: "13.35pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "4.5pt",
            
                fontSize: "16px",
              }}
            >
              Microsoft Clarity – w celu analizy statystyk i optymalizacji
              Serwisu,
            </li>
            <li
              className="Default"
              style={{
                marginLeft: "13.35pt",
                marginBottom: "6pt",
                textAlign: "justify",
                lineHeight: "150%",
                paddingLeft: "4.5pt",
            
                fontSize: "16px",
              }}
            >
              Bunny.net – celem umożliwienia użytkownikowi korzystania z tzw.
              streamingu materiałów udostępnionych w ramach działania Serwisu.
            </li>
          </ol>
          <p>&nbsp;</p>
          <div style={{ clear: "both" }}>
            <div style={{ float: "right" }}>
              <p className="Footer">
              </p>
            </div>
            <br style={{ clear: "both" }} />
            <p className="Footer" style={{ marginRight: "18pt" }}>
              &nbsp;
            </p>
          </div>
        </div>
      </div>
      <LandingFooter />
    </div>
  );
};
