import { ChangeEvent, Dispatch, FormEvent, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import "./changeUsername.scss";
import "../userSettings.scss";

import { UserPanelPageProps } from "../../UserPanel";
import { AuthContext } from "../../../auth/authContext/AuthContextProvider";
import { get, patch } from "../../../../utilities/rest/apiClient";
import { usernameSettingsDto } from "../../../../../../shared/userSettingsDto";
import { TextFallback } from "../../../shared/components/fallbacks/skeleton/TextFallback";
import { ValidationDot } from "../mutual/ValidationDot";
import { handleNotification } from "../mutual/handleNotification";


interface ChangeUsernameProps extends UserPanelPageProps {
    setUsernameUpdate:  Dispatch<SetStateAction<boolean>>
}

export const ChangeUsername = (props: ChangeUsernameProps) => {
    const { t } = useTranslation();
    const authContext = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);
    const [name, setName] = useState("");
    const [newName, setNewName] = useState("");
    const [newUsername, setNewUsername] = useState("");
    const [userName, setUserName] = useState("");

    const [displayGoodNotification, setDisplayGoodNotification] = useState(false);
    const [displayBadNotification, setDiplayBadNotification] = useState(false);

    const checkMinimumSigns = () => {
        const minimumSignsCnt = 5;
        return (newUsername.length >= minimumSignsCnt) 
    }

    const checkMaximumSigns = () => {
        const maximumSignsCnt = 15;
        return (newUsername.length <= maximumSignsCnt)
    }

    const handleSubmitButtonClass = () => {
        const base = "data-panel__user-settings__button-wrapper__submit-button";
        let finalClassName = `${base} ${base}`;
        
        const usernameChanged = (checkMaximumSigns() && checkMinimumSigns());

        const whitespaceRegex = /^\S.*\S$/;
        const nameChanged = ( (newName.length > 5) &&  whitespaceRegex.test(newName));
        
        finalClassName += (usernameChanged || nameChanged) ? "--valid" : "--invalid";

        return finalClassName;
    }


    const handleUserDataChange = () => {

        const usernameChanged = (checkMaximumSigns() && checkMinimumSigns());
        const whitespaceRegex = /^\S.*\S$/;
        const nameChanged = ( (newName.length > 5) &&  whitespaceRegex.test(newName));

        if(usernameChanged || nameChanged) {

            const data = { username: newUsername, name: newName};

            const displayTime = 4000;

            patch<any, any>(`users/usernameSettings`, data)
            .then(() => {
                if(nameChanged) setName(newName);
                if(usernameChanged) { 
                    setUserName(newUsername);
                    
                    props.setUsernameUpdate(true);
                }

                setDiplayBadNotification(false);
                setDisplayGoodNotification(true);

                setTimeout(() => {
                    setDisplayGoodNotification(false);
                }, displayTime);
            })
            .catch((error) => {
                setDisplayGoodNotification(false);
                setDiplayBadNotification(true);

                setTimeout(() => {
                    setDiplayBadNotification(false);
                }, displayTime);
            });

            setNewName("");
            setNewUsername("");
        }
    };

    useEffect(() => {
        props.setActivePanel("changeUsername");
        const activeChapter = props.activePanelNameToIndexMap.get("changeUsername");
        if(activeChapter){
            props.setActiveChapter(activeChapter);
        }

        setIsLoading(true);
        (async ()=> {
            try{
                const data = await get<usernameSettingsDto>("users/usernameSettings");
                const notSet = t("userPanel.settings.notSet");

                setName(data.name === "" ? notSet : data.name);
                setUserName(data.username === "" ? notSet : data.username);

                setIsLoading(false);
            } catch (error) {
                authContext.handleAuthErrors(error);
                //TODO pozostałe mozliwe błędy
            }
        })()
    }, [])
    return (
        <div className="data-panel__change-username data-panel__user-settings">
            <div className="data-panel__user-settings__description">
                <h4>{t("userPanel.settings.changeCurrentUsername")}</h4>
            </div>
            <div className="data-panel__user-settings__text">
                <span>
                    {t("userPanel.settings.changeUsernameDescription")}
                </span>
            </div>
            <div className="data-panel__user-settings__input-row">
                <div className="data-panel__user-settings__input-row__text">
                    <span>{`${t("userPanel.settings.currentUsername")}:`}</span>
                </div>
                <div className="data-panel__user-settings__input-row__data">
                    {isLoading? <span className="skeleton"/> : <span>{userName}</span>}
                </div>
            </div>
            <div className="data-panel__user-settings__spacer"/>

            <form>
                <div className="data-panel__user-settings__input-row">
                    <div className="data-panel__user-settings__input-row__text">
                        <span>{`${t("userPanel.settings.newUsername")}:`}</span>
                    </div>
                    <div className="data-panel__user-settings__input-row__input-wrapper">
                        <input 
                            type="text" 
                            placeholder={`${t("userPanel.settings.username")}`} 
                            name="username" 
                            maxLength={20}
                            value={newUsername}
                            onChange={(e) => setNewUsername(e.target.value)}
                        />
                    </div>
                </div>
                <div className="data-panel__user-settings__spacer" />

                <div className="data-panel__change-password__requirements">
                    <span className="data-panel__change-password__requirements__list-name">
                        {`${t("userPanel.settings.usernameRequirement")}:`}
                    </span>
                    <div className="data-panel__change-password__requirements__list data-panel__change-password__requirements__list--short">
                        <ValidationDot isValid={checkMinimumSigns()} title={t("userPanel.settings.minimum5Signs")}/>
                        <ValidationDot isValid={checkMaximumSigns()} title={t("userPanel.settings.maximum20Signs")}/>
                    </div>
                    <br/>
                </div>

                <div className="data-panel__user-settings__text data-panel__user-settings__text--middle-margin">
                    <span>{t("userPanel.settings.nameAndSurnameDescription")}</span>
                </div>
                <div className="data-panel__user-settings__input-row">
                    <div className="data-panel__user-settings__input-row__text">
                        <span>{`${t("userPanel.settings.currentNameAndSurname")}:`}</span>
                    </div>
                    <div className="data-panel__user-settings__input-row__data">
                        {isLoading? <span className="skeleton"/> : <span>{name}</span>}
                    </div>
                </div>
                <div className="data-panel__user-settings__spacer" />
                <div className="data-panel__user-settings__input-row">
                    <div className="data-panel__user-settings__input-row__text">
                        <span>{`${t("userPanel.settings.newNameAndSurname")}:`}</span>
                    </div>
                    <div className="data-panel__user-settings__input-row__input-wrapper">
                        <input 
                            type="text" 
                            placeholder={`${t("userPanel.settings.nameSurname")}`} 
                            name="name-surname" 
                            maxLength={25}
                            value={newName}
                            onChange={(e) => setNewName(e.target.value)}
                        />
                    </div>
                </div>
                <div className="data-panel__user-settings__spacer" />
                <div className="data-panel__user-settings__button-wrapper">
                    { handleNotification(displayGoodNotification, displayBadNotification) }
                    <div onClick={handleUserDataChange} className={handleSubmitButtonClass()}>
                        <span>{t("userPanel.settings.confirmChanges")}</span>
                    </div>
                </div>
            </form>
        </div>
    );
}