import { useNavigate } from "react-router-dom";

interface ColElementProps {
    text: string,
    icon?: JSX.Element,
    param?: string,
    path: string,
}

export const ColElement = (props: ColElementProps) => {
    const navigate = useNavigate();

    const handleClick = (path: string) => {

        if(path === "") return;

        if(path.startsWith("http")) {
            window.location.href = path;
        } else {
            window.scrollTo(0, 0);
            navigate(path);
        }
    } 

    return(
        <li className={(props.param === "fixed")? "fixed": ""}>
            <p onClick={() => handleClick(props.path)} className={`${props.path === "" ? "disabled":""}`}>
                {props.icon}
                {props.text}
            </p>
        </li>
    )
}