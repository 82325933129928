import { Dispatch, SetStateAction } from "react";
import { VideoOffer } from "../videoOffer/videoOffer";
import { VideoSolution } from "../videoSolution/videoSolution";

export type FinalTestPart = 'preview' | 'test' | undefined;

interface VideoOfferSolutionWrapperProps {
    videoSolutionOpen: boolean;
    setVideoSolutionOpen: Dispatch<SetStateAction<boolean>>
    openAssignmentPossesion: boolean;
    assignmentNumber: number;
    assignmentsCount: number;
    videoLink?: string;
    isFinalTest: FinalTestPart;
}

export const VideoOfferSolutionWrapper = (props: VideoOfferSolutionWrapperProps) => {
    const handleOpeningVideoSolution = () => {
        let html = document.querySelector('html');

        if(!props.videoSolutionOpen){    
            if(html !== null) html.classList.add('html-not-scrollable');
            document.body.classList.add('body-not-scrollable');
        } else {
            document.body.classList.remove('body-not-scrollable');
            if(html !== null) html.classList.remove('html-not-scrollable');
        }
        props.setVideoSolutionOpen(!props.videoSolutionOpen);
    }

    if(!props.videoSolutionOpen) return <></>;

    if(props.openAssignmentPossesion) {
        return <VideoSolution 
            handleOpeningVideoSolution={handleOpeningVideoSolution} 
            assignmentNumber={props.assignmentNumber} 
            assignmentsCount={props.assignmentsCount}
            videoLink={props.videoLink}
            isFinalTest={props.isFinalTest}/>
    }

    return <VideoOffer handleOpeningVideoSolution={handleOpeningVideoSolution}/>
}