import { useContext } from "react";
import { CourseContext } from "../../CourseContextProvider";
import { useTranslation } from "react-i18next";

interface CourseVideoChapterProps {
    id: string;
    index: number;
    title: string;
    stamp: string;
    src: string;
    loadFallback?: boolean;
}
//chapter__data-space__data--lite-highlight
export const CourseVideoChapter = (props: CourseVideoChapterProps) => {
    const { t } = useTranslation();
    const ctx = useContext(CourseContext);

    const maxTitleLength = 29;

    const getActiveClass = () => {
        if(ctx.activeChapter === props.index) {
            if(ctx.activeAddition !== "disabled") {
                return "chapter__data-space__data--highlited chapter__data-space__data--go-back-button chapter__data-space__data--clickable";
            }
            return "chapter__data-space__data--highlited chapter__data-space__data--equal-mobile-padding";
        }
        return "";
    }

    const getExtendedClass = () => {
        if(props.title.length > maxTitleLength) {
            if(ctx.activeAddition === "disabled")
                return "chapter__data-space__data--extended"
            if(ctx.activeChapter !== props.index)
                return "chapter__data-space__data--extended"
        }

        return ""
    }

    const getIcon = () => {
        if(ctx.activeChapter === props.index) {
            if(ctx.activeAddition === "disabled")
                return "pause_circle";
            else
                return "arrow_back_ios";
        }
        return "play_circle";
    }

    const additionalIconClass = () => {
        if(ctx.activeChapter === props.index)
            if(ctx.activeAddition !== "disabled")
                return "icon--centered-arrow";
        
        return "";
    }

    const timeStampLengthEqualizer = () => {
        if(ctx.activeChapter === props.index) 
            if(ctx.activeAddition !== "disabled")
                return ""

        let timeStamp = props.stamp;

        if(timeStamp.length !== 5) {
            let splited = timeStamp.split(":");
            if(splited[0].length == 1){
                splited[0] = "0" + splited[0];
            
            }
            if(splited[1].length == 1){
                splited[1] = "0" + splited[1];
            
            }

            timeStamp = splited.join(":");
            
        }

        return timeStamp
    }

    const getCurrentText = () => {
        if(ctx.activeChapter === props.index) 
            if(ctx.activeAddition !== "disabled")
                return t("kurs.nawigacja.backToTheCourse");
                
        if(props.title.length > maxTitleLength) {
            const { title } = props;

            const divIndex = title.substring(0, maxTitleLength).lastIndexOf(" ");
            const firstPart = props.title.substring(0, divIndex);
            const secondPart = props.title.substring(divIndex, title.length); 
            return (
                <>
                    <div className="text">{`${props.index+1}. ${firstPart}`}</div>
                    <div className="text text--artifically-positioned">{secondPart}</div>
                </>
            );
        }
        return <div className="text">{`${props.index+1}. ${props.title}`}</div>;
    }

    if(props.loadFallback){
        return (
            <div className={`chapter__data-space__data chapter__data-space__data--additional-responsive skeleton skeleton-chapter-navigation-lesson`}>
                <div className={`icon icon--additional-responsive`}>
                    <span className="material-symbols-outlined">
                    </span>
                </div>
                <div className="stamp"></div>
            </div>
        )
    } return (
        <div className={`chapter__data-space__data chapter__data-space__data--additional-responsive ${getActiveClass()} ${getExtendedClass()} ${props.loadFallback ? "skeleton" : ""}`}
            onClick={() => ctx.handleChapterClick(props.index, props.src, props.title)}>
            <div className={`icon icon--additional-responsive ${additionalIconClass()}`}>
                <span className="material-symbols-outlined">
                    {getIcon()}
                </span>
            </div>
            {getCurrentText()}
            <div className="stamp">{timeStampLengthEqualizer()}</div>
        </div>
    )
}