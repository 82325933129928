import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { get, patch } from "../../../../utilities/rest/apiClient";
import { TitleWithBackArrowButton } from "../../../shared/components/titleWithBackButton/TitleWithBackArrowButton";
import { CourseContext } from "../../CourseContextProvider";
import { CoursePath } from "../../coursePath/CoursePath";
import { Block } from "../../../../../../shared/block";
import "./notes.scss";
import { combineBlocks } from "../../../shared/utilities/combiningBlocksOfTextIntoJsx/combiningBlocksOfTextIntoJsx";
import { NotesDto } from "../../../../../../shared/notesDto";
import { AuthContext } from "../../../auth/authContext/AuthContextProvider";
import { NotesLinesFallback } from "./NotesLinesFallback";
import { GlobalDataContext } from "../../../global/globalDataContext/GlobalDataContextProvider";
import { MainContext } from "../../main/MainContextProvider";

export const Notes = () => {
    const { t, i18n } = useTranslation();
    const [notes, setNotes] = useState<Block[]>([]);
    const ctx = useContext(CourseContext);
    const [isLoading, setIsLoading] = useState(true);
    const authContext = useContext(AuthContext);
    const globalCtx = useContext(GlobalDataContext);
    const mainCtx = useContext(MainContext);

    useEffect(() => {  
      ctx.setActiveAddition("notes");    
      (async ()=> {
        try {
          setIsLoading(true);
          const data = await get<NotesDto>(`reactRoutes/getNotes/${ctx.getLessonId()}/${i18n.language}`);

          if(!ctx.chapters.lessons[ctx.activeChapter].notesVisited) {
            await patch<any, any>(`userChapters/incrementVisitedLessonNotes/${ctx.getLessonId()}`, {});
            ctx.setNotesEverVisited(1);
          }

          setNotes(data.blocks);
          setIsLoading(false);
        } catch(error) {
          authContext.handleAuthErrors(error);
          console.log(error);
          //TODO other errors
        }
      })()
  }, [])

        return (
          <div className="data-panel__notes">

            <div className="data-panel__notes__headline">
                <h4>{t("kurs.notatki.przegladajNotatkiZLekcjiNaStronie")}</h4>
            </div>

            <div className="data-panel__notes__container-spacer data-panel__notes__container-spacer--top" />
            <div className="data-panel__notes__container">
                <div className="data-panel__notes__container__data">
                    {isLoading ? <NotesLinesFallback numberOfLines={15}/> : combineBlocks(notes)}
                </div>
            </div>
            <div className="data-panel__notes__container-spacer data-panel__notes__container-spacer--bottom" />

            <div className="data-panel__notes__download">
              <div className="data-panel__notes__download__headline data-panel__notes__download__headline--big">
                <span>
                  {t("kurs.notatki.materialsToDownload")}
                </span>
              </div>

              <div className="data-panel__notes__download__text">
                <span>
                  {`${t("kurs.notatki.documentIncludingCombinedNotes")} - `}
                </span>
                <span className="data-panel__notes__download__every-note__link">
                  {t("kurs.notatki.goToCourse")}
                </span>
                <span>.</span>
              </div>

              <div className="data-panel__notes__download__row">
                <div className="data-panel__notes__download__row__description">
                    <span>
                      {t("kurs.notatki.downloadNotesInfo")}
                    </span>
                </div>
                <div className="data-panel__notes__download__row__button">
                    <span>
                        {t("kurs.notatki.downloadPDF")}
                    </span>
                    <span className="material-symbols-outlined">
                        download
                    </span>
                </div>
              </div>

              <div className="data-panel__notes__download__spacer"/>
            </div>

          </div>
        );   
}