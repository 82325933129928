import { useContext, useEffect, useMemo, useState } from "react";
import { Lesson } from "../../../../../../../shared/mainDto";
import { MainChapterLesson } from "./mainChapterLesson/MainChapterLesson";
import { MainContext } from "../../MainContextProvider";

interface MainChapterProps {
    index: number;
    title: string;
    lessons: Lesson[];
    openedAtStart: boolean;
}

export const MainChapter = (props: MainChapterProps) => {
    const [isOpened, setIsOpened] = useState(props.openedAtStart);
    const mainCtx = useContext(MainContext);

    useEffect(() => {
        setIsOpened(mainCtx.currentlyOpenedChapterDropdown === props.index);
    }, [mainCtx.currentlyOpenedChapterDropdown])

    const handleClickOpen = () => mainCtx.setCurrentlyOpenedChapterDropdown(props.index);
    const getLessons = () => {
        const result: JSX.Element[] = [];
        let index = 1;

        for(let lesson of props.lessons.slice(1)) {
            result.push(
                <MainChapterLesson 
                    index={index} 
                    key={index}
                    chapterIndex={props.index} 
                    title={lesson.title}/>
            )
            index++;
        }

        return result;
    }

    const lessons = useMemo(getLessons, props.lessons);

    if(!isOpened) {
        return (
            <div className="chapter__data-space__data chapter__data-space__data--wide-panel" onClick={handleClickOpen}>
                <div className="text">{`${props.index + 1}. ${props.title}`}</div>
                <div className="icon icon--bold icon--scaled">
                <span className="material-symbols-outlined">expand_more</span>
                </div>
            </div>
        )
    } else {
        return (
            <div className="chapter__data-space__opened-data">
            <div className="chapter__data-space__data" onClick={handleClickOpen}>
                <div className="text text--bold">{`${props.index + 1}. ${props.title}`}</div>
                <div className="icon icon--bold icon--scaled">
                    <span className="material-symbols-outlined">expand_less</span>
                </div>
            </div>
            <div className="chapter__data-space__opened-data__lessons-list">
                {lessons}
            </div>
            </div>
        )
    }
}