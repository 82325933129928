import { useContext, useEffect } from "react";
import { CourseContext } from "../CourseContextProvider";

interface CourseDescriptionProps {
    title: string;
    text: string | undefined;
}

export const CourseDescription = (props: CourseDescriptionProps) => {
    const ctx = useContext(CourseContext);

    return (
        <div>
            <div id="data-panel__description-title">{props.title}</div>
            <div id="data-panel__description">{props.text}</div>
        </div>
    )
}