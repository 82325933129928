import { useTranslation } from "react-i18next";
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from "react";
import { UpdatePasswordDto } from "../../../../../../../shared/updatePasswordDto";

import { ConfirmEmailUpdateDto } from "../../../../../../../shared/confirmEmailUpdateDto";

import "./codePanel.scss";
import { patch } from "../../../../../utilities/rest/apiClient";

interface CodePanelProps {
    setDisplayCodePanel: Dispatch<SetStateAction<boolean>>;
    codeValue: string;
    setCodeValue: Dispatch<SetStateAction<string>>;
}

export const CodePanel = (props: CodePanelProps) => {
    const { t } = useTranslation();

    const [displayMessage, setDisplayMessage] = useState(false);
    const [message, setMessage] = useState("");
    const [goodMessage, setGoodMessage] = useState(false);

    const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
        
        const input = event.target;

        const digitRegex = /^\d+$/;

        if(digitRegex.test(input.value) || (input.value === "") ){
            if(input.selectionStart !== null)
                input.style.setProperty('--_otp-digit', input.selectionStart.toString());
            
            props.setCodeValue(input.value);
        }
    };

    const handleConfirmButtonClass = () => {
        const base = "data-panel__user-settings__email-code-panel__window__buttons__button";
        let finalClass = `${base} ${base}--full`;
        return finalClass += (props.codeValue.length === 6) ? ` ${base}--active` : ` ${base}--inactive`; 
    }

    const handleAuthCodeSubmit = () => {

        if(props.codeValue.length !== 6) return;
            
        const dto: ConfirmEmailUpdateDto = {
            confirmationCode: props.codeValue
        }

        patch<any, ConfirmEmailUpdateDto>("users/confirmEmailChange", dto).then(data => {
            setMessage("Adres email powiązany z twoim kontem został pomyślnie zmieniony");
            setGoodMessage(true);
        })
        .catch(error => { 
            setMessage("Podano niepoprawny kod lub kod, którego data ważności upłynęła.");
            setGoodMessage(false);
        })
        .then(() => {
            setDisplayMessage(true);  
        });

        

        props.setCodeValue("");
    }

    return (
        <div className="data-panel__change-email data-panel__user-settings__email-code-panel">

            <div className={`data-panel__user-settings__email-code-panel__window data-panel__user-settings__email-code-panel__window--${displayMessage ? "small-size" : "full-size"}`}>
                {
                    displayMessage ? (
                        <>
                            <div className="data-panel__user-settings__email-code-panel__window__notification">
                                {
                                    goodMessage ? 
                                    <span className="material-symbols-outlined success">
                                        check_circle
                                    </span> :
                                    <span className="material-symbols-outlined error">
                                        error
                                    </span>
                                }

                                <span>{message}</span>
                            </div>
                            <div className="data-panel__user-settings__email-code-panel__window__spacer">

                            </div>
                            <div className="data-panel__user-settings__email-code-panel__window__buttons data-panel__user-settings__email-code-panel__window__buttons--centered">
                                <div 
                                    onClick={() => {props.setDisplayCodePanel(false)}}
                                    className="data-panel__user-settings__email-code-panel__window__buttons__button data-panel__user-settings__email-code-panel__window__buttons__button--yellow">
                                    <span>
                                        {t("userPanel.settings.codePanel.backToMenu")}
                                    </span>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="data-panel__user-settings__email-code-panel__window__headline">
                                <span>
                                    {t("userPanel.settings.codePanel.confirmEmailChange")}
                                </span>
                            </div>                
                            <div className="data-panel__user-settings__email-code-panel__window__main-text">
                                <span>
                                    {t("userPanel.settings.codePanel.emailHasBeenSent")}
                                </span>
                            </div>

                            <div className="data-panel__user-settings__email-code-panel__window__input-row">
                                <label>
                                    <span>
                                        {t("userPanel.settings.codePanel.insertConfirmationCode")}:&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                    <input 
                                        type="text" 
                                        value={props.codeValue} 
                                        autoComplete="one-time-code" 
                                        inputMode="numeric" 
                                        maxLength={6} 
                                        onChange={handleInput}
                                    />
                                </label>
                            </div>
                            
                            <div className="data-panel__user-settings__email-code-panel__window__buttons">
                                <div onClick={handleAuthCodeSubmit} className={handleConfirmButtonClass()}>
                                    <span className="data-panel__user-settings__email-code-panel__window__buttons__button__text--long">
                                        {t("userPanel.settings.codePanel.confirmChange")}
                                    </span>
                                    <span className="data-panel__user-settings__email-code-panel__window__buttons__button__text--short">
                                        {t("userPanel.settings.codePanel.confirm")}
                                    </span>
                                </div>
                                <div 
                                    onClick={() => {props.setDisplayCodePanel(false)}}
                                    className="data-panel__user-settings__email-code-panel__window__buttons__button data-panel__user-settings__email-code-panel__window__buttons__button--empty">
                                    <span>
                                        {t("userPanel.settings.codePanel.cancel")}
                                    </span>
                                </div>
                            </div>
                        </>
                    )
                }
            </div> 
        </div>
    );
}