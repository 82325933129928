import { useTranslation } from "react-i18next";
import { useContext, useState } from "react";


import "./LanguageElement.scss"

interface LanguageElementProps {
    country: string,
    language: string,
    selectedLanguage: string,
    shortcut: string,
    available: boolean
}

export const LanguageElement = (props: LanguageElementProps ) => {
    const { t } = useTranslation();

    const [selectedLanguage, setSelectedLanguage] = useState("");

    const handleActive = () => {
        let prepClass = "";
        prepClass += (props.selectedLanguage === props.shortcut) ? "flag--active" : "";
        prepClass += (props.available) ? " flag--avaiable" : " flag--unavaiable";

        return prepClass;
    }
    return (
        <div className="navigation__user-panel__language-selector-menu__selector__element">

            <div className={`flag flag--${props.country} ${handleActive()}`}></div>
            <div className="navigation__user-panel__language-selector-menu__selector__element__language">
                <span> {props.language}</span>
            </div>
        </div>
    );
}