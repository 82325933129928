import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Block } from "../../../../../shared/block";
import { QuizQuestion } from "../../../components/course/additions/quiz/quizTest/quizQuestion/QuizQuestion";
import { QuizAnswers } from "../../../components/course/additions/quiz/quizTest/quizAnswers/QuizAnswers";
import { UserAnswer } from "../../../components/course/additions/quiz/Quiz";
import { AnswerElement } from "../../../components/course/additions/quiz/quizTest/QuizTest";
import { QuizAnswer } from "../../../components/course/additions/quiz/quizTest/quizAnswers/quizAnswer/QuizAnswer";
import { combineBlocks } from "../../../components/shared/utilities/combiningBlocksOfTextIntoJsx/combiningBlocksOfTextIntoJsx";
import { AnswerForQuestion, AnswerType } from "../../../components/course/additions/quiz/quizResult/QuizResult";
import { Answer } from "shared/quizesDto";
import Latex from "react-latex";

type PrevNext = "prev" | "next";

export interface Quiz {
    text: Block[];
    id: number;
    correctAnswerId: number;
    answers: {
        id: number
        imageSrc: string;
        correct: boolean;
        srcIsImage: boolean;
    }[];
}

interface PreviewQuizProps {

    quizAnswersDisplayed: boolean;
    setQuizAnswersDisplayed: Dispatch<SetStateAction<boolean>>;

    activeQuiz: number;
    setActiveQuiz: Dispatch<SetStateAction<number>>;

    quizUserAnswers: UserAnswer[];
    setQuizUserAnswers: Dispatch<SetStateAction<UserAnswer[]>>;

    showAllQuizes: boolean;
    setShowAllQuizes:  Dispatch<SetStateAction<boolean>>;

    quizAllResultIndex: number;
    setAllQuizResultIndex: Dispatch<SetStateAction<number>>;

    quizWrongResultIndex: number;
    setWrongQuizResultIndex: Dispatch<SetStateAction<number>>;

    wrongAnswers: UserAnswer[];
    setWrongAnswers: Dispatch<SetStateAction<UserAnswer[]>>;

    resultQuizesSet: Quiz[];
    setResultQuizesSet: Dispatch<SetStateAction<Quiz[]>>;

    resultUserAnswersSet: UserAnswer[];
    setResultUserAnswersSet: Dispatch<SetStateAction<UserAnswer[]>>;

    setQuizCompleted: Dispatch<SetStateAction<number>>;

    quizes: Quiz[];
}

export const PreviewQuiz = (props: PreviewQuizProps) => {
    const { t } = useTranslation();

    const { activeQuiz, setActiveQuiz, quizes } = props;
    const { resultQuizesSet, setResultQuizesSet, resultUserAnswersSet, setResultUserAnswersSet} = props;
    
    const [activeMobileIndex, setActiveMobileIndex] = useState(0);
    const [firstMobileClicked, setFirstMobileClicked] = useState(false);

    const mobilePanelsMaxIndex = 3;
    
    const handleShowAllQuizes = () => {
        let changedState = false, updatedShowAllQuizes = props.showAllQuizes;

        if(props.wrongAnswers.length !== 0 ) {
            changedState = true;
            props.setShowAllQuizes(!props.showAllQuizes);
            updatedShowAllQuizes = !props.showAllQuizes;
        }

        if(updatedShowAllQuizes) {
            setResultQuizesSet(quizes);
            setResultUserAnswersSet(props.quizUserAnswers);
        }
        else {
            setResultUserAnswersSet(props.wrongAnswers);
            const wrongAnswersQuizIndexes = props.wrongAnswers.map(el => el.questionId);

            setResultQuizesSet(
                quizes.filter((el, index) => wrongAnswersQuizIndexes.includes(el.id))
            );
        }
    }

    useEffect(() => {
        if(props.quizAnswersDisplayed && (props.wrongAnswers.length === 0)) {
            props.setWrongAnswers(props.quizUserAnswers.filter(element => element.userAnswerId !== element.correctAnswerId));
        }
    }, [props.quizAnswersDisplayed]);

    useEffect(() => {
        if(props.showAllQuizes) {
            setResultQuizesSet(quizes);
            setResultUserAnswersSet(props.quizUserAnswers);
        }
        else {
            setResultUserAnswersSet(props.wrongAnswers);
            const wrongAnswersQuizIndexes = props.wrongAnswers.map(el => el.questionId);

            setResultQuizesSet(
                quizes.filter((el, index) => wrongAnswersQuizIndexes.includes(el.id))
            );
        }
    }, [props.quizUserAnswers])

    const handleClickAnswer = (userAnswerId: number, correctQuestionId: number): void => {

        const answer: UserAnswer = {
            questionId: quizes[activeQuiz].id,
            userAnswerId: userAnswerId,
            correctAnswerId: correctQuestionId,
        }

        props.setQuizUserAnswers(userAnswers => [...userAnswers, answer]);

        console.log([...props.quizUserAnswers, answer]);

        if(activeQuiz < quizes.length - 1) {
            setActiveQuiz(activeQuiz+1);
        }
        else {
            props.setQuizAnswersDisplayed(true);
        }
    }

    const redoQuiz = () => {
        props.setQuizAnswersDisplayed(false);
        setActiveQuiz(0);
    
        props.setShowAllQuizes(true);
    
        props.setAllQuizResultIndex(0);
        props.setWrongQuizResultIndex(0);

        props.setQuizCompleted(0);
    }

    const handleButtonClass = (type: PrevNext) => {
        const currentIndex = (props.showAllQuizes ? props.quizAllResultIndex : props.quizWrongResultIndex);
        const length = resultQuizesSet.length;

        const base = "data-panel__results-1__buttons__button";

        let finalClassName = `${base} ${base}--${type}`;
        let inactive = false;

        if(type === "prev") inactive = (currentIndex === 0);
        else if(type === "next") inactive = ((currentIndex+1) === length);


        finalClassName += inactive ? ` ${base}--inactive` : "";
        
        return finalClassName;
    }

    const getAnswers = () => {
        return quizes[activeQuiz].answers;
    }

    const getCorrectQuestionId = () => {
        return quizes[activeQuiz].correctAnswerId;
    }

    const getNumberOfCorrectAnswers = () => {

        return props.quizUserAnswers.filter(element => element.userAnswerId === element.correctAnswerId).length;
    }

    const getCorrectAnswer = () => {
        const index = (props.showAllQuizes ? props.quizAllResultIndex : props.quizWrongResultIndex);
        
        const answer = resultQuizesSet[index].answers.filter((el) => el.correct)[0];
        return  answer;
    }

    const getUserAnswer = () => {
        const index = (props.showAllQuizes ? props.quizAllResultIndex : props.quizWrongResultIndex);
    
        const answer = resultQuizesSet[index].answers[resultUserAnswersSet[index].userAnswerId];
        return answer;
    }

    return (
        <>
            {
                (!props.quizAnswersDisplayed) ? (
                    <div className="data-panel__quiz">
                        <div className="data-panel__quiz__name">
                            <h4>{`${t("kurs.quiz.pytanie")}: ${activeQuiz+1} / ${quizes.length}`}</h4>
                        </div>
    
                        <QuizQuestion text={quizes[activeQuiz].text} />
    
                        <div className="data-panel__quiz__command">
                            <span>{t("kurs.quiz.wybierzPoprawnaOdpowiedz")}</span>
                        </div>
                        
                        <div className="data-panel__quiz__anwsers">
                            { 
                                ['A', 'B', 'C', 'D'].map( (label, index) => 
                                    <QuizAnswer 
                                        key={index}
                                        currentIndex={index}
                                        label={label}
                                        activeMobileIndex={activeMobileIndex}
                                        setActiveMobileIndex={setActiveMobileIndex}
                                        src={getAnswers()[index].imageSrc} 
                                        id={getAnswers()[index].id}
                                        correctQuestionId={getCorrectQuestionId()}
                                        handleClickAnswer={handleClickAnswer}
                                        srcIsImage={getAnswers()[index].srcIsImage}
                                    />   
                                )
                            }
                        </div>
                        
                        <div className="data-panel__quiz__mobile-nav-bar">
                        
                        <div 
                            className={`data-panel__quiz__mobile-nav-bar__button data-panel__quiz__mobile-nav-bar__button--${(activeMobileIndex !== 0) ? "active" : "inactive"}`}
                            onClick={() => { 
                                if(activeMobileIndex !== 0) setActiveMobileIndex(activeMobileIndex-1);
                                if(!firstMobileClicked) setFirstMobileClicked(true);
                            } } 
                        >
                            <span className="material-symbols-outlined">
                                arrow_back_ios
                            </span>
                        </div>
    
                        <div className="data-panel__quiz__mobile-nav-bar__wrapper">
                            {
                                ['A', 'B', 'C', 'D'].map((el, index) => 
                                    <AnswerElement 
                                        key={index} 
                                        index={index} 
                                        symbol={el} 
                                        activeIndex={activeMobileIndex}
                                    />
                                )
                            }
                        </div>
    
                        <div 
                            className={`data-panel__quiz__mobile-nav-bar__button data-panel__quiz__mobile-nav-bar__button--${(activeMobileIndex !== mobilePanelsMaxIndex) ? "active" : "inactive"}`}
                            onClick={() => { 
                                if(activeMobileIndex !== mobilePanelsMaxIndex) setActiveMobileIndex(activeMobileIndex+1);
                                if(!firstMobileClicked) setFirstMobileClicked(true);
                            } }  
                        >
                            <span className="material-symbols-outlined">
                            arrow_forward_ios
                            </span>
                        </div>
    
                        </div>
    
                        {
                        (firstMobileClicked) ? "" : (
                            <div className="data-panel__quiz__mobile-tip">
                            <div className="data-panel__quiz__mobile-tip__symbol">
                                <span className="material-symbols-outlined">
                                emoji_objects
                                </span>
                            </div>
                            <div className="data-panel__quiz__mobile-tip__text">
                                <span>
                                {t("kurs.quiz.uzyjStrzalekAbyWybrazOdpowiedz")}
                                </span>
                            </div>
                            </div>
                        )
                        }
                    </div>
                ) : (
                    <div className="data-panel__results-1">
                        <div className="data-panel__results-1__score">
                            <h4>
                                {t("kurs.quiz.odpowiedzialesDobrzeNa")}<span>{getNumberOfCorrectAnswers()} </span> {t("kurs.quiz.z")} <span>{quizes.length}</span> {t("kurs.quiz.mozliwychPytan")}
                            </h4>
                        </div>
            
                        <div className="data-panel__results-1__correct-anwsers">
            
                            <div className="data-panel__results-1__correct-anwsers__checkbox">
                                <label htmlFor="click" className={`text text--${props.showAllQuizes? "inactive": "active"}`}>
                                    {
                                        props.showAllQuizes ? t("kurs.quiz.showAllAnswers") : t("kurs.quiz.showOnlyWrongAnswers")
                                    }
                                </label>
                                <input 
                                    type="checkbox" 
                                    id="click" 
                                    checked={props.showAllQuizes} 
                                    onChange={handleShowAllQuizes}
                                />
                            </div>
            
                            <div className="data-panel__results-1__correct-anwsers__spacer"></div>
                            
                            <div className="data-panel__results-1__correct-anwsers__question-number">
                                <h4>{t("kurs.quiz.pytanie")}: {(props.showAllQuizes ? props.quizAllResultIndex : props.quizWrongResultIndex)+1}/{resultQuizesSet.length}</h4>
                            </div>
            
                            <div className="data-panel__quiz__question">
                                <div className="data-panel__quiz__question__text">
                                    { 
                                      combineBlocks(
                                        resultQuizesSet[(props.showAllQuizes ? props.quizAllResultIndex : props.quizWrongResultIndex)].text
                                      ) 
                                    }
                                </div>
                            </div>
                            
                            <div className="data-panel__results-1__correct-anwsers__headlines-spacer" />
            
                            <div className="data-panel__results-1__correct-anwsers__headlines-wrapper">
                                <div className="data-panel__results-1__correct-anwsers__headlines-wrapper__headline data-panel__results-1__correct-anwsers__headlines-wrapper__headline--correct">
                                    <span className="material-symbols-outlined">check_box</span>
                                    <span>{t("kurs.quiz.yourAnswer")}</span>
                                </div>
            
                                <div className="data-panel__results-1__correct-anwsers__headlines-wrapper__mobile">
                                    <AnswerForQuestion answerType={AnswerType.CORRECT} correctAnswer={getCorrectAnswer()} userAnswer={getUserAnswer()}/>
                                </div>
                            
                                <div className="data-panel__results-1__correct-anwsers__headlines-wrapper__headline data-panel__results-1__correct-anwsers__headlines-wrapper__headline--yours">
                                    <span className="material-symbols-outlined">indeterminate_check_box</span>
                                    <span>{t("kurs.quiz.correctAnswer")}</span>
                                </div>
            
                                <div className="data-panel__results-1__correct-anwsers__headlines-wrapper__mobile">
                                    <AnswerForQuestion answerType={AnswerType.USER} correctAnswer={getCorrectAnswer()} userAnswer={getUserAnswer()}/>

                                </div>
                            </div>
            
                            <div className="data-panel__results-1__correct-anwsers__anwsers-wrapper">
                                <div className="data-panel__results-1__correct-anwsers__anwsers-wrapper__anwser data-panel__results-1__correct-anwsers__anwsers-wrapper__anwser--left">
                                    <AnswerForQuestion answerType={AnswerType.CORRECT} correctAnswer={getCorrectAnswer()} userAnswer={getUserAnswer()}/>
                                </div>
                                <div className="data-panel__results-1__correct-anwsers__anwsers-wrapper__anwser data-panel__results-1__correct-anwsers__anwsers-wrapper__anwser--right">
                                    <AnswerForQuestion answerType={AnswerType.USER} correctAnswer={getCorrectAnswer()} userAnswer={getUserAnswer()}/>
                                </div>
                            </div>
                        </div>
            
                        <div className="data-panel__results-1__buttons">
                            <div 
                                className={ handleButtonClass("prev")} 
                                onClick={() => {
                                    const index = (props.showAllQuizes ? props.quizAllResultIndex : props.quizWrongResultIndex);
                                    if(index > 0) {
                                        props.showAllQuizes ? 
                                            props.setAllQuizResultIndex(props.quizAllResultIndex - 1) :
                                            props.setWrongQuizResultIndex(props.quizWrongResultIndex - 1);
                                    }
                                }}
                            >
                                <span className="material-symbols-outlined">arrow_back_ios</span>
                                <span>{t("kurs.quiz.previous")}</span>
                            </div>
        
                            <div onClick={redoQuiz} className="data-panel__results-1__buttons__button data-panel__results-1__buttons__button--center  data-panel__results-1--mobile-hidden">
                                <span className="material-symbols-outlined">replay</span>
                                <span>{t("kurs.quiz.repeatQuiz")}</span>
                            </div>
        
                            <div 
                                className={handleButtonClass("next")} 
                                onClick={() => {
                                    const index = (props.showAllQuizes ? props.quizAllResultIndex : props.quizWrongResultIndex);
                                    if(index < props.resultQuizesSet.length - 1) {
                                        props.showAllQuizes ? 
                                            props.setAllQuizResultIndex(props.quizAllResultIndex + 1) :
                                            props.setWrongQuizResultIndex(props.quizWrongResultIndex + 1);
                                    }
                                }}
                            >
                                <span>{t("kurs.quiz.next")}</span>
                                <span className="material-symbols-outlined">arrow_forward_ios</span>
                            </div>
                        </div>

                        <div onClick={redoQuiz} className="data-panel__results-1__repeat-button-mobile">
                            <div className="data-panel__results-1__repeat-button-mobile__button data-panel__results-1__buttons__button data-panel__results-1__buttons__button--center">
                                <span className="material-symbols-outlined">replay</span>
                                <span>{t("kurs.quiz.repeatQuiz")}</span>        
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}