import * as React from "react"

export const LeftIcons = () => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={1333.333}
    height={400}
    viewBox={"0 0 1300 400"}
  >
    <g
      style={{
        strokeWidth: 13.50000034,
        strokeDasharray: "none",
      }}
    >
      <path
        d="m0 0-60.947-59.896L0-120.842"
        style={{
          fill: "none",
          stroke: "#a29b9a",
          strokeWidth: 13.50000034,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="matrix(1.33333 0 0 -1.33333 882.327 115.936)"
      />
    </g>
    <g
      style={{
        strokeWidth: 13.50000034,
        strokeDasharray: "none",
      }}
    >
      <path
        d="m0 0 60.946-59.896L0-120.842"
        style={{
          fill: "none",
          stroke: "#a29b9a",
          strokeWidth: 13.50000034,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="matrix(1.33333 0 0 -1.33333 1185.422 115.936)"
      />
    </g>
    <g
      style={{
        strokeWidth: 13.50000034,
        strokeDasharray: "none",
      }}
    >
      <path
        d="m0 0 24.01-24.431L48.441 0"
        style={{
          fill: "none",
          stroke: "#a29b9a",
          strokeWidth: 13.50000034,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="matrix(1.33333 0 0 -1.33333 447.575 181.786)"
      />
    </g>
    <g
      style={{
        strokeWidth: 12.0000003,
        strokeDasharray: "none",
      }}
    >
      <path
        d="M0 0v-145.011"
        style={{
          fill: "none",
          stroke: "#a29b9a",
          strokeWidth: 12.0000003,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="matrix(1.33333 0 0 -1.33333 370.936 103.326)"
      />
    </g>
    <path
      d="M209.899 89.579H62.787v120.842h147.112z"
      style={{
        fill: "none",
        stroke: "#a29b9a",
        strokeWidth: 12.0000003,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      transform="matrix(1.33333 0 0 -1.33333 0 400)"
    />
    <path
      d="M0 0v-118.215"
      style={{
        fill: "none",
        stroke: "#a29b9a",
        strokeWidth: 8.144,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      transform="matrix(1.33333 0 0 -1.33333 152.369 121.19)"
    />
    <path
      d="M0 0h15.236"
      style={{
        fill: "none",
        stroke: "#a29b9a",
        strokeWidth: 8.144,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      transform="matrix(1.33333 0 0 -1.33333 104.732 161.47)"
    />
    <path
      d="M0 0h15.236"
      style={{
        fill: "none",
        stroke: "#a29b9a",
        strokeWidth: 8.144,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      transform="matrix(1.33333 0 0 -1.33333 104.732 183.887)"
    />
    <path
      d="M0 0h15.236"
      style={{
        fill: "none",
        stroke: "#a29b9a",
        strokeWidth: 8.144,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      transform="matrix(1.33333 0 0 -1.33333 104.732 206.305)"
    />
  </svg>
)
