import { useContext } from "react";

import { AuthContext } from "../auth/authContext/AuthContextProvider";
import { AlertBox } from "./AlertBox";

import './Alert.scss';


interface AlertProps { }

export const Alert = (props: AlertProps) => {
    const authContext = useContext(AuthContext);

    return (
        authContext.alertDisplayed ? <AlertBox/>: null       
    )
}