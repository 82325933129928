import { useTranslation } from "react-i18next";
import "./EmptyCoursePanel.scss";

export const EmptyCoursePanel = () => {
    const { i18n, t } = useTranslation();
    
    return (
        <div className="course-panel ad-panel">
            <div className="course-panel__main-name">
                <div className="course-panel__main-name__img">
                    <img
                        src={"https://na-sesje-public.s3.eu-central-1.amazonaws.com/course-icons/empty-course-wide.png"}
                        alt="kurs"
                    />
                </div>
                <div className="course-panel__main-name__text">
                    <span className="course-panel__main-name__text__element course-panel__main-name__text__element--first">{t("kurs.preview.goToShopPanel.browse")}</span>
                    <span className="course-panel__main-name__text__element course-panel__main-name__text__element--second">{t("kurs.preview.goToShopPanel.newCourses")}</span>
                </div>
            </div>

            <div className="ad-panel__courses-list">
                <span className="ad-panel__courses-list__headline">
                    {t("kurs.preview.goToShopPanel.coursesSelectedForYou")}:
                </span>
                <div className="ad-panel__courses-list__element">
                <div className="ad-panel__courses-list__element__dot" />
                <span className="ad-panel__courses-list__element__text">
                    {t("kurs.preview.goToShopPanel.selectedCourseNames.mathematicalAnalysis")}
                </span>
                </div>
                <div className="ad-panel__courses-list__element">
                <div className="ad-panel__courses-list__element__dot" />
                <span className="ad-panel__courses-list__element__text">
                {t("kurs.preview.goToShopPanel.selectedCourseNames.algebraAndGeometry")}
                </span>
                </div>
                <div className="ad-panel__courses-list__element">
                <div className="ad-panel__courses-list__element__dot" />
                <span className="ad-panel__courses-list__element__text">
                {t("kurs.preview.goToShopPanel.selectedCourseNames.formalLanguagesTheory")}
                </span>
                </div>
            </div>
            <div className="ad-panel__text">
                <span className="ad-panel__text__headline">
                    {t("kurs.preview.goToShopPanel.getToTheNextLevel")}
                </span>
                <span>
                    {t("kurs.preview.goToShopPanel.completeYourKnowledge")}.
                </span>
            </div>
            <div className="ad-panel__button-wrapper">
                <div className="ad-panel__button-wrapper__button">
                    <span>
                        {t("kurs.preview.goToShopPanel.seeTheFullOffer")}
                    </span>
                </div>
            </div>
            </div>

    );
}