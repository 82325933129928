import { t } from "i18next";

export const handleNotification = (displayGoodNotification: boolean, displayBadNotification: boolean) => {
    let message = "";
    if(displayGoodNotification) message = t("userPanel.settings.userNotification.goodNotification");
    else if(displayBadNotification) message = t("userPanel.settings.userNotification.badNotification");

    const classExtension = displayGoodNotification ? "good" : (displayBadNotification ? "bad" : "");

    const base = "data-panel__user-settings__button-wrapper__notification";
    const elementClass = `${base} ${base}--${classExtension}`;

    return (displayBadNotification || displayGoodNotification) ? (
        <div className={elementClass}>
            <span>{message}</span>
        </div> 
    )  : "";     
}