import "./courseIconChange.scss";

import { Profile01 } from "./profileIcons/Profile01";
import { Profile02 } from "./profileIcons/Profile02";
import { Profile03 } from "./profileIcons/Profile03";
import { Profile04 } from "./profileIcons/Profile04";
import { Profile05 } from "./profileIcons/Profile05";
import { Profile06 } from "./profileIcons/Profile06";
import { Profile07 } from "./profileIcons/Profile07";
import { Profile08 } from "./profileIcons/Profile08";

interface CourseIconChangeProps {
    handleIconChange: Function,
    handleActiveIcon: Function,
    activeIcon: number
}

interface IconProps {
    index: number,
    active: boolean,
    handleActiveIcon: Function
}

interface Icons {
    id: number,
    component: JSX.Element
}

export const Icon = (props: IconProps) => {
    const getClasses = () => {
        let classes = "chapter__data-space__course-icon-change__panel__icons__icon";
        if(props.active)
            classes += " " + classes + "--active";

        return classes;
    }

    const icons: Icons[] = [
        { id: 1, component: <Profile01/>},
        { id: 2, component: <Profile02/>},
        { id: 3, component: <Profile03/>},
        { id: 4, component: <Profile04/>},
        { id: 5, component: <Profile05/>},
        { id: 6, component: <Profile06/>},
        { id: 7, component: <Profile07/>},
        { id: 8, component: <Profile08/>},
    ];

    return (
        <div className={getClasses()} onClick={() => props.handleActiveIcon(props.index)}>
            { icons[props.index-1].component }
        </div>
    );
}

export const CourseIconChange = (props: CourseIconChangeProps) => {

    document.body.classList.add('body-not-scrollable');

    const handleIconsClosing = () => {
        document.body.classList.remove('body-not-scrollable');
        props.handleIconChange();
    }

    const getImages = (activeIcon: number) => {
        let images: JSX.Element[] = [];
        const max = 8;
        for(let i=1; i<= max; i++)
            images.push(<Icon key={i} active={(i===activeIcon)} index={i} handleActiveIcon={props.handleActiveIcon}/>);

        images.push(
            <div key={max+1} className="chapter__data-space__course-icon-change__panel__icons__escape" onClick={handleIconsClosing}>
                <span className="material-symbols-outlined">
                    arrow_forward_ios
                </span>
            </div>
        );
        return images;
    }

    return (
        <div className="chapter__data-space__course-icon-change">
            <div className="chapter__data-space__course-icon-change__overlay" onClick={handleIconsClosing}>
    
            </div>
            <div className="chapter__data-space__course-icon-change__panel">
                <div className="chapter__data-space__course-icon-change__panel__triangle"></div>
                <div className="chapter__data-space__course-icon-change__panel__icons">
                    {getImages(props.activeIcon)}
                </div>

            </div>
        </div>
    );
}