
import "./BlankCoursePanel.scss";

export const BlankCoursePanel = () => {
    
    return (
        <div className="course-panel course-panel__blank-panel">
           
            <div className="ad-panel__courses-list">
                <span className="course-panel__blank-panel__filler">
                    ____________________________________                
                </span>
            </div>
        </div>
    );
}