import { useEffect, useState } from "react";
import { SingleQuiz } from "../../../../../../../shared/quizesDto";
import { ExcerciseAnswers, MainTestDto } from "../../../../../../../shared/finalTest";
import { combineBlocks } from "../../../../shared/utilities/combiningBlocksOfTextIntoJsx/combiningBlocksOfTextIntoJsx";
import { QuizExcerciseAnswer } from "./QuizExcerciseAnswer";
import { QuizQuestion } from "../../../additions/quiz/quizTest/quizQuestion/QuizQuestion";
import { useTranslation } from "react-i18next";
import { QuizExcerciseResults } from "./QuizExcerciseResults";
import { AnswerElement, QuizMobileNavigation } from "../../../additions/quiz/quizTest/QuizTest";

interface QuizExcerciseProps {
    excerciseData: SingleQuiz;
    revealAnswer: boolean;
    modifyIsFinished: (index: number, finished: boolean) => void;
    currentExcercise: number;
    modifyExerciseAnswers: (index: number, newAnswers: ExcerciseAnswers) => void;
    userAnswers: ExcerciseAnswers;
    testDto: MainTestDto;
    notifyIfClicked: number;
}

export const QuizExcercise = (props: QuizExcerciseProps) => {
    const firstLetter = "A";
    const [message, setMessage] = useState("");
    const { t } = useTranslation();
    const mobilePanelsMaxIndex = 3;

    const [activeMobileIndex, setActiveMobileIndex] = useState(0);
    const [firstMobileClicked, setFirstMobileClicked] = useState(false);

    //
    useEffect(() => {
            const currentExcercise = props.testDto.excercises[props.currentExcercise];
            if(currentExcercise.excerciseAnswers){
                if(currentExcercise.excerciseAnswers.length > 0){
                    if(currentExcercise.excerciseAnswers[0] === props.excerciseData.correctAnswerId){
                        setMessage("good answer")
                    } else {
                        setMessage("bad answer")
                    }
                } else {
                    setMessage("")
                }
            } else {
                setMessage("")
            }
    }, [props.testDto.excercises[props.currentExcercise].excerciseAnswers])

    useEffect(() => {
        const excercise = props.testDto.excercises[props.currentExcercise].excerciseAnswers;
        if(excercise){
            if(excercise.length > 0){
                props.modifyIsFinished(props.currentExcercise, true);
            }
        }
    }, [props.testDto.excercises[props.currentExcercise].excerciseAnswers])

    if(props.revealAnswer && props.userAnswers && props.userAnswers.length > 0 && props.userAnswers[0] !== undefined){

        const index = parseInt(props.userAnswers[0].toString()) - 1;
        const userAnswer = props.excerciseData.answers[index];
        return <QuizExcerciseResults text={props.excerciseData.text} correctAnswer={props.excerciseData.answers[props.excerciseData.correctAnswerId - 1]} userAnswer={userAnswer}/>
    }
    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            <QuizQuestion text={props.excerciseData.text} isMainTest={true}/>
            <div className="data-panel__quiz__command mainTest">
                <span>{t("kurs.quiz.wybierzPoprawnaOdpowiedz")}</span>
            </div>
            <div className={`data-panel__quiz__anwsers ${props.excerciseData.answers.length <= 3 ? 'main-test' : ''}`}>
                {props.excerciseData.answers.map((e, index) => {
                    return <QuizExcerciseAnswer
                                currentExcercise={props.currentExcercise}
                                label={String.fromCharCode(firstLetter.charCodeAt(0) + index)}
                                src={e.imageSrc}
                                id={e.id}
                                correctQuestionId={props.excerciseData.correctAnswerId}
                                key={index}
                                modifyExerciseAnswers={props.modifyExerciseAnswers}
                                modifyIsFinished={props.modifyIsFinished}
                                testDto={props.testDto}
                                srcIsImage={e.srcIsImage}
                                activeMobileIndex={activeMobileIndex}
                                setActiveMobileIndex={setActiveMobileIndex}
                                currentIndex={index}
                                />
                })}
            </div>
            
            <QuizMobileNavigation
                      activeMobileIndex={activeMobileIndex}
                      setActiveMobileIndex={setActiveMobileIndex}
                      firstMobileClicked={firstMobileClicked}
                      setFirstMobileClicked={setFirstMobileClicked}
                      numberOfAnswers={props.excerciseData.answers.length}
                  />
        </div>
    )
}