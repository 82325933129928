
export const Email = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
  >
    <path
      stroke="#2F848F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M2 4.667c0-.737.597-1.333 1.333-1.333h9.334c.736 0 1.333.596 1.333 1.333v6.667c0 .736-.597 1.333-1.333 1.333H3.333A1.333 1.333 0 0 1 2 11.334V4.667Z"
    />
    <path
      stroke="#2F848F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m14 4.667-6 4-6-4"
    />
  </svg>
)
