
export const DownLeftRayTeam = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={705}
    height={505}
    fill="none"
    className="down-left-ray-team"

  >
    <g opacity={0.3}>
      <g fill="#0E0C2D" opacity={0.16}>
        <path d="M72.781 42.12H24.717l-.1-.025-7.031-12.197h61.99L92.21 51.212l-7.006 12.244L72.781 42.12Z" />
        <path d="m65.077 113.439-15.521 26.883L31.619 109.9H0l37.248-64.546.161-.366h33.72l12.426 21.344-6.848 11.971 70.165 123.79h31.063l15.578 27.069-31.411 54.403-97.029-170.126h.004Z" />
      </g>
      <path
        fill="#0E0C2D"
        d="M131.358 142.562h24.021l30.601 53.633h-30.353L89.431 80.103l2.908-5.038 39.019 67.497ZM257.302 211.063h-44.188L98.115 9.196H66.446L61.004.158h45.784L217.66 196.195h31.056l8.586 14.868Z"
        opacity={0.16}
      />
      <g fill="#0E0C2D" opacity={0.16}>
        <path d="m248.769 319.845-10.741 18.604-34.885-59.685 10.723-18.579 34.903 59.66Z" />
        <path d="m292.374 380.045-20.218-36.351h-31.063l-1.402-2.395 12.391-21.465-36.093-61.7c-.151-.279-.305-.559-.455-.838 1.183-2.048 2.362-4.095 3.545-6.142l-8.938-15.266h25.09l104.293 183.14h-68.838l21.684-38.986.004.003Z" />
      </g>
      <g fill="#0E0C2D" opacity={0.16}>
        <path d="m51.392 354.267-33.516-59.19 13.71-23.846 33.853 58.706-14.047 24.33Z" />
        <path d="M170.083 399.922c-17.116 29.557-34.229 59.118-51.345 88.675L.907 283.737l19.34-32.175 9.683 16.794-15.36 26.707 36.797 64.98 15.725-27.238 52.392 90.855 13.871-23.742h36.728v.004Z" />
      </g>
      <path
        fill="#0E0C2D"
        d="M290.807 457.667h22.057l15.643 26.502H173.64l15.244-26.502h52.31l9.77 15.055h30.575l9.268-15.055Z"
        opacity={0.16}
      />
      <g fill="#0E0C2D" opacity={0.16}>
        <path d="m326.814 358.688 63.492 110.176h-51.779l-15.972-27.858H178.817l-24.922 43.163h-20.733l46.103-83.222h92.07l-15.338 27.127H354.4l-44.053-78.504h88.302l5.263 9.118h-77.098Z" />
        <path d="M170.33 484.169h-13.122l23.265-40.295h140.418l15.976 27.858h58.401L331.78 361.556h73.789L476.4 484.169H331.837l-17.335-29.37h-25.298l-9.268 15.054H252.52l-9.767-15.054h-55.525l-16.898 29.37Z" />
      </g>
      <g fill="#0E0C2D" opacity={0.16}>
        <path d="M636.247 467.498H531.936l-61.147-108.814h-57.178l-11.753-20.379h-98.352l-63.75-107.566h-30.765l-51.948-91.049h-24.032L93.996 72.194 105.25 52.7 88.525 24.401h-70.62L4.288 0c2.578.054 5.156.104 7.733.158H57.66l7.167 11.907h31.626L211.45 213.93h47.512l56.48 97.825h177.203l77.754 134.323h100.346L704.773 505h-46.946l-21.577-37.499-.003-.003Z" />
        <path d="M415.267 361.556h53.844l61.147 108.814h104.329L654.51 505h-54.783c-4.127-6.751-8.253-13.499-12.384-20.25-33.404-.129-66.812-.261-100.216-.391l-71.864-122.803h.004Z" />
      </g>
      <path
        fill="#0E0C2D"
        d="M281.593 375.341h-62.589l-18.217-31.02h-27.868l-28.722 48.864 127.672.843 9.724-18.687Zm-82.243-28.51 18.217 31.02h59.888l-7.106 13.656-121.785-.806 25.789-43.874h24.997v.004ZM53.74 450.324l-28.765-49.85H.907v2.51h22.62l25.867 44.831H34.541l-22.946-39.722H2.567v2.51h7.58l22.945 39.721H53.74ZM22.143 454.196 5.94 426.238l-2.17 1.259 15.493 26.735-16.331 29.855 2.201 1.205 17.009-31.096ZM10.663 221.352l19.392-.168 14.517-24.212-13.642-24.523H11.018l-2.6-4.514 1.768-2.685 2.492 4.331h19.938l15.27 27.445-16.2 27.012-20.998.183-.025-2.869ZM158.133 337.871l-60.77-105.694-79.494-.001 18.339 31.146-2.43 4.213c-.55-.961-1.098-1.926-1.647-2.886l.757-1.313-20.035-34.028h86.17l60.767 105.684-1.653 2.879h-.004ZM102.848 478.583l-79.63-137.761-19.565 34.003-2.172-1.251 21.734-37.779 81.081 140.272-1.448 2.516Z"
        opacity={0.16}
      />
      <g fill="#0E0C2D" opacity={0.16}>
        <path d="m110.821 487.281 1.449 2.51H8.816l19.31-33.379h37.908l-35.936-62.391H2.567v-2.51h28.98l38.828 67.411h-40.8l-16.406 28.359h97.652ZM113.403 489.709v.082h-.054l.054-.082Z" />
      </g>
      <g fill="#0E0C2D" opacity={0.16}>
        <path d="m65.392 330.235-.093-.054.028-.05.065.104ZM168.896 338.703l-27.553 47.369-9.96-17.249-6.267 10.34-31.217-54.661H109.9l-19.038-32.859-22.135 38.341c-.55-.961-1.098-1.925-1.646-2.886l23.778-41.185 24.017 41.457H98.839l26.349 46.139 6.252-10.318 9.91 17.163 25.897-44.519h38.445l17.012 29.163 13.743-23.806 1.656 2.868-15.384 26.649-18.673-32.009H168.9l-.004.003Z" />
      </g>
      <path
        fill="#0E0C2D"
        d="m61.627 132.595-15.814 25.829-19.69-33.394-15.633 27.22c-.548-.965-1.097-1.929-1.645-2.89l17.245-30.034 19.77 33.53 15.832-25.858 94.727 163.085-1.649 2.868-93.143-160.356Z"
        opacity={0.16}
      />
      <path
        fill="#0E0C2D"
        d="m213.433 254.187-1.65 2.865-9.899-16.93-45.246 80.21-55.16-95.534H41.593l15.585-26.951-18.826-33.35H17.08L13 157.95l1.736-2.635 3.936 6.314h21.354l20.454 36.236-13.914 24.065h56.569l53.471 92.615 45.232-80.181 11.595 19.823Z"
        opacity={0.16}
      />
    </g>
  </svg>
)
