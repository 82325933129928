export const DownLeftRay = () => (
  <svg
    className="down-left-ray"
    xmlns="http://www.w3.org/2000/svg"
    width={451}
    height={649}
    fill="none"
  >
    <g filter="url(#a)" opacity={0.75}>
      <g fill="#57E3E6" opacity={0.16}>
        <path d="M430.908 612.915v46.614l.025.097 11.829 6.819v-60.119l-20.672-12.253-11.874 6.794 20.692 12.048Z" />
        <path d="m361.742 620.387-26.071 15.053 29.503 17.396V683.5l62.598-36.123.355-.157v-32.702l-20.699-12.051-11.61 6.641-120.053-68.047v-30.125l-26.252-15.108-52.762 30.463 164.991 94.1v-.004Z" />
      </g>
      <path
        fill="#57E3E6"
        d="M333.498 556.107V532.81l-52.014-29.677v29.437l112.588 64.198 4.885-2.82-65.459-37.841ZM267.065 433.964v42.855l195.773 111.527v30.713l8.766 5.278v-44.402L281.484 472.41v-30.119l-14.419-8.327Z"
        opacity={0.16}
      />
      <g fill="#57E3E6" opacity={0.16}>
        <path d="m161.567 442.239-18.043 10.418 57.883 33.832 18.018-10.4-57.858-33.85Z" />
        <path d="m103.183 399.951 35.254 19.607v30.126l2.323 1.359 20.817-12.017 59.837 35.004c.272.146.543.296.814.442 1.985-1.148 3.971-2.292 5.956-3.439l14.806 8.668v-24.332L65.377 354.224v66.76l37.81-21.03-.004-.003Z" />
      </g>
      <g fill="#57E3E6" opacity={0.16}>
        <path d="m128.183 633.66 57.404 32.503 23.126-13.296-56.934-32.831-23.596 13.624Z" />
        <path d="M83.906 518.551c-28.665 16.599-57.333 33.195-85.998 49.795L196.585 682.62l31.203-18.755-16.287-9.392-25.901 14.896-63.018-35.685 26.415-15.251-88.112-50.81L83.91 554.17v-35.619h-.004Z" />
      </g>
      <path
        fill="#57E3E6"
        d="M27.904 401.47v-21.391l-25.702-15.17v150.192l25.702-14.784v-50.731l-14.6-9.475v-29.652l14.6-8.989Z"
        opacity={0.16}
      />
      <g fill="#57E3E6" opacity={0.16}>
        <path d="m123.896 366.55-106.85-61.575v50.216l27.017 15.49v139.4L2.202 534.25v20.108l80.71-44.712v-89.291L56.604 435.23v-95.432l76.134 42.723v-85.637l-8.842-5.105v74.771Z" />
        <path d="M2.202 518.311v12.726l39.079-22.563v-136.18L14.264 356.8v-56.638l106.85 61.572v-71.561L2.202 221.479v140.2l28.484 16.811v24.535l-14.6 8.988v26.589l14.6 9.472v53.849L2.202 518.311Z" />
      </g>
      <g fill="#57E3E6" opacity={0.16}>
        <path d="M18.37 66.457V167.62l105.53 59.301v55.453l19.763 11.397v95.384l104.32 61.826v29.836l88.3 50.38v23.307l65.46 37.837 18.905-10.914 27.444 16.22v68.488l23.665 13.206-.153-7.5v-44.259l-11.547-6.951v-30.672L264.283 478.432v-46.078l-94.872-54.774V205.725L39.142 130.317V33.001L-18 0v45.529l36.367 20.925.003.003Z" />
        <path d="M121.114 280.767v-52.219L15.585 169.247V68.067L-18 48.745v53.13c6.547 4.002 13.091 8.004 19.639 12.01.125 32.396.253 64.795.379 97.191l119.096 69.695v-.004Z" />
      </g>
      <path
        fill="#57E3E6"
        d="M107.745 410.407v60.699l30.084 17.667V515.8l-47.39 27.855-.816-123.819 18.122-9.429Zm27.65 79.76L105.311 472.5v-58.081l-13.244 6.892.782 118.109 42.549-25.01v-24.243h-.003ZM35.026 631.382l48.345 27.896v23.342h-2.434v-21.937l-43.478-25.087v14.406l38.523 22.253v8.755h-2.434v-7.35l-38.523-22.254v-20.024ZM31.27 662.025l27.114 15.713-1.22 2.104-25.929-15.024-28.953 15.838-1.169-2.135 30.157-16.496ZM257.086 673.159l.163-18.807 23.481-14.079 23.783 13.23v19.312l4.378 2.521 2.604-1.715-4.2-2.416v-19.336l-26.617-14.809-26.197 15.709-.177 20.366 2.782.024ZM144.084 530.14l102.504 58.936v77.094l-30.205-17.785-4.086 2.357 2.799 1.596 1.273-.733 33.001 19.43v-83.569l-102.494-58.933-2.792 1.603v.004ZM7.62 583.756l133.602 77.226-32.976 18.975 1.213 2.107 36.638-21.078L10.06 582.352l-2.44 1.404Z"
        opacity={0.16}
      />
      <path
        fill="#57E3E6"
        d="m-.816 576.023-2.434-1.404V674.95l32.371-18.728v-36.763L89.63 654.31v26.7h2.434v-28.105l-65.376-37.657v39.57L-.816 670.729v-94.706Z"
        opacity={0.16}
      />
      <g fill="#57E3E6" opacity={0.16}>
        <path d="m151.49 620.082.052.089.048-.027-.1-.062ZM143.277 519.702l-45.939 26.721 16.728 9.659-10.027 6.078 53.011 30.275v-15.518l31.868 18.463-37.184 21.468 2.799 1.596 39.941-23.06-40.206-23.293v15.553l-44.746-25.553 10.007-6.064-16.645-9.611 43.175-25.115v-37.285l-28.283-16.498 23.088-13.328-2.781-1.606-25.846 14.92 31.044 18.108v34.086l-.004.004Z" />
      </g>
      <path
        fill="#57E3E6"
        d="m343.164 623.732-25.049 15.338 32.386 19.096-26.398 15.16c.935.532 1.871 1.064 2.802 1.596l29.128-16.725-32.518-19.172 25.077-15.355-158.162-91.868-2.782 1.599 155.516 90.331Z"
        opacity={0.16}
      />
      <path
        fill="#57E3E6"
        d="m225.243 476.509-2.779 1.6 16.419 9.6-77.789 43.881 92.65 53.495v58.077l26.137-15.114 32.344 18.258v20.629l6.349 3.957 2.556-1.683-6.123-3.818v-20.709l-35.143-19.837-23.338 13.495v-54.862l-89.82-51.857 77.761-43.867-19.224-11.245Z"
        opacity={0.16}
      />
    </g>
    <defs>
      <filter
        id="a"
        width={497.757}
        height={691.5}
        x={-22}
        y={0}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_85_62" />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_85_62"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)

