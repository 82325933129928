import { Dispatch, SetStateAction, useEffect, useLayoutEffect, useRef, useState } from "react";
import "./searchPanel.scss";
import { t } from "i18next";
import { useTranslation } from "react-i18next";


interface SearchPanelProps {
    searchVal: string;
    handleChange: Function;
    handleClick: Function;
    setHighlightColor: Dispatch<SetStateAction<string>>;
}

export const SearchPanel = (props: SearchPanelProps) => {
    const { t } = useTranslation();
    
    const buttonRef = useRef<HTMLButtonElement>(null);
    
    useEffect(() => {
        if (buttonRef.current) {
          const style = window.getComputedStyle(buttonRef.current);
          const highlightColor = style.getPropertyValue('background-color');

          props.setHighlightColor(highlightColor);
        }
      }, []);

    const getText = (text: string | undefined | null) => {
        if(text === undefined) return "undefined"
        else if(text === null) return "null"
        else return text;
    }

    return (
        <div className="FAQ-section__filter">
        <span className="material-symbols-outlined">
            search
        </span>
        <input placeholder={getText(t("FAQ.insertWord"))} type="text" value={props.searchVal} onChange={(event) => {props.handleChange(event)}}></input>
            <button ref={buttonRef} onClick={(event) => props.handleClick(event)}>{t("FAQ.reset")}</button>
        </div>
    )
}
