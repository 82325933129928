
import { Dispatch, SetStateAction, useState } from "react";
import "./displayedCourseContents.scss";

interface ChapterPanelProps {
    chapterName: string;
    chpaterIndex: number;
    activeChapterIndex: number;
    setActiveChapterIndex: Dispatch<SetStateAction<number>>; 
    lessons: string[]
}

const ChapterPanel = (props: ChapterPanelProps) => {

    const isActive = (): boolean => {
        return (props.chpaterIndex === props.activeChapterIndex);
    }

    const handleChapterClass = () => {
        const base = "course-preview__main-section__left-panel__content-section__panel";
        let finalClass = `not-selectable ${base} ${base}--${isActive()? "active" : "inactive"}`;
        
        return finalClass;
    }

    const handleClick = () => {
        if(!isActive()) { 
            props.setActiveChapterIndex(props.chpaterIndex);
        }
    }

    const alphabet = 'abcdefghijklmnopqrstuvwxyz';

    return (
        <div onClick={handleClick} className={handleChapterClass()}>
            <div className="course-preview__main-section__left-panel__content-section__panel__chapter-row">
                <div className="course-preview__main-section__left-panel__content-section__panel__chapter-row__chapter-name">
                    <div>
                        <span>{props.chpaterIndex+1}.&nbsp;</span>
                    </div>
                    <div>
                        <span>{props.chapterName}</span>
                    </div>
                </div>

                <span className="material-symbols-outlined">
                    {
                        isActive() ? "expand_less" : "expand_more"
                    }
                </span>
            </div>

            {
                isActive() ? (
                    <div className="course-preview__main-section__left-panel__content-section__panel__lessons">
                        {
                            props.lessons.map((el, index) => (
                                <div className="course-preview__main-section__left-panel__content-section__panel__lessons__lesson">
                                    <span>{`${alphabet[index]}) ${el}`}</span>
                                </div>
                            ))
                        }
                    </div>
                ) : ""
            }

        </div>
    );
} 


interface DisplayedCourseContentsProps {
    courseContent: {
        chapterName: string;
        lessons: string[];
    }[]
}

export const DisplayedCourseContents = (props: DisplayedCourseContentsProps) => {
    
    const [activeChapterIndex, setActiveChapterIndex] = useState(0);


    return (
        <div className="course-preview__main-section__left-panel__content-section">
            {
                props.courseContent.map((el, index) => 
                    <ChapterPanel 
                        key={index}
                        chpaterIndex={index}
                        chapterName={el.chapterName}
                        activeChapterIndex={activeChapterIndex}
                        setActiveChapterIndex={setActiveChapterIndex}
                        lessons={el.lessons}
                    />)
            }
        </div>
    )
}