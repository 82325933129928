export const Instagram = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
  >
    <path
      fill="#2F848F"
      fillRule="evenodd"
      d="M2 8c0-1.864 0-2.796.304-3.53A4 4 0 0 1 4.47 2.303C5.204 2 6.136 2 8 2s2.796 0 3.53.304a4 4 0 0 1 2.165 2.165C14 5.204 14 6.136 14 8s0 2.796-.305 3.53a4 4 0 0 1-2.164 2.165C10.796 14 9.864 14 8 14s-2.796 0-3.53-.305a4 4 0 0 1-2.166-2.164C2 10.796 2 9.864 2 8Zm10-3a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM8.667 8.667a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0Zm2 0a2.667 2.667 0 1 1-5.334 0 2.667 2.667 0 0 1 5.334 0Z"
      clipRule="evenodd"
    />
  </svg>
)

