import { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { OfferPoint } from "./offerPoint";

import { CubeGraphicDark } from "./CubeGraphicDark";
import { CubeGraphicLight } from "./CubeGraphicLight";

import "./videoOffer.scss"

import "../../../../../landing/Checkout/checkout.scss";

import { isLiteralTypeNode } from "typescript";
import { Elements } from "@stripe/react-stripe-js";

import { StripePaymentPanel } from "../../../../../landing/Checkout/StripePaymentPanel.tsx/StripePaymentPanel";
import { OrderType, PaymentIntent } from "shared/paymentIntent";
import { get, post } from "../../../../../utilities/rest/apiClient";
import { AuthContext } from "../../../../auth/authContext/AuthContextProvider";
import { stripePromise, options } from "../../../../../paymentComponents/paymentCompontents";
import { StripeElementsOptions } from "@stripe/stripe-js";
import { emailSettingsDto } from "shared/userSettingsDto";
import { CoursePriceDto } from "shared/coursePriceDto";



interface VideoOfferProps {
    handleOpeningVideoSolution: Function
}



export const VideoOffer = (props: VideoOfferProps) => {
    const { t } = useTranslation();

    const authContext = useContext(AuthContext);
    const [isHovered, setIsHovered] = useState(false);

    const [email, setEmail] = useState("");

    const [recordingPrice, setRecordingPrice] = useState(-1);

    const [clientSecret, setClientSecret] = useState("");
    const [paymentPrepError, setPaymentPrepError] = useState(false);

    const [buyNowClicked, setBuyNowClicked] = useState(true);


    const { courseId } = useParams();

    const offerPointsDesktop = [
        t("kurs.assignmentOffer.desktop.el1"),
        t("kurs.assignmentOffer.desktop.el2"),
        t("kurs.assignmentOffer.desktop.el3"),
        t("kurs.assignmentOffer.desktop.el4"),
    ];

    const offerPointsMobile = [
        t("kurs.assignmentOffer.mobile.el1"),
        t("kurs.assignmentOffer.mobile.el2"),
        t("kurs.assignmentOffer.mobile.el3"),
        t("kurs.assignmentOffer.mobile.el4"),
    ]; 

    const updatedOptions: StripeElementsOptions = {
        ...options, 
        clientSecret: clientSecret,
        mode: undefined
    };


    const sendPaymentIntent = async () => {

        if (courseId === null || courseId === undefined) {
            return; 
        }

        let courseID = courseId;
        const orderId = [courseID];
        let orderType: OrderType[] = ['explanation'];

        const data: PaymentIntent = {
            orderId,
            orderType
        };

        try{
            const emailData = await get<emailSettingsDto>("users/emailSettings");
            setEmail(emailData.email);
        } catch (error) {
            authContext.handleAuthErrors(error);
        }

        try{
            const pricing = await get<CoursePriceDto>(`reactRoutes/getCoursePaymentData/${orderId}`);
        
            setRecordingPrice(pricing.recordingsPrice);
 
            const intent = await post<any, PaymentIntent>("payment/create-payment-intent", data);
            setClientSecret(intent.clientSecret);
        } catch (error) {
            setPaymentPrepError(true);
        }

    }

    useEffect(() => {
        if(!buyNowClicked) {
            props.handleOpeningVideoSolution();
        }
    }, [buyNowClicked]); 

    return (

        <>
            <div className="page-overlay" onClick={() => props.handleOpeningVideoSolution()}/>
            {
                (clientSecret && (recordingPrice !== -1) && (!paymentPrepError)) ? (
                    <div className="checkout" style={(clientSecret) ? {height: 'auto', maxHeight: '650px'} : {height: '566px', maxHeight: '566px'}}>
                        <Elements options={updatedOptions} stripe={stripePromise}>
                            <StripePaymentPanel email={email} price={recordingPrice} setBuyNowClicked={setBuyNowClicked} purchaseType="explanation" courseID={courseId as string}/>
                        </Elements>
                    </div>
                ) : (
                    <div className="open-test-panel">

                        <div className="open-test-panel__offer">

                            <div className="open-test-panel__offer__preview">

                                <div className="popup-close-button popup-close-button--mobile" onClick={() => props.handleOpeningVideoSolution()}/>
                                
                                <div className="open-test-panel__offer__preview__headline">
                                    <span>
                                        {t("kurs.assignmentOffer.doYouNeedBetterExplanation")}
                                    </span>
                                </div>

                                <div className="open-test-panel__offer__preview__text">
                                    <span className="open-test-panel__offer__preview__text__headline">
                                        {t("kurs.assignmentOffer.weHaveASpecialOfferForYou")}
                                    </span>

                                    <br></br>

                                    <div className="open-test-panel__offer__preview__text__description open-test-panel__offer__preview__text__description--desktop">
                                        {
                                            offerPointsDesktop.map(
                                                (el, index) => <OfferPoint key={index} text={el}/>
                                            )
                                        }
                                    </div>

                                    <div className="open-test-panel__offer__preview__text__description open-test-panel__offer__preview__text__description--mobile">
                                        {
                                            offerPointsMobile.map(
                                                (el, index) => <OfferPoint key={index} text={el}/>
                                            )
                                        }
                                    </div>          

                                </div>
                            </div>

                            <div className="open-test-panel__offer__button"
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                                data-testid="offer-button"
                                onClick={() => sendPaymentIntent()}
                            >
                                <span className="material-symbols-outlined">
                                    lock
                                </span>
                                <span className="open-test-panel__offer__button__text"></span>
                            </div>

                        </div>

                        <div className="open-test-panel__graphic">
                            <div className="popup-close-button popup-close-button--desktop" onClick={() => props.handleOpeningVideoSolution()}/>
                            
                            <CubeGraphicLight isHovered={isHovered}/>
                            <CubeGraphicDark/> 
                        </div>
                    </div>
                )
            }
        </>
    );
}