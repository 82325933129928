import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { post } from "../../../utilities/rest/apiClient";

import { Logo } from "../../navbar/logo/logo";

import "../login/login.scss";

import { useTranslation } from "react-i18next";

interface ForgotPassword {
    email: string
}

export const SendResetEmail = () => {
    const { t } = useTranslation();

    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
 
    const [displayMessage, setDisplayMessage] = useState(false);
    const [isGoodMessage, setIsGoodMessage] = useState(false);
  
    const handleUsernameChange = (e: ChangeEvent<HTMLInputElement>) => {
      const new_email = e.target.value;
      setEmail(new_email);

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setValidEmail(emailRegex.test(new_email) && (new_email.length !== 0));
    };
  

    const getEmailFieldState = () => {
        if(validEmail)  return "input--valid"
        else if( (!validEmail) && (email.length !== 0) ) return "input--invalid"
        else return "input--default";
    }

    const handleForgotPassword = (e: FormEvent) => {
        e.preventDefault();

        if(validEmail) {

            const data: ForgotPassword = { email: email };

            post<ForgotPassword, any>(`users/forgotPassword`, data)
            .then(() => {
                setIsGoodMessage(true);
            })
            .catch((error) => {
                setIsGoodMessage(false);
            });

            setTimeout(() => {
                setDisplayMessage(true);
            }, 200);

            setTimeout(() => {
                setDisplayMessage(false);
            }, 9000);

            setEmail("");
            setValidEmail(false);
        }
    };

    const handleSubmitButtonStyle = () => {
        let buttonClass = "button button--desktop-full-width button";
        buttonClass += validEmail ? "--active" : "--inactive";

        return buttonClass;
    }

    const handleDisplayedCredentials = () => {
        const message = t(`sendResetEmail.${isGoodMessage? "emailSent" : "errorOccured"}`);
        return (
            <div className="sendResetEmail-panel__form__forgot-password__invalid-credentials"> {
                displayMessage ?  <span className={isGoodMessage ? "valid" : ""}>{message}</span> : <a>{t("sendResetEmail.ifEmailIsCorrect")}</a>
            }</div>
        );
    }
    
    return (
        <div className="sendResetEmail-panel">
            <div className="sendResetEmail-panel__form">
                <Logo/>
                <div className="signUp-panel__form__headline">
                        <span>{t("resetPassowrd.resetYourPassword")}</span>
                    </div>
                    <div className="signUp-panel__form__sign-up">
                        <span>{t("resetPassowrd.regainYourAccountAccess")} </span>
                </div>

                <form onSubmit={handleForgotPassword}>
                    <div className="sendResetEmail-panel__form__email-input-section">
                        <label htmlFor="username">{t("login.emailAddress")}</label>
                        <input
                        className={getEmailFieldState()}
                        type="text"
                        id="username"
                        value={email}
                        onChange={handleUsernameChange}
                        />
                        
                        { validEmail ? <span className="material-symbols-outlined material-symbols-outlined--valid"> done </span> : ""}    
                        { ((!validEmail) && (email.length !== 0)) ? <span className="material-symbols-outlined material-symbols-outlined--invalid">warning</span>: ""}
                    </div>

                    <div className="sendResetEmail-panel__form__buttons">
                        <button type="submit" className={ handleSubmitButtonStyle()}>{t("resetPassowrd.resetPassowrd")}</button>
                    </div>
                </form>

                <div className="sendResetEmail-panel__form__forgot-password">
                    { handleDisplayedCredentials() }
                </div>

            </div>

            <div className="sendResetEmail-panel__svg-container login-panel__svg-container">
                <img src="https://na-sesje-public.s3.eu-central-1.amazonaws.com/signupGraphic.png" alt="" />
            </div>
        </div>
    );
}