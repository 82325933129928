import React, { ReactNode, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ChapterData } from "../../../../../shared/courseMainDto";
import { MainDto } from "../../../../../shared/mainDto";
import { AuthContext } from "../../auth/authContext/AuthContextProvider";
import { GlobalDataContext } from "../../global/globalDataContext/GlobalDataContextProvider";
import { LastVisitedDto } from "../../../../../shared/lastVisitedDto";
import i18n from "../../../i18n";
import { get } from "../../../utilities/rest/apiClient";
import { NotFound } from "../../../utilities/rest/errors";
import { WideMenu } from "../../shared/components/wideMenu/WideMenu";
import { ContentPanelWrapper } from "../../shared/components/wrappers/contentPanelWrapper/ContentPanelWrapper";
import { DataPanelWrapper } from "../../shared/components/wrappers/dataPanelWrapper/DataPanelWrapper";
import { MainPath } from "./mainPath/MainPath";
import { TitleHeader } from "../../shared/components/titleHeader/TitleHeader";
import { getContentFallbackWithRegardsToUrl, MainNavigationFallbackWithRegardsToUrl } from "../../shared/components/fallbacks/fallbackUtils";

interface MainContextProviderProps {
    children: ReactNode;
}

type ActiveMainComponent = 'course' | 'notes' | 'assignment' | 'quiz' | 'mainTest';

interface MainContextProps {
    path: string[];
    setPath: React.Dispatch<React.SetStateAction<string[]>>;
    activeComponent: ActiveMainComponent;
    setActiveComponent: React.Dispatch<React.SetStateAction<ActiveMainComponent>>;
    active: ActiveChapterAndLesson;
    setActive: React.Dispatch<React.SetStateAction<ActiveChapterAndLesson>>;
    mainDto: MainDto;
    isLoading: boolean;
    handleNext: () => void;
    handlePrevious: () => void;
    currentlyOpenedChapterDropdown: number;
    setCurrentlyOpenedChapterDropdown: React.Dispatch<React.SetStateAction<number>>;
    chapterData: ChapterData[];
    setChapterData: React.Dispatch<React.SetStateAction<ChapterData[]>>;
    finishedFinalTest: boolean;
    setFinishedFinalTest: React.Dispatch<React.SetStateAction<boolean>>
}

export interface ActiveChapterAndLesson {
    chapterIndex: number;
    lessonIndex: number;
}

export const MainContext = React.createContext<MainContextProps>({} as MainContextProps);

export const MainContextProvider = (props: MainContextProviderProps) => {
    const [path, setPath] = useState<string[]>([]);
    const [activeComponent, setActiveComponent] = useState<ActiveMainComponent>('course');

    //navigation
    const [active, setActive] = useState<ActiveChapterAndLesson>({chapterIndex: 0, lessonIndex: 0});
    const globalCtx = useContext(GlobalDataContext);
    const [mainDto, setMainDto] = useState<MainDto>({} as MainDto);
    const [isLoading, setIsLoading] = useState(true);
    const [currentlyOpenedChapterDropdown, setCurrentlyOpenedChapterDropdown] = useState(0); //TODO to powinien być lastVisitedChapter
    const [chapterData, setChapterData] = useState<ChapterData[]>([]);
    const { courseId } = useParams();
    const authContext = useContext(AuthContext);

    const [finishedFinalTest, setFinishedFinalTest] = useState(false);

    const handlePathNameChange = (newActive: ActiveChapterAndLesson) => {
        setPath([path[0], path[1], mainDto.chapters[newActive.chapterIndex].lessons[newActive.lessonIndex].title]);
      }
  
      const isFirstLesson = () => {
          return active.lessonIndex === 0;
        }
    
        const isLastLesson = () => {
          const lastLessonIndex = mainDto.chapters[active.chapterIndex].lessons.length-1;
          if(active.lessonIndex === lastLessonIndex) {
            return true;
          }
          return false;
        }
    
        const isFirstLessonGlobally = () => {
          return active.chapterIndex === 0 && active.lessonIndex === 0;
        }
    
        const isFirstChapter = () => {
          return active.chapterIndex === 0;
        }
    
        const isLastChapter = () => {
          const chapterIndex = active.chapterIndex;
          return chapterIndex === mainDto.chapters.length-1;
        }
    
        const handleNext = () => {
          if(isLastLesson() && isLastChapter()) return;
          
          let newActive: ActiveChapterAndLesson;
  
          const chapterIndex = active.chapterIndex;
          if(isLastLesson() && !isLastChapter()){
            newActive = {chapterIndex: chapterIndex + 1, lessonIndex: 0};
            setActive(newActive);
          } else {
            newActive = {chapterIndex: chapterIndex, lessonIndex: active.lessonIndex + 1};
            setActive(newActive);
          }
  
          handlePathNameChange(newActive);
        }
    
        const handlePrevious = () => {
          if(isFirstLessonGlobally()) return;
          const chapterIndex = active.chapterIndex;
  
          let newActive: ActiveChapterAndLesson;
          if(isFirstLesson() && !isFirstChapter()) {
            const lastLessonFromPreviousChapterIndex = 
                mainDto.
                chapters[chapterIndex - 1].
                lessons.length - 1;
            
            newActive = {chapterIndex: chapterIndex - 1, lessonIndex: lastLessonFromPreviousChapterIndex};
            setActive(newActive);
          } else {
            newActive = {chapterIndex: chapterIndex, lessonIndex: active.lessonIndex - 1}
            setActive(newActive);
          }
  
          handlePathNameChange(newActive);
        }
  
      useEffect(() => {
          (async ()=> {
            let progress: LastVisitedDto;
            let isFreshlyStarted = false;
            try{
              progress = await get<LastVisitedDto>(`reactRoutes/getLastVisited/${courseId}`);
            } catch(error) {
              if(error instanceof NotFound) {
                isFreshlyStarted = true;
              }
              authContext.handleAuthErrors(error);
            }
            try {
              const data = await get<MainDto>(`reactRoutes/getMain/${courseId}/${i18n.language}`);
              
              if(isFreshlyStarted){
                const firstChapter = data.chapters[0].id;
                const firstLesson = data.chapters[0].lessons[0].id;
  
                progress = {lastVisitedChapter: firstChapter, lastVisitedLesson: firstLesson};
              }
  
              const chapter = data.chapters.find(c => c.id === progress.lastVisitedChapter);
              if(chapter) { //TODO jeśli coś będzie falsy to jakiś popup moze z generyczną informacją?
                const chapterIndex = data.chapters.indexOf(chapter);
                const lesson = data.chapters[chapterIndex].lessons.find(l => l.id === progress.lastVisitedLesson);
                if(lesson) {
                  const lessonIndex = data.chapters[chapterIndex].lessons.indexOf(lesson);
                  setActive({chapterIndex: chapterIndex, lessonIndex: lessonIndex});
                  setCurrentlyOpenedChapterDropdown(chapterIndex);
                }
              }
              setMainDto(data);
              globalCtx.setTitle(data.title);
              setIsLoading(false);
            } catch(error) {
              //TODO obłsuga błędu
              console.log(error);
            }
          })()
        }, [])

    if(isLoading) {
      return (
        <>
          <TitleHeader title={`${globalCtx.title}`}/>
          <ContentPanelWrapper>
              <WideMenu mainCoursePanel={globalCtx.isMainView}/>
              <DataPanelWrapper>
                  <MainPath isGrayedOut={true}/>
                  {getContentFallbackWithRegardsToUrl()}
              </DataPanelWrapper>
              <MainNavigationFallbackWithRegardsToUrl/>
          </ContentPanelWrapper>
       </>
      )
    } 
    return (
        <MainContext.Provider value={{
                path,
                setPath,
                activeComponent,
                setActiveComponent,
                active,
                setActive,
                mainDto,
                isLoading,
                handleNext,
                handlePrevious,
                currentlyOpenedChapterDropdown,
                setCurrentlyOpenedChapterDropdown,
                chapterData,
                setChapterData,
                finishedFinalTest,
                setFinishedFinalTest
            }}>
            {props.children}
        </MainContext.Provider>
    )
}