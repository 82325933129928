import { useTranslation } from "react-i18next";
import { AssignmentFallback } from "../../../course/additions/assignment/AssignmentFallback";
import { NotesLinesFallback } from "../../../course/additions/notes/NotesLinesFallback";
import { QuizTestFallback } from "../../../course/additions/quiz/quizTest/QuizTestFallback";
import { CourseMainFallback } from "../../../course/main/courseMain/courseMainFallback/CourseMainFallback";
import { MainNavigationFallback } from "../../../course/main/courseMain/courseMainNavigation/courseMainNavigationFallback/MainNavigationFallback";
import { CourseMainNavigationFallback } from "../../../course/main/courseMain/courseMainNavigation/courseMainNavigationFallback/courseMainNavigationFallback";
import { VideoPageFallback } from "../../../course/videoPage/videoPageFallback/VideoPageFallback";
import { Notes } from "../../../course/additions/notes/Notes";
import { NotesFallback } from "../../../course/additions/notes/NotesFallback";
import { NotesMain } from "../../../course/main/notesMain/NotesMain";

export type AvailableContents = 'quiz' | 'assignment' | 'notes' | 'course' | 'main';

export const isWordPresentInUrl = (content: AvailableContents) => {
    return window.location.href.includes(content);
}

export const getContentFallbackWithRegardsToUrl = () => {
    if(isWordPresentInUrl('quiz')){
        return <QuizTestFallback/>;
    } else if(isWordPresentInUrl('assignment')){
        //TODO prev i next
        return <AssignmentFallback prevButtonClass={""} nextButtonClass={""}/>
    } else if(isWordPresentInUrl('notes')){
        return <NotesFallback/>
    } else if(isWordPresentInUrl('course') && isWordPresentInUrl('main')){
        return <CourseMainFallback textLines={5}/>;
    }
    
    return <VideoPageFallback/>
}

export const MainNavigationFallbackWithRegardsToUrl = () => {
    const { t } = useTranslation();

    if(isWordPresentInUrl('quiz')) {
        return <MainNavigationFallback title={t("kurs.mainNavigation.selectQuizesFromTheLesson")}/>
    } else if(isWordPresentInUrl('assignment')) {
        return <MainNavigationFallback title={t("kurs.mainNavigation.selectAssignmentsFromTheLesson")}/> 
    } else if(isWordPresentInUrl('notes')) {
        return <MainNavigationFallback title={t("kurs.mainNavigation.selectNotesFromTheLesson")}/> 
    }

    return <CourseMainNavigationFallback/>
}