
export const TopRightRayTeam = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={705}
    height={505}
    fill="none"
    className="top-right-ray-team"
  >
    <g opacity={0.3}>
      <g fill="#0E0C2D" opacity={0.16}>
        <path d="M631.991 462.88h48.065l.1.025 7.031 12.197h-61.99l-12.634-21.314 7.005-12.244 12.423 21.336Z" />
        <path d="m639.696 391.561 15.521-26.882 17.937 30.421h31.619l-37.248 64.546-.161.366h-33.72l-12.427-21.343 6.848-11.972-70.164-123.789h-31.063l-15.578-27.069 31.411-54.404L639.7 391.561h-.004Z" />
      </g>
      <path
        fill="#0E0C2D"
        d="M573.415 362.438h-24.022l-30.6-53.633h30.353l66.195 116.092-2.907 5.038-39.019-67.497ZM447.47 293.937h44.189l114.998 201.867h31.669l5.443 9.038h-45.784L487.113 308.805h-31.056l-8.587-14.868Z"
        opacity={0.16}
      />
      <g fill="#0E0C2D" opacity={0.16}>
        <path d="m456.003 185.156 10.742-18.604 34.885 59.684-10.724 18.579-34.903-59.659Z" />
        <path d="m412.399 124.955 20.217 36.351h31.063l1.402 2.395-12.39 21.465 36.093 61.7c.15.279.304.559.455.839l-3.546 6.141 8.938 15.267h-25.09L365.249 85.972h68.837l-21.683 38.986-.004-.003Z" />
      </g>
      <g fill="#0E0C2D" opacity={0.16}>
        <path d="m653.381 150.733 33.515 59.19-13.71 23.846-33.852-58.706 14.047-24.33Z" />
        <path d="M534.69 105.078c17.116-29.557 34.229-59.118 51.345-88.675l117.831 204.86-19.339 32.175-9.684-16.794 15.359-26.707-36.796-64.98-15.725 27.238-52.392-90.855-13.871 23.742H534.69v-.004Z" />
      </g>
      <path
        fill="#0E0C2D"
        d="M413.966 47.333h-22.057l-15.643-26.502h154.867l-15.245 26.502h-52.309l-9.77-15.055h-30.575l-9.268 15.055Z"
        opacity={0.16}
      />
      <g fill="#0E0C2D" opacity={0.16}>
        <path d="M377.958 146.312 314.466 36.136h51.779l15.973 27.858h143.738l24.921-43.163h20.734l-46.103 83.222h-92.071l15.338-27.127h-98.402l44.053 78.504h-88.303l-5.263-9.118h77.098Z" />
        <path d="M534.442 20.83h13.123L524.3 61.127H383.881l-15.976-27.858h-58.401l63.489 110.176h-73.789L228.373 20.831h144.562L390.27 50.2h25.298l9.268-15.054h27.417l9.766 15.054h55.526l16.897-29.37Z" />
      </g>
      <g fill="#0E0C2D" opacity={0.16}>
        <path d="M68.526 37.502h104.31l61.148 108.814h57.178l11.753 20.379h98.352l63.75 107.566h30.765l51.947 91.049h24.033l39.015 67.496-11.254 19.494 16.725 28.298h70.62L700.485 505c-2.578-.054-5.156-.104-7.734-.158h-45.637l-7.167-11.907h-31.626L493.323 291.069H445.81l-56.479-97.825H212.128L134.373 58.921H34.028L0 0h46.946l21.576 37.499.004.003Z" />
        <path d="M289.506 143.444h-53.844L174.514 34.63H70.186L50.262 0h54.784c4.126 6.751 8.253 13.499 12.383 20.25 33.404.129 66.812.262 100.217.39l71.863 122.804h-.003Z" />
      </g>
      <path
        fill="#0E0C2D"
        d="M423.18 129.659h62.588l18.217 31.02h27.869l28.722-48.864-127.673-.843-9.723 18.687Zm82.243 28.51-18.217-31.02h-59.889l7.106-13.656 121.786.806-25.789 43.874h-24.997v-.004ZM651.033 54.676l28.765 49.85h24.068v-2.51h-22.62l-25.868-44.83h14.854l22.946 39.721h9.028v-2.51h-7.579l-22.946-39.721h-20.648ZM682.63 50.804l16.202 27.958 2.169-1.258-15.492-26.736 16.331-29.855-2.201-1.204-17.009 31.095ZM694.11 283.647l-19.393.169-14.517 24.211 13.642 24.524h19.913l2.599 4.514-1.767 2.685-2.492-4.331h-19.938l-15.269-27.445 16.198-27.012 20.999-.183.025 2.868ZM546.64 167.129l60.77 105.694h79.494l-18.339-31.145 2.431-4.213 1.645 2.886-.756 1.312 20.034 34.028H605.75l-60.767-105.683 1.653-2.879h.004ZM601.925 26.416l79.629 137.762 19.565-34.003 2.173 1.251-21.734 37.779-81.082-140.272 1.449-2.517Z"
        opacity={0.16}
      />
      <g fill="#0E0C2D" opacity={0.16}>
        <path d="m593.951 17.718-1.448-2.51h103.454l-19.311 33.38h-37.907l35.935 62.391h27.532v2.51h-28.98l-38.829-67.41h40.801l16.406-28.36h-97.653ZM591.37 15.291v-.082h.054l-.054.082Z" />
      </g>
      <g fill="#0E0C2D" opacity={0.16}>
        <path d="m639.381 174.765.093.054-.029.05-.064-.104ZM535.877 166.297l27.553-47.369 9.96 17.249 6.267-10.34 31.217 54.661h-16.001l19.038 32.86 22.135-38.342 1.646 2.887-23.778 41.184-24.018-41.457h16.037l-26.348-46.139-6.253 10.318-9.91-17.162-25.896 44.518h-38.445l-17.013-29.163-13.742 23.807-1.656-2.869 15.384-26.649 18.672 32.01h35.147l.004-.004Z" />
      </g>
      <path
        fill="#0E0C2D"
        d="m643.145 372.405 15.815-25.829 19.691 33.394 15.631-27.22 1.646 2.89-17.245 30.034-19.77-33.53-15.832 25.858-94.727-163.085 1.649-2.868 93.142 160.356Z"
        opacity={0.16}
      />
      <path
        fill="#0E0C2D"
        d="m491.34 250.813 1.649-2.865 9.899 16.93 45.247-80.21 55.16 95.534h59.885l-15.586 26.951 18.827 33.35h21.271l4.08 6.547-1.735 2.635-3.936-6.314h-21.354l-20.455-36.236 13.915-24.065h-56.569l-53.471-92.615-45.232 80.181-11.595-19.823Z"
        opacity={0.16}
      />
    </g>
  </svg>
)
