import * as React from "react"

export const RightIcons = () => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={1333.333}
    height={400}
    viewBox={"0 0 1300 400"}
  >
    <path
      d="M914.936 58.917H804.022v115.634h110.914z"
      style={{
        fill: "none",
        stroke: "#a29b9a",
        strokeWidth: 12.0000003,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 48.8,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      transform="matrix(1.33333 0 0 -1.33333 0 400)"
    />
    <path
      d="M0 0v36.283h-110.914V-79.351h35.398"
      style={{
        fill: "none",
        stroke: "#a29b9a",
        strokeWidth: 12.0000003,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      transform="matrix(1.33333 0 0 -1.33333 1172.716 167.265)"
    />
    <path
      d="M0 0v-129.203"
      style={{
        fill: "none",
        stroke: "#a29b9a",
        strokeWidth: 15.00000038,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      transform="matrix(1.33333 0 0 -1.33333 659.64 133.047)"
    />
    <path
      d="M0 0h119.026"
      style={{
        fill: "none",
        stroke: "#a29b9a",
        strokeWidth: 15.00000038,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      transform="matrix(1.33333 0 0 -1.33333 580.29 219.182)"
    />
    <path
      d="M0 0h37.366v-115.043H-77.678V0h37.367"
      style={{
        fill: "none",
        stroke: "#a29b9a",
        strokeWidth: 12.0000003,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      transform="matrix(1.33333 0 0 -1.33333 241.678 167.265)"
    />
    <path
      d="M0 0v116.371"
      style={{
        fill: "none",
        stroke: "#a29b9a",
        strokeWidth: 12.0000003,
        strokeLinecap: "round",
        strokeLinejoin: "miter",
        strokeMiterlimit: 10,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      transform="matrix(1.33333 0 0 -1.33333 214.803 248.754)"
    />
    <path
      d="m0 0 31.179 31.726L62.904 0"
      style={{
        fill: "none",
        stroke: "#a29b9a",
        strokeWidth: 12.0000003,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
        strokeDasharray: "none",
        strokeOpacity: 1,
      }}
      transform="matrix(1.33333 0 0 -1.33333 172.867 135.893)"
    />
  </svg>
)
