import { Dispatch, FormEvent, SetStateAction, useEffect, useState } from "react";

import {
    PaymentElement,
    LinkAuthenticationElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";

import { StripePaymentElementOptions } from "@stripe/stripe-js";

import "./stripePaymentPanel.scss";
import { useTranslation } from "react-i18next";


interface StripePaymentPanelProps {
  email: string,
  price: number,
  setBuyNowClicked: Dispatch<SetStateAction<boolean>>;
  purchaseType: "course" | "explanation";
  courseID: string;
}

export const StripePaymentPanel = (props: StripePaymentPanelProps) => {
    const { t } = useTranslation();

    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const paymentElementOptions: StripePaymentElementOptions = {
        layout: {
          type: 'tabs',
        },

        defaultValues: {
          billingDetails: {
            email: props.email
          }
        }
    }

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    
        if (!stripe || !elements) {
          return;
        }
    
        setIsLoading(true);
        //TODO WAŻNE ogarnij to tak żeby na developmencie był localhost
        const { error } = await stripe.confirmPayment({
          elements,
          confirmParams: {
            // Make sure to change this to your payment completion page
            return_url: `${process.env.REACT_APP_REDIRECT_PREFIX}paymentConfirmation?courseID=${props.courseID}&purchaseType=${props.purchaseType}`,
          },
        });
    
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.

        if (error.type === "card_error" || error.type === "validation_error") {
            if(error.message !== undefined)
                setMessage(error.message);
        } else {
          setMessage(`${t("checkout.stripeSection.errors.unexpectedError")}`);
        }
        setIsLoading(false);
    };

    useEffect(() => {

        console.log(props);

        if (!stripe) {
          return;
        }
    
        const clientSecret = new URLSearchParams(window.location.search).get(
          "payment_intent_client_secret"
        );
    
        if (!clientSecret) {
          return;
        }
    
        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            if(paymentIntent?.status !== undefined) {
                switch (paymentIntent.status) {
                    case "succeeded":
                    setMessage(`${t("checkout.stripeSection.paymentSuccesfull")}`);
                    break;
                    case "processing":
                    setMessage(`${t("checkout.stripeSection.paymentProcessing")}`);
                    break;
                    case "requires_payment_method":
                    setMessage(`${t("checkout.stripeSection.errors.paymentFailed")}`);
                    break;
                    default:
                    setMessage(`${t("checkout.stripeSection.errors.unexpectedError")}`);
                    break;
                }
            }
        });
    }, [stripe]);
    
    return (
        <div className="checkout__stripe-box">

          <div className="checkout__signup-box__title">
            <div>
                <span className="material-symbols-outlined">
                  credit_card
                </span>
                <span>
                  {`${t("checkout.payment")}: 2/2`}
                </span>
            </div>
            <div 
                className="checkout__signup-box__title__mobile-close-button"
                onClick={() => {
                    let html = document.querySelector('html');
                    if(html !== null) html.classList.remove('html-not-scrollable');
                    document.body.classList.remove('body-not-scrollable');

                    props.setBuyNowClicked(false);
                }
            }    
            >
                <span className="material-symbols-outlined">
                    close
                </span>
            </div>
          </div>

            <div className="checkout__stripe-box__infomertial">
                <span>{t("checkout.stripeSection.choosePrefferedPaymentType")}</span>
            </div>
            <form id="payment-form" onSubmit={handleSubmit}>
                <PaymentElement id="payment-element" options={paymentElementOptions} />
                <div id="payment-button-wrapper">
                  <button disabled={isLoading || !stripe || !elements} id="submit">
                      <span id="button-text">
                        {isLoading ? <div className="spinner" id="spinner"></div> : `${t("checkout.stripeSection.payForThePurchase")} - ${props.price} zł`}
                      </span>
                  </button>
                </div>
                {
                  message && <div id="payment-message">{message}</div>
                }  
                {/* TODO: implement proper handling */}
            </form>
        </div>
    )
}