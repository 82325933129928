
export const CheckMark = () => (
    <div className="main-page__introduction__left-section__bullet-points__bullet-point__checkmark">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            fill="none"

        >
            <path
            fill="#FFCA42"
            fillRule="evenodd"
            d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Zm4.768-11.36a1 1 0 1 0-1.536-1.28l-3.598 4.317c-.347.416-.542.647-.697.788l-.006.006-.007-.005c-.168-.127-.383-.339-.765-.722l-1.452-1.451a1 1 0 0 0-1.414 1.414l1.451 1.451.041.041c.327.327.64.641.933.862.327.248.756.48 1.305.456.55-.025.956-.296 1.26-.572.27-.247.555-.588.85-.943l.037-.044 3.598-4.318Z"
            clipRule="evenodd"
            />
        </svg>
    </div>
)