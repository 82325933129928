import "./paymentConfirmation.scss";


import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { LandingFooter } from "../LandingFooter/LandingFooter";
import { LandingNavbar } from "../LandingNavbar/LandingNavbar";
import { DownLeftRayTeam } from "../MainPage/graphics/DownLeftRayTeam";
import { TopRightRayTeam } from "../MainPage/graphics/TopRightRayTeam";
import { useContext, useEffect } from "react";
import { AuthContext } from "../../components/auth/authContext/AuthContextProvider";
import { get } from "../../utilities/rest/apiClient";


export const PaymentConfirmation = () => {
    const { t } = useTranslation();
    
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);

    useEffect(() => {
      const redirectTimeout = setTimeout(() => {

        navigate('/userPanel');
      }, 15000); 
  
      return () => clearTimeout(redirectTimeout);
    }, [navigate]);
  
    useEffect(() => {
        const url = new URL(window.location.href);

        const params = new URLSearchParams(url.search);

        // Get the value of a specific parameter by name
        const courseID = params.get('courseID') as string;
        if(!courseID) {
            console.log("course ID is null")
            return;
        }

        const purchaseType = params.get('purchaseType') as string;
        if(!purchaseType) {
            console.log("purchase type is null")
            return;
        }

        let intervalId:  NodeJS.Timer;

        if(purchaseType === "course") {
            intervalId = setInterval(() => {
                (async () => {
                    try{    
                        get<any>(`reactRoutes/getUserHasCourse/${courseID}`)
                        .then((res) => {
                            navigate(`/userPanel`);
                        }).catch((err) => {

                        });
                    }catch{

                    }
                })();
            }, 1000);
        } else if(purchaseType === "explanation") {
            intervalId = setInterval(() => {
                (async () => {
                    try{    
                        get<any>(`reactRoutes/getUserHasExplanations/${courseID}`)
                        .then((res) => {
                            navigate(`/userPanel`);
                        }).catch((err) => {

                        });
                    }catch{

                    }
                })();
            }, 1000);
        }
        

        return () => clearInterval(intervalId);
    }, []);
    
    return (
        <div className="payment-confirmation">
            <LandingNavbar/>
            
            <div className="payment-confirmation__main">
                <DownLeftRayTeam/>
                <TopRightRayTeam/>

                <div className="payment-confirmation__main__panel">

                    <div className="payment-confirmation__main__panel__headline_1">
                        <span> Dziękujemy za dokonanie zakupu.</span>
                    </div>

                    <div className="payment-confirmation__main__panel__confirmation">
                        <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    </div>

                    <div className="payment-confirmation__main__panel__text">
                        <span> Zaraz przekierujemy cię do twojego panelu użytkownika.</span>
                    </div>

                    <div className="payment-confirmation__main__panel__footer">
                        <span>
                            Potwiedzenie płatności zostanie wysłane na twojego maila.
                        </span>
                    </div>
                </div>
            </div>

            {/* <LandingFooter/> */}
        </div>
    )
}