import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { QuizContext } from "../QuizContextProvider";
import { QuizAnswers } from "./quizAnswers/QuizAnswers";
import { QuizQuestion } from "./quizQuestion/QuizQuestion";
import { NotesLinesFallback } from "../../notes/NotesLinesFallback";
import { TextFallback } from "../../../../shared/components/fallbacks/skeleton/TextFallback";


interface AnswerElementProps {
  index: number,
  activeIndex: number,
  symbol: string
  
}

export const AnswerElement = (props: AnswerElementProps) => {
  const { index, activeIndex, symbol } = props;

  const handleClassName = () => {
    const base = "data-panel__quiz__mobile-nav-bar__wrapper__element";
    return `${base} ${(index === activeIndex) ? `${base}--active` : ""}`
  }

  return(
    <div 
      data-testid={`quiz-option-${symbol}-mobile`}
      className={handleClassName()}>
      <span>
        {symbol}
      </span>
    </div>
  );
}

interface QuizMobileNavigationProps {
  activeMobileIndex: number;
  setActiveMobileIndex: Dispatch<SetStateAction<number>>;
  firstMobileClicked: boolean;
  setFirstMobileClicked: Dispatch<SetStateAction<boolean>>;
  numberOfAnswers: number;
}

export const QuizMobileNavigation = (props: QuizMobileNavigationProps) => {
  const mobilePanelsMaxIndex = props.numberOfAnswers-1;
  const { t } = useTranslation();
  return (
    <>
      <div className="data-panel__quiz__mobile-nav-bar">
                    
                    <div 
                      className={`data-panel__quiz__mobile-nav-bar__button data-panel__quiz__mobile-nav-bar__button--${(props.activeMobileIndex !== 0) ? "active" : "inactive"}`}
                      onClick={() => { 
                        if(props.activeMobileIndex !== 0) props.setActiveMobileIndex(props.activeMobileIndex-1);
                        if(!props.firstMobileClicked) props.setFirstMobileClicked(true);
                      } } 
                    >
                      <span className="material-symbols-outlined">
                        arrow_back_ios
                      </span>
                    </div>

                    <div className="data-panel__quiz__mobile-nav-bar__wrapper">
                      {
                        ['A', 'B', 'C', 'D'].slice(0,props.numberOfAnswers).map((el, index) => <AnswerElement key={index} index={index} symbol={el} activeIndex={props.activeMobileIndex}/>)
                      }
                    </div>

                    <div 
                      className={`data-panel__quiz__mobile-nav-bar__button data-panel__quiz__mobile-nav-bar__button--${(props.activeMobileIndex !== mobilePanelsMaxIndex) ? "active" : "inactive"}`}
                      onClick={() => { 
                        if(props.activeMobileIndex !== mobilePanelsMaxIndex) props.setActiveMobileIndex(props.activeMobileIndex+1);
                        if(!props.firstMobileClicked) props.setFirstMobileClicked(true);
                      } }  
                    >
                      <span className="material-symbols-outlined">
                        arrow_forward_ios
                      </span>
                    </div>

                  </div>

                  {
                    (props.firstMobileClicked) ? "" : (
                      <div className="data-panel__quiz__mobile-tip">
                        <div className="data-panel__quiz__mobile-tip__symbol">
                          <span className="material-symbols-outlined">
                            emoji_objects
                          </span>
                        </div>
                        <div className="data-panel__quiz__mobile-tip__text">
                          <span>
                            {t("kurs.quiz.uzyjStrzalekAbyWybrazOdpowiedz")}
                          </span>
                        </div>
                      </div>
                    )
                  }
    </>
  )
}

interface QuizTestProps {
  numberOfQuestions: number;
  handleClickAnswer: (userAnswerId: number, correctQuestionId: number) => Promise<void>;
}

export const QuizTest = (props: QuizTestProps) => {
    const ctx = useContext(QuizContext);
    const { t } = useTranslation();
    const [activeMobileIndex, setActiveMobileIndex] = useState(0);
    const [firstMobileClicked, setFirstMobileClicked] = useState(false);

      return ( 
          <div className="data-panel__quiz">
                  <div className="data-panel__quiz__name">
                    <h4>{`${t("kurs.quiz.pytanie")}: ${ctx.currentQuiz+1} / ${props.numberOfQuestions}`}</h4>
                  </div>

                  <QuizQuestion text={ctx.quizes[ctx.currentQuiz].text} />

                  <div className="data-panel__quiz__command">
                    <span>{t("kurs.quiz.wybierzPoprawnaOdpowiedz")}</span>
                  </div>
                  <QuizAnswers activeMobileIndex={activeMobileIndex} setActiveMobileIndex={setActiveMobileIndex} handleClickAnswer={props.handleClickAnswer}/>
                  
                  <QuizMobileNavigation
                      activeMobileIndex={activeMobileIndex}
                      setActiveMobileIndex={setActiveMobileIndex}
                      firstMobileClicked={firstMobileClicked}
                      setFirstMobileClicked={setFirstMobileClicked}
                      numberOfAnswers={ctx.quizes[ctx.currentQuiz].answers.length}
                  />
        </div>
      ); 
}
