import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import { logout } from "../../../../utilities/rest/logout";

import { FooterPartWideMenu } from "./footerPartWideMenu/FooterPartWideMenu";
import { WideMenuElement } from "./wideMenuElement/wideMenuElement";
import { useEffect, useState } from "react";


interface WideMenuProps {
    mainCoursePanel?: boolean,
    children?: React.ReactNode,
    inactive?: boolean,
}

export const WideMenu = (props: WideMenuProps) => {
    const { t } = useTranslation();
    const { courseId } = useParams();

    const navigate = useNavigate();

    const menuElements = [
        { icon: "article", text: t("kurs.nawigacja.backToCoursePanel"), 
          path: !props.mainCoursePanel? `/course/${courseId}/main`: "/", 
          displayed: !props.mainCoursePanel},
        { icon: "person", text: t("kurs.nawigacja.mojeKonto"), path: "/userPanel/changeUsername", displayed: true},
        { icon: "shopping_bag", text: t("kurs.nawigacja.sklepZKursami"), path: "/shop", displayed: true},
        { icon: "library_books", text: t("kurs.nawigacja.mojeKursy"), path: "/userPanel", displayed: true},
        { icon: "logout", text: t("navbar.logout"), path: "logout", displayed: true} 
    ];


    return (
        <div data-testid='wide-menu' id="wide-menu">
            <div className="chapter chapter--main">
                <div className="chapter__name">{t("kurs.nawigacja.szybkiDostep")} </div>
                <div className="chapter__data-space">
                    {
                        menuElements
                        .filter((el) => el.displayed)
                        .map( 
                            (el, index) =>  
                            <WideMenuElement 
                                key={index} 
                                icon={el.icon} 
                                path={el.path} 
                                text={el.text}
                                inactive={(props.inactive !== undefined) ? props.inactive : false }
                            />
                        )
                    }
                </div>
            </div>

            {props.children}
            
            <FooterPartWideMenu/>
        </div>
    );
}