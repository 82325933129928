import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "../auth/authContext/AuthContextProvider";

import './Alert.scss';


export const AlertBox = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const authContext = useContext(AuthContext);

    useEffect(()=>{
        let html = document.querySelector('html');
        if(html !== null) html.classList.add('html-not-scrollable');
        document.body.classList.add('body-not-scrollable');
    }, []);
    
    useEffect( () => () => {
        let html = document.querySelector('html');
        if(html !== null) html.classList.remove('html-not-scrollable');
        document.body.classList.remove('body-not-scrollable');
    }, []);

    const handleAlertBoxAction = () => {
        authContext.setLoggedIn(false);

        navigate('/');
        authContext.setAlertDisplayed(false); 
    }

    return (
        <>
            <div className="page-overlay page-overlay--top"/>
            <div className="alert">
                <div className="alert__text">
                    <span className="alert__text--head">
                        {authContext.alertHeadline}
                    </span>

                    <div className="alert__text__spacer"/>

                    <span>
                        {authContext.alertMessage}
                    </span>
                </div>
                <div className="alert__button-section">
                    <button onClick={ handleAlertBoxAction }>
                        <span>
                            {t("alert.understandButton")}
                        </span>
                    </button>
                </div>
            </div>
        </>   
    )
}