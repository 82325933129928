import { useContext, useEffect, useState } from "react";
import { GlobalDataContext } from "../../global/globalDataContext/GlobalDataContextProvider";
import { MainContext } from "../main/MainContextProvider";
import "../course.scss";
import { TestTopNavigation } from "./testTopNavigation/TestTopNavigation";
import { SingleExcercise } from "./singleExcercise/SingleExcercise";
import { handleButtonClass } from "../additions/quiz/quizResult/QuizResult";
import { useTranslation } from "react-i18next";
import { VideoOfferSolutionWrapper } from "../additions/assignment/videoOfferSolutionWrapper/VideoOfferSolutionWrapper";
import { ExcerciseAnswers, FinalTestProgressDto, MainTestDto, SingleExcerciseProgress } from "../../../../../shared/finalTest"
import "./mainTest.scss";
import { AuthContext } from "../../auth/authContext/AuthContextProvider";
import { get, post } from "../../../utilities/rest/apiClient";
import { useNavigate, useParams } from "react-router-dom";
import { popLastElementFromUrl } from "../../shared/utilities/url/url";

export const MainTest = () => {
    const globalCtx = useContext(GlobalDataContext);
    const mainCtx = useContext(MainContext);
    const [currentExcercise, setCurrentExcercise] = useState(0);
    const [excercises, setExcercises] = useState({} as MainTestDto);
    const [notifyIfClicked, setNotifyIfClicked] = useState(0);
    const [shouldDisplayRevealButton, setShouldDisplayRevealButton] = useState(true);
    const [videoSolutionOpen, setVideoSolutionOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const { t } = useTranslation();
    const authContext = useContext(AuthContext);
    const {courseId} = useParams();
    const navigate = useNavigate();

    const handleSaveAndExit = () => {
        const finalTestProgress: SingleExcerciseProgress[] = [];

        for(let i=0; i<excercises.excercises.length; i++){
            
            const excercise = excercises.excercises[i];
            if(excercise.finished === true){
                const singleExcerciseProgress: SingleExcerciseProgress = {} as SingleExcerciseProgress;
                singleExcerciseProgress.excerciseType = excercise.excerciseType;
                singleExcerciseProgress.finished = true;
                singleExcerciseProgress.index = i;
                singleExcerciseProgress.userAnswers = excercise.excerciseAnswers;

                finalTestProgress.push(singleExcerciseProgress);
            }
        }

        post<any, FinalTestProgressDto>(`userCourses/updateFinalTestProgress/${courseId}`, {progresses: finalTestProgress})
        .then((data) => {
            navigate("preview");
        })
        .catch(err => console.log(err)); //TODO popup z napisem w stylu "niestety aktualnie nie można zapisać progresu - czy chcesz wyjść mimo tego?"
    }

    useEffect(() => {
        globalCtx.setIsMainTest(true);
        setIsLoading(true);
        mainCtx.setPath([globalCtx.title, "test końcowy"]); //TODO translate
        mainCtx.setActiveComponent("mainTest");

        (async ()=> {
            try {
              const data = await get<MainTestDto>(`courses/getFinalTest/${courseId}`);
    
              setExcercises(data)
              setIsLoading(false);
            } catch(error) {
              authContext.handleAuthErrors(error);
              console.log(error);
              //TODO other errors
            }
          })()
        

        return () => {
            globalCtx.setIsMainTest(false);
        }
    }, []);

    useEffect(() => {
        if(isExcerciseQuiz(currentExcercise)){
            setShouldDisplayRevealButton(false);
        } else {
            setShouldDisplayRevealButton(true);
        }

    }, [currentExcercise]);

    const handleNext = () => {
        if(currentExcercise < excercises.excercises.length-1){
            setCurrentExcercise(currentExcercise+1);
        }
    }

    const hanlePrevious = () => {
        if(currentExcercise > 0){
            setCurrentExcercise(currentExcercise-1);
        }
    }

    const modifyExerciseAnswers = (index: number, newAnswers: ExcerciseAnswers) => {
        const newExercises = [...excercises.excercises];
        const exerciseToModify = { ...newExercises[index] };
        exerciseToModify.excerciseAnswers = newAnswers;
        newExercises[index] = exerciseToModify;
      
        const newTestDto: MainTestDto = {
          ...excercises,
          excercises: newExercises
        };
      
        setExcercises(newTestDto);
    };

    const modifyIsFinished = (index: number, finished: boolean) => {
        const newExercises = [...excercises.excercises];
        const exerciseToModify = { ...newExercises[index] };
        exerciseToModify.finished = finished;
        newExercises[index] = exerciseToModify;
      
        const newTestDto: MainTestDto = {
          userBoughtVideoExplanations: excercises.userBoughtVideoExplanations,
          ...excercises.excercises,
          excercises: newExercises
        };
      
        setExcercises(newTestDto);    
    };

    const handleClick = () => {
        setNotifyIfClicked(notifyIfClicked+1);
    }

    const isExcerciseQuiz = (index: number) => {
        if(excercises.excercises){
            return excercises.excercises[index].excerciseType === 'quiz';
        } return false;
    }

    const handleOpeningVideoSolution = () => {
        let html = document.querySelector('html');

        if(!videoSolutionOpen){    
            if(html !== null) html.classList.add('html-not-scrollable');
            document.body.classList.add('body-not-scrollable');
        } else {
            document.body.classList.remove('body-not-scrollable');
            if(html !== null) html.classList.remove('html-not-scrollable');
        }
        setVideoSolutionOpen(!videoSolutionOpen);
    }

    if(isLoading) return <></>
    else if(excercises.excercises.length === 0) return <h1>Test nie zawiera żadnych pytań</h1> //TODO nie powinno być w ogóle takiej sytuacji że nie ma pytań.
    return (
        <div className="data-panel__mainTest">
            <TestTopNavigation 
                count={excercises.excercises.length} 
                currentExcercise={currentExcercise} 
                setCurrentExcercise={setCurrentExcercise}
                isFinishedAll={excercises.excercises.map(e => e.finished)}
                onSaveAndFinishedAction={handleSaveAndExit}/>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', position: 'relative', alignItems: 'center'}}>
                <div className="data-panel__mainTest__question-number--wrapper">
                    <div style={{whiteSpace: 'nowrap'}} className="data-panel__mainTest__question-number">
                        <h4 className="underlined">{t("kurs.zadaniaOtwarte.zadanie")}: {currentExcercise+1} / {excercises.excercises.length}</h4>
                    </div>
                </div>
                <div onClick={handleOpeningVideoSolution} className={`data-panel__mainTest__nav__buttons__button expand data-panel__mainTest__video-explanation`}>
                    <span className="material-symbols-outlined video">videocam</span>
                </div>
            </div>
            
            <VideoOfferSolutionWrapper 
                    openAssignmentPossesion={excercises.userBoughtVideoExplanations} 
                    videoSolutionOpen={videoSolutionOpen} 
                    setVideoSolutionOpen={setVideoSolutionOpen} 
                    assignmentNumber={currentExcercise+1} 
                    assignmentsCount={excercises.excercises.length}
                    videoLink={excercises.excercises[currentExcercise].videoExplanationSrc}
                    isFinalTest={'test'}/>
            <SingleExcercise 
                key={currentExcercise}
                excerciseData={excercises.excercises[currentExcercise]} 
                revealAnswer={excercises.excercises[currentExcercise].finished} 
                modifyIsFinished={modifyIsFinished}
                currentExcercise={currentExcercise} 
                modifyExerciseAnswers={modifyExerciseAnswers}
                testDto={excercises}
                notifyIfClicked={notifyIfClicked}
                setNotifyIfClicked={setNotifyIfClicked}
                />
                <div className="data-panel__results-1__buttons">
                    <div className={handleButtonClass("prev", currentExcercise, excercises.excercises.length)} onClick={hanlePrevious}>
                        <span className="material-symbols-outlined">arrow_back_ios</span>
                        <span>{t("kurs.quiz.previous")}</span>
                    </div>

                    <div>
                        <div onClick={handleClick} className="data-panel__results-1__buttons__button data-panel__results-1__buttons__button--center  data-panel__results-1--mobile-hidden">
                            <span className="material-symbols-outlined">check</span>
                            <span>{"Sprawdź rozwiązanie"}</span>
                        </div>
                    </div>

                    <div className={handleButtonClass("next", currentExcercise, excercises.excercises.length)} onClick={handleNext}>
                        <span>{t("kurs.quiz.next")}</span>
                        <span className="material-symbols-outlined">arrow_forward_ios</span>
                    </div>
                </div>
                <div onClick={handleClick} className="data-panel__results-1__repeat-button-mobile">
                    <div className="data-panel__results-1__repeat-button-mobile__button data-panel__results-1__buttons__button data-panel__results-1__buttons__button--center">
                        <span className="material-symbols-outlined">check</span>
                        <span>{"Sprawdź rozwiązanie"}</span>        
                    </div>
                </div>
        </div>
    );
}