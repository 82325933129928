import React, { useContext, useEffect } from "react";
import { Dispatch, ReactNode, SetStateAction, useState } from "react";
import { SingleQuiz } from "../../../../../../shared/quizesDto";
import { CourseContext } from "../../CourseContextProvider";
import { GlobalDataContext } from "../../../global/globalDataContext/GlobalDataContextProvider";
import { get } from "../../../../utilities/rest/apiClient";

interface QuizContextProps {
    currentQuiz: number;
    setCurrentQuiz: Dispatch<SetStateAction<number>>;
    quizes: SingleQuiz[];
    setQuizes: React.Dispatch<React.SetStateAction<SingleQuiz[]>>;
}

export const QuizContext = React.createContext<QuizContextProps>({} as QuizContextProps);

interface QuizContextProviderProps {
    children: ReactNode;
}

export const QuizContextProvider = (props: QuizContextProviderProps) => {
    const globalCtx = useContext(GlobalDataContext);
    const courseCtx = useContext(CourseContext);
    const [currentQuiz, setCurrentQuiz] = useState(0);
    const [quizes, setQuizes] = useState<SingleQuiz[]>([]);

    useEffect(() => {
      if(!globalCtx.isMainView){
        courseCtx.setChapters(prevState => {
          // Clone the prevState
          let newState = {...prevState};
          // Find the lesson to update
          let lesson = newState.lessons.find(lesson => lesson.id === courseCtx.getLessonId());
          if (lesson) {
              // If found, increment completedQuickTests
            //   lesson.completedQuickTests = currentQuiz;
          }
          return newState;
      });
      }
    }, [currentQuiz]);

    useEffect(() => {
      (async ()=> {
        try {
          const quizProgress = (await get<Array<any>>(`userChapters/getClosedTestCompletion/${courseCtx.getLessonId()}`)).length;
          setCurrentQuiz(quizProgress);
        } catch(error) {
          //TODO obłsuga błędu
          console.log(error);
        }
      })()
      
    }, [courseCtx.activeChapter, courseCtx.activeAddition]);

    return (
        <QuizContext.Provider value={{
            currentQuiz: currentQuiz,
            setCurrentQuiz: setCurrentQuiz,
            quizes: quizes,
            setQuizes: setQuizes
        }}>
            {props.children}
        </QuizContext.Provider>
    )
}