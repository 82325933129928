interface ChaptersFallbackProps {
    title: string;
} 

export const ChaptersFallback = (props: ChaptersFallbackProps) => {
    return (
        <div className="chapter chapter--main">
                <div className="chapter__name">{props.title}</div>
                <div className="chapter__data-space">
                    {Array(5).fill(null).map((_, index) => (
                        <div key={index} className="chapter__data-space__wrapper chapter__data-space__wrapper--wide skeleton">
                            <div className="chapter__data-space__data chapter__data-space__data--chapter">
                                <div className="skeleton"></div>
                            </div>
                            <div className="chapter__data-space__params chapter__data-space__course-params">
                                <div className="chapter__data-space__course-params__lessons">
                                    <div className="skeleton"/>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
    )
}