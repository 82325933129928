import { ChangeEvent, Dispatch, FormEvent, SetStateAction, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { StripeElementLocale, StripeElementsOptions, loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { checkNumberOfCharacters, checkCapitalization, checkNonCapitlization, checkDigits, getInputFieldState } from "../../components/auth/shared/sharedFunctions";
import { PasswordLabel } from "../../components/auth/signUp/passwordLabel/PasswordLabel";
import { Alert } from "../../components/auth/shared/sharedInterfaces";
import { CheckBox } from "../../components/auth/signUp/CheckBox/CheckBox";
import { CheckoutFallback } from "./CheckoutFallback/CheckoutFallback";

import { StripePaymentPanel } from "./StripePaymentPanel.tsx/StripePaymentPanel";

import { stripePromise, options } from "../../paymentComponents/paymentCompontents";

import { OrderType, PaymentIntent } from "../../../../shared/paymentIntent";

import "./checkout.scss";

import i18n from "../../i18n";
import { post } from "../../utilities/rest/apiClient";
import { Unauthorized } from "../../utilities/rest/errors";
import { AuthContext } from "../../components/auth/authContext/AuthContextProvider";

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;


interface CheckoutProps {
    setBuyNowClicked: Dispatch<SetStateAction<boolean>>;
    courseID: string | undefined,
    basicOptionSelected: boolean,
    enchancedOptionSelected: boolean,
    price: number
}


export const Checkout = (props: CheckoutProps) => {
    const { t } = useTranslation();

    const authContext = useContext(AuthContext);
    
    const [alreadyHaveAccount, setAlreadyHaveAccount] = useState(false);
    
    const [displayFallback, setDisplayFallback] = useState(true);

    const [email, setEmail] = useState("");
    const [validEmail, setValidEmail] = useState(false);
    const [emailSelected, setEmailSelected] = useState(false);

    const [password, setPassword] = useState("");
    const [validPassword, setValidPassword] = useState(false);
    const [passwordSelected, setPasswordSelected] = useState(false);

    const [confirmPassword, setConfirmPassword] = useState('');
    const [validPasswordConfirmation, setValidPasswordConfirmation] = useState(false);
    const [passwordConfirmSelected, setPasswordConfirmSelected] = useState(false);

    const [eightSymbolsChecked, setEightSymbolsChecked] = useState(false);
    const [bigLetterChecked, setBigLetterChecked] = useState(false);
    const [digitChecked, setDigitChecked] = useState(false);
    const [smallLetterChecked, setSmallLetterChecked] = useState(false);

    const [formChecked, setFormChecked] = useState(false);

    const [signupError, setSignupError] = useState(false);
    const [signupErrorMessage, setSignupErrorMessage] = useState("");

    const [clientSecret, setClientSecret] = useState("");

    const emailAlert = t("checkout.incorrectEmailAddress");

    const updatedOptions: StripeElementsOptions = {
        ...options, 
        clientSecret: clientSecret,
        mode: undefined
    };

    useEffect(() => {
        if(authContext.loggedIn) {
            sendPaymentIntent();
        }
        else {
            setDisplayFallback(false);
        }
        
    }, []);

    const passwordAlerts: Alert[] = [
        { text: t("signUp.passwordRequirements.min8signs"), status: eightSymbolsChecked },
        { text: t("signUp.passwordRequirements.min1capitalLetter"), status: bigLetterChecked },
        { text: t("signUp.passwordRequirements.min1nonCapitalLetter"), status: smallLetterChecked },
        { text: t("signUp.passwordRequirements.min1digit"), status: digitChecked },
    ];

    
    
    const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        const new_email = e.target.value;
        
        setEmail(new_email);
  
        setValidEmail(emailRegex.test(new_email) && (new_email.length !== 0));
    };

    const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        const new_password = e.target.value;
        setPassword(new_password);
  
        const digitsCheck = checkDigits(new_password, 1);
        const capitalizationCheck = checkCapitalization(new_password, 1);
        const numberOfCharactersCheck = checkNumberOfCharacters(new_password, 8);
        const nonCapitalizationCheck = checkNonCapitlization(new_password, 1);
  
        setEightSymbolsChecked(numberOfCharactersCheck);
        setBigLetterChecked(capitalizationCheck);
        setDigitChecked(digitsCheck);
        setSmallLetterChecked(nonCapitalizationCheck);
  
        if((confirmPassword === new_password) && (new_password.length !== 0)) setValidPasswordConfirmation(true)
        else setValidPasswordConfirmation(false);
  
        if(digitsCheck && capitalizationCheck && numberOfCharactersCheck && nonCapitalizationCheck) 
          setValidPassword(true);
        else
          setValidPassword(false);
    };

    const handleConfirmPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        const new_passwordConfirmation = e.target.value; 
        
        setConfirmPassword(new_passwordConfirmation);

        if((new_passwordConfirmation === password) && (password.length !== 0)) setValidPasswordConfirmation(true)
        else setValidPasswordConfirmation(false);
    }

    const checkIfMaskActive = () => {
        const displayForPassword = !(validPassword || password.length === 0 ) && passwordSelected;
        const displayForPasswordConfirmation = !(validPasswordConfirmation || confirmPassword.length === 0) && passwordConfirmSelected;
        const displayForEmail = !(validEmail || email.length === 0 ) && emailSelected;


        return displayForPassword || displayForPasswordConfirmation || displayForEmail || signupError;
    }

    const enabledFinalButton = () => {

        if(!alreadyHaveAccount) {
            return (validEmail && validPassword && validPasswordConfirmation && formChecked);
        } else {
            return (validEmail && validPassword);
        }
    }

    const handleFinalButtonClick = () => {

        if(!enabledFinalButton()) {
            return;
        } 

        if(!alreadyHaveAccount) {

            const data = { email: email, password: password, passwordConfirm: confirmPassword};

            post<any, any>(`users/quickSignup/${i18n.language}`, data)
            .then(() => {
                sendPaymentIntent();
            })
            .catch((error) => {
                setEmail("");
                setValidEmail(false);
                setSignupError(true);
    
                if(error instanceof Unauthorized){
                    const message = t("signUp.error.accountAlreadyExists");
                    setSignupErrorMessage(message);
                } else {
                    const message = t("signUp.error.internalError");
                    setSignupErrorMessage(message);
    
                    setPassword("");
                    setValidPassword(false);
    
                    setConfirmPassword("");
                    setValidPasswordConfirmation(false);
    
                    setFormChecked(false);
                }
            });
        }
        else {
            const data = { email: email, password: password };

            post<any, any>("users/login", data)
            .then(() => {
                sendPaymentIntent();
            })
            .catch((error) => {
                setEmail("");
                setValidEmail(false);

                setPassword("");
                setValidPassword(false);

                setSignupError(true);
    
                if(error instanceof Unauthorized){
                    setSignupErrorMessage(`${t("checkout.invalidEmailOrPassword")}`);
                }
            });
        }

    }

    const sendPaymentIntent = () => {

        const courseID = props.courseID as string;

        const orderId = [courseID];
        let orderType: OrderType[] = ['course'];

        if(props.enchancedOptionSelected) {
            orderType = ['courseAndExplanation']
        }

        const data: PaymentIntent = {
            orderId,
            orderType
        };

        post<any, PaymentIntent>("payment/create-payment-intent", data)
        .then((returned_data) => {
            setClientSecret(returned_data.clientSecret);
        })
        .catch((error) => {
            setSignupError(true);

            if(authContext.loggedIn) {
                setSignupErrorMessage(`${t("checkout.stripeSection.errors.unexpectedError")}`);
            }
            else if(!alreadyHaveAccount) {
                setSignupErrorMessage(`${t("checkout.accountCreatedServerErrorOccured")}`);
            } else {
                setSignupErrorMessage(`${t("checkout.loggedInServerErrorOccured")}`);
            }

            setEmail("");
            setValidEmail(false);

            setPassword("");
            setValidPassword(false);
            
            setConfirmPassword("");
            setValidPasswordConfirmation(false);
        });
    }

    return (
        <div className="checkout-wrapper not-selectable">
            <div 
                className="checkout"
                style={(clientSecret || displayFallback) ? {height: 'auto', maxHeight: '650px'} : {height: '566px', maxHeight: '566px'}}
            >

            {
                signupError ? (
                    <div className="checkout__popup">
                        <div className="checkout__popup__headline">
                            <span>{t("checkout.warning")}</span>
                        </div>
                        <div className="checkout__popup__message">
                            <span>
                                {signupErrorMessage}
                            </span>
                        </div>
                        <div 
                            onClick={() => {
                                setSignupError(false);
                                if(authContext.loggedIn) {
                                    let html = document.querySelector('html');
                                    if(html !== null) html.classList.remove('html-not-scrollable');
                                    document.body.classList.remove('body-not-scrollable');
            
                                    props.setBuyNowClicked(false);
                                }

                            }} 
                            className="checkout__popup__close-button">
                            <span>{t("checkout.understood")}</span>
                        </div>
                    </div>
                ) : ""
            }

            { 
                checkIfMaskActive() && <div className={`checkout__mask ${authContext.loggedIn ? "checkout__mask--fallback-height" : ""}`}/>
            }

            {
                (clientSecret) ? (
                    <Elements options={updatedOptions} stripe={stripePromise}>
                        <StripePaymentPanel email={email} price={props.price} setBuyNowClicked={props.setBuyNowClicked} purchaseType="course" courseID={props.courseID as string}/>
                    </Elements>
                ) : displayFallback ? <CheckoutFallback/> : (
                    <div className="checkout__signup-box">

                        <div className="checkout__signup-box__title">
                            <div>
                                <span className="material-symbols-outlined">
                                    credit_card
                                </span>
                                <span>{`${t("checkout.payment")}: 1/2`}</span>
                            </div>
                            <div 
                                className="checkout__signup-box__title__mobile-close-button"
                                onClick={() => {
                                    let html = document.querySelector('html');
                                    if(html !== null) html.classList.remove('html-not-scrollable');
                                    document.body.classList.remove('body-not-scrollable');
            
                                    props.setBuyNowClicked(false);
                                }
                            }    
                            >
                                <span className="material-symbols-outlined">
                                    close
                                </span>
                            </div>
                        </div>
    
                        <div className="checkout__signup-box__signup-login-buttons">
                            <div 
                                className={`not-selectable checkout__signup-box__signup-login-buttons__button checkout__signup-box__signup-login-buttons__button--${!alreadyHaveAccount ? "active" : "inactive"}`}
                                onClick={() => {if(alreadyHaveAccount) setAlreadyHaveAccount(false)}}
                            >
                                <div>
                                    <span className="checkout__signup-box__signup-login-buttons__button__text--desktop">
                                        {t("checkout.IdontHaveAccountLong")}
                                    </span>
                                    <span className="checkout__signup-box__signup-login-buttons__button__text--mobile">
                                        {t("checkout.IdontHaveAccountShort")}
                                    </span>
                                </div>
                            </div>
                            <div 
                                className={`not-selectable checkout__signup-box__signup-login-buttons__button checkout__signup-box__signup-login-buttons__button--${alreadyHaveAccount? "active" : "inactive"}`}
                                onClick={() => {if(!alreadyHaveAccount) setAlreadyHaveAccount(true)}}
                            >
                                <div>
                                    <span>
                                        {t("checkout.IAlreadyHaveAccount")}
                                    </span>
                                </div>
                            </div>
                        </div>
                        
                        <div className={`checkout__signup-box__login-ending checkout__signup-box__login-ending--${alreadyHaveAccount ? "displayed" : "hidden"}`}>

                            <div className="checkout__signup-box__login-ending__login-text">
                                <div className="checkout__signup-box__login-ending__login-text__headline">
                                    <span>
                                        {t("checkout.welcomeBack")}
                                    </span>
                                </div>
                                <div className="checkout__signup-box__login-ending__login-text__main-text">
                                    <span>
                                        {t("checkout.logInToYourAccount")}
                                    </span>
                                </div>
                            </div>

                            <div className={`checkout__signup-box__email-box${(emailSelected && checkIfMaskActive())? " visible" : ""}`}>
                                <span>
                                    {t("checkout.emailAddress")}
                                </span>
                                <input
                                    className={
                                        (email.length !== 0) ? (validEmail ? "valid" : "invalid") : "" 
                                    }
                                    onFocus={() => setEmailSelected(true)}
                                    onBlur={() => setEmailSelected(false)}
                                    type="text"
                                    id="username"
                                    value={email}
                                    onChange={handleEmailChange}
                                />

                                <div className={`checkout__signup-box__errors checkout__signup-box__errors--${((validEmail || email.length === 0) || !emailSelected ) ? "hide" : "display"}`}>
                                    <div className="checkout__signup-box__errors__triangle"/>
                                    <PasswordLabel text={emailAlert} status={ validEmail || (email.length === 0)} />
                                </div>

                                { validEmail ? <span className="material-symbols-outlined material-symbols-outlined--valid"> done </span> : ""}    
                                { ((!validEmail) && (email.length !== 0)) ? <span className="material-symbols-outlined material-symbols-outlined--invalid">warning</span>: ""}
                            </div>

                            <div className={`checkout__signup-box__password-box checkout__signup-box__password-box--first${(passwordSelected && checkIfMaskActive())? " visible" : ""}`}>
                                <span>
                                    {t("checkout.password")}
                                </span>
                                <input
                                    className={
                                        (password.length !== 0) ? (validPassword ? "valid" : "invalid") : ""
                                    }
                                    onFocus={() => setPasswordSelected(true)}
                                    onBlur={() => setPasswordSelected(false)}
                                    type="password"
                                    maxLength={15}
                                    id="password"
                                    value={password}
                                    onChange={handlePasswordChange}
                                />
                                <div className={`checkout__signup-box__errors checkout__signup-box__errors--${((validPassword || password.length === 0) || !passwordSelected ) ? "hide" : "display"}`}>
                                    <div className="checkout__signup-box__errors__triangle"/>
                                    {
                                        passwordAlerts.map((el, index) => 
                                            <PasswordLabel 
                                                key={index} 
                                                text={el.text} 
                                                status={ el.status || (password.length === 0)} 
                                            />
                                        )
                                    }
                                </div>

                                { validPassword ? <span className="material-symbols-outlined material-symbols-outlined--valid"> done </span> : ""}    
                                { ((!validPassword) && (password.length !== 0)) ? <span className="material-symbols-outlined material-symbols-outlined--invalid">warning</span>: ""}
                            </div>
                        </div>
                        
                        <div className={`checkout__signup-box__signup-ending checkout__signup-box__signup-ending--${!alreadyHaveAccount ? "displayed" : "hidden"}`}>
                            <div className={`checkout__signup-box__password-box checkout__signup-box__password-box--second${(passwordConfirmSelected && checkIfMaskActive())? " visible" : ""}`}>
                                <span>
                                    {t("checkout.confirmPassword")}
                                </span>
                                <input
                                    className={
                                        (confirmPassword.length !== 0) ? (validPasswordConfirmation ? "valid" : "invalid") : ""
                                    }
                                    onFocus={() => setPasswordConfirmSelected(true)}
                                    onBlur={() => setPasswordConfirmSelected(false)}
                                    type="password"
                                    maxLength={15}
                                    id="password-confirm"
                                    value={confirmPassword}
                                    onChange={handleConfirmPasswordChange}
                                />

                                <div className={`checkout__signup-box__errors checkout__signup-box__errors--${((validPasswordConfirmation || confirmPassword.length === 0) || !passwordConfirmSelected ) ? "hide" : "display"}`}>
                                    <div className="checkout__signup-box__errors__triangle"/>
                                    <PasswordLabel text={t("signUp.passwordsNotTheSame")} status={ validPasswordConfirmation || (confirmPassword.length === 0)} />
                                </div>

                                { validPasswordConfirmation ? <span className="material-symbols-outlined material-symbols-outlined--valid"> done </span> : ""}    
                                { ((!validPasswordConfirmation) && (confirmPassword.length !== 0)) ? <span className="material-symbols-outlined material-symbols-outlined--invalid">warning</span>: ""}
                            </div>

                            <CheckBox
                                formChecked={formChecked}
                                setFormChecked={setFormChecked}
                                
                                validPassword={validPassword}
                                validEmail={validEmail}
                                validPasswordConfirmation={validPasswordConfirmation}
                            />

                        </div>

                        <div className="checkout__signup-box__finalize-transaction">
                            <div 
                                className={`checkout__signup-box__finalize-transaction__button checkout__signup-box__finalize-transaction__button--${enabledFinalButton() ? "active" : "inactive"}`}
                                onClick={handleFinalButtonClick}
                            >
                                <span>
                                    {t("checkout.goToTheLastStep")}
                                </span>
                            </div>
                        </div>
                    </div>
                )
            }

            </div>
        </div>
    );
}