interface UserNavProps {
    handleMenuOpeningDesktop: Function,
    handleMenuOpeningMobile: Function,
    buttonVisible: boolean,
    menuOpen: boolean
}

export const UserNav = (props: UserNavProps) => {
    const { buttonVisible, menuOpen, handleMenuOpeningDesktop, handleMenuOpeningMobile } = props;

    return (
        <div id="navigation__user-panel__my-profile" className={buttonVisible? "" : "active"}>
            <div id="navigation__user-panel__my-profile__icon-bundle" onClick={() => handleMenuOpeningDesktop()}>
                    <img alt="img" src={require("./user-icon-6.png")}/>
                    <span data-testid='main-nav-button' className="material-symbols-outlined">
                        expand_more
                    </span>
            </div>

            <div data-testid='main-nav-button-mobile' data-test id="navigation__user-panel__my-profile__hamburger-menu" onClick={() => handleMenuOpeningMobile()} className={menuOpen ? "active" : ""}>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    )
}