import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Block } from "../../../../../shared/block";
import { combineBlocks } from "../../../components/shared/utilities/combiningBlocksOfTextIntoJsx/combiningBlocksOfTextIntoJsx";


import "./previewNotes.scss";

interface PreviewNotesProps {
    notes: Block[];
    setNotesActivated: Dispatch<SetStateAction<boolean>>;
}

export const PreviewNotes = (props: PreviewNotesProps) => {
    const { t } = useTranslation();
    
    useEffect(() => {
        props.setNotesActivated(true);
    }, []);
      
    return (
        <div className="data-panel__notes">

            <div className="data-panel__notes__headline">
                <h4>{t("kurs.notatki.przegladajNotatkiZLekcjiNaStronie")}</h4>
            </div>

            <div className="data-panel__notes__container-spacer data-panel__notes__container-spacer--top" />
            <div className="data-panel__notes__container">
                <div className="data-panel__notes__container__data">
                   {combineBlocks(props.notes)}
                </div>
            </div>
            <div className="data-panel__notes__container-spacer data-panel__notes__container-spacer--bottom" />

            <div className="data-panel__notes__download data-panel__preview-notes__download">
                <div className="data-panel__notes__download__headline data-panel__notes__download__headline--big data-panel__preview-notes__download__headline">
                    <span>
                    {t("kurs.notatki.materialsToDownload")}
                    </span>
                </div>

                <div className="data-panel__notes__download__text data-panel__preview-notes__download__text">
                    <span>
                        {`${t("kurs.notatki.documentIncludingCombinedNotes")} - `}
                    </span>
                    <span className="data-panel__notes__download__every-note__link data-panel__preview-notes__download__every-note__link">
                        {t("kurs.notatki.goToCourse")}
                    </span>
                    <span>.</span>
                </div>

                <div className="data-panel__notes__download__row data-panel__preview-notes__download__row">
                    <div className="data-panel__notes__download__row__description data-panel__preview-notes__download__row__description">
                        <span>
                            {t("kurs.notatki.downloadNotesInfo")}
                        </span>
                    </div>
                    <div className="data-panel__notes__download__row__button data-panel__preview-notes__download__row__button">
                        <span>
                            {t("kurs.notatki.downloadPDF")}
                        </span>
                        <span className="material-symbols-outlined">
                            download
                        </span>
                    </div>
                </div>

                <div className="data-panel__notes__download__spacer"/>
            </div>

        </div>
    )
}