
export const MailIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
  >
    <path
      stroke="#14181F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M3 7a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V7Z"
    />
    <path
      stroke="#14181F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m21 7-9 6-9-6"
    />
  </svg>
)

