import { Block } from "../../../../../shared/block";
import { combineBlocks } from "../../../components/shared/utilities/combiningBlocksOfTextIntoJsx/combiningBlocksOfTextIntoJsx";
import { useTranslation } from "react-i18next";

interface DisplayedCourseDescriptionProps {
    description: Block[];
}

export const DisplayedCourseDescription = (props: DisplayedCourseDescriptionProps) => {
    const { t } = useTranslation();

    const bulletPoints = [
        t("kurs.preview.previewPage.bulletPoints.first"),
        t("kurs.preview.previewPage.bulletPoints.second"),
        t("kurs.preview.previewPage.bulletPoints.third"),
        t("kurs.preview.previewPage.bulletPoints.fourth")
    ];

    return (
        <div className="course-preview__main-section__left-panel__text-section">
            <div className="course-preview__main-section__left-panel__text-section__text">
                {combineBlocks(props.description, null)}
            </div>

            <div className="course-preview__main-section__left-panel__text-section__learning-method">
                <div className="course-preview__main-section__left-panel__text-section__learning-method__hedline">
                    <span>{t("kurs.preview.previewPage.howIsOurCourseCarriedOut.title")}</span>
                </div>

                <div className="course-preview__main-section__left-panel__text-section__learning-method__text">
                    <span>
                        {
                            t("kurs.preview.previewPage.howIsOurCourseCarriedOut.ourGoalIs")}
                            <b>
                                {t("kurs.preview.previewPage.howIsOurCourseCarriedOut.nasesje")}
                            </b>
                                {t("kurs.preview.previewPage.howIsOurCourseCarriedOut.isToDefineNewQuality")}
                            <b> 
                                {t("kurs.preview.previewPage.howIsOurCourseCarriedOut.dedicatedLearningPlatform")}
                            </b>
                                {t("kurs.preview.previewPage.howIsOurCourseCarriedOut.funAndInterestingToLearn")} 
                    </span>
                    <br/><br/>
                    <span>
                        {t("kurs.preview.previewPage.everyLessonConsistsOf")}
                    </span>
                    <br/><br/>
                    {
                        bulletPoints.map((el, index) => 
                            <div 
                                key={index}
                                className="course-preview__main-section__left-panel__text-section__learning-method__text__bullet-point"
                            >
                                <div className="course-preview__main-section__left-panel__text-section__learning-method__text__bullet-point__number">
                                    {index+1}.&nbsp;
                                </div>
                                <span>{el}</span>
                            </div>
                        )
                    }
                    <br/>
                    <span>
                        {t("kurs.preview.previewPage.afterFinishing")}
                        <b>
                            {t("kurs.preview.previewPage.interactiveFinalTest")}
                        </b>
                            {t("kurs.preview.previewPage.testingYourKnowledge")}
                    </span>

                </div>

            </div>

        </div>
    )
}