import { useTranslation } from "react-i18next";
import { TextFallback } from "../../../shared/components/fallbacks/skeleton/TextFallback";
import { NotesLinesFallback } from "../notes/NotesLinesFallback";

interface AssignmentFallbackProps {
    prevButtonClass: string;
    nextButtonClass: string;
}

export const AssignmentFallback = (props: AssignmentFallbackProps) => {
    const { t } = useTranslation();

    return (
    <>  
        <div className="data-panel__open-test">
            <div className="data-panel__open-test__question">
                <div className="data-panel__open-test__question__question-number">
                    <TextFallback variant="h4" text={t("kurs.zadaniaOtwarte.zadanie")}/>
                </div>

                <div className="data-panel__open-test__question__question-data">

                    <div className="data-panel__open-test__question__question-data"> 
                        <NotesLinesFallback numberOfLines={7}/>
                    </div>

                </div>

            </div>
            
            <div className="data-panel__open-test__anwser">                
                    <div className="data-panel__open-test__question__question-number">
                        <TextFallback text={t("kurs.zadaniaOtwarte.rozwiazanie")} variant="h4"></TextFallback>
                    </div>

                    : 
                    <div className="data-panel__open-test__anwser__headline">
                        <span>{t("kurs.zadaniaOtwarte.rozwiazanie")}:</span>
                    </div>
                <div className="data-panel__open-test__anwser__data">

                    <div className='data-panel__open-test__anwser__data__anwser'>
                        <NotesLinesFallback numberOfLines={7}/>
                    </div>
                </div>

            </div>

            <div className="data-panel__open-test__buttons-spacer data-panel__open-test__buttons-spacer--pre"></div>

            <div className="data-panel__open-test__nav-buttons">
                <div 
                    className={props.prevButtonClass}
                    data-testid='previous-button'
                    >
                        <span className="material-symbols-outlined">
                            arrow_back_ios
                        </span>     
                        <span>
                            {t("kurs.zadaniaOtwarte.poprzednie")}
                        </span>
                </div>
                <div 
                    className={props.nextButtonClass}
                    data-testid='next-button'
                    >
                        <span>
                            {t("kurs.zadaniaOtwarte.nastepne")}
                        </span>
                        <span className="material-symbols-outlined">
                            arrow_forward_ios
                        </span>
                </div>
            </div>

        </div>
    </>);
}