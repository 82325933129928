import { useTranslation } from "react-i18next";

import "./displayedMessage.scss";
import { useNavigate } from "react-router-dom";

interface DisplayedMessageInterface {
    goodMessage: boolean,
    text: string
}

export const DisplayedMessage = (props: DisplayedMessageInterface) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className="resetPassword-panel__message">

            <div className={`resetPassword-panel__message__main-text resetPassword-panel__message__main-text--${props.goodMessage? "good" : "bad"}`}>
                <span>{props.text}</span>
            </div>
                            
            <div 
                className={`resetPassword-panel__message__button resetPassword-panel__message__button--${props.goodMessage? "good" : "bad"}`}
                onClick={ () => { 
                    navigate(`/${props.goodMessage ? "login" : "sendResetEmail"}`); 
                }}    
            >
                <span>
                    {
                        props.goodMessage ? t("resetPassowrd.logToYourAccount") : t("resetPassowrd.tryAgain")
                    }
                </span>
            </div> 
            
        </div>
    );
}