import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";


import "./checkBox.scss";

interface CheckBoxProps {
    formChecked: boolean,
    setFormChecked: Dispatch<SetStateAction<boolean>>,
    
    validPassword: boolean,
    validEmail: boolean,
    validPasswordConfirmation: boolean,
    
}

export const CheckBox = (props: CheckBoxProps) => {
    const { t } = useTranslation();

    const handleCheckBoxStyle = () => {
        let checkBoxClass = `checkmark ${props.formChecked ? "checkmark--filled" : ""}`;
        checkBoxClass +=  (
            (props.validPassword && props.validEmail && props.validPasswordConfirmation) && (!props.formChecked) ?
                " checkmark--red" : ""
        );
        return checkBoxClass;
    }
    
    return (
        <div className="form__checkbox">
            <label className="container">
                <input type="checkbox" checked={props.formChecked} onChange={() => props.setFormChecked(!props.formChecked)} />
                <span className={handleCheckBoxStyle()}></span>
                {props.formChecked ? <span className="material-symbols-outlined material-symbols-outlined--check">done</span>: ""}
            </label>
            <label className="form__checkbox__text">
                {`${t("signUp.acceptTermsAndPolicy.IAccept")} `}
                <a href="">{t("signUp.acceptTermsAndPolicy.statute")}</a>
                {` ${t("signUp.acceptTermsAndPolicy.and")} `}
                <a href="">{t("signUp.acceptTermsAndPolicy.privacyPolicy")}</a>
                {` ${t("signUp.acceptTermsAndPolicy.service")} ${t("signUp.acceptTermsAndPolicy.NaSesjeCom")}.`}

            </label>
        </div>
    )

}