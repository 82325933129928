import { useTranslation } from "react-i18next";
import { TitleHeaderFallback } from "./titleHeaderFallback/TitleHeaderFallback";
import { useState } from "react";

interface TitleHeaderProps {
    title: string;
}

export const TitleHeader = (props: TitleHeaderProps) => {
    const { t } = useTranslation();
    if(props.title) {
        return <div id="content-name">{`${t("kurs.kurs")} - ${props.title}`}</div>
    }
    return <TitleHeaderFallback/>
}