export const CarouselBackground = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={1023}
    height={680}
    fill="none"
  >
    <g opacity={0.75}>
      <g fill="#c0c4c4" opacity={0.16}>
        <path d="M104.748 645.542H34.913l-.146.036L24.55 663.3h90.068l18.358-30.969-10.179-17.79-18.05 31.001Z" />
        <path d="m93.553 541.919-22.55-39.059-26.063 44.201H-1l54.119 93.782.234.531h48.993l18.056-31.01-9.95-17.394 101.945-179.86h45.133l22.635-39.33-45.639-79.046L93.548 541.919h.005Z" />
      </g>
      <path
        fill="#c0c4c4"
        d="M189.857 499.604h34.902l44.461-77.925h-44.102L128.94 590.355l4.224 7.319 56.693-98.07ZM372.848 400.076h-64.204L141.556 693.378H95.543l-7.908 13.133h66.523l161.091-284.832h45.123l12.476-21.603Z"
        opacity={0.16}
      />
      <g fill="#c0c4c4" opacity={0.16}>
        <path d="m360.45 242.022-15.607-27.031-50.686 86.719 15.581 26.994 50.712-86.682Z" />
        <path d="M423.805 154.553 394.43 207.37h-45.133l-2.037 3.48 18.003 31.187-52.442 89.647c-.218.406-.442.812-.661 1.219 1.719 2.974 3.433 5.949 5.152 8.923l-12.987 22.181h36.455L492.312 97.913H392.294l31.506 56.645.005-.005Z" />
      </g>
      <g fill="#c0c4c4" opacity={0.16}>
        <path d="m73.67 192.008-48.697 86 19.92 34.646 49.186-85.296-20.41-35.35Z" />
        <path d="M246.122 125.673c-24.869-42.945-49.733-85.895-74.602-128.84L.318 294.484l28.099 46.748 14.07-24.4-22.317-38.804 53.463-94.412 22.848 39.575 76.123-132.008 20.154 34.495h53.364v-.005Z" />
      </g>
      <path
        fill="#c0c4c4"
        d="M421.528 41.773h32.048l22.728-38.507H251.29l22.149 38.507h76.004l14.195-21.874h44.424l13.466 21.874Z"
        opacity={0.16}
      />
      <g fill="#c0c4c4" opacity={0.16}>
        <path d="m473.845 185.585 92.251-160.08h-75.233L467.656 65.98H258.812l-36.21-62.714h-30.125l66.986 120.917h133.774L370.951 84.77h142.974l-64.006 114.062h128.298l7.648-13.247h-112.02Z" />
        <path d="M246.481 3.266h-19.065l33.802 58.547h204.021l23.213-40.476h84.853l-92.245 160.08h107.212L691.186 3.266H481.143L455.956 45.94H419.2l-13.466-21.874h-39.835L351.708 45.94h-80.675L246.481 3.266Z" />
      </g>
      <g fill="#c0c4c4" opacity={0.16}>
        <path d="M923.435 27.489H771.877L683.033 185.59h-83.077l-17.076 29.609H439.979l-92.625 156.289h-44.701l-75.477 132.289h-34.918l-56.687 98.069 16.352 28.323-24.301 41.117H25.015L5.23 706.74c3.746-.078 7.49-.151 11.236-.229h66.31l10.412-17.3h45.951l167.087-293.302h69.033l82.062-142.135h257.468L827.762 58.609h145.797L1023-27h-68.21l-31.35 54.484-.005.005Z" />
        <path d="M602.363 181.417h78.232l88.844-158.1h151.585L949.971-27h-79.597a93402.357 93402.357 0 0 1-17.993 29.422c-48.535.187-97.075.38-145.609.568L602.357 181.417h.006Z" />
      </g>
      <path
        fill="#c0c4c4"
        d="M408.14 161.388h-90.938l-26.468 45.07h-40.492l-41.731-70.997 185.502-1.224 14.127 27.151Zm-119.495 41.424 26.468-45.071h87.016l-10.325-19.842-176.948 1.172 37.47 63.746h36.319v-.005ZM77.082 52.441l-41.794 72.43H.318v-3.647h32.865l37.585-65.136H49.186L15.846 113.8H2.73v-3.646h11.012l33.34-57.714h30ZM31.172 46.815 7.632 87.437 4.48 85.608l22.51-38.845L3.26 3.385l3.198-1.75 24.713 45.18ZM14.492 385.126l28.177.245 21.093 35.178L43.94 456.18H15.008l-3.777 6.558 2.569 3.902 3.62-6.293h28.969l22.186-39.877-23.536-39.246-30.51-.266-.037 4.168ZM228.76 215.83l-88.297 153.569h-115.5l26.645-45.253-3.532-6.121c-.797 1.396-1.594 2.797-2.39 4.194l1.099 1.906-29.11 49.441h125.199l88.292-153.553-2.401-4.183h-.005ZM148.433 11.382 32.735 211.542 4.308 162.138l-3.157 1.818 31.579 54.89L150.537 15.039l-2.104-3.657Z"
        opacity={0.16}
      />
      <path
        fill="#c0c4c4"
        d="m160.018-1.256 2.105-3.646H11.809l28.057 48.498h55.078L42.73 134.247H2.73v3.647h42.106l56.416-97.945H41.971L18.133-1.256h141.885Z"
        opacity={0.16}
      />
      <g fill="#c0c4c4" opacity={0.16}>
        <path d="m94.011 226.925-.134.078.041.072.093-.15ZM244.398 214.621l-40.034-68.825-14.471 25.062-9.106-15.023-45.357 79.42h23.249l-27.661 47.743-32.162-55.708-2.391 4.194 34.548 59.838 34.897-60.234h-23.301l38.283-67.038 9.084 14.992 14.399-24.937 37.626 64.684h55.859l24.718-42.373 19.967 34.59 2.407-4.167-22.353-38.721-27.13 46.508h-51.066l-.005-.005Z" />
      </g>
      <path
        fill="#c0c4c4"
        d="m88.542 514.086-22.978-37.528-28.61 48.519-22.712-39.548c-.797 1.401-1.594 2.802-2.391 4.198l25.057 43.638 28.723-48.717 23.004 37.57L226.27 285.264l-2.396-4.168-135.331 232.99Z"
        opacity={0.16}
      />
      <path
        fill="#c0c4c4"
        d="m309.107 337.419-2.396-4.162-14.383 24.598-65.741-116.542-80.144 138.806h-87.01l22.644 39.158-27.354 48.457H23.817l-5.928 9.512 2.52 3.829 5.72-9.174h31.027l29.719-52.65-20.218-34.964h82.192l77.691-134.566 65.72 116.5 16.847-28.802Z"
        opacity={0.16}
      />
    </g>
  </svg>
)

