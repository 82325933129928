import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { t } from "i18next";

import { logout } from "../../../../../utilities/rest/logout";
import { AuthContext } from "../../../../auth/authContext/AuthContextProvider";


interface WideMenuElementProps {
    icon: string,
    text: string,
    path: string,
    inactive: boolean
}

export const WideMenuElement = (props: WideMenuElementProps) => {
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);
    
    const handleAction = (actionName: string) => {
        if(props.inactive) return;

        if(actionName === "logout"){ 
            logout();
            
            const headline = t("alert.logout.headline");
            const message = t("alert.logout.message")       
            
            if(headline !== null && message !== null) {
                authContext.setAlertHeadline(headline);
                authContext.setAlertMessage(message);
                authContext.setAlertDisplayed(true);    
            } 
            else navigate('/');
    
        }
        else navigate(actionName);
    }
    
    return (
        <div 
            className={`chapter__data-space__data ${props.inactive ? "chapter__data-space__data--inactive" : ""}`} 
            onClick={() => handleAction(props.path)}
        >
            <div className="icon icon--spacer">
                <span className="material-symbols-outlined">
                    {props.icon}
                </span>
            </div>
            <div className="text">
                {props.text}
            </div>
        </div>
    );
}