import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";


import "./videoSolution.scss"
import { FinalTestPart } from "../videoOfferSolutionWrapper/VideoOfferSolutionWrapper";
import { GlobalDataContext } from "../../../../global/globalDataContext/GlobalDataContextProvider";

interface VideoSolutionProps {
    handleOpeningVideoSolution: Function,
    assignmentNumber: number,
    assignmentsCount: number,
    videoLink?: string;
    isFinalTest: FinalTestPart;
}

export const VideoSolution = (props: VideoSolutionProps) => {
    const { t } = useTranslation();
    const { assignmentNumber, assignmentsCount} = props;
    const globalCtx = useContext(GlobalDataContext);

    const getTitle = (isMobile: boolean) => {
        if(isMobile){
            if(props.isFinalTest === 'test'){
                return `${t("kurs.testKoncowy.rozwiazanieDoZadania")}: ${assignmentNumber} / ${assignmentsCount}`
            } else if(props.isFinalTest === 'preview') {
                return `${t("kurs.testKoncowy.rozwiazanieDoTestu")} ${globalCtx.title}`
            } else {
                return `${t("kurs.assignmentVideo.openTask")}: ${assignmentNumber} / ${assignmentsCount}`
            }
        } else {
            if(props.isFinalTest === 'test'){
                return `${t("kurs.testKoncowy.rozwiazanieDoZadania")}: ${assignmentNumber} / ${assignmentsCount}`
            } else if(props.isFinalTest === 'preview') {
                return `${t("kurs.testKoncowy.rozwiazanieDoTestu")} ${globalCtx.title}`
            } else { 
                return `${t("kurs.assignmentVideo.solutionToTheOpenTask")}: ${assignmentNumber} / ${assignmentsCount}`
            }
        }
    }

    return (
        <>
            <div className="page-overlay" onClick={() => props.handleOpeningVideoSolution()}/>

            <div className="open-test-video-panel">
                <div className="open-test-video-panel__title">
                    <span className="material-symbols-outlined" onClick={() => props.handleOpeningVideoSolution()}>
                        arrow_back_ios
                    </span>
                    <div className="open-test-video-panel__title__text open-test-video-panel__title__text--desktop">
                        <span>
                            {getTitle(false)}
                        </span>
                    </div>
                    <div className="open-test-video-panel__title__text open-test-video-panel__title__text--mobile">
                        <span>
                            {getTitle(true)}
                        </span>
                    </div>
                </div>
                <div className="open-test-video-panel__responsive-container">
                    {   
                        props.videoLink && 
                        <iframe 
                            title="landing-material"
                            src={props.videoLink} 
                            loading="lazy" 
                            allow="accelerometer; gyroscope; encrypted-media; picture-in-picture;" 
                            allowFullScreen={true}
                            scrolling="no"
                            frameBorder={0}
                        ></iframe>
                    }
                </div>
            </div>
        </>
    );
}