import { useTranslation } from "react-i18next";

import { Title } from "../../../components/shared/components/titleWithBackButton/title/Title";
import { CourseDescription } from "../../../components/course/courseDescription/CourseDescription";

interface PreviewVideoPageProps {
    src: string;
    lessonDescription: string;
}

export const PreviewVideoPage = (props: PreviewVideoPageProps) => {
    const { t } = useTranslation();


    return (
        <>
            <div id="data-panel__title" className="preview-data-panel--inactive">
                <span data-testid='back-button' className="material-symbols-outlined">
                    arrow_back_ios
                </span>
                <Title title={t("kurs.nawigacja.backToCourseMenu")}/>
            </div>

            <div className="responsive-container">
            
                <iframe
                    src={props.src}
                    frameBorder="0" allow="fullscreen; picture-in-picture" allowFullScreen
                    title="file_example_MP4_1920_18MG">
                </iframe>

            </div>

            <CourseDescription
                title={t("kurs.video.shortLessonDescription")}
                text={props.lessonDescription}
            />
        </>
    );
}