
export const Play = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
  >
    <path
      fill="#2F848F"
      d="M4.16 3.2A2.555 2.555 0 0 0 1.6 5.76v4.48a2.555 2.555 0 0 0 2.56 2.56h7.68a2.555 2.555 0 0 0 2.56-2.56V5.76a2.555 2.555 0 0 0-2.56-2.56H4.16ZM6.4 5.76 9.92 8 6.4 10.24V5.76Z"
    />
  </svg>
)

