import { useTranslation } from "react-i18next";
import { ChangeEvent, useEffect, useState } from "react";
import { patch } from "../../../../utilities/rest/apiClient";
import { UpdatePasswordDto } from "../../../../../../shared/updatePasswordDto";

import "./changePassword.scss";
import "../userSettings.scss";

import { UserPanelPageProps } from "../../UserPanel";
import { Unauthorized } from "../../../../utilities/rest/errors";
import { ValidationDot } from "../mutual/ValidationDot";
import { handleNotification } from "../mutual/handleNotification";

export const ChangePassword = (props: UserPanelPageProps) => {
    const requiredCharacters = 8;
    const requiredCapitalCharactes = 1;
    const requiredNonCapitalizedCharacters = 1;
    const requiredDigits = 1;

    const { t } = useTranslation();
    const [isNumberOfCharactersValid, setIsNumberOfCharactersValid] = useState(false);
    const [isCapitalizationValid, setIsCapitalizationValid] = useState(false);
    const [isNonCapitalizedValid, setIsNonCapitalizedValid] = useState(false);
    const [isDigitValid, setIsDigitValid] = useState(false);
    const [passwordCurrent, setPasswordCurrent] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [isValid, setIsValid] = useState(false);
    const [passwordsAreTheSameCheckboxValue, setPasswordsAreTheSameCheckboxValue] = useState(false);
    const [invalidCurrentPassword, setInvalidCurrentPassword] = useState(false);

    const [displayGoodNotification, setDisplayGoodNotification] = useState(false);
    const [displayBadNotification, setDiplayBadNotification] = useState(false);

    useEffect(() => {
        props.setActivePanel("changePassword");
        const activeChapter = props.activePanelNameToIndexMap.get("changePassword");
        if(activeChapter){
            props.setActiveChapter(activeChapter);
        }
    }, []);

    useEffect(() => {
        if(passwordCurrent.length > 0)
            setInvalidCurrentPassword(false);
    }, [passwordCurrent]);

    useEffect(() => {
        const passwordsAreTheSame = password === passwordConfirm;
        setPasswordsAreTheSameCheckboxValue(passwordsAreTheSame)

        const isNumberOfCharactersValid = checkNumberOfCharacters(password, requiredCharacters)
        setIsNumberOfCharactersValid(isNumberOfCharactersValid);

        const isNumberOfCapitalCharactersValid = checkCapitalization(password, requiredCapitalCharactes);
        setIsCapitalizationValid(isNumberOfCapitalCharactersValid);

        const isNumberOfNonCapitalizedCharactersValid = checkNonCapitlization(password, requiredNonCapitalizedCharacters);
        setIsNonCapitalizedValid(isNumberOfNonCapitalizedCharactersValid);

        const isNumberOfDigitsValid = checkDigits(password, requiredDigits)
        setIsDigitValid(isNumberOfDigitsValid);

        const checkIfValid = isNumberOfCharactersValid && isNumberOfCapitalCharactersValid && isNumberOfNonCapitalizedCharactersValid && isNumberOfDigitsValid && passwordsAreTheSame;
        setIsValid(checkIfValid);

    }, [password, passwordConfirm]);

    const handleChangePasswordCurrent = (event: ChangeEvent<HTMLInputElement>) => {
        setPasswordCurrent(event.target.value);
    };
    const handleChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };

    const handleChangePasswordConfirm = (event: ChangeEvent<HTMLInputElement>) => {
        setPasswordConfirm(event.target.value);
    };

    const checkNumberOfCharacters = (password: string, length: number) => {
        return password.length >= length;
    }

    const checkCapitalization = (password: string, numberOfCapitalized: number) => {
        const regex = new RegExp(`([A-Z].*){${numberOfCapitalized}}`);
        return regex.test(password);
    }

    const checkNonCapitlization = (password: string, numberOfNonCapitalized: number) => {
        const regex = new RegExp(`([a-z].*){${numberOfNonCapitalized}}`);
        return regex.test(password);
    }

    const checkDigits = (password: string, numberOfDigits: number) => {
        const regex: RegExp = new RegExp(`^(?=.*?\\d){${numberOfDigits}}.*$`);
        return regex.test(password);
    }

    const submitChangePassword = () => {
        if(!isValid) {
            console.log("password is invalid");
            return;
        }

        const dto: UpdatePasswordDto = {
            password: password,
            passwordConfirm: passwordConfirm,
            passwordCurrent: passwordCurrent
        }

        const displayTime = 4000;

        patch<any, UpdatePasswordDto>("users/updateMyPassword", dto).then(data => {
            setDisplayGoodNotification(true);
            setDiplayBadNotification(false);

            setPassword("");
            setPasswordConfirm("");

            setTimeout(() => {
                setDisplayGoodNotification(false);
            }, displayTime);
        })
        .catch(error => {
            if(error instanceof Unauthorized){
                setInvalidCurrentPassword(true);

                setDisplayGoodNotification(false);
                setDiplayBadNotification(true);
    
                setTimeout(() => {
                    setDiplayBadNotification(false);
                }, displayTime);
            }
        });

        setPasswordCurrent("");
    }

    const handleSubmitButtonClass = () => {
        const base = "data-panel__user-settings__button-wrapper__submit-button";
        let finalClassName = `${base} ${base}`;
        finalClassName += isValid ? "--valid" : "--invalid";

        return finalClassName;
    }

    const handleSameNewPasswordTextClass = () => {
        const base1 = "data-panel__change-password__requirements__list-name";
        const base2 = "data-panel__change-password__requirements__yesNo";
        return `${base1} ${base2} ${base2}--${passwordsAreTheSameCheckboxValue? "yes" : "no"}`
    }

    return (
        <div className="data-panel__change-password data-panel__user-settings">
            <div className="data-panel__user-settings__description">
                <h4>{t("userPanel.settings.changeYourPassword")}</h4>
            </div>
            <div className="data-panel__user-settings__text">
                <span>
                    {t("userPanel.settings.changeYourPasswordDescription")}
                </span>
            </div>
            <div className="data-panel__user-settings__input-row">
                <div className="data-panel__user-settings__input-row__text">
                    <span>{`${t("userPanel.settings.insertOldPassword")}:`}</span>
                </div>
                <div className="data-panel__user-settings__input-row__input-wrapper invalid">
                    <input className={invalidCurrentPassword? "invalid": ""} type="password" value={passwordCurrent} onChange={handleChangePasswordCurrent} placeholder={invalidCurrentPassword? `${t("userPanel.settings.badPassword")}`: `${t("userPanel.settings.oldPassword")}`} name="old-password" />
                </div>
            </div>
            <div className="data-panel__user-settings__spacer" />
            <div className="data-panel__change-password__requirements">
                <span className="data-panel__change-password__requirements__list-name">
                    {`${t("userPanel.settings.newPasswordRequirements")}:`}
                </span>
                <div className="data-panel__change-password__requirements__list">
                    <ValidationDot short={true} isValid={isNumberOfCharactersValid} title={t("userPanel.settings.passwordRequirements.signsCount")}/>
                    <ValidationDot short={true} isValid={isCapitalizationValid} title={t("userPanel.settings.passwordRequirements.capitalLetter")}/>
                    <ValidationDot short={true} isValid={isNonCapitalizedValid} title={t("userPanel.settings.passwordRequirements.smallLetter")}/>
                    <ValidationDot short={true} isValid={isDigitValid} title={t("userPanel.settings.passwordRequirements.oneDigit")}/>
                </div>
                <br/>
            </div>
            <div className="data-panel__user-settings__input-row">
                <div className="data-panel__user-settings__input-row__text">
                    <span>
                        {`${t("userPanel.settings.enterNewPassword")}:`}
                    </span>
                </div>
                <div className="data-panel__user-settings__input-row__input-wrapper">
                    <input type="password" placeholder={`${t("userPanel.settings.newPassword")}`} name="new-password" value={password} onChange={handleChangePassword} />
                </div>
            </div>
            <div className="data-panel__user-settings__spacer" />
            <div className="data-panel__user-settings__input-row">
                <div className="data-panel__user-settings__input-row__text">
                    <span>
                        {`${t("userPanel.settings.repeatNewPassword")}:`}
                    </span>
                </div>
                <div className="data-panel__user-settings__input-row__input-wrapper">
                    <input type="password" placeholder={`${t("userPanel.settings.newPassword")}`} name="new-password" value={passwordConfirm} onChange={handleChangePasswordConfirm} />
                </div>
            </div>
            <div className="data-panel__user-settings__spacer" />

            <div className="data-panel__change-password__requirements">
                <span className="data-panel__change-password__requirements__list-name">
                    {`${t("userPanel.settings.passwordAndPasswordConfirmMustMatch")}:`}
                </span>
                <span className={handleSameNewPasswordTextClass()}>
                    {passwordsAreTheSameCheckboxValue? t("userPanel.settings.yesBig") : t("userPanel.settings.noBig")}
                </span>
            </div>
            
            <div className="data-panel__user-settings__button-wrapper" onClick={isValid? submitChangePassword : ()=>{}}>
                { handleNotification(displayGoodNotification, displayBadNotification) }
                <div className={handleSubmitButtonClass()}>
                    <span>
                        {t("userPanel.settings.updatePassword")}
                    </span>
                </div>
            </div>
        </div>
    );
}