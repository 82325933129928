import { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import "./mainPage.scss";

import { DownLeftRay } from "./graphics/DownLeftRay";
import { CheckMark } from "./graphics/CheckMark";
import { Play } from "./graphics/Play";
import { ArrowGraphic } from "./graphics/ArrowGraphic";


import { OfferBoxes } from "./OfferBoxes/OfferBoxes";
import { Carousel } from "./Carousel/Carousel";
import { Opinions } from "./Opinions/Opinions";
import { Features } from "./Features/Features";
import { CourseSellSection } from "./CourseSellSection/CourseSellSection";

import { LandingFooter } from "../LandingFooter/LandingFooter";
import { LandingNavbar } from "../LandingNavbar/LandingNavbar";
import { AuthContext } from "../../components/auth/authContext/AuthContextProvider";
import { VideoPreview } from "./VideoPreview/VideoPreview";

import { TeamSection } from "./TeamSection/TeamSection";
import { MessangerIcon } from "./Messanger/messangerIcon";


interface BulletPointProps {
    text: string
}

const BulletPoint = (props: BulletPointProps) => {
    return (
        <div className="main-page__introduction__left-section__bullet-points__bullet-point">
            <CheckMark/>
            <span>{props.text}</span>  
        </div>
    )
}

export const MainPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const authContext = useContext(AuthContext);

    const ref = useRef<HTMLElement | null>(null);

    const [wasMessengerHovered, SetWasMessengerHovered] = useState(false);

    const handleScrollToClick = () => {
        if(ref.current !== null) {
            ref.current.scrollIntoView({ 
                behavior: 'smooth',
            });
        }
    };
    
    const bulletTexts = [
        t("landing.bulletPoints.innovativeAndEfficient"),
        t("landing.bulletPoints.coursesPrepByExperts"),
        t("landing.bulletPoints.mathOnAcademicLevel"),
        t("landing.bulletPoints.specializedEnginneringSubjects"),
    ];

    return (
        <div className="main-page">

            <LandingNavbar/>
            
            <div className="main-page__introduction">
                <DownLeftRay/>
                {/* <TopRightRay/> */}
                    <img 
                        className="test"
                        alt="" src="https://na-sesje-public.s3.eu-central-1.amazonaws.com/landing-page/arena.svg"
                    />
                <div className="main-page__introduction__left-section">
                    <div className="main-page__introduction__left-section__headline">
                        <span className="main-page__introduction__left-section__headline__text-1">
                            {t("landing.withOurPlatform")}&nbsp;
                        </span>
                        <span className="main-page__introduction__left-section__headline__text-2">
                            {t("landing.fearBeforeSession")}
                        </span>
                    </div>
                    <div className="main-page__introduction__left-section__bullet-points">
                        {
                            bulletTexts.map((el, index) => <BulletPoint key={index} text={el}/>)
                        }
                    </div>
                    <div className="main-page__introduction__left-section__buttons">
                        {
                            authContext.loggedIn ? (
                                <div 
                                    className="main-page__introduction__left-section__buttons__button main-page__introduction__left-section__buttons__button--left yellow-button"
                                    onClick={() => {
                                        window.scrollTo(0, 0);
                                        navigate('/userPanel')
                                    }}
                                >
                                    <span>
                                        {t("landing.goToPlatform")}
                                    </span>
                                </div>
                            ) : (
                                <div 
                                    className="main-page__introduction__left-section__buttons__button main-page__introduction__left-section__buttons__button--left yellow-button"
                                    onClick={() => { 
                                        window.scrollTo(0, 0);
                                        navigate('/shop')
                                    }}
                                >
                                    <span>
                                        {t("landing.goToShop")}
                                    </span>
                                </div>
                            )           
                        }
                        <div 
                            className="main-page__introduction__left-section__buttons__button main-page__introduction__left-section__buttons__button--right"
                            onClick={ handleScrollToClick }
                        >
                            <span>
                                {t("landing.seeHowWeOperate")}
                            </span>
                            <Play/>
                        </div>
                    </div>
                </div>
                <div  className="main-page__introduction__right-section">
                    <div>

                    </div>
                </div>
            </div>

            <div>
            </div>

            <TeamSection/>
            

            <Carousel/>

            <div className="main-page__final-test-information">
                    <span>
                        {t("landing.whatsMore")}
                        <b>{` ${t("landing.crossSectionalTest")} `}</b>
                        {t("landing.withPreciseExplanation")}
                    </span>
            </div>

            {/* <Opinions/> */}

            <Features/>


            <OfferBoxes/>

            <div ref={ref as React.Ref<HTMLDivElement>}/>
            <VideoPreview/>

            <CourseSellSection/>

            <div className="main-page__go-to-offers">
                <div className="main-page__go-to-offers__wrapper">
                    <div className="main-page__go-to-offers__wrapper__headline"> 
                        <span>
                            {t("landing.andNowTheBest")}:
                        </span>
                    </div>
                    <div className="main-page__go-to-offers__wrapper__main-text">
                        <span>
                            {t("landing.hoursOfRecordings")}
                            <b>&nbsp;{t("landing.inPriceOfOnly3Hours")}</b>.
                        </span>
                    </div>
                    <div 
                        className="main-page__go-to-offers__wrapper__button yellow-button"
                        onClick={() => {
                            window.scrollTo(0, 0);
                            navigate("/shop")
                        }}
                    >
                        <span>
                            {t("landing.findYourFirstCourse")}
                        </span>
                        <ArrowGraphic/>
                    </div>
                </div>
            </div>


            <div className='main-page__messenger' onMouseLeave={() => SetWasMessengerHovered(true)} onClick={()=> {
                const url = "https://m.me/61558293877112";
                window.open(url, '_blank');
            }}>
                <MessangerIcon/>
            </div>

            <div className={`main-page__messanger-text ${wasMessengerHovered ? 'main-page__messanger-text--inactive' : ''}`}>
                <span>
                    👋 Jak możemy ci pomóc?
                </span>
            </div>
            

            <LandingFooter/>

        </div>
    );
}