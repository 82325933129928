import { Dispatch, SetStateAction, useContext } from "react";
import { QuizContext } from "../../QuizContextProvider";
import { QuizAnswer } from "./quizAnswer/QuizAnswer";
import { QuizAnswerFallback } from "./quizAnswer/quizAnswerFallback/QuizAnswerFallback";

interface QuizAnswersProps {
    handleClickAnswer: (userAnswerId: number, correctQuestionId: number) => Promise<void>;
    activeMobileIndex: number;
    setActiveMobileIndex: Dispatch<SetStateAction<number>>;
}

export const QuizAnswers = (props: QuizAnswersProps) => {    
    const quizContext = useContext(QuizContext);

    const getAnswers = () => {
        return quizContext.quizes[quizContext.currentQuiz].answers;
    }

    const getCorrectQuestionId = () => {
        return quizContext.quizes[quizContext.currentQuiz].correctAnswerId
    }

    return (
        <div className="data-panel__quiz__anwsers">

            { 
                ['A', 'B', 'C', 'D'].map( (label, index) => 
                    <QuizAnswer 
                    key={index}
                    currentIndex={index}
                    label={label}
                    activeMobileIndex={props.activeMobileIndex}
                    setActiveMobileIndex={props.setActiveMobileIndex}
                    src={getAnswers()[index].imageSrc} 
                    id={getAnswers()[index].id}
                    correctQuestionId={getCorrectQuestionId()}
                    handleClickAnswer={props.handleClickAnswer}
                    srcIsImage={getAnswers()[index].srcIsImage}
                    />   
                )
            }

        </div>
    )
}