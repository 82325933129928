import { Dispatch, ReactNode, SetStateAction, useEffect, useLayoutEffect, useRef, useState } from "react";
import { SingleExcerciseButton } from "./singleExcerciseButton/SingleExcerciseButton";
import "./testTopNavigation.scss";
import { ExpandButton } from "./expandButton/ExpandButton";
import { CloseAndSaveButton } from "./closeAndSaveButton/CloseAndSaveButton";
import MediaQuery from "react-responsive";
import { useMediaQuery } from "../../../../hooks/useMediaQuery";

interface TestTopNavigationProps {
    count: number;
    currentExcercise: number;
    setCurrentExcercise: Dispatch<SetStateAction<number>>;
    isFinishedAll: Array<boolean>;
    onSaveAndFinishedAction: () => void;
}

export const TestTopNavigation = (props: TestTopNavigationProps) => {
    const [singleButtons, setSingleButtons] = useState<ReactNode[]>([]);
    const [expand, setExpand] = useState(false);
    const [displayExpanded, setDisplayExpanded] = useState(false);
    const divRef = useRef<HTMLDivElement | null>(null);
    const [buttonGroupSize, setButtonGroupSize] = useState(Math.min(props.count, 7));
    const [numberOfButtonGroups, setNumberOfButtonGroups] = useState(0);

    const isDesktop = useMediaQuery('(min-width: 951px)');
    const isMobile = useMediaQuery('(max-width: 450px)');

    useEffect(() => {
        if(isMobile){
            setButtonGroupSize(Math.min(props.count, 5));
            return;
        }

        setButtonGroupSize(Math.min(props.count, 8));

    }, [isMobile, isDesktop])

    const handleExpandedButtons = () => {
        const buttonGroups: ReactNode[][] = [];
        const numberOfButtonGroups = Math.floor(props.count / buttonGroupSize) + 1; //+1 for expand button
        
        setNumberOfButtonGroups(numberOfButtonGroups);

        for(let i=0; i<numberOfButtonGroups; i++){
            buttonGroups.push([]);
        }



        let buttonGroupCounter = 0;
        for(let i=0; i<props.count; i++){
            if((i % (buttonGroupSize) === 0) && i >= buttonGroupSize){
                buttonGroupCounter += 1;
            }
            
            buttonGroups[buttonGroupCounter].push(<SingleExcerciseButton fullBorder={true} excerciseCount={props.count} visibleIndices={undefined} excerciseNumber={i} key={i} isFinished={props.isFinishedAll[i]} currentExcercise={props.currentExcercise} setCurrentExcercise={props.setCurrentExcercise}/>)
        }

        const finalGroupsInWrappers = buttonGroups.map((group, gIndex) => {
            return(
                <div key={gIndex}>
                        <div style={{display: "flex", flexDirection: "row"}}>{group.map((el, index) => {return el})}</div>
                </div>)});
        
        setSingleButtons(finalGroupsInWrappers);
    }

    const handleNotExpandedButtons = () => {
        const singleButtons: ReactNode[] = [];

        let startIndex = props.currentExcercise;

        let endIndex = startIndex + buttonGroupSize;

        const middleIndex = Math.floor(buttonGroupSize / 2);
        if(startIndex <= middleIndex){
            startIndex = props.currentExcercise - startIndex;
            endIndex = buttonGroupSize;
        } else {
            const currentEndDifference = props.count - props.currentExcercise;
            if(startIndex >= props.count - middleIndex){
                startIndex = props.currentExcercise - (buttonGroupSize - currentEndDifference);
                endIndex = props.count;
            } else {
                startIndex = props.currentExcercise - middleIndex;
                endIndex -= middleIndex;
            }
        }

        const visibleIndices: Array<number> = [];

        for(let i=startIndex; i<endIndex; i++){
            visibleIndices.push(i);
        }

        for(let i=startIndex; i<endIndex; i++){
            singleButtons.push(<SingleExcerciseButton fullBorder={false} excerciseCount={props.count} visibleIndices={visibleIndices}  excerciseNumber={i} key={i} isFinished={props.isFinishedAll[i]} currentExcercise={props.currentExcercise} setCurrentExcercise={props.setCurrentExcercise}/>)
        }

        setSingleButtons(singleButtons);
    }

    useEffect(() => {
        setDisplayExpanded(expand);
    }, [])

    useLayoutEffect(() => {
        if(displayExpanded){
            handleExpandedButtons();
        } else {
            handleNotExpandedButtons();
        }
    }, [props.currentExcercise, displayExpanded, buttonGroupSize]);

    useEffect(() => {
        let timeout: NodeJS.Timeout;

        if(!expand) {
            timeout = setTimeout(() => {
                setDisplayExpanded(expand);
            }, 200);
        } else {
            setDisplayExpanded(expand);
        }

        return () => clearTimeout(timeout);
      }, [expand]);

    useEffect(() => {
        if (divRef.current) {
            console.log(`${numberOfButtonGroups} - print`);
            divRef.current.style.setProperty('--excercise-nav-height', `${numberOfButtonGroups * 55}px`);
        }

    }, [singleButtons, buttonGroupSize, numberOfButtonGroups]);

    useEffect(() => {
        
    }, [buttonGroupSize])
    
    return <>
    <div className="data-panel__mainTest__nav">
        <div style={{display: displayExpanded ? 'block' : 'none'}}>
            <div className={`data-panel__mainTest__nav__buttons__wrapperExpanded--desktop ${expand ? 'expanded' : 'not-expanded'}`} ref={divRef}>
                <MediaQuery minWidth={951}>
                    <CloseAndSaveButton onSaveAndFinishedAction={props.onSaveAndFinishedAction}/>
                        <div className="data-panel__mainTest__nav__buttons--expanded-buttons">
                            {singleButtons.map(el => {return el})}
                        </div>
                    <ExpandButton expand={expand} setExpand={setExpand}/>
                </MediaQuery>
            </div>
            <MediaQuery maxWidth={950}>
                <div className={`data-panel__mainTest__nav__buttons__wrapperExpanded--mobile ${expand ? 'expanded' : 'not-expanded'}`} ref={divRef}>
                    <div className="data-panel__mainTest__nav__buttons__wrapper-close-and-expand--mobile">
                        <CloseAndSaveButton onSaveAndFinishedAction={props.onSaveAndFinishedAction}/>
                        <ExpandButton expand={expand} setExpand={setExpand}/>
                    </div>
                    <div className="data-panel__mainTest__nav__buttons__wrapperNotExpanded">
                        <div className="data-panel__mainTest__nav__buttons--expanded-buttons">
                            {singleButtons.map(el => {return el})}
                        </div>
                    </div>
                </div>
            </MediaQuery>
        </div>
        <div style={{display: displayExpanded ? 'none' : 'block'}} className="data-panel__mainTest__nav__buttons__wrapperNotExpanded">
            <div className="data-panel__mainTest__nav__buttons--notExpanded">
                <MediaQuery minWidth={951}>
                    <CloseAndSaveButton onSaveAndFinishedAction={props.onSaveAndFinishedAction}/>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        {singleButtons.map(el => {return el})}
                    </div>
                    <ExpandButton expand={expand} setExpand={setExpand}/>
                </MediaQuery>
            </div>
            <MediaQuery maxWidth={950}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <div className="data-panel__mainTest__nav__buttons__button-wrapper" style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <CloseAndSaveButton onSaveAndFinishedAction={props.onSaveAndFinishedAction}/>
                        <ExpandButton expand={expand} setExpand={setExpand}/>
                    </div>
                    <div className="data-panel__mainTest__nav__buttons--notExpanded">
                        {singleButtons.map(el => {return el})}
                    </div>
                </div>
            </MediaQuery>
            
        </div>
    </div>
    <div className="data-panel__mainTest__nav__spacer-bottom"/>
    </>
}