import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { langPl } from './translations/pl';
import { langUa } from './translations/ua';


i18n
    .use(initReactI18next)
    .init({
      react: {
        useSuspense: true
      },
        debug: false, //jakby coś się jebało to pamiętać o tej fladze
        resources : {
            pl: langPl,
            ua: langUa
        },
        lng: "pl",
        fallbackLng: "pl",
        interpolation: { escapeValue: false}
        });

export default i18n;