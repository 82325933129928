import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";

import "../userSettings.scss";

import { get, patch } from "../../../../utilities/rest/apiClient";
import { AuthContext } from "../../../auth/authContext/AuthContextProvider";
import { notificationsSettings, notificationsSettingsDto } from "../../../../../../shared/userSettingsDto";
import { UserPanelPageProps } from "../../UserPanel";

const FallbackCheckbox = () => {
    return <input type="checkbox" className="skeleton"/>;
}

export const ChangeNotifications = (props: UserPanelPageProps) => {
    const { t } = useTranslation();

    const [notificationsSettings, setNotificationsSettings] = useState({} as notificationsSettings);
    const [isLoading, setIsLoading] = useState(true);
    const authContext = useContext(AuthContext);

    const patchNotificationSettings = async (notificationsSettings: notificationsSettings) => {
        await patch<any, notificationsSettings>(`users/userSettings`, notificationsSettings);
        setNotificationsSettings(notificationsSettings);
    }
    
    const handleProgressChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        patchNotificationSettings({...notificationsSettings, progressInformations: event.target.checked});
    }

    const handleDiscountsChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        patchNotificationSettings({...notificationsSettings, discountsInformations: event.target.checked});
    }

    const handleNewCoursesInformationsChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        patchNotificationSettings({...notificationsSettings, newCoursesInformations: event.target.checked});
    }

    const handleCourseExpirationInformationsChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        patchNotificationSettings({...notificationsSettings, courseExpirationInformations: event.target.checked});
    }

    const handleProductsInformationsChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        patchNotificationSettings({...notificationsSettings, productsInformations: event.target.checked});
    }

    useEffect(() => {
        props.setActivePanel("changeNotifications");
        const activeChapter = props.activePanelNameToIndexMap.get("changeNotifications");
        if(activeChapter){
            props.setActiveChapter(activeChapter);
        }

        (async ()=> {
            try {
              setIsLoading(true);
              const data = await get<notificationsSettingsDto>(`users/userSettings`);
              setNotificationsSettings(data.notificationsSettings);
              setIsLoading(false);
            } catch(error) {
                authContext.handleAuthErrors(error);
            }
          })()
    }, [])

    return (
        <div className="data-panel__change-notifications data-panel__user-settings">
            <div className="data-panel__user-settings__description">
                <h4>{t("userPanel.settings.notificationsSettings")}</h4>
            </div>
            <div className="data-panel__user-settings__input-row">
                <div className="data-panel__user-settings__input-row__text">
                    <span>{t("userPanel.settings.progressInformations")}</span>
                </div>
                <div className="data-panel__user-settings__input-row__checkbox">
                    {isLoading ? <FallbackCheckbox/> : <input data-testid='real-checkbox' type="checkbox" id="click" checked={notificationsSettings.progressInformations} onChange={event => handleProgressChange(event)}/>}
                </div>
            </div>
            <div className="data-panel__user-settings__spacer" />
            <div className="data-panel__user-settings__input-row">
                <div className="data-panel__user-settings__input-row__text">
                    <span>{t("userPanel.settings.discountInformations")}</span>
                </div>
                <div className="data-panel__user-settings__input-row__checkbox">
                    {isLoading ? <FallbackCheckbox/> : <input type="checkbox" id="click" checked={notificationsSettings.discountsInformations} onChange={event => handleDiscountsChange(event)}/>}
                </div>
            </div>
            <div className="data-panel__user-settings__spacer" />
            <div className="data-panel__user-settings__input-row">
                <div className="data-panel__user-settings__input-row__text">
                    <span>{t("userPanel.settings.newCoursesInformations")}</span>
                </div>
                <div className="data-panel__user-settings__input-row__checkbox">
                    {isLoading ? <FallbackCheckbox/> : <input type="checkbox" id="click" checked={notificationsSettings.newCoursesInformations} onChange={event => handleNewCoursesInformationsChange(event)}/>}
                </div>
            </div>
            <div className="data-panel__user-settings__spacer" />
            <div className="data-panel__user-settings__input-row">
                <div className="data-panel__user-settings__input-row__text">
                    <span>{t("userPanel.settings.courseExpirationInformations")}</span>
                </div>
                <div className="data-panel__user-settings__input-row__checkbox">
                    {isLoading ? <FallbackCheckbox/> : <input type="checkbox" id="click" checked={notificationsSettings.courseExpirationInformations} onChange={event => handleCourseExpirationInformationsChange(event)}/>}
                </div>
            </div>
            <div className="data-panel__user-settings__text data-panel__user-settings__text--middle-margin">
                <span>
                    {t("userPanel.settings.textSpacer")}
                </span>
            </div>
            <div className="data-panel__user-settings__input-row">
                <div className="data-panel__user-settings__input-row__text">
                    <span>{t("userPanel.settings.productsInformations")}</span>
                </div>
                <div className="data-panel__user-settings__input-row__checkbox">
                    {isLoading ? <FallbackCheckbox/> : <input type="checkbox" id="click" checked={notificationsSettings.productsInformations} onChange={event => handleProductsInformationsChange(event)}/>}
                </div>
            </div>
            <div className="data-panel__user-settings__spacer" />
        </div>
    );
}