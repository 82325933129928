import { CourseVideoChapterDto } from "../../../../../shared/courseVideoChapterDto";
import { post } from "../../../utilities/rest/apiClient";

export const checkIfChapterIsCompleted = (courseChapterDto: CourseVideoChapterDto) => {
    for(let i=0; i<courseChapterDto.lessons.length; i++){
        const lesson = courseChapterDto.lessons[i];
        const lessonNumber = i+1;
        const lessonId = lesson.id;

        if(lesson.openTestsCount === lesson.completedOpenTestsIndex + 1 && lesson.completedQuickTests === lesson.quickTestsCount && lesson.notesVisited){
            post(`userChapters/completeLesson/${lessonId}/${lessonNumber}`, {})
            .then((data) => {
                console.log(`completed ${lessonNumber}`);})
            .catch((error) => {console.log(error)});
        }
    }
}