import * as React from "react"
export const TikTok = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    // style={{
    //   enableBackground: "new 0 0 64 64",
    // }}
    viewBox="0 0 64 64"

  >
    <style>{".st11{fill:#ff004f}.st12{fill:#00f7ef}.st14{fill:#ffff}"}</style>
    <g id="Layer_11">
      <path
        d="M58 19.4v9.3c-.5 0-1.1.1-1.7.1-4.5 0-8.7-1.7-11.9-4.4v19.8c0 4-1.3 7.8-3.6 10.8-3.2 4.3-8.4 7.2-14.3 7.2-6.4 0-12-3.4-15.1-8.4 3.2 3 7.5 4.9 12.2 4.9 5.8 0 11-2.8 14.2-7.2 2.2-3 3.6-6.7 3.6-10.8V20.8c3.2 2.8 7.3 4.4 11.9 4.4.6 0 1.1 0 1.7-.1v-6c.9.2 1.7.3 2.6.3h.4z"
        className="st11"
      />
      <path
        d="M29 26.3v10.3c-.7-.2-1.5-.3-2.2-.3-4.4 0-8 3.7-8 8.2 0 1 .2 1.9.5 2.8-2-1.5-3.4-3.9-3.4-6.6 0-4.5 3.6-8.2 8-8.2.8 0 1.5.1 2.2.3v-6.6h.6c.8 0 1.6 0 2.3.1zM45.9 12c-1.8-1.6-3.1-3.8-3.8-6.1h2.4v1.4c.2 1.6.7 3.2 1.4 4.7z"
        className="st11"
      />
      <path d="M55.1 19.2v6c-.5.1-1.1.1-1.7.1-4.5 0-8.7-1.7-11.9-4.4v19.8c0 4-1.3 7.8-3.6 10.8-3.3 4.4-8.4 7.2-14.2 7.2-4.7 0-9-1.9-12.2-4.9-1.7-2.8-2.7-6-2.7-9.5 0-9.7 7.7-17.6 17.3-17.9V33c-.7-.2-1.5-.3-2.2-.3-4.4 0-8 3.7-8 8.2 0 2.7 1.3 5.2 3.4 6.6 1.1 3.1 4.1 5.4 7.5 5.4 4.4 0 8-3.7 8-8.2V5.9h7.3c.7 2.4 2 4.5 3.8 6.1 1.8 3.6 5.2 6.3 9.2 7.2z" 
            className="st14"
      />
      <path
        d="M26.1 22.8v3.4c-9.6.3-17.3 8.2-17.3 17.9 0 3.5 1 6.7 2.7 9.5C8.1 50.3 6 45.7 6 40.5c0-9.9 8-17.9 17.8-17.9.8 0 1.6.1 2.3.2z"
        className="st12"
      />
      <path
        d="M42.1 5.9h-7.3v38.6c0 4.5-3.6 8.2-8 8.2-3.5 0-6.4-2.2-7.5-5.4 1.3.9 2.9 1.5 4.6 1.5 4.4 0 8-3.6 8-8.1V2h9.7v.2c0 .4 0 .8.1 1.2 0 .8.2 1.7.4 2.5zM55.1 15.5v3.6c-4-.8-7.4-3.5-9.3-7.1 2.5 2.3 5.7 3.6 9.3 3.5z"
        className="st12"
      />
    </g>
  </svg>
)
