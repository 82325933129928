import { useTranslation } from "react-i18next";
import { CourseChapterFallback } from "../courseChapter/courseChapterFallback/CourseChapterFallback";

export const CourseNavigationFallback = () => {
    const { t } = useTranslation();

    return (
        //Nawigacja po rozdziałach
        <div id="navigation-panel">
            <div className="chapter chapter--main">
                <div className="chapter__name"> <div className="skeleton"/></div>
                <div className="chapter__data-space">
                    <CourseChapterFallback numberOfLines={6}/>
                </div>
            </div>
            <div className="chapter">
                <div className="chapter__data-space">
                    <div className="chapter__name"> <div className="skeleton"/></div>
                    <CourseChapterFallback numberOfLines={3}/>
                </div>
            </div>
        </div>
    );
}