import { useTranslation } from "react-i18next";
import { TextFallback } from "../../../../shared/components/fallbacks/skeleton/TextFallback";
import { NotesLinesFallback } from "../../notes/NotesLinesFallback";
import { AnswerElement } from "./QuizTest";
import { useState } from "react";
import { QuizAnswerFallback } from "./quizAnswers/quizAnswer/quizAnswerFallback/QuizAnswerFallback";

export const QuizTestFallback = () => {
    const { t } = useTranslation();
    const [activeMobileIndex, setActiveMobileIndex] = useState(0);
    const [firstMobileClicked, setFirstMobileClicked] = useState(false);
    const mobilePanelsMaxIndex = 3;
    
    return (
        <div className="data-panel__quiz">
                  <div className="data-panel__quiz__name">
                    <TextFallback variant="h4" text={`${t("kurs.quiz.pytanie")}: ${0} / ${0}`}/>
                  </div>

                  <NotesLinesFallback numberOfLines={4}/>

                  <div className="data-panel__quiz__command">
                    <span><TextFallback variant="h4" text={t("kurs.quiz.wybierzPoprawnaOdpowiedz")}/></span>
                  </div>
                    <div className="data-panel__quiz__anwsers">
                        <QuizAnswerFallback hideMobile={false}/>
                        <QuizAnswerFallback hideMobile={true}/>
                        <QuizAnswerFallback hideMobile={true}/>
                        <QuizAnswerFallback hideMobile={true}/>
                    </div>
                  <div className="data-panel__quiz__mobile-nav-bar">
                    
                    <div 
                      className={`data-panel__quiz__mobile-nav-bar__button data-panel__quiz__mobile-nav-bar__button--${(activeMobileIndex !== 0) ? "active" : "inactive"}`}
                      onClick={() => { 
                        if(activeMobileIndex !== 0) setActiveMobileIndex(activeMobileIndex-1);
                        if(!firstMobileClicked) setFirstMobileClicked(true);
                      } } 
                    >
                      <span className="material-symbols-outlined">
                        arrow_back_ios
                      </span>
                    </div>

                    <div className="data-panel__quiz__mobile-nav-bar__wrapper">
                      {
                        ['A', 'B', 'C', 'D'].map((el, index) => <AnswerElement key={index} index={index} symbol={el} activeIndex={activeMobileIndex}/>)
                      }
                    </div>

                    <div 
                      className={`data-panel__quiz__mobile-nav-bar__button data-panel__quiz__mobile-nav-bar__button--${(activeMobileIndex !== mobilePanelsMaxIndex) ? "active" : "inactive"}`}
                      onClick={() => { 
                        if(activeMobileIndex !== mobilePanelsMaxIndex) setActiveMobileIndex(activeMobileIndex+1);
                        if(!firstMobileClicked) setFirstMobileClicked(true);
                      } }  
                    >
                      <span className="material-symbols-outlined">
                        arrow_forward_ios
                      </span>
                    </div>

                  </div>

                  {
                    (firstMobileClicked) ? "" : (
                      <div className="data-panel__quiz__mobile-tip">
                        <div className="data-panel__quiz__mobile-tip__symbol">
                          <span className="material-symbols-outlined">
                            emoji_objects
                          </span>
                        </div>
                        <div className="data-panel__quiz__mobile-tip__text">
                          <span>
                            {t("kurs.quiz.uzyjStrzalekAbyWybrazOdpowiedz")}
                          </span>
                        </div>
                      </div>
                    )
                  }
                </div>
      ); 
}