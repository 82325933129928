interface QuizAnswerFallbackProps {
    hideMobile: boolean;
}

export const QuizAnswerFallback = (props: QuizAnswerFallbackProps) => {
    return (
        <div className={`data-panel__quiz__anwsers__anwser${props.hideMobile ? " data-panel__quiz__anwsers__anwser--hide-mobile" : ""} skeleton`}>
            <div className="data-panel__quiz__anwsers__anwser__index skeleton">{""}</div>
            <img alt="" className="skeleton skeleton__img" src="" />
        </div>
    );
}