import * as React from "react"

export const Shield = () => (
  <svg
  className="shield"
  xmlns="http://www.w3.org/2000/svg"
  width={533}
  height={533}
>
  <defs>
    <clipPath id="a" clipPathUnits="userSpaceOnUse">
      <path d="M0 400h400V0H0Z" />
    </clipPath>
    <clipPath id="b" clipPathUnits="userSpaceOnUse">
      <path d="M0 400h400V0H0Z" />
    </clipPath>
  </defs>
  <g
    clipPath="url(#a)"
    style={{
      strokeWidth: 28.50000071,
      strokeDasharray: "none",
    }}
    transform="matrix(1.33333 0 0 -1.33333 0 533.333)"
  >
    <g
      style={{
        strokeWidth: 28.50000071,
        strokeDasharray: "none",
      }}
    >
      <path
        d="m0 0 100.798-44.799v-156.797L0-283.355"
        style={{
          fill: "none",
          stroke: "#a29b9a",
          strokeWidth: 28.50000071,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(200 341.677)"
      />
    </g>
  </g>
  <path
    d="m0 0-100.798 81.759v156.797L0 283.355"
    style={{
      fill: "#a29b9a",
      fillOpacity: 1,
      fillRule: "nonzero",
      stroke: "none",
    }}
    transform="matrix(1.33333 0 0 -1.33333 266.667 455.57)"
  />
  <g
    clipPath="url(#b)"
    style={{
      paintOrder: "markers fill stroke",
      strokeWidth: 28.50000071,
      strokeDasharray: "none",
    }}
    transform="matrix(1.33333 0 0 -1.33333 0 533.333)"
  >
    <g
      style={{
        paintOrder: "markers fill stroke",
        strokeWidth: 28.50000071,
        strokeDasharray: "none",
      }}
    >
      <path
        d="m0 0-100.798 81.759v156.797L0 283.355"
        style={{
          fill: "none",
          stroke: "#a29b9a",
          strokeWidth: 28.50000071,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
          strokeDasharray: "none",
          strokeOpacity: 1,
          paintOrder: "markers fill stroke",
        }}
        transform="translate(200 58.322)"
      />
    </g>
  </g>
</svg>
)
