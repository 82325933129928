import "./skeleton.scss";

type Variant = 'normal' | 'h4';
type Fill = 'narrow' | 'medium' | 'wide';

export interface TextFallbackProps {
    variant: Variant;
    text?: string | null;
    fill?: Fill;
    additionalClasses?: string;
}

export const TextFallback = (props: TextFallbackProps) => {
    let classes = "skeleton";

    if(props.variant !== 'h4') {
        classes += " skeleton__text "
    }

    if(props.fill === 'narrow') {
        classes += "skeleton__text--narrow";
    } else if(props.fill === 'medium') {
        classes += "skeleton__text--medium";
    } else if(props.fill === "wide") {
        classes += "skeleton__text--wide";
    }

    if(props.additionalClasses) {
        classes += props.additionalClasses;
    }

    if(props.variant === 'normal') {
        return <div className={classes}>{props.text}</div>
    } else if(props.variant === 'h4') {
        return <h4 className={classes}>{props.text}</h4>
    }

    return <></>
}