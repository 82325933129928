import { useTranslation } from "react-i18next";
import "../../../components/auth/login/login.scss";
import { ChangeEvent, SetStateAction, useEffect, useState } from "react";
import { emailRegex } from "../../Checkout/Checkout";
import { Dispatch } from "react";
import { get, post } from "../../../utilities/rest/apiClient";
import { useParams } from "react-router-dom";

interface NotifyMeWhenAvailableProps {
    setBuyNowClicked: Dispatch<SetStateAction<boolean>>;
}

export const NotifyMeWhenAvailable = (props: NotifyMeWhenAvailableProps) => {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const {courseId} = useParams();
    const [emailSent, setEmailSent] = useState(false);
    const [finalMessage, setFinalMessage] = useState("");
    const [error, setError] = useState(false);

    useEffect(() => {
        (async ()=> {
            try{
                const data = await get<any>("users/emailSettings");
                setEmail(data.email);
                setValidEmail(emailRegex.test(data.email) && (data.email.length !== 0));
            } catch (error) {
                //tutaj nic nie trzeba robić bo jak ejst error to sam sobie tego maila wpisze
            }
        })()
    }, [])

    const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        const new_email = e.target.value;
        
        setEmail(new_email);
  
        setValidEmail(emailRegex.test(new_email) && (new_email.length !== 0));
    };

    return <>
        <div className="checkout-wrapper not-selectable">
            <div 
                className="checkout"
                style={{height: '350px', maxHeight: '566px'}}
            >

            
                    <div className="checkout__signup-box">

                        <div className="checkout__signup-box__title">
                            <div>
                                <span className="material-symbols-outlined">
                                    email
                                </span>
                                <span>{`${t("checkout.notifyMe")}`}</span>
                            </div>
                            <div 
                                className="checkout__signup-box__title__mobile-close-button"
                                onClick={() => {
                                    let html = document.querySelector('html');
                                    if(html !== null) html.classList.remove('html-not-scrollable');
                                    document.body.classList.remove('body-not-scrollable');
            
                                    props.setBuyNowClicked(false);
                                }
                            }    
                            >
                                <span className="material-symbols-outlined">
                                    close
                                </span>
                            </div>
                        </div>

                        <>
                            <div className={`checkout__signup-box__login-ending checkout__signup-box__login-ending--displayed`}>

                                <div className="checkout__signup-box__login-ending__login-text">
                                    <div className="checkout__signup-box__login-ending__login-text__headline">
                                        <span>
                                            {t("checkout.courseIsBeingCreated")}
                                        </span>
                                    </div>
                                    <div className="checkout__signup-box__login-ending__login-text__main-text">
                                        <span>
                                            {t("checkout.giveMail")}
                                        </span>
                                    </div>
                                </div>

                                <div className={`checkout__signup-box__email-box--notify visible`}>
                                    <span>
                                        {t("checkout.emailAddress")}
                                    </span>
                                    <input
                                        className={
                                            (email.length !== 0) ? (validEmail ? "valid" : "invalid") : "" 
                                        }

                                        style={{boxShadow: "none"}}

                                        type="text"
                                        id="username"
                                        value={email}
                                        onChange={handleEmailChange}
                                    />
                                    { validEmail ? <span className="material-symbols-outlined material-symbols-outlined--valid"> done </span> : ""}    
                                    { ((!validEmail) && (email.length !== 0)) ? <span className="material-symbols-outlined material-symbols-outlined--invalid">warning</span>: ""}
                                </div>
                                <div style={{marginBottom: "18px", height: "18px", color: `${error? "red": ""}`}}>{emailSent? finalMessage : ""}</div>
                            </div>
                            
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                                <button 
                                    className={`send-notification-when-available ${validEmail ? "active" : "inactive"}`}
                                    onClick={() => {
                                        if(!validEmail) return;

                                        post<any, any>('notifyWhenAvailable', {email, courseId})
                                        .then(() => {
                                            setFinalMessage("Wiadomość została wysłana")
                                        }).catch(e => {
                                            setFinalMessage("Coś poszło nie tak, spróbuj ponownie później")
                                            setError(true);
                                        }).finally(() => {
                                            setEmailSent(true);
                                        })
                                    }}
                                >Wyślij</button>
                            </div>
                        </> 
                    </div>
                    
            </div>
        </div>
    </>
}