// import { svgServerUrl } from "../../../../dummyUtilities/svgServer";
import { Block, BlockType, ProgrammingLanguage } from "../../../../../../shared/block";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { nightOwl as codeStyle } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { useTranslation } from "react-i18next";
import "./block.scss"
import Latex from "react-latex";

interface BlockProps {
    content: string;
    prefix: string | null;
    type?: BlockType;
}

const TextBlock = (props: BlockProps) => {
    const className = props.prefix ? `${props.prefix}__text` : "";
    return (
        <div className={className}>
            <Latex>{props.content}</Latex>
        </div>
    );
}

const BreakeBlock = () => <br/>;

const ImageBlock = (props: BlockProps) => {
    return (
        <div className={`${props.prefix}__img-wrapper`}>
            <img alt="" src={props.content} />
        </div>
    )
}

const CodeBlock = (props: BlockProps) => {
    const className = props.prefix ? `${props.prefix}__text` : "";
    const { t } = useTranslation();

    const copyToClipboard = async (text: string) => {
        await navigator.clipboard.writeText(text);
    };

    return (
        <div className={className + " " + "block__code"}>
            <div className="block__code__button-wrapper">
                <div onClick={() => copyToClipboard(props.content)} className="block__code__button">{t("code.copy")}</div>
            </div>
            <div className="block__code__button-wrapper">
                <div className="block__code__programming-language">{props.type}</div>
            </div>
            <SyntaxHighlighter language={props.type} style={codeStyle}
                customStyle={{marginTop: '0px', marginBottom: '0px', borderRadius: '10px', padding: '25px'}}
            >
                {"\n\n\n" + props.content}
            </SyntaxHighlighter>
        </div>
    );
}

export const combineBlocks = (blocks: Block[], classPrefix: string | null = "data-panel__notes__container__data") => {
    const result = new Array<JSX.Element>();

    let i = 0;
    for(let block of blocks) {
        switch(block.bType) {
            case 'text':
                result.push(<TextBlock key={i++} content={block.content} prefix={classPrefix}/>);
                break;
            case 'image':
                result.push(<ImageBlock key={i++} content={block.content} prefix={classPrefix}/>);
                break;
            case 'python': case 'c++': case 'java': case 'c#': case 'c': case 'javascript':
                result.push(<CodeBlock key={i++} type={block.bType} content={block.content} prefix={classPrefix}/>)
                break;
            case 'break':
                result.push(<BreakeBlock key={i++}/>);
                break;
        }
    }

    return result;
}