import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction, useContext, useState } from "react";

import { LanguageElement } from "./LanguageElement/LanguageElement";

import './languageSelector.scss'

import 'material-symbols';

interface LanguageSelectorProps {
    selectedLanguage: string,
    setSelectedLanguage: Dispatch<SetStateAction<string>>,
    activePanel: boolean,
    setActivePanel: Dispatch<SetStateAction<boolean>>
}

export const LanguageSelector = (props: LanguageSelectorProps) => {
    const { t } = useTranslation();

    const { selectedLanguage, setSelectedLanguage } = props;
    const { activePanel, setActivePanel } = props;

    const languages = [
        { country: "poland", lang: "polski", available: true, shortcut: 'PL' },
        { country: "ukraine", lang: "українська", available: false, shortcut: 'UA' },
        { country: "lithuania", lang: "lietuvių", available: false, shortcut: 'LT' },
        { country: "hungary", lang: "magyar", available: false, shortcut: 'HU' }
    ];

    const handleMenuTurn = (state: boolean) => {
        let html = document.querySelector('html');
        
        if(state) {
            setActivePanel(true);
            if(html !== null) html.classList.add('html-not-scrollable');
            document.body.classList.add('body-not-scrollable');
        } else {
            setActivePanel(false)
            if(html !== null) html.classList.remove('html-not-scrollable');
            document.body.classList.remove('body-not-scrollable');
        }
    } 

    return (
        <>
            <div id="navigation__user-panel__language-selector" onClick={ () => handleMenuTurn(true) }>
                <span className="material-symbols-outlined">language</span>
                <span id="navigation__user-panel__language-selector__language">{selectedLanguage}</span>
            </div>  

            {
                activePanel ? (
                    <>
                        <div onClick={() => handleMenuTurn(false) } id="navigation__user-panel__language-selector-menu__background"></div>

                        <div id="navigation__user-panel__language-selector-menu">
                            <div id="navigation__user-panel__language-selector-menu__headline">
                                <span>{t("languageSelector.languageSettings")}</span>
                            </div>
                            <div id="navigation__user-panel__language-selector-menu__spacer"></div>
                            <div id="navigation__user-panel__language-selector-menu__text">
                                <span>{t("languageSelector.languageChangeInfo")}</span>
                            </div>
                            <div id="navigation__user-panel__language-selector-menu__spacer"></div>
                            <div id="navigation__user-panel__language-selector-menu__selector-text">
                                <span>{t("languageSelector.chooseOneFromTheAvaiable")}:</span>
                            </div>

                            <div id="navigation__user-panel__language-selector-menu__selector">
                                {
                                    languages.map(
                                        (el, index) => <LanguageElement key={index} selectedLanguage={selectedLanguage} country={el.country} language={el.lang} available={el.available} shortcut={el.shortcut}/>
                                    )
                                }
                            </div>

                            <div id="navigation__user-panel__language-selector-menu__close-button">
                                <button onClick={ () => handleMenuTurn(false) }>{t("languageSelector.closeWindow")}</button>
                            </div>
                        </div>       
                    </>
                ) : ""
            }
        </>
    );
}