

interface ValidationDotProps {
    title: string;
    isValid: boolean;
    short?: boolean
}

export const ValidationDot = (props: ValidationDotProps) => {
    const className = props.isValid ? "material-symbols-outlined material-symbols-outlined--filled" : "material-symbols-outlined";

    const handleClassName = () => {
        const base = "data-panel__change-password__requirements__list__element";
        let finalClassName = `${base} ${base}--`;

        if(props.short !== undefined) 
            finalClassName += props.short ? "short" : "";

        return finalClassName;
    }

    return (
        <div className={handleClassName()}>
            <span className={className}>
                fiber_manual_record
            </span>
            <span> {props.title} </span>
        </div>
    )
}
