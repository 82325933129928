
export const Chat = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
  >
    <path
      stroke="#2F848F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M14 8a6 6 0 0 1-9.21 5.07L2 14l.93-2.79A6 6 0 1 1 14 8Z"
    />
    <path
      fill="#2F848F"
      d="M9 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM12 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM6 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
    />
  </svg>
)
