import { useTranslation } from "react-i18next";

export const VideoDescriptionFallback = () => {
    const { t } = useTranslation();

    return (
        <div>
            <div id="data-panel__description-title">{t("kurs.video.shortLessonDescription")}</div>
            <div id="data-panel__description">
                <div className="skeleton"/>
                <div className="skeleton"/>
                <div className="skeleton"/>
                <div className="skeleton"/>
                <div className="skeleton"/>
            </div>
        </div>
    );
}