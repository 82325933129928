import { useTranslation } from "react-i18next";
import { NotesLinesFallback } from "./NotesLinesFallback";
import { BackArrowButton } from "../../../shared/components/titleWithBackButton/backArrowButton/BackArrowButton";

export const NotesFallback = () => {
    const { t } = useTranslation();

    return (
        <div className="data-panel__notes">
          <div className="data-panel__notes__container-spacer data-panel__notes__container-spacer--top" />
          <div className="data-panel__notes__container">
              <div className="data-panel__notes__container__data">
                  <NotesLinesFallback numberOfLines={15}/>
              </div>
          </div>
          <div className="data-panel__notes__container-spacer data-panel__notes__container-spacer--bottom" />

          <div className="data-panel__notes__download">
            <div className="data-panel__notes__download__headline data-panel__notes__download__headline--big">
              <span>
                {t("kurs.notatki.materialsToDownload")}
              </span>
            </div>

            <div className="data-panel__notes__download__text">
              <span>
                {`${t("kurs.notatki.documentIncludingCombinedNotes")} - `}
              </span>
              <span className="data-panel__notes__download__every-note__link">
                {t("kurs.notatki.goToCourse")}
              </span>
              <span>.</span>
            </div>

            <div className="data-panel__notes__download__row">
              <div className="data-panel__notes__download__row__description">
                  <span>
                    {t("kurs.notatki.downloadNotesInfo")}
                  </span>
              </div>
              <div className="data-panel__notes__download__row__button">
                  <span>
                      {t("kurs.notatki.downloadPDF")}
                  </span>
                  <span className="material-symbols-outlined">
                      download
                  </span>
              </div>
            </div>

            <div className="data-panel__notes__download__spacer"/>
          </div>

        </div>
      );   
}