import { StripeElementLocale, StripeElementsOptions, loadStripe } from "@stripe/stripe-js";
import i18n from "../i18n";

type Appearance = {
    theme: "stripe" | "night" | "flat";
    variables: {
        colorPrimary?: string;
        colorSecondary?: string;
        colorText?: string;
        spacingUnit?: string
    }
};

export const appearance: any | Appearance = {
    theme: 'stripe',
    rules: {
        '.Input': {
            marginTop: '9px',
            borderColor: '#a1a1a1',
            fontWeight: "550",
        },

        '.Input--invalid': {
            marginBottom: '9px',
            boxShadow: '0',
            border: '1px solid #b70f0f',
            color: '#1a1a21'
        },

        '.Tab': {
            border: '1px solid #a1a1a1',
        },

        '.TabLabel--selected': {
            color: '#1a1a21',
        },

        '.Label': {
            fontSize: "16px",
            fontWeight: "550",
            color: "#1a1a21"
        }
    },
    variables: {
        colorPrimary: '#40aab8',

        fontSizeSm: '16px',

        fontSizeXs: '14px',
        fontWeightNormal: '500',

        spacingGridRow: "25px",

    },
};

export const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);


export const options: StripeElementsOptions = {
    clientSecret: "",
    appearance,
    locale: i18n.language as StripeElementLocale,

    fonts: [
        { 
            family: "Arimo",
            weight: "500",
            cssSrc: 'https://fonts.googleapis.com/css2?family=Arimo:wght@400;500;600;700&display=swap'
        }
    ],
};