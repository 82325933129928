import { get } from "./apiClient";
import { useNavigate } from "react-router-dom";

export async function logout() {
  try {
    const data = await get<any>(`users/logout`);
  } catch (error) {
    // error not expected
  }
}
