
export const PlayCircle = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
  >
    <circle
      cx={12}
      cy={12}
      r={9}
      stroke="#FFCA42"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
    <path
      stroke="#FFCA42"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M14.98 11.132a1 1 0 0 1 0 1.736l-3.984 2.277a1 1 0 0 1-1.496-.868V9.723a1 1 0 0 1 1.496-.868l3.985 2.277Z"
    />
  </svg>
)
