import { useContext, useEffect } from "react";
import { Quiz } from "../../additions/quiz/Quiz"
import { MainContext } from "../MainContextProvider";

export const QuizMain = () => {
    const mainCtx = useContext(MainContext);

    useEffect(() => {
        mainCtx.setActiveComponent('quiz');
    }, []);
    
    return <Quiz/>
}