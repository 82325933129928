import { Dispatch, SetStateAction, useEffect } from "react";
import { ExcerciseAnswers, MainTestDto, MathDto, SingleExcerciseDto } from "../../../../../../shared/finalTest";
import { LatexWithPlaceholders } from "../../latex/LatexTest";
import { AssignmentDto } from "../../../../../../shared/assignmentDto";
import { AssignmentExcercise } from "./assignmentExcercise/AssignmentExcercise";
import { SingleQuiz } from "../../../../../../shared/quizesDto";
import { QuizExcercise } from "./quizExcercise/QuizExcercise";
import { useTranslation } from "react-i18next";

interface SingleExcerciseProps {
    excerciseData: SingleExcerciseDto;
    revealAnswer: boolean;
    modifyIsFinished: (index: number, finished: boolean) => void;
    currentExcercise: number;
    modifyExerciseAnswers: (index: number, newAnswers: ExcerciseAnswers) => void;
    testDto: MainTestDto;
    notifyIfClicked: number;
    setNotifyIfClicked: Dispatch<SetStateAction<number>>;
}

export const SingleExcercise = (props: SingleExcerciseProps) => {
    const { t } = useTranslation();

    useEffect(() => {
        props.setNotifyIfClicked(0);
    })

    const getContent = () => {
        if(props.excerciseData.excerciseType === 'math') {
            return <div className="data-panel__mainTest__single-excercise-wrapper">
                <LatexWithPlaceholders 
                excerciseData={props.excerciseData.excerciseContent as MathDto} 
                revealAnswer={props.revealAnswer} 
                modifyIsFinished={props.modifyIsFinished}
                currentExcercise={props.currentExcercise}
                modifyExerciseAnswers={props.modifyExerciseAnswers} 
                testDto={props.testDto}
                notifyIfClicked={props.notifyIfClicked}/>
            </div>
            
        } else if(props.excerciseData.excerciseType === 'assignment') {
            return(<div className="data-panel__mainTest__single-excercise-wrapper">
                <AssignmentExcercise 
                    excerciseData={props.excerciseData.excerciseContent as AssignmentDto} 
                    revealAnswer={props.revealAnswer} 
                    modifyIsFinished={props.modifyIsFinished}
                    notifyIfClicked={props.notifyIfClicked}
                    currentExcercise={props.currentExcercise}/>
                </div>);
        } else if(props.excerciseData.excerciseType === 'quiz') {
            return(<div className="data-panel__mainTest__single-excercise-wrapper">
                <QuizExcercise
                    excerciseData={props.excerciseData.excerciseContent as SingleQuiz}
                    revealAnswer={props.revealAnswer} 
                    modifyIsFinished={props.modifyIsFinished}
                    notifyIfClicked={props.notifyIfClicked}
                    currentExcercise={props.currentExcercise}
                    modifyExerciseAnswers={props.modifyExerciseAnswers}
                    userAnswers={props.testDto.excercises[props.currentExcercise].excerciseAnswers}
                    testDto={props.testDto}
                    /></div>
            )
        }
    
        return <></>
    }

    return <>
        {getContent()}
        <div className="data-panel__mainTest__spacer"/>
    </>
    
}