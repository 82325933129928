import { CourseVideoChapter } from "../CourseChapter"

interface CourseChapterFallbackProps {
    numberOfLines: number;
}

export const CourseChapterFallback = (props: CourseChapterFallbackProps) => {
    return (
        <>
            {Array(props.numberOfLines).fill(null).map((_, __) => {
                return <CourseVideoChapter key={__} loadFallback={true} id={""} index={0} title={""} stamp={""} src={""}/>;
            })}
        </>
    )
    
    
    
}