import { useTranslation } from "react-i18next";

import "./changeCourseButton.scss";

interface ChangeCourseButtonProps {
    prev: boolean,
    page: number,
    pages: number,
    handleChangeFunction: () => void;
}

export const ChangeCourseButton = (props: ChangeCourseButtonProps) => {
    const { t } = useTranslation();
    const { prev, handleChangeFunction } = props;

    const handleButtonClass = () => {
        const base = "data-panel__user-panel__buttons__button";
        let finalClassName = `${base} ${base}--${props.prev? 'prev' : 'next'}`;

        let inactiveFlag = false;

        if( (props.pages === 0) || (props.prev && (props.page === 0)) || ( (!props.prev) && (props.page + 1 === props.pages) ) ) 
            inactiveFlag = true;
 
        if(inactiveFlag) finalClassName += ` ${base}--inactive`;

        return finalClassName;
    }

    return (
        <div className={handleButtonClass()} onClick={handleChangeFunction}>
            {prev? <span className="material-symbols-outlined">arrow_back_ios</span> : null}

            <div className="data-panel__user-panel__buttons__button__text data-panel__user-panel__buttons__button__text--desktop">
                <span>
                    {prev? t("userPanel.courseButtons.previousPage.full"): t("userPanel.courseButtons.nextPage.full")}
                </span>
            </div>
            
            <div className="data-panel__user-panel__buttons__button__text data-panel__user-panel__buttons__button__text--mobile">
                <span>
                    {prev? t("userPanel.courseButtons.previousPage.short"): t("userPanel.courseButtons.nextPage.short")}
                </span>        
            </div>
            
            {prev? null : <span className="material-symbols-outlined">arrow_forward_ios</span>}
        </div>
    )
}