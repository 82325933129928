import "./coursePanelFallback.scss";

interface CoursePanelFallbackProps {
    shouldDissapearOnSmallerScreen?: boolean;
}

export const CoursePanelFallback = (props: CoursePanelFallbackProps) => {
    return <div className={props.shouldDissapearOnSmallerScreen ? "course-panel second__panel__fallback" : "course-panel"}>
    <div className="course-panel__main-name skeleton">
        <div className="course-panel__main-name__img">
            <div className="skeleton"/>
        </div>
        <div className="course-panel__main-name__text">
            <div>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
            </div>
        </div>
    </div>
            <div className="course-panel__name">
                <span className={"skeleton"}></span>
            </div>
            <div className="course-panel__description  course-panel__description__skeleton">
                    <>
                        <div className="skeleton"></div>
                        <div className="skeleton"></div>
                        <div className="skeleton"></div>
                        <div className="skeleton"></div>
                    </>
            </div>
            
            <div className="course-panel__invisible-buffor">
                <span>_______________________________________</span>
            </div>

    <div className="course-panel__params">
        <div className={"course-panel__params__param skeleton"}>
                <div></div>
        </div>
        <div className={"course-panel__params__param skeleton"}>
                <div></div> 
        </div>
    </div>
    <div className="course-panel__content-wrapper">

        <div className="course-panel__content-wrapper__score skeleton"/>
        
        <div className="course-panel__content-wrapper__button skeleton">
            <span>Przejdź do kursu</span>
        </div>
    </div>
    <div className="course-panel__valid-time">
        <div className="skeleton"/>
    </div>
</div>
}