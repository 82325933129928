
export const Play = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
  >
    <path
      stroke="#14181F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M17.752 12.832a1 1 0 0 0 0-1.664L8.555 5.036A1 1 0 0 0 7 5.87V18.13a1 1 0 0 0 1.555.832l9.197-6.131Z"
    />
  </svg>
)

