import { useTranslation } from "react-i18next";
import { ReactNode, useContext, useEffect, useState } from "react";

import "./myCourses.scss";

import { CoursePanel } from "./coursePanel/CoursePanel";
import { NoCoursesPanel } from "./noCoursesPanel/NoCoursesPanel";

import { BlankCoursePanel } from "./coursePanel/blankCoursePanel/BlankCoursePanel";
import { EmptyCoursePanel } from "./emtyCoursePanel/EmptyCoursePanel";
import { CoursesPreviewDTO } from "../../../../../../shared/coursesPreviewDto";
import { get } from "../../../../utilities/rest/apiClient";
import { AuthContext } from "../../../auth/authContext/AuthContextProvider";
import { UserPanelPageProps } from "../../UserPanel";
import { ChangeCourseButton } from "./changeCourseButton/changeCourseButton";
import { CoursePanelFallback } from "./coursePanel/coursePanelFallback/CoursePanelFallback";

export const MyCourses = (props: UserPanelPageProps) => {
    const { i18n, t } = useTranslation();
    const [coursesDto, setCoursesDto] = useState<CoursesPreviewDTO>({} as CoursesPreviewDTO);
    const [courseComponents, setCourseComponents] = useState<ReactNode[]>([]);
    const [currentlyDisplayedComponents, setCurrentlyDisplayedComponents] = useState<ReactNode[]>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(0);
    const [numberOfAllCourses, setNumberOfAllCourses] = useState(0);
    const [numberOfMyCourses, setNumberOfMyCourses] = useState(0);
    const authContext = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        props.setActivePanel("myCourses");
        const activeChapter = props.activePanelNameToIndexMap.get("myCourses");
        
        if(activeChapter !== undefined){
            props.setActiveChapter(activeChapter);
        }

        setIsLoading(true);

        (async ()=> {
            try {
              const data = await get<CoursesPreviewDTO>(`reactRoutes/getCoursesPreview/${i18n.language}`);
              setCoursesDto(data);
              const courseComponents = getCoursesComponents(data);
              setCourseComponents(courseComponents);
              getCoursesCounts(data);
              setIsLoading(false);

            } catch(error) {
                authContext.handleAuthErrors(error);
                //TODO handle other errors
            }
          })()
    }, [])

    useEffect(() => {
        if(isLoading) return;   

        handlePageNumberChange(courseComponents, currentPage, numberOfPages);
    }, [currentPage, courseComponents, numberOfPages])

    const handlePageNumberChange = (components: ReactNode[], currentPage: number, numberOfPages: number) => {
        if(components.length === 0){
            setCurrentlyDisplayedComponents([<NoCoursesPanel/>])
        }

        else if(components.length === 1){
            setCurrentlyDisplayedComponents([components[0], <EmptyCoursePanel key={1}/>])
        }
            
        else if(components.length % 2 === 1 && currentPage === numberOfPages-1) {
            setCurrentlyDisplayedComponents([components[components.length-1], <EmptyCoursePanel key={1}/>])
        }
            
        else {
            if(currentPage + 1 === numberOfPages && components.length % 2 === 0) {
                setCurrentlyDisplayedComponents([<EmptyCoursePanel key={0}/>, <BlankCoursePanel key={1}/>])
                return;
            }

            const startIndex = currentPage * 2;
            const endIndex = startIndex + 2;
            setCurrentlyDisplayedComponents([...components.slice(startIndex, endIndex)])
        }
            
    }

    const handleNext = () => {
        if(currentPage >= numberOfPages - 1) return;
        setCurrentPage(currentPage + 1);
    }

    const handlePrevious = () => {
        if(currentPage === 0) return;
        setCurrentPage(currentPage - 1);
    }

    const getCoursesComponents = (data: CoursesPreviewDTO) => {
        const result: Array<React.ReactNode> = [];
        let key = 1000;
        for(let course of data.courses){
            const courseComponent = 
                <CoursePanel
                    courseId={course.courseID}
                    imgSrc={course.icon}
                    name={course.title}
                    nameWithYearUpdated={course.titleWithDate}
                    description={course.shortDescription}
                    numberOfHours={course.numberOfHours}
                    numberOfLessons={course.numberOfLessons}
                    percentFinished={course.completionStatus}
                    activeToDate={course.expirationDate}
                    key={key++}
                />
            result.push(courseComponent);
        }

        const numberOfCourses = result.length;
        
        //1 kurs - 1 strona
        //2 kursy - 2 strony
        //3 kursy - 2 strony
        //4 kursy - 3 strony
        //5 kursy - 3 strony
        //6 kursy - 4 strony
        //7 kursy - 4 strony
        //8 kursy - 5 strony
        //9 kursy - 5 strony

        //1 // 2 + 1 = 1
        //2 // 2 + 1 = 2
        //3 // 2 + 1 = 2
        //4 // 2 + 1 = 3
        //5 // 2 + 1 = 3
        //6 // 2 + 1 = 3
        let pages: number;
        if(numberOfCourses === 1) {
            pages = 1;
        } else if(numberOfCourses === 2){ 
            pages = 2;
        } else {
            if(numberOfCourses % 2 === 0){
                pages = (numberOfCourses / 2) + 1;
            } else {
                pages = Math.floor(numberOfCourses / 2) + 1;
            }
        }

        setNumberOfPages(pages); 
        handlePageNumberChange(result, currentPage, pages);

        return result;
    }

    const getCoursesCounts = (data: CoursesPreviewDTO) => {
        setNumberOfAllCourses(data.allCoursesCount);
        setNumberOfMyCourses(data.myCoursesCount);
    }

    return (
        <div className="data-panel__user-panel">
            <div className="data-panel__user-panel__description">
                <h4>
                    { 
                        (numberOfPages !== 0) ? (<>
                            {t("userPanel.avaiableCoursesInfo.youOwn")} <span className="blue-sign">{numberOfMyCourses}</span> {t("userPanel.avaiableCoursesInfo.of") + " "}
                            <span className="blue-sign">{numberOfAllCourses}</span> {t("userPanel.avaiableCoursesInfo.avaiableCourses")}.
                        </>) : t("userPanel.noCoursesPanel.youDontHaveAnyCourses")
                    }
                </h4>
            </div>
            
            <div className="data-panel__user-panel__courses-large">
                {   
                    isLoading ? 
                        <>
                            <CoursePanelFallback/>
                            <CoursePanelFallback shouldDissapearOnSmallerScreen={true}/>
                        </> : ( (numberOfPages !== 0) ? currentlyDisplayedComponents : <NoCoursesPanel/>)
                }
            </div>

            <div className="data-panel__user-panel__buttons">
                <ChangeCourseButton prev={true} page={currentPage} pages={numberOfPages} handleChangeFunction={isLoading? () => {} : handlePrevious}/>
                {!isLoading && 
                    <div className="data-panel__user-panel__buttons__navigation">
                        <span className="data-panel__user-panel__buttons__navigation__number">
                            {currentPage + 1}
                        </span>
                        <span className="data-panel__user-panel__buttons__navigation__separator">
                            {t("userPanel.avaiableCoursesInfo.of")}
                        </span>
                        <span className="data-panel__user-panel__buttons__navigation__number">
                            { (numberOfPages !== 0) ? numberOfPages: 1}
                        </span>
                    </div>
                }
                <ChangeCourseButton prev={false} page={currentPage} pages={numberOfPages} handleChangeFunction={isLoading? () => {} : handleNext}/>
            </div>
        </div>
    );
}