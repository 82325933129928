import { NotFound, Unauthorized } from "./errors";

const baseUrl = "/api/v1/";
const commonErrorMessage = "Something went wrong!";

export class RequestError {
  public statusCode: number;
  public messages: string[];

  constructor(statusCode: number, messages: string[]) {
    this.statusCode = statusCode;
    this.messages = messages;
  }
}

export const post = function <T, B>( //T is data returned and B is body model
  url: string,
  bodyModel: B,
  headers?: any
) : Promise<T> {
  return makeRequest<T>(url, "post", undefined, bodyModel);
}

export const beacon = function (
  url: string,
  data?: BodyInit | null | undefined
) : boolean {
  return navigator.sendBeacon(`${baseUrl}${url}`, data);
}

export const get = function <T>(
  url: string,
  params?: URLSearchParams,
): Promise<T> {
  return makeRequest<T>(url, "get", params);
};

export const delete_ = function <T, B>(
  url: string,
  bodyModel?: B,
  params?: URLSearchParams,
): Promise<T> {
  return makeRequest<T>(url, "DELETE", params, bodyModel);
};


export const patch = function <T, B>( //T is data returned and B is body model
  url: string,
  bodyModel: B,
  headers?: any
) : Promise<T> {
  return makeRequest<T>(url, "PATCH", undefined, bodyModel);
}

export const makeRequest = async <T>(
  endpoint: string,
  method: string,
  params?: URLSearchParams,
  bodyModel?: any
): Promise<T> => {
  let fullUrl = `${baseUrl}${endpoint}`;
  if (params) {
    fullUrl = `${fullUrl}?${params}`;
  }

  const requestParams = {
    method: method,
    body: bodyModel ? JSON.stringify(bodyModel) : null,
    headers: {
      'Content-Type': 'application/json'
    }
  }

  const response = await fetch(fullUrl, requestParams);

  const json: any = await handleResponse(response);
  return json.data as T;
};

const handleResponse = async <T>(response: Response): Promise<T> => {
  const jsonString = await response.text();

  let json = {} as any;

  if (jsonString.trim()) {
    try {
      json = JSON.parse(jsonString);
    } catch (e) {
      console.error(
        `Response could not be parsed to JSON! Error code: ${response.status}, url: ${response.url}`
      );

      throw new RequestError(response.status, [commonErrorMessage]);
    }
  }

  if (!response.ok) {
    if(response.status === 401) {
      throw new Unauthorized("You are not logged in");
    }

    if(response.status === 404) {
      throw new NotFound("Resource not found");
    }
    
    if (json.statusCode && json.messages) {
      throw new RequestError(json.statusCode, json.messages);
    }

    var error = response.statusText.trim();

    throw new RequestError(response.status, [
      error ? error : commonErrorMessage,
    ]);
  }

  return json;
};