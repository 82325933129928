import { useContext } from "react";
import { To, useNavigate } from "react-router-dom";
import { MainContext } from "../../../../course/main/MainContextProvider";

interface BackArrowButtonProps {
    navigateTo: To | undefined;
}

export const BackArrowButton = (props: BackArrowButtonProps) => {
    const navigate = useNavigate();
    const mainCtx = useContext(MainContext);

    return (
        <span data-testid='back-button' className="material-symbols-outlined" onClick={() => props.navigateTo ? navigate(props.navigateTo) : () => {}}>
            arrow_back_ios
        </span>
    );
}