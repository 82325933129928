import { Dispatch, SetStateAction } from "react";
import { UserPanelNavigationChapter } from "../userPanelNavigationChapter/UserPanelNavigationChapter";

interface UserPanelNavigationChaptersProps {
    activeChapter: number;
    setActiveChapter: Dispatch<SetStateAction<number>>;
}

export const UserPanelNavigationChapters = (props: UserPanelNavigationChaptersProps) => {
    const {activeChapter, setActiveChapter} = props;

     return (
        <>
          <UserPanelNavigationChapter
            key={0}
            id={0}
            title="Moje kursy"
            icon="library_books"
            navigateTo=""
            activeChapter={activeChapter}
            setActiveChapter={setActiveChapter}
          />
          <UserPanelNavigationChapter
            key={1}
            id={1}
            title="Zmień nazwę użytkownika"
            icon="badge"
            navigateTo="changeUsername"
            activeChapter={activeChapter}
            setActiveChapter={setActiveChapter}
          />
          <UserPanelNavigationChapter
            key={2}
            id={2}
            title="Zmień hasło"
            icon="key"
            navigateTo="changePassword"
            activeChapter={activeChapter}
            setActiveChapter={setActiveChapter}
          />
          <UserPanelNavigationChapter
            key={3}
            id={3}
            title="Zmień adres e-mail"
            icon="mail"
            navigateTo="changeEmail"
            activeChapter={activeChapter}
            setActiveChapter={setActiveChapter}
          />
          <UserPanelNavigationChapter
            key={4}
            id={4}
            title="Ustawienia powiadomień"
            icon="notifications"
            navigateTo="changeNotifications"
            activeChapter={activeChapter}
            setActiveChapter={setActiveChapter}
          />
          <UserPanelNavigationChapter
            key={5}
            id={5}
            title="Zamknij konto"
            icon="person_remove"
            navigateTo="closeAccount"
            activeChapter={activeChapter}
            setActiveChapter={setActiveChapter}
            optionalIconClass="icon--artificially-centered"
          />
        </>
      );
}