import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { UserPanelNavigation } from "./userPanelNavigation/UserPanelNavigation";
import { MyCourses } from "./userPanelPages/myCourses/MyCourses";
import { ChangeUsername } from "./userPanelPages/changeUsername/ChangeUsername";
import { ChangePassword } from "./userPanelPages/changePassword/ChangePassword";
import { ChangeEmail } from "./userPanelPages/changeEmail/ChangeEmail";
import { ChangeNotifications } from "./userPanelPages/changeNotifications/ChangeNotifications";
import { CloseAccount } from "./userPanelPages/closeAccount/CloseAccount";
import { WideMenu } from "../shared/components/wideMenu/WideMenu";
import { DataPanelWrapper } from "../shared/components/wrappers/dataPanelWrapper/DataPanelWrapper";
import { ContentPanelWrapper } from "../shared/components/wrappers/contentPanelWrapper/ContentPanelWrapper";
import { NotFound } from "../error/NotFound";

export type ActivePanel = 'myCourses' | 'changeUsername' | 'changePassword' | 'changeEmail' | 'changeNotifications' | 'closeAccount' | "";

export interface UserPanelPageProps {
    setActivePanel: Dispatch<SetStateAction<ActivePanel>>;
    setActiveChapter: Dispatch<SetStateAction<number>>;
    activePanelNameToIndexMap: Map<ActivePanel, number>;
}

export const UserPanel = () => {
    const { t } = useTranslation();

    const [activePanel, setActivePanel] = useState<ActivePanel>("myCourses");
    const [activeChapter, setActiveChapter] = useState(0);
    const [activePanelNameToIndexMap] = useState<Map<ActivePanel, number>>(new Map<ActivePanel, number>([
        ['myCourses', 0],
        ['changeUsername', 1],
        ['changePassword', 2],
        ['changeEmail', 3],
        ['changeNotifications', 4],
        ['closeAccount', 5]
    ]));
    const [usernameUpdate, setUsernameUpdate] = useState(false);

    return (
        <>
            <div id="content-name">{`${t("userPanel.userPanelName")} - ${t(`userPanel.courseSubtitles.${activePanel}`)}`}</div>
            <ContentPanelWrapper>
                <WideMenu mainCoursePanel={true}/>
                <DataPanelWrapper currentPanel={activePanel}>
                        <Routes>
                            <Route path="" element={<MyCourses activePanelNameToIndexMap={activePanelNameToIndexMap} setActiveChapter={setActiveChapter} setActivePanel={setActivePanel}/>}/>
                            <Route path="changeUsername" element={<ChangeUsername setUsernameUpdate={setUsernameUpdate} activePanelNameToIndexMap={activePanelNameToIndexMap} setActiveChapter={setActiveChapter} setActivePanel={setActivePanel}/>}/>
                            <Route path="changePassword" element={<ChangePassword activePanelNameToIndexMap={activePanelNameToIndexMap} setActiveChapter={setActiveChapter} setActivePanel={setActivePanel}/>}/>
                            <Route path="changeEmail" element={<ChangeEmail activePanelNameToIndexMap={activePanelNameToIndexMap} setActiveChapter={setActiveChapter} setActivePanel={setActivePanel}/>}/>
                            <Route path="changeNotifications" element={<ChangeNotifications activePanelNameToIndexMap={activePanelNameToIndexMap} setActiveChapter={setActiveChapter} setActivePanel={setActivePanel}/>}/>
                            <Route path="closeAccount" element={<CloseAccount activePanelNameToIndexMap={activePanelNameToIndexMap} setActiveChapter={setActiveChapter} setActivePanel={setActivePanel}/>}/>  
                            <Route path="" element={<NotFound />} /> 
                            <Route path="*" element={<NotFound />} /> 
                        </Routes>
                </DataPanelWrapper>
                <UserPanelNavigation usernameUpdate={usernameUpdate} setUsernameUpdate={setUsernameUpdate} setActiveChapter={setActiveChapter} activeChapter={activeChapter}/>
            </ContentPanelWrapper>
        </>
    )
}