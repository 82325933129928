import { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";

interface UserPanelNavigationChapterProps {
    id: number;
    activeChapter: number;
    setActiveChapter: Dispatch<SetStateAction<number>>
    title: string;
    icon: string;
    navigateTo: string;

    optionalIconClass?: string;
}

export const UserPanelNavigationChapter = (props: UserPanelNavigationChapterProps) => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate(props.navigateTo);
        props.setActiveChapter(props.id);
    }

    const getStyleContainer = () => {
        return props.activeChapter === props.id ? "chapter__data-space__data chapter__data-space__data--highlited" : "chapter__data-space__data";
    }

    const getStyleIcon = () => {
        return props.activeChapter === props.id ? "icon icon--spacer icon--bold" : "icon icon--spacer"; 
    }

    return (
        <div className={getStyleContainer()}
            onClick={handleClick}>
            <div className={getStyleIcon() + ` ${props.optionalIconClass}`}>
                <span className="material-symbols-outlined">{props.icon}</span>
            </div>
            <div className="text">{props.title}</div>
        </div>
    );
}