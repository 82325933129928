import { useContext, useEffect, useMemo, useState } from "react";
import { MainChapter } from "./mainChapter/MainChapter";
import { MainContext } from "../MainContextProvider";
import { GlobalDataContext } from "../../../global/globalDataContext/GlobalDataContextProvider";
import { useTranslation } from "react-i18next";
import { isWordPresentInUrl } from "../../../shared/components/fallbacks/fallbackUtils";
import { MainNavigationFallback } from "../courseMain/courseMainNavigation/courseMainNavigationFallback/MainNavigationFallback";

interface MainNavigationProps {
    getTranslationForAddition: () => string;
}

export const MainNavigation = (props: MainNavigationProps) => {
    const mainCtx = useContext(MainContext);
    const globalCtx = useContext(GlobalDataContext);

    const { t } = useTranslation();
    
    useEffect(() => {

        mainCtx.setPath([globalCtx.title, props.getTranslationForAddition(), mainCtx.mainDto.chapters[mainCtx.active.chapterIndex].lessons[mainCtx.active.lessonIndex].title])
    }, [mainCtx.active])
    
    const getChaptersAndLessons = () => {
        const result: JSX.Element[] = [];
        let index = 0;


        for(let chapter of mainCtx.mainDto.chapters) {
            const openedAtStart = index === 1 ? true : false;
            result.push(
                <MainChapter openedAtStart={openedAtStart} title={chapter.title} index={index} key={index} lessons={chapter.lessons} />
            );
            index++;
        }

        return result;
    }

    const chaptersAndLessons = useMemo(getChaptersAndLessons, mainCtx.mainDto.chapters);

    const getNavigationTitleWithRegardsToUrl = () => {
        if(isWordPresentInUrl('quiz')) {
            return t("kurs.mainNavigation.selectQuizesFromTheLesson");
        } else if(isWordPresentInUrl('assignment')) {
            return t("kurs.mainNavigation.selectAssignmentsFromTheLesson");
        } else if(isWordPresentInUrl('notes')) {
            return t("kurs.mainNavigation.selectNotesFromTheLesson");
        }
    }

    if(mainCtx.isLoading) {
        return <></> //TODO loading fallback
    } else {
        return (
            <div id="navigation-panel">
                <div className="chapter chapter--main">
                    <div className="chapter__name">{getNavigationTitleWithRegardsToUrl()}</div>
                    <div className="chapter__data-space chapter__data-space--no-padding">
                        {chaptersAndLessons}
                    </div>
                </div>
                {
                    (mainCtx.activeComponent === "notes") ? (
                        <div className="chapter">
                            <div className="chapter__name">{t("kurs.mainNavigation.downloadAllNotes")}</div>
                            <div className="chapter__data-space">
                            <div className="chapter__data-space__data chapter__data-space__data--highlited chapter__data-space__data--clickable">
                                <div className="text">{t("kurs.mainNavigation.downloadPDFwithNotes")}</div>
                                <div className="icon icon--bold">
                                <span className="material-symbols-outlined">download</span>
                                </div>
                            </div>
                            </div>
                        </div>
                    ) : ""
                }
            </div>
    );
    }
}