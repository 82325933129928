import React from 'react';
import { VideoPageFallback } from '../../../videoPage/videoPageFallback/VideoPageFallback';
import { VideoPlayerFallback } from '../../../videoPage/videoPageFallback/VideoPlayerFallback';
import { useTranslation } from 'react-i18next';

interface CourseMainFallbackProps {
  textLines?: number;
}

const Attribute = () => {
  return (
    <div className="data-panel__course__attributes__section__element">
      <span className='skeleton'></span>
    </div>
  )
}

const Addition = () => {
  return (
    <div className="data-panel__course__materials__data__row">
      <div className="data-panel__course__materials__data__row__description">
        <div className='skeleton'></div>
        <div className='skeleton'></div>
      </div>
    </div>
  )
}

export const CourseMainFallback: React.FC<CourseMainFallbackProps> = ({ textLines = 1 }) => {
  const skeletons = [...Array(textLines)];
  const { t } = useTranslation();

  return (
    <div className="data-panel__course">
      <div className="data-panel__course__headline headline">
        <div className="skeleton__course-main skeleton__text--narrow" />
      </div>
      <div className="data-panel__course__headline">
          <span>{t("kurs.glowna.nagranieWprowadzajace")}</span>
      </div>
      <VideoPlayerFallback isMainVideoPage={true}/>
      <div className="data-panel__course__attributes">
          <div className="data-panel__course__attributes__section">
              <Attribute/>
              <Attribute/>
          </div>
          <div className="data-panel__course__attributes__section">
              <Attribute/>
              <Attribute/>
          </div>
      </div>
      <div className="data-panel__course__description">
        <div className="data-panel__course__description__title">
          <div className='skeleton'></div>
        </div>
        <div className="data-panel__course__description__text">
          {skeletons.map((_, index) => (
            <div key={index} className='skeleton'></div>
          ))}
        </div>
      </div>
      <div className="data-panel__course__materials">
        <div className="data-panel__course__materials__headline">
          <div className="skeleton" />
        </div>
        <div className="data-panel__course__materials__data">
          <Addition/>
          <Addition/>
          <Addition/>
          <div className="data-panel__course__materials__data__spacer" />
          <div className="data-panel__course__materials__data__mobile-scroll-satus">
            <div className="skeleton__course-main skeleton__text" />
          </div>
        </div>
      </div>
    </div>
  );
};
