

import { useNavigate } from "react-router-dom";
import "./aboutUs.scss";
import { LandingNavbar } from "../LandingNavbar/LandingNavbar";
import { LandingFooter } from "../LandingFooter/LandingFooter";
import { ArrowGraphic } from "../MainPage/graphics/ArrowGraphic";


interface JoinTheTeamProps {
    mobile: boolean;
}

const JoinTheTeam = (props: JoinTheTeamProps) => {

    return (
        <div className={`join-team join-team--${props.mobile ? 'mobile' : 'desktop'}`}>
            <div className={`header header--margined header--small header--${props.mobile ? 'centered' : ""}`}>
                <span>
                    Dołącz do zespołu
                </span>
            </div>

            <div>
                <span className="text text--margined">
                    Jeśli tak jak my jesteś pasjonatem z jakiejś dziedziny, chcesz podzielić się nią z innymi i posiadasz doświadczenie w branży edukacyjnej, możesz do nas dołączyć.
                </span>
                <div className="join-us-button">
                    <span>
                        Wyślij zgłoszenie
                    </span>
                    <ArrowGraphic/>
                </div>
            </div>
        </div>
    );
}

export const AboutUs = (props: any) => {

    const navigate = useNavigate();


    const bulletPoints = [
        "Udostępnienie lepszej i tańszej alternatywy dla tradycyjnych korepetycji.", 
        "Stworzenie atmosfery sprzyjającej rozwojowi i nauce.",
        "Zamienienie męczących materiałów ze studiów w estetyczną i fascynującą formę.",
        "Jednym zdaniem: pomoc studentom przygotować się NaSesje."
    ];

    return (
        <div className="about-us">
            <LandingNavbar/>

            <div className="about-us__header">
                <span className="material-symbols-outlined" onClick={() => navigate('/')}>
                    arrow_back_ios
                </span>
                <span>Kilka słów </span>
                <span className="colored">o nas</span>
            </div>
            <div className="about-us__split">
                <div className="about-us__split__left">
                    <img alt="" src="https://na-sesje-public.s3.eu-central-1.amazonaws.com/aboutUs_prod.png"></img>

                    <div className="header header--margined">
                        <span>
                            Nasza misja
                        </span>
                    </div>
                    <span className="text text--margined">
                        Wiemy, że stres i zakuwanie do 3 nad ranem to chleb powszedni studenta, przecież sami nimi byliśmy. Dostosowaliśmy nasz sposób edukacji w ten sposób, żebyś mógł przez studia przejść spokojnie i z gracją. Wyznaczamy twój kierunek na sesję.
                    </span>
                    
                    <br></br><br></br>
                    <JoinTheTeam mobile={false}/>
                </div>

                <div className="about-us__split__right">
                    <div className="header">
                        <span>
                            Kim jesteśmy?
                        </span>
                    </div>

                    <div className="about-us__split__right__body">
                        <span className="text">
                            Jesteśmy grupą pasjonatów edukacji i techniki, którzy na własnej skórze poznali co to znaczy <b>studiowanie</b> - seria wzlotów i upadków a kierunek tego lotu zależy głównie od humoru prowadzącego zajęcia. 
                            <br></br><br></br>
                            My na szczęście <b>mamy dobry humor</b> i wzięliśmy z poznanych przez nas metod nauczania to co najlepsze i opakowaliśmy to w estetyczną i przystępną formę.
                            <br></br><br></br>
                            Reszta niech zostanie za starymi murami uczelni. 
                        </span>
                    </div>

                    <br></br><br></br>
                    <div className="header header--small">
                        <span>
                            Nasze cele
                        </span>
                        <span className="material-symbols-outlined">
                            rocket_launch
                        </span>
                    </div>

                    <div className="panel">
                        {
                            bulletPoints.map((el, index) => (
                                <div className="panel__point" key={index}>
                                    <div className="panel__point__bullet"/>
                                    <span>{el}</span>
                                </div>
                            ))
                        }
                    </div>

                    <JoinTheTeam mobile={true}/>
                </div>
            </div>

            <div  className="about-us__join-us">
                <span>
                    {/* Rozwijamy się każdego dnia, jeśli chcesz dołączyć do zespołu - napisz do nas! */}
                </span>
            </div>

            <LandingFooter/>
            
        </div>
    )
}