import { get, patch, post } from "../../../utilities/rest/apiClient";
import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Logo } from "../../navbar/logo/logo";
import { PasswordLabel } from "../signUp/passwordLabel/PasswordLabel";
import { BadRequest } from "../../../utilities/rest/errors";
import { DisplayedMessage } from "./DisplayedMessage/DisplayedMessage";

import { checkNumberOfCharacters, checkCapitalization, checkNonCapitlization, checkDigits } from "../shared/sharedFunctions"
import { Alert } from "../shared/sharedInterfaces";


interface ResetPasswordInterface {
    password: string,
    passwordConfirm: string
}

export const ResetPassword = () => {

    const navigate = useNavigate();
    const { t } = useTranslation();

    const [resetToken, setResetToken] = useState("");

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [validPassword, setValidPassword] = useState(false);
    const [validPasswordConfirmation, setValidPasswordConfirmation] = useState(false);

    const [eightSymbolsChecked, setEightSymbolsChecked] = useState(false);
    const [bigLetterChecked, setBigLetterChecked] = useState(false);
    const [digitChecked, setDigitChecked] = useState(false);
    const [smallLetterChecked, setSmallLetterChecked] = useState(false);

    const [displayMessage, setDisplayMessage] = useState(false);
    const [isGoodMessage, setIsGoodMessage] = useState(false);
    const [displayedMessage, setDisplayedMessage] = useState("");


    const alerts: Alert[] = [
        { text: t("signUp.passwordRequirements.min8signs"), status: eightSymbolsChecked },
        { text: t("signUp.passwordRequirements.min1capitalLetter"), status: bigLetterChecked },
        { text: t("signUp.passwordRequirements.min1nonCapitalLetter"), status: smallLetterChecked },
        { text: t("signUp.passwordRequirements.min1digit"), status: digitChecked },
    ];
    

    useEffect(() => {
        const urlPath = window.location.pathname;
        const urlSegments = urlPath.split("/");
        const lastSegment = urlSegments[urlSegments.length - 1];

        setResetToken(lastSegment);
    }, []);
    
  
    const handleSignup = (e: FormEvent) => {
        e.preventDefault();

        if(validPassword && validPasswordConfirmation) {

            const data: ResetPasswordInterface = { password: password, passwordConfirm: confirmPassword };

            patch<ResetPasswordInterface, any>(`users/resetPassword/${resetToken}`, data)
            .then(() => {
                setIsGoodMessage(true);
                setDisplayedMessage(`${t("resetPassowrd.passwordChanged")}`);
            })
            .catch((error) => {
                setIsGoodMessage(false);

                if(error instanceof BadRequest)
                    setDisplayedMessage(`${t("resetPassowrd.error.linkExpired")}`);
                else 
                    setDisplayedMessage(`${t("resetPassowrd.error.internalError")}`);
            
            });

            
            setTimeout(() => {
                setDisplayMessage(true);
            }, 1000);

            setPassword("");
            setValidPassword(false);

            setConfirmPassword("");
            setValidPasswordConfirmation(false);
        }
    };

    const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
      const new_password = e.target.value;
      setPassword(new_password);

      const digitsCheck = checkDigits(new_password, 1);
      const capitalizationCheck = checkCapitalization(new_password, 1);
      const numberOfCharactersCheck = checkNumberOfCharacters(new_password, 8);
      const nonCapitalizationCheck = checkNonCapitlization(new_password, 1);

      setEightSymbolsChecked(numberOfCharactersCheck);
      setBigLetterChecked(capitalizationCheck);
      setDigitChecked(digitsCheck);
      setSmallLetterChecked(nonCapitalizationCheck);

      if((confirmPassword === new_password) && (new_password.length !== 0)) setValidPasswordConfirmation(true)
      else setValidPasswordConfirmation(false);

      if(
        digitsCheck && 
        capitalizationCheck && 
        numberOfCharactersCheck && 
        nonCapitalizationCheck
      ) 
        setValidPassword(true);
      else
        setValidPassword(false);
    };

    const handleConfirmPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        const new_passwordConfirmation = e.target.value; 
        
        setConfirmPassword(new_passwordConfirmation);

        if((new_passwordConfirmation === password) && (password.length !== 0)) setValidPasswordConfirmation(true)
        else setValidPasswordConfirmation(false);
    }


    const getInputFieldState = (elementValidation: boolean, elementLength: number) => {
        if(elementValidation)  return "input--valid"
        else if( (!elementValidation) && (elementLength!== 0) ) return "input--invalid"
        else return "input--default";
    }

    const handleSubmitButtonStyle = () => {
        let buttonClass = "button";
        buttonClass += (validPassword && validPasswordConfirmation) ? "--active" : "--inactive";
        buttonClass += " button";
        return buttonClass;
    }

    const handlePasswordInputStyle = (validElement: boolean, elementTextLength: number) => {
        const base = "resetPassword-panel__form__password-input-section";
        let passwordInputClass = base + " " + base + "--short";

        if(elementTextLength !== undefined)
            if( (!validElement) && (elementTextLength !== 0) )
                passwordInputClass +=  " " + base + "--validation-active";

        return passwordInputClass;
    }
    
    const handleMainComponentClass = () => {
        const base = "resetPassword-panel";
        let finalClass = base;
        
        const passwordClosed = ( (password.length === 0) || validPassword);
        const passwordConfirmClosed = ( (confirmPassword.length === 0) || validPasswordConfirmation);

        if(passwordClosed && passwordConfirmClosed)
            finalClass += ` ${base}--everything-closed`;
        else if((!passwordClosed) && passwordConfirmClosed)
            finalClass += ` ${base}--pass-active`;
        else if(passwordClosed && (!passwordConfirmClosed))
            finalClass += ` ${base}--pass-confirm-active`;
        else
            finalClass += ` ${base}--double-pass-active`;

        return finalClass;
    }
    
    return ( 
            <div className={handleMainComponentClass()}>
                <div className="resetPassword-panel__form">
                    <Logo/>
                    <div className="resetPassword-panel__form__headline">
                        <span>{t("resetPassowrd.resetYourPassword")}</span>
                    </div>
                    <div className="resetPassword-panel__form__sign-up">
                        <span>{t("resetPassowrd.regainYourAccountAccess")} </span>
                    </div>

                    {
                        displayMessage ? <DisplayedMessage goodMessage={isGoodMessage} text={displayedMessage}/> : ""
                    }
                    
                    {
                        displayMessage ? "" :
                        <form onSubmit={handleSignup}>
                            
                            <div className={handlePasswordInputStyle(validPassword, password.length)}>
                                <label htmlFor="password">{t("resetPassowrd.newPassword")}</label>
                                <input
                                className={getInputFieldState(validPassword, password.length)}
                                type="password"
                                maxLength={15}
                                id="password"
                                value={password}
                                onChange={handlePasswordChange}
                                />

                                {
                                    alerts.map((el, index) => <PasswordLabel key={index} text={el.text} status={ el.status || (password.length === 0)} />)
                                }

                                { validPassword ? <span className="material-symbols-outlined material-symbols-outlined--valid"> done </span> : ""}    
                                { ((!validPassword) && (password.length !== 0)) ? <span className="material-symbols-outlined material-symbols-outlined--invalid">warning</span>: ""}
                            </div>
                            <div className={handlePasswordInputStyle(validPasswordConfirmation, confirmPassword.length)}>
                                <label htmlFor="password-confirm">{t("resetPassowrd.confirmNewPassword")}</label>
                                <input
                                className={getInputFieldState(validPasswordConfirmation, confirmPassword.length)}
                                type="password"
                                maxLength={15}
                                id="password-confirm"
                                value={confirmPassword}
                                onChange={handleConfirmPasswordChange}
                                />

                                <PasswordLabel text={t("signUp.passwordsNotTheSame")} status={ validPasswordConfirmation || (confirmPassword.length === 0)} />

                                { validPasswordConfirmation ? <span className="material-symbols-outlined material-symbols-outlined--valid"> done </span> : ""}    
                                { ((!validPasswordConfirmation) && (confirmPassword.length !== 0)) ? <span className="material-symbols-outlined material-symbols-outlined--invalid">warning</span>: ""}
                            </div>

                            <div className="resetPassword-panel__form__buttons">
                                <button type="submit" className={ handleSubmitButtonStyle() + " button--huge-mobile"}>{t("resetPassowrd.resetPassowrd")}</button>
                            </div>
                        </form>
                    }
                    
                </div>

                <div className="resetPassword-panel__svg-container login-panel__svg-container">
                <img src="https://na-sesje-public.s3.eu-central-1.amazonaws.com/signupGraphic.png" alt="" />
                </div>
            </div>
        );
}