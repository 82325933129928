
export const ArrowGraphic = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={24}
    fill="none"
  >
    <path
      stroke="#14181F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M7.5 12h10M13.5 8l4 4-4 4"
    />
  </svg>
)

