
export const Phone = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
  >
    <path
      stroke="#2F848F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M2.533 5.547a12.028 12.028 0 0 0 7.92 7.92c1.9.587 3.547-1.079 3.547-3.067 0-.497-.404-.894-.898-.953a7.452 7.452 0 0 1-1.843-.464l-1.14 1.14A9.037 9.037 0 0 1 5.878 5.88l1.14-1.14a7.45 7.45 0 0 1-.464-1.842C6.494 2.404 6.097 2 5.6 2 3.612 2 1.946 3.648 2.533 5.547Z"
    />
  </svg>
)

