import Latex from "react-latex";
import { ExcerciseAnswers, MainTestDto } from "../../../../../../../shared/finalTest";
import { QuizAnswer } from "../../../additions/quiz/quizTest/quizAnswers/quizAnswer/QuizAnswer";
import { Dispatch, SetStateAction } from "react";

interface QuizExcerciseAnswerProps {
    currentExcercise: number;
    label: string;
    src: string;
    srcIsImage: boolean;
    id: number;
    correctQuestionId: number;
    modifyIsFinished: (index: number, finished: boolean) => void;
    modifyExerciseAnswers: (index: number, newAnswers: ExcerciseAnswers) => void;
    testDto: MainTestDto;
    activeMobileIndex: number;
    setActiveMobileIndex: Dispatch<SetStateAction<number>>;
    currentIndex: number;
}

export const QuizExcerciseAnswer = (props: QuizExcerciseAnswerProps) => {
    const handelClickAnswer = () => {
        const answers = props.testDto.excercises[props.currentExcercise].excerciseAnswers;
        if(answers){
            if(answers.length > 0){
                return;
            }
        }
        props.modifyIsFinished(props.currentExcercise, true);   
        props.modifyExerciseAnswers(props.currentExcercise, [props.id]);
    }

    return <QuizAnswer label={props.label} src={props.src} id={0} currentIndex={props.currentIndex} correctQuestionId={0} handleClickAnswer={handelClickAnswer} srcIsImage={props.srcIsImage} activeMobileIndex={props.activeMobileIndex} setActiveMobileIndex={props.setActiveMobileIndex}/>
}