import { LandingFooter } from "../../landing/LandingFooter/LandingFooter"
import { LandingNavbar } from "../../landing/LandingNavbar/LandingNavbar"
import { NotFoundError } from "./NotFoundError"

export const NotFoundErrorUnlogged = () => {

    return (
        <>
            <LandingNavbar/>
            <NotFoundError/>
            <LandingFooter/>
        </>
    )
}