import { useContext, useEffect } from "react";
import { combinePath } from "../../../shared/utilities/combiningPath/combinePath";
import { MainContext } from "../MainContextProvider";
import { CoursePathFallback } from "../../coursePath/coursePathFallback/CoursePathFallback";

interface MainPathProps {
    isGrayedOut: boolean
}

export const MainPath = (props: MainPathProps) => {
    const ctx = useContext(MainContext);

    if(ctx.path && ctx.path.length > 0) {
        return <div id="data-panel__nav">
            {combinePath(ctx.path, props.isGrayedOut)}
         </div> 
    }
    return <CoursePathFallback/>
}