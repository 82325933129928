import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";

import React from "react";


interface PasswordLabelProps {
    text: string,
    status: boolean,
    noSign?: boolean
}

export const PasswordLabel = (props: PasswordLabelProps) => {
    const { t } = useTranslation();

 
    return (
        <>
            { (!props.status) ? <label className="signUp-panel__form__password-input-section__password-validation">{`! ${props.text}`}</label> : "" }
        </>
    )
}