import { Dispatch, SetStateAction, useEffect, useLayoutEffect, useState } from "react";

interface SingleExcerciseButtonProps {
    excerciseNumber: number;
    currentExcercise: number;
    excerciseCount: number;
    setCurrentExcercise: Dispatch<SetStateAction<number>>;
    isFinished: boolean;
    visibleIndices: number[] | undefined;
    fullBorder: boolean;
}

export const SingleExcerciseButton = (props: SingleExcerciseButtonProps) => {
    const [isActive, setIsActive] = useState(false);
    const [padding, setPadding] = useState(1);
    const [scale, setScale] = useState(0);

    useEffect(() => {
        setScale(1);
    }, [])

    useEffect(() => {
        if(props.currentExcercise === props.excerciseNumber){
            setIsActive(true);
        } else {
            setIsActive(false);
        }
    }, [props.currentExcercise])

    const handleClick = () => {
        props.setCurrentExcercise(props.excerciseNumber);
    }

    const getButtonClass = () => {
        let className = 'data-panel__mainTest__nav__buttons__button single-excercise ';

        let suffix = "";

        if(props.fullBorder) {
            className += 'full-border '
        } else {
            className += 'left-border '
        }

        if(props.isFinished && !isActive) {
            suffix = "finished ";
            return className + suffix;
        }

        if(isActive){
            suffix = 'active ';
        } else {
            suffix = 'inactive ';
        }

        return className + suffix;
    }

    const NORMAL = 15;
    const MEDIUM = 8;
    const SMALL = 5;
    const SMALLEST = 3;

    const getPadding = () => {
        if(props.visibleIndices === undefined || props.visibleIndices.length < 6){
            return NORMAL;
        }

        if((props.excerciseNumber <= 2 && props.visibleIndices[0] === 0)) {
            return NORMAL;
        }

        if(props.excerciseNumber >= props.excerciseCount-3 && props.visibleIndices[props.visibleIndices.length-1] === props.excerciseCount-1){
            return NORMAL;
        }

        if(props.excerciseNumber === props.visibleIndices[0] || props.excerciseNumber === props.visibleIndices[props.visibleIndices.length-1]){
            return SMALLEST;
        }

        if(props.excerciseNumber === props.visibleIndices[2]  || props.excerciseNumber === props.visibleIndices[props.visibleIndices.length-3]){
            return MEDIUM;
        }

        if(props.excerciseNumber === props.visibleIndices[1] || props.excerciseNumber === props.visibleIndices[props.visibleIndices.length-2]){
            return SMALL;
        }
        
        
        return NORMAL;
    }

    useEffect(() => {
        setPadding(getPadding());
    }, [props.visibleIndices, props.currentExcercise])

    const handleMouseOver = () => {
        setPadding(NORMAL);
        setScale(1.2);
    }

    const handleMouseLeave = () => {
        setPadding(getPadding());
        setScale(1);
    }

    return <span onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave} style={{paddingTop: `${padding}px`, paddingBottom: `${padding}px`}} className={getButtonClass()} onClick={handleClick}>
        {`${props.excerciseNumber + 1}`}
    </span>
}