import { useTranslation } from "react-i18next";
import "./noCoursesPanel.scss";
import { useNavigate } from "react-router-dom";

interface CoursePreviewPanelProps {
    path: string,
    text: string[],
    modifier: string
}

const CoursePreviewPanel = (props: CoursePreviewPanelProps) => {

    const handleClassName = () => {
        const base = "data-panel__empty-user-panel__courses__course";
        return `${base}${(props.modifier.length !== 0) ? ` ${base}--${props.modifier}` : ""}`;
    }

    return (
        <div className={handleClassName()}>                    
            <div className="data-panel__empty-user-panel__courses__course__img">
                <img src={props.path} alt="kurs"/>
            </div>
            
            <div className="data-panel__empty-user-panel__courses__course__wrapper">
                <div className="data-panel__empty-user-panel__courses__course__wrapper__text">
                    <span
                        className="data-panel__empty-user-panel__courses__course__wrapper__text__element data-panel__empty-user-panel__courses__course__wrapper__text__element--first">
                        {props.text[0]}
                    </span>
                    <span
                        className="data-panel__empty-user-panel__courses__course__wrapper__text__element data-panel__empty-user-panel__courses__course__wrapper__text__element--second">
                        {props.text[1]}
                    </span>
                </div>
            </div>
        </div>
    );
}

interface CoursePreviewPanelProps {
    path: string,
    text: string[],
    modifier: string
}


interface CourseProsProps {
    text: string,
    last: boolean
}

const CoursePros = (props: CourseProsProps) => {

    return (
        <div className={`data-panel__empty-user-panel__offer__pros__element${props.last ? " data-panel__empty-user-panel__offer__pros__element--last" : ""}`}>
            <div className="data-panel__empty-user-panel__offer__pros__element__dot"/>
            <span className="data-panel__empty-user-panel__offer__pros__element__text">
                {props.text}
            </span>
        </div>
    );
}

export const NoCoursesPanel = () => {
    const { i18n, t } = useTranslation();

    const navigate = useNavigate();
    
    const urlBase = "https://na-sesje-public.s3.eu-central-1.amazonaws.com/course-icons/";
    
    const coursesData = [
        { path: `${urlBase}mathematical-analysis.png`, text: [t("userPanel.noCoursesPanel.mathematicalAnalysis.el1"), t("userPanel.noCoursesPanel.mathematicalAnalysis.el2")], modifier: "" },
        { path: `${urlBase}logic-and-set-theory.png`, text: [t("userPanel.noCoursesPanel.logicAndSetTheory.el1"), t("userPanel.noCoursesPanel.logicAndSetTheory.el2")], modifier: "" },
        { path: `${urlBase}linear-algebra.png`, text: [t("userPanel.noCoursesPanel.linearAlgebra.el1"), t("userPanel.noCoursesPanel.linearAlgebra.el2")], modifier: "bottom" },
        { path: `${urlBase}discrete-mathematics.png`, text: [t("userPanel.noCoursesPanel.discreteMathematics.el1"), t("userPanel.noCoursesPanel.discreteMathematics.el2")], modifier: "additional" },
    ];

    const bulletPoints = [
        t("userPanel.noCoursesPanel.bulletPoints.b1"),
        t("userPanel.noCoursesPanel.bulletPoints.b2"),
        t("userPanel.noCoursesPanel.bulletPoints.b3")
    ];

    return (
        <div className="data-panel__empty-user-panel__wrapper">

            <div className="data-panel__empty-user-panel__courses data-panel__empty-user-panel__courses--desktop">

                <div className="data-panel__empty-user-panel__courses__text">
                    <span>
                        {t("userPanel.noCoursesPanel.mostOftenSelected")}
                    </span>
                </div>

                {
                    coursesData.map((el, index) => 
                        <CoursePreviewPanel key={index} {...el}/>)
                }
            </div>

            <div className="data-panel__empty-user-panel__offer">

                <div className="data-panel__empty-user-panel__offer__wrapper">
                    <div className="data-panel__empty-user-panel__offer__wrapper__headline">
                        <span>
                            {t("userPanel.noCoursesPanel.findYourFirstCourse")}.
                        </span>
                    </div>

                    <div className="data-panel__empty-user-panel__offer__wrapper__text">
                        <span>
                            {t("userPanel.noCoursesPanel.recordingsAccess")}.
                        </span>
                    </div>

                    <div className="data-panel__empty-user-panel__offer__wrapper__button-wrapper">
                        <div 
                            className="data-panel__empty-user-panel__offer__wrapper__button-wrapper__button" 
                            onClick={() => navigate("/shop")}
                        >
                            {t("userPanel.noCoursesPanel.searchAllCourses")}
                        </div>
                    </div>
                </div>

                <div className="data-panel__empty-user-panel__offer__pros">
                    <span className="data-panel__empty-user-panel__offer__pros__headline">
                        {t("userPanel.noCoursesPanel.whyItIsWorthIt")}?
                    </span>

                    {
                        bulletPoints.map(
                            (el, index) => <CoursePros key={index} text={el} last={index === (el.length-1)}/>
                        )
                    }

                </div>

            </div>

            <div className="data-panel__empty-user-panel__courses data-panel__empty-user-panel__courses--mobile">

                <div className="data-panel__empty-user-panel__courses__text">
                    <span>
                        {t("userPanel.noCoursesPanel.mostOftenSelected")}
                    </span>
                </div>

                {
                    coursesData.map((el, index) => 
                        <CoursePreviewPanel key={index} {...el}/>)
                }

            </div>

        </div>
    )
}