import { useContext, useEffect, useState } from "react";
import "./quiz.scss"
import "./quizTest/quizAnswers/quizAnswer/quizAnswerFallback/quizAnswerFallback.scss";
import { useTranslation } from "react-i18next";
import { QuizTest } from "./quizTest/QuizTest";
import { QuizResult } from "./quizResult/QuizResult";
import { CourseContext } from "../../CourseContextProvider";
import { QuizContext } from "./QuizContextProvider";
import { QuizesDto } from "../../../../../../shared/quizesDto";
import { get, post } from "../../../../utilities/rest/apiClient";
import { AuthContext } from "../../../auth/authContext/AuthContextProvider";
import { GlobalDataContext } from "../../../global/globalDataContext/GlobalDataContextProvider";
import { ClosedTestAnswer } from "../../../../../../shared/closedTestAnswer";
import { MainContext } from "../../main/MainContextProvider";
import { QuizTestFallback } from "./quizTest/QuizTestFallback";

export interface UserAnswer {
  questionId: number;
  userAnswerId: number;
  correctAnswerId: number;
}

interface AddClosedTestCompletionRequestBody {
  questionsAnswer: ClosedTestAnswer;
}

export const Quiz = () => {
    const quizCtx = useContext(QuizContext);
    const courseCtx = useContext(CourseContext);
    const globalCtx = useContext(GlobalDataContext);
    const { t, i18n } = useTranslation();
    const authContext = useContext(AuthContext);
    const [finished, setFinished] = useState(false);
    const [reset, setReset] = useState(false);
    const [numberOfQuestions, setNumberOfQuestions] = useState(0);
    const [userAnswers, setUserAnswers] = useState<UserAnswer[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const mainCtx = useContext(MainContext);

     //TODO do debugowania
     useEffect(() => {
      const keyDownHandler = (event: KeyboardEvent) => {
        if(event.key === 'q') {
          setIsLoading(true);
        }
      };
  
      const keyUpHandler = (event: KeyboardEvent) => {
        if(event.key === 'q') {
          setIsLoading(false);
        }
      };
  
      window.addEventListener('keydown', keyDownHandler);
      window.addEventListener('keyup', keyUpHandler);
  
      return () => {
        window.removeEventListener('keydown', keyDownHandler);
        window.removeEventListener('keyup', keyUpHandler);
      };
    }, []);

    const handleClickAnswer = async (
      userAnswerId: number,
      correctQuestionId: number) => {

        const answer: UserAnswer = {
          questionId: quizCtx.currentQuiz,
          userAnswerId: userAnswerId,
          correctAnswerId: correctQuestionId,
        }

        const isGoodAnswer = userAnswerId === correctQuestionId;

        const requestBody: AddClosedTestCompletionRequestBody = {
          questionsAnswer: {
            questionNumber: quizCtx.currentQuiz + 1,
            givenAnswer: userAnswerId + 1,
            goodAnswer: isGoodAnswer
          }
        };

        try {
          await post<any, AddClosedTestCompletionRequestBody>(`userChapters/addClosedTestCompletion/${courseCtx.getLessonId()}`, requestBody)
          if(quizCtx.currentQuiz === numberOfQuestions - 1) {
            setUserAnswers(userAnswers => [...userAnswers, answer]);
            setFinished(true);
            return;
          }
  
          if(quizCtx.currentQuiz < numberOfQuestions) {
            quizCtx.setCurrentQuiz(quizCtx.currentQuiz+1);
            setUserAnswers(userAnswers => [...userAnswers, answer]);
          }
        }
        catch(error) {
          //TODO handle error
        }

    }

    useEffect(() => {
      setFinished(false);
    }, [courseCtx.activeAddition]);

    useEffect(() => {
      setIsLoading(true);
    }, []);

    useEffect(() => {
      setIsLoading(true);
      (async ()=> {
        try {
          const lessonId = courseCtx.getLessonId();
          const data = await get<QuizesDto>(`reactRoutes/getQuizes/${lessonId}/${i18n.language}`);
          const quizProgress = (await get<Array<any>>(`userChapters/getClosedTestCompletion/${courseCtx.getLessonId()}`)).length;
          quizCtx.setQuizes(data.quizes);
          setNumberOfQuestions(data.quizes.length);
          const lessonIndex = globalCtx.isMainView ? mainCtx.active.lessonIndex : courseCtx.activeChapter;
          quizCtx.setCurrentQuiz(quizProgress);
          setFinished(courseCtx.chapters.lessons[lessonIndex].quickTestsCount === quizProgress);
           
          setIsLoading(false);
          setReset(false);

        } catch(error) {
          authContext.handleAuthErrors(error);
          //TODO obłsuga błędu
          console.log(error);
        }
      })()
    }, [mainCtx.active, courseCtx.isLoading, reset]); // experimental reset property


    useEffect(() => {
      courseCtx.setActiveAddition('quiz'); //jakby ktoś wszedł sobie przez url to zeby sie podświetliło
    }, []);

    const getQuizTest = (isLoading: boolean) => {
      return isLoading ? <QuizTestFallback/> : <QuizTest handleClickAnswer={handleClickAnswer} numberOfQuestions={numberOfQuestions}/>;
    }

    return finished ? <QuizResult isLoading={isLoading} setReset={setReset}/> : getQuizTest(isLoading);
}